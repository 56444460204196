'use strict';

angular.module('vmaxmanagerApp').controller('MenuCtrl', MenuCtrl);

MenuCtrl.$inject = ['$scope', '$rootScope', '$location', '$state', 'Auth', 'Principal', 'ENV'];

function MenuCtrl($scope, $rootScope, $location, $state, Auth, Principal, ENV) {
	var scp = $scope;

	scp.isAuthenticated = Principal.isAuthenticated;
	scp.$state = $state;
	scp.isInRole = Principal.isInRole;
	scp.inProduction = ENV === 'prod';

	var menu = jQuery('#menu').data('mmenu');
	console.log(menu)


	Principal.identity().then(function(account) {
	    $scope.account = account;
	    if($scope.account){
            if (_.isEmpty($scope.account.firstName) && _.isEmpty($scope.account.lastName)) {
	            $rootScope.userName = $scope.account.login;
            }  else {
                var firstName = _.isEmpty($scope.account.firstName) ? '' : $scope.account.firstName;
                var lastName = _.isEmpty($scope.account.lastName) ? '' : $scope.account.lastName;
                $rootScope.userName = firstName + ' ' + lastName;
            }
	    }
	});

	scp.logout = function () {
		var api = jQuery("#menu").data( "mmenu" );
		api.close();
		Auth.logout();
		$state.go('login');
	};

	scp.showMenu = function() {
		console.log($rootScope.showTitleAndMenu);
		return $rootScope.showTitleAndMenu;
	}


	scp.toggleMenu = function() {
		angular.element('.mm-slideout').toggleClass('menu-closed')
	}



}