'use strict';

angular.module('vmaxmanagerApp')
    .controller('UserManagementController', function ($scope,$rootScope,Principal, User, ParseLinks, Language, USER_ROLES) {
        $scope.users = [];
        $scope.user;
        $scope.fields = User.filterVars();

        Language.getAll().then(function (languages) {
            $scope.languages = languages;
        });

		Principal.identity().then(function(account) {
            $scope.currentAccount = account;
        });
		$scope.predicate = 'id';
		$scope.reverse = true;
        $scope.page = 0;
        $scope.loadAll = function () {

            var pageable = {page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']};

            User.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.users.push(result[i]);
                }
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.users = [];
            $scope.loadAll();
        };
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.setActive = function (user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                $scope.loadAll();
                $scope.clear();
            });
        };

        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null
            };
        };
    });
