'use strict';

angular.module('vmaxmanagerApp')
    .service('Parameters', function () {

        var REGULAR_EXPRESSION_VALIDATION_PASSWORDS = '((?=.*\\d)(?=.*[A-Za-z])(?=.*\\W).{8,32})';

        var REGULAR_EXPRESSION_VALIDATION_ALPHANUMERIC = '[A-Za-z0-9]*';

        var REGULAR_EXPRESSION_MOBILE_NUMBER = '(\\(([0-9]{3})\\) [0-9]{3} - [0-9]{4}){1,16}';

        var getRegularExpressionValidationPassword = function(){
            return REGULAR_EXPRESSION_VALIDATION_PASSWORDS;
        }

        var getRegularExpressionValidationAlphanumeric = function(){
            return REGULAR_EXPRESSION_VALIDATION_ALPHANUMERIC;
        }

        var getRegularExpressionMobileNumber = function(){
            return REGULAR_EXPRESSION_MOBILE_NUMBER;
        }

        return{
            getRegularExpressionValidationPassword:getRegularExpressionValidationPassword,
            getRegularExpressionValidationAlphanumeric:getRegularExpressionValidationAlphanumeric,
            getRegularExpressionMobileNumber:getRegularExpressionMobileNumber
        }

    });
