'use strict';

angular.module('seon.settingsConfigurator', ['seonsm/settingsConfigurator.html']);

angular.module('seon.settingsConfigurator').filter('highlight', function($sce) {
    return function(text, phrase) {
        if (phrase && text) text = text.replace(new RegExp('('+phrase+')', 'gi'), '<span class="setting-highlight">$1</span>')
            return $sce.trustAsHtml(text)
    }
});

angular.module('seon.settingsConfigurator').directive('urlExists', function($http) {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function(scope, ele, attr, ctrl) {

			function checkUrl(ngModelValue) {
				console.log(ngModelValue)
				ctrl.$setValidity('urlExistsValidator', true);

				$http.get(ngModelValue).then(function successCallback(response) {
					console.log('SUCCESS', response)
				}, function errorCallback(response) {
					console.log('ERROR', response)
				    ctrl.$setValidity('urlExists', false);
				});

				return ngModelValue;
			}

			ctrl.$parsers.unshift(checkUrl);
		}
	}
});

angular.module('seon.settingsConfigurator').directive('settingsConfigurator', settingsConfigurator);
settingsConfigurator.$inject = ['$http'];


function settingsConfigurator($http) {
	return {
		restrict: 'E',
		scope: {
			json: '=',
			config: '=',
			endpointErrorCallback: '&',
            readonly: '=?',
            notallowedcharacters: '=?'
		},
		templateUrl: function(element, attrs) {
		  return attrs.templateUrl || 'seonsm/settingsConfigurator.html';
		},
		link: function postLink(scope, element, attrs) {
			var layouts = ['wide', 'narrow']

			if (!scope.config) {scope.config = {};}

			if (!scope.config.layout || layouts.indexOf(scope.config.layout) === -1) {
				scope.config.layout = 'wide';
			}

			scope.urlPattern = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
			scope.ipPattern = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;


			scope.loadEndpoint = function(field) {
				console.log('loading endpoint', field);
				$http.get(field.url).then(function successCallback(response) {
					scope.endpoints[(field.id)] = response.data;
				}, function errorCallback(response) {
					console.log('ERROR', response);
					scope.endpoints[(field.id)] = [];
					if (scope.config.errorMessageCallback) {
						scope.config.errorMessageCallback("Unable to contact endpoint: '" + field.url + "'");
					}
				});
			}

			scope.changeSection = function(section) {
				scope.selectedSection = section;
			};

			scope.collapseSection = function(section) {
				section.collapsed = !(section.collapsed === true);
			};


			scope.validateString = function (field) {
				var maxLen = field['max-length']? field['max-length'] : 254;
				if (field.data && field.data.length > maxLen) {
					field.data = field.data.substring(0, maxLen);
				}
			}

			scope.checkNumbers = function(ev) {
				var key = ev.keyCode || ev.charCode;
				if(isNaN(String.fromCharCode(key))
					      || (String.fromCharCode(key) == "-" && field.data.toString().indexOf("-") >= -1) ) {
					ev.preventDefault();
				}
			}

			scope.validateNumber = function (field) {
				var maxValue = field.max? parseInt(field.max): 999999;
				var minValue = field.min? parseInt(field.min): -999999;
				var maxLength = maxValue.toString().length;
				if (field.data && field.data.toString().length > maxLength) {
					field.data = Number(field.data.toString().substring(0, maxLength));
				}
				if (field.max && field.data && parseInt(field.data) > maxValue) {
					field.data = maxValue;
				}
				if (field.max && field.data && parseInt(field.data) < minValue) {
					field.data = minValue;
				}
			}

			scope.checkDecimalNumbers = function(ev, field) {
				var key = ev.keyCode || ev.charCode;
				if(isNaN(String.fromCharCode(key))
					      || (String.fromCharCode(key) == "." && field.data.toString().indexOf(".") >= -1)
					      || (String.fromCharCode(key) == "-" && field.data.toString().indexOf("-") >= -1) ) {
					ev.preventDefault();
				}
			}

			scope.validateDecimalNumber = function (field) {
				var maxValue = field.max? parseFloat(field.max): 999999;
				var minValue = field.min? parseFloat(field.min): -999999;
				var maxLen = 25;
				if (field.data && field.data.toString().length > maxLen) {
					field.data = Number(field.data.toString().substring(0, maxLen));
				}
				if (field.max && field.data && parseFloat(field.data) > maxValue) {
					field.data = maxValue;
				}
				if (field.max && field.data && parseFloat(field.data) < minValue) {
					field.data = minValue;
				}
			}

			scope.preventEnter = function(event) {
				var key = event.keyCode || event.charCode;
				if(key == 13) {
					event.preventDefault();
					return false;
				}
			};

			scope.$watch('json', function(newVal, oldVal) {
				if (newVal) {
					scope.json = newVal;
					newVal && (scope.selectedSection = scope.json[0]);
					scope.search = '';
					angular.forEach(scope.json, function(section) {
						section.resultFound = false;
						angular.forEach(section.fields, function(field) {
							field.matchesSearch = false;
						});
					});
				}
			});

			scope.$watch('config.layout', function(newVal) {
				console.log(newVal);
				scope.config.layout = (layouts.indexOf(newVal) === -1? 'wide' : newVal);
			});


			scope.$watch('search', function(s) {
				scope.endpoints = {};
				if (s !== '' && s !== undefined) {
					var str = s.toLowerCase();
					angular.forEach(scope.json, function(section, idx) {
						section.resultFound = false;
						angular.forEach(section.fields, function(field, i) {
							field.matchesSearch = (field.label.toLowerCase().indexOf(str) > -1 || field.tooltip.toLowerCase().indexOf(str) > -1);
							section.resultFound = (section.resultFound || field.matchesSearch);
						});
					});
				}
				else {
					angular.forEach(scope.json, function(section, idx) {
						section.resultFound = false;
					});
				}
			});

			scope.translatedString = function(str) {
				if (!str || str.length > 100 || !scope.config || !scope.config.translations || !scope.config.translations[str] ) { return str; }
				return scope.config.translations[str];
			}


			scope.timezones = [];
			_.forEach( moment.tz.names(), function(timezone) {
			    var timezoneId =  timezone;
			    var zone = moment.tz(timezone).format("Z");
			    var timezoneDescription = "(GTM" + zone + ") - " + timezoneId;
			    scope.timezones.push({name: timezoneId, description: timezoneDescription});
			});
            
            scope.checkReadOnly = function(field) {
                var isReadOnlyField = false;
				if (scope.readonly !== '' && scope.readonly !== undefined && scope.readonly !== null) {
                    if (scope.readonly == true) {
                        isReadOnlyField = true;
                    }   
                }
                if (field.isreadonly !== '' && field.isreadonly !== undefined && field.isreadonly !== null && isReadOnlyField === false) {
                    if (field.isreadonly == true) {
                        isReadOnlyField = true;
                    }   
                }
                return isReadOnlyField;
			};
            
            scope.checkValidCharacters = function(ev) {
                var isNotAllowedCharacter = false;
                if (scope.notallowedcharacters !== '' && scope.notallowedcharacters !== undefined && scope.notallowedcharacters !== null) {
                    if (_.isArray(scope.notallowedcharacters)) {
                        scope.notallowedcharacters.forEach(function(itemChar){
                            if (ev.charCode === itemChar.charCodeAt(0)) {
                                isNotAllowedCharacter = true;
                            }
                        });
                    }
                }
                
                if (isNotAllowedCharacter === true) {
                    ev.preventDefault();
                }
            };
            
            scope.setInternalValue = function(field) {
                var key = field.key;
                var seletedItem = field.options.filter(function(option){
                    return option.key === key;
                });
                if (seletedItem) {                
                    field.data = seletedItem[0].value; 
                }
            };
            
		}
	};
}


angular.module("seonsm/settingsConfigurator.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("seonsm/settingsConfigurator.html",
    "<div class=\"settings-container\" ng-class=\"config.layout\">\n" +
    "\n" +
    "    <div class=\"form-inline col-xs-12\">\n" +
    "        <div class=\"form-group pull-right\">\n" +
    "            <label for=\"settingsSearch\"> {{translatedString('Search')}}: </label>\n" +
    "            <input id=\"settingsSearch\" type=\"text\" class=\"search form-control\" ng-model=\"search\" ng-keydown=\"preventEnter($event)\">\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"sections-panel\" ng-show=\"config.layout == 'wide'\">\n" +
    "        <ul class=\"\">\n" +
    "            <li ng-repeat=\"section in json\" ng-class=\"{'active': selectedSection == section, 'result-found' : section.resultFound}\" ng-click=\"changeSection(section)\">\n" +
    "                <div>  <span ng-bind-html=\"translatedString(section.name) | highlight:search\"></span></div>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "    <div class=\"fields-panel\" ng-class=\"{'collapsed' : section.collapsed, 'result-found' : (config.layout === 'narrow' && section.resultFound)}\" ng-repeat=\"section in json\" ng-show=\"config.layout == 'narrow' || section === selectedSection\">\n" +
    "        <div class=\"section-title\" ng-show=\"config.layout === 'narrow'\"  ng-click=\"collapseSection(section)\">\n" +
    "            <div><span ng-bind-html=\"translatedString(section.name) | highlight:search\"></span></div>\n" +
    "        </div>\n" +
    "        <div class=\"settings-input-group\" ng-repeat=\"field in section.fields\" ng-hide=\"search && !field.matchesSearch\" ng-form name=\"jsonInputForm\">\n" +
    "            <div class=\"form-group\" ng-class=\"config.inputGroupClass\" ng-switch=\"field.type\">\n" +
    "                <label ng-class=\"config.labelClass\">\n" +
    "                    <span class=\"text-danger\" ng-show=\"field.required\">* </span>\n" +
    "                    <span ng-bind-html=\"translatedString(field.label) | highlight:search\"></span>\n" +
    "                </label>\n" +
    "\n" +
    "                <input ng-switch-when=\"text\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" type=\"text\" class=\"form-control\" ng-class=\"config.inputClass\"\n" +
    "                       ng-model=\"field.data\" ng-required=\"field.required\" placeholder=\"{{field['default-value']}}\" ng-change=\"validateString(field)\" ng-readonly=\"checkReadOnly(field)\" ng-keypress=\"checkValidCharacters($event)\"/>\n" +
    "                \n" +
    "                <textarea  ng-switch-when=\"notMappedParameters\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" ng-model=\"field.data\" ng-required=\"field.required\" cols=\"{{field.cols}}\" \n" +
    "                       rows=\"{{field.rows}}\" placeholder=\"{{field['default-value']}}\" ng-readonly=\"checkReadOnly(field)\"/>                   \n" +
    "\n" +
    "                <input ng-switch-when=\"ip-address\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\"  type=\"text\" class=\"form-control\" ng-class=\"config.inputClass\"\n" +
    "                       ng-model=\"field.data\" ng-required=\"field.required\" ng-pattern=\"ipPattern\" placeholder=\"{{field['default-value']}}\" ng-readonly=\"readonly===true\"/>\n" +
    "\n" +
    "                <input ng-switch-when=\"number\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" type=\"text\" class=\"form-control\" ng-class=\"config.inputClass\"\n" +
    "                       ng-model=\"field.data\" ng-required=\"field.required\" min=\"{{field.min && field.min || '0'}}\" limit-to=\"{{field.max && field.max.toString().length || '4'}}\"\n" +
    "                       placeholder=\"{{field['default-value']}}\" ng-keypress=\"checkNumbers($event)\" ng-change=\"validateNumber(field)\" ng-readonly=\"readonly===true\"/>\n" +
    "\n" +
    "                <input ng-switch-when=\"number-decimal\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" type=\"number\" class=\"form-control\" ng-class=\"config.inputClass\"\n" +
    "                       ng-model=\"field.data\" ng-required=\"field.required\" step=\"0.01\" min=\"{{field.min && field.min || '0'}}\" limit-to=\"{{field.max && field.max.toString().length + 3 || '7'}}\"\n" +
    "                       placeholder=\"{{field['default-value']}}\" ng-keypress=\"checkDecimalNumbers($event, field)\" ng-change=\"validateDecimalNumber(field)\" ng-readonly=\"readonly===true\"/>\n" +
    "\n" +
    "                <input ng-switch-when=\"email\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" type=\"email\" class=\"form-control\" ng-class=\"config.inputClass\"\n" +
    "                       ng-model=\"field.data\" ng-required=\"field.required\" ng-maxlength=\"254\" placeholder=\"{{field['default-value']}}\" ng-readonly=\"readonly===true\"/>        \n" +
    "                \n" +
    "                <input ng-switch-when=\"url\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" type=\"text\" name=\"url-{{field.id}}\" class=\"form-control\" ng-class=\"config.inputClass\"\n" +
    "                       ng-model=\"field.data\" ng-required=\"field.required\" placeholder=\"{{field['default-value']}}\" ng-pattern=\"urlPattern\" ng-model-options=\"{ debounce: 500 }\" url-exists ng-readonly=\"readonly===true\"/>\n" +
    "\n" +
    "                <select ng-switch-when=\"select\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" class=\"form-control\" ng-model=\"field.data\" ng-class=\"config.selectClass\"\n" +
    "                        ng-options=\"option as option for option in field.options\" ng-required=\"field.required\" ng-disabled=\"readonly===true\"></select>\n" +
    "\n" +
    "                <select ng-switch-when=\"key-value-select\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" class=\"form-control\" ng-class=\"config.selectClass\" ng-model=\"field.data\"\n" +
    "                        ng-options=\"option.key as option.value for option in field.options\" ng-required=\"field.required\" ng-disabled=\"readonly===true\"></select>\n" +
    "                \n" +
    "                <select ng-switch-when=\"internal-key-value-select\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" class=\"form-control\" ng-class=\"config.selectClass\" ng-model=\"field.key\"\n" +
    "                        ng-options=\"option.key as option.key for option in field.options\" ng-required=\"field.required\" ng-disabled=\"readonly===true\" ng-change=\"setInternalValue(field)\"></select>\n" +
    "\n" +
    "                <select ng-switch-when=\"timezone\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" class=\"form-control\" ng-class=\"config.selectClass\" ng-model=\"field.data\"\n" +
    "                        ng-options=\"timezone.name as timezone.description for timezone in timezones\" ng-required=\"field.required\" placeholder=\"{{field['default-value']}}\" ng-disabled=\"readonly===true\"></select>\n" +
    "\n" +
    "                <select ng-switch-when=\"keyvalue-endpoint\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" class=\"form-control\" ng-class=\"config.selectClass\" ng-model=\"field.data\"\n" +
    "                        ng-options=\"endpointOption[(field.options.key)] as endpointOption[(field.options.value)] for endpointOption in endpoints[field.id]\" ng-required=\"field.required\" placeholder=\"{{field['default-value']}}\" ng-init=\"loadEndpoint(field)\" ng-disabled=\"readonly===true\"></select>\n" +
    "\n" +
    "                <div ng-switch-when=\"checkbox\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" class=\"checkbox\">\n" +
    "                    <label> <input type=\"checkbox\" ng-model=\"field.data\" ng-required=\"field.required\"  ng-class=\"config.checkboxClass\" ng-readonly=\"readonly===true\"> </label>\n" +
    "                </div>\n" +
    "\n" +
    "                <div ng-switch-default class=\"alert alert-danger\">Unsupported field type: \"{{field.type}}\"</div>\n" +
    "\n" +
    "                <!-- errors  -->\n" +
    "                <ul class=\"json-input-errors\">\n" +
    "                    <li ng-class=\"config.errorTextClass\" ng-show=\"jsonInputForm[('jsonName' + field.id)].$error.pattern\"> {{translatedString('PATTERN_ERROR')}}</li>\n" +
    "                    <li ng-class=\"config.errorTextClass\" ng-show=\"jsonInputForm[('jsonName' + field.id)].$error.required\"> {{translatedString('REQUIRED_ERROR')}}</li>\n" +
    "                    <li ng-class=\"config.errorTextClass\" ng-show=\"jsonInputForm[('jsonName' + field.id)].$error.maxLength\"> {{translatedString('MAX_LENGTH_ERROR')}} ({{field.max}})</li>\n" +
    "                    <li ng-class=\"config.errorTextClass\" ng-show=\"jsonInputForm[('jsonName' + field.id)].$error.minLength\"> {{translatedString('MIN_LENGTH_ERROR')}} ({{field.min}})</li>\n" +
    "                    <li ng-class=\"config.errorTextClass\" ng-show=\"jsonInputForm[('jsonName' + field.id)].$error.urlExists\"> {{translatedString('URL_EXISTS_ERROR')}}</li>\n" +
    "                </ul>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"field-description\" ng-switch=\"!!field['default-value']\">\n" +
    "                <div ng-switch-when=\"true\" class=\"text-container\"><span ng-bind-html=\"(field.tooltip + '<br><b>Default:</b> ' + field['default-value']) | highlight:search\"></span> </div>\n" +
    "                <div ng-switch-default class=\"text-container\"><span ng-bind-html=\"(field.tooltip) | highlight:search\"></span> </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>");
}]);
