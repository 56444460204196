'use strict';

angular.module('vmaxmanagerApp').controller('SoftwareLicenseDialogController',
    ['$scope', '$stateParams', 'entity', 'SoftwareLicense', 'SoftwareGroup', 'LicenseVersionEnum', '$state',
        function($scope, $stateParams, entity, SoftwareLicense, SoftwareGroup, LicenseVersionEnum, $state) {

        $scope.softwareLicense = entity;
        $scope.softwaregroups = SoftwareGroup.query();
        $scope.licenseVersionEnum = LicenseVersionEnum.query();
        $scope.load = function(id) {
            SoftwareLicense.get({id : id}, function(result) {
                $scope.softwareLicense = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:softwareLicenseUpdate', result);
            $state.go('softwareLicense');
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.softwareLicense.id != null) {
                SoftwareLicense.update($scope.softwareLicense, onSaveSuccess, onSaveError);
            } else {
                SoftwareLicense.save($scope.softwareLicense, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $state.go('softwareLicense');
        };
}]);
