'use strict';

angular.module('vmaxmanagerApp')
    .factory('DeviceSettingsRequestAudit', function ($resource, DateUtils) {
        return $resource('api/deviceSettingsAudit/findLocalDateTime/:id', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    var info = {}
                    info.localDate = data;
                    return info;
                }
            }
        });
    });
