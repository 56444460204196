'use strict';

angular.module('vmaxmanagerApp')
.factory('ExportService', function () {

    var myStyle = {};

    var setStyle = function(sheetName, bookName){
        myStyle = {
            sheetid: sheetName,
            headers: true,
            caption: {
                title: bookName
            }
        };
    };

    var getStyle = function(){
        return myStyle;
    };

    var exportToExcel = function (filename, style, data) {
        return alasql('SELECT * INTO XLS("'+filename+'.xls",?) FROM ?',[style, data]);
    };

    return {
        setStyle : setStyle,
        getStyle : getStyle,
        exportToExcel : exportToExcel
    }
})
.service('MapServerToExport', function() {

    this.convert = function(serves){

        var ser = [];

        _.forEach(serves, function(serve) {
            var newServe = {
                "Name": serve.name ? serve.name : "",
                "Url": serve.url ? serve.url : "",
                "IP Address": serve.ipAddress ? serve.ipAddress : ""
            };

            ser.push(newServe);
        });

        return ser;
    }
})
.service('MapCustomerToExport', function() {

    this.convert = function(customers){

        var cus = [];

        _.forEach(customers, function(customer) {
            var newCustomer = {
                "Name": customer.name ? customer.name : "",
                "Description": customer.description ? customer.description : ""
            };

            cus.push(newCustomer);
        });

        return cus;
    }
})
.service('MapFleetToExport', function() {

    this.convert = function(fleets){

        var fle = [];


        _.forEach(fleets, function(fleet) {
            var newFleet = {
                "Fleet Id": fleet.fleetId ? fleet.fleetId : "",
                "Deployment Type": fleet.deploymentType ? fleet.deploymentType : "",
                "Customer": fleet.customer.name ? fleet.customer.name : "",
                "Server": fleet.server? fleet.server.name : "",
                "Sales Region": fleet.salesRegion ? fleet.salesRegion.name : ""
            };

            fle.push(newFleet);
        });

        return fle;
    }
})
.service('MapLicenseToExport', function($filter) {

    this.convert = function(licenses){

        var lic = [];

        _.forEach(licenses, function(license) {
            var newLicense = {
                "License No": license.id ? license.id : "",
                "Software": license.slotType && license.slotType.software && license.slotType.software.name ? license.slotType.software.name : "",
                "Deploy": license.fleet && license.fleet.deploymentType ? license.fleet.deploymentType : "",
                "Fleet": license.fleet && license.fleet.fleetId ? license.fleet.fleetId : "",
                "Slot Type": license.slotType && license.slotType.slotType ? license.slotType.slotType : "",
                "Slots": license.slots ? license.slots : 0,
                "Slot Used": license.slotUsed ? license.slotUsed : 0,
                "Expires": license.expiryDate ? $filter('date')(license.expiryDate, 'MM-dd-yyyy') : "",
                "Sales Order": license.salesOrder ? license.salesOrder : "",
                "Prev Sales Order": license.previousSalesOrder ? license.previousSalesOrder : "",
                "Status": license.licenseStatus ? license.licenseStatus : "",
                "Charge Type": license.chargeType ? license.chargeType : "",
                "Software Version": license.softwareLicenses && license.softwareLicenses.softwareVersion ? license.softwareLicenses.softwareVersion : ""
            };

            lic.push(newLicense);
        });

        return lic;
    }
})
.service('MapSalesRegionToExport', function() {

    this.convert = function(salesRegions){

        var saleRegion = [];

        _.forEach(salesRegions, function(salesRegion) {
            var newSalesRegion = {
                "Name": salesRegion.name ? salesRegion.name : "",
                "Sales Representative": salesRegion.salesRepresentative ? salesRegion.salesRepresentative : "",
                "Email": salesRegion.emails ? salesRegion.emails : ""
            };

            saleRegion.push(newSalesRegion);
        });

        return saleRegion;
    }
})
.service('MapDeviceToExport', function($filter) {

    this.convert = function(devices){

        var dev = [];

        _.forEach(devices, function(device) {
            var newDevice = {
                "Provider ID": device.providers && device.providers.id ? device.providers.id : "",
                "Serial Number": device.serialNumber ? device.serialNumber : "",
                "Device ID": device.deviceId ? device.deviceId : "",
                "Sim ID": device.simId ? device.simId : "",
                "Fleet": device.fleet ? device.fleet.fleetId : "",
                "Device Type": device.deviceType && device.deviceType.name ? device.deviceType.name : "",
                "Provider": device.providers && device.providers.name ? device.providers.name : "",
                "Sales Order": device.salesOrder ? device.salesOrder : "",
                "Expiration Date": device.expirationDate ? $filter('date')(device.expirationDate, 'MM-dd-yyyy') : "",
                "Provision Date": device.provisionDate ? $filter('date')(device.provisionDate, 'MM-dd-yyyy') : "",
                "Is Import": device.isImport ? device.isImport : "",
                "Status": device.status ? device.status : ""
            };

            dev.push(newDevice);
        });

        return dev;
    }
})
.service('MapDeviceTypeToExport', function($filter) {

    this.convert = function(deviceTypes){

        var devType = [];

        _.forEach(deviceTypes, function(deviceType) {
            var newDeviceType = {
                "Name": deviceType.name ? deviceType.name : "",
                "Part Id": deviceType.partId ? deviceType.partId : "",
                "Last Synchronization Date": deviceType.lastSynchronizationDate ? $filter('date')(deviceType.lastSynchronizationDate, 'MM-dd-yyyy-HH-mm') : ""
            };

            devType.push(newDeviceType);
        });

        return devType;
    }
})
.service('MapSlotTypeToExport', function($filter) {

    this.convert = function(slotTypes){

        var slotTyp = [];

        _.forEach(slotTypes, function(slotType) {
            var newSlotType = {
                "Slot Type": slotType.slotType ? slotType.slotType : "",
                "Software": slotType.software && slotType.software.name ? slotType.software.name : "",
                "Feature": slotType.feature ? slotType.feature : "",
                "Requires Data": slotType.requiresData ? slotType.requiresData : false,
                "Requires Slot": slotType.requiresSlot ? slotType.requiresSlot : false,
                "Requires Device": slotType.requiresDevice ? slotType.requiresDevice : false,
                "Requires Software Version": slotType.requiresSoftwareVersion ? slotType.requiresSoftwareVersion : false,
                "Software License": slotType.softwareLicense && slotType.softwareLicense.softwareVersion ? slotType.softwareLicense.softwareVersion : ""
            };

            slotTyp.push(newSlotType);
        });

        return slotTyp;
    }
})
.service('MapProvidersToExport', function() {

    this.convert = function(providers){

        var prov = [];

        _.forEach(providers, function(provider) {
            var newProvider = {
                "Name": provider.name ? provider.name : ""
            };

            prov.push(newProvider);
        });

        return prov;
    }
})
.service('MapSoftwareToExport', function() {

    this.convert = function(softwares){

        var sw = [];

        _.forEach(softwares, function(software) {
            var newSoftware = {
                "Name": software.name ? software.name : "",
                "Cloud": software.cloud ? software.cloud : false,
                "Local": software.local ? software.local : false,
                "Software Group": software.softwareGroup && software.softwareGroup.name ? software.softwareGroup.name : ""
            };

            sw.push(newSoftware);
        });

        return sw;
    }
})
.service('MapSoftwareLicenseToExport', function() {

    this.convert = function(softwareLicenses){

        var swLicense = [];

        _.forEach(softwareLicenses, function(softwareLicense) {
            var newSoftwareLicense = {
                "Software Version": softwareLicense.softwareVersion ? softwareLicense.softwareVersion : "",
                "License Version": softwareLicense.licenseVersion ? softwareLicense.licenseVersion : "",
                "Software Group": softwareLicense.softwareGroup && softwareLicense.softwareGroup.name ? softwareLicense.softwareGroup.name : ""
            };

            swLicense.push(newSoftwareLicense);
        });

        return swLicense;
    }
})
.service('MapSettingToExport', function() {

    this.convert = function(settings){

        var set = [];

        _.forEach(settings, function(setting) {
            var newSetting = {
                "Name": setting.name ? setting.name : "",
                "Settings Type": setting.settingsTemplate && setting.settingsTemplate.typeEntitySettingsEnum ?
                                    setting.settingsTemplate.typeEntitySettingsEnum : "",
                "Software Group": setting.settingsTemplate && setting.settingsTemplate.softwareGroup && setting.settingsTemplate.softwareGroup.name ?
                                    setting.settingsTemplate.softwareGroup.name : "",
                "Device Type": setting.settingsTemplate && setting.settingsTemplate.deviceType && setting.settingsTemplate.deviceType.name ?
                                    setting.settingsTemplate.deviceType.name : ""
            };

            set.push(newSetting);
        });

        return set;
    }
})
.service('MapUserToExport', function() {

    this.convert = function(users){

        var u = [];

        _.forEach(users, function(user) {
            var newUser = {
                "Login": user.login ? user.login : "",
                "First name": user.firstName ? user.firstName : "",
                "Last name": user.lastName ? user.lastName : "",
                "Email": user.email ? user.email : "",
                "Roles": user.authorities ? user.authorities.toString() : "",
                "Status": user.activated ? user.activated : false
            };

            u.push(newUser);
        });

        return u;
    }
})
.service('MapAppResourceToExport', function() {

    this.convert = function(appResources){

        var appRes = [];

        _.forEach(appResources, function(appResource) {
            var newAppResources = {
                "Name": appResource.name ? appResource.name : "",
                "Url": appResource.url ? appResource.url : "",
                "Max Downloads": appResource.maxDownloads ? appResource.maxDownloads : "",
                "Resource Type": appResource.resourceTypeEnum ? appResource.resourceTypeEnum : "",
                "Restriction Download Type": appResource.restrictionDownloadTypeEnum ? appResource.restrictionDownloadTypeEnum : "",
                "Device Type": appResource.deviceType && appResource.deviceType.name ? appResource.deviceType.name : ""
            };

            appRes.push(newAppResources);
        });

        return appRes;
    }
})
.service('MapAppResourceUserProfileToExport', function() {

    this.convert = function(appResourceUserProfiles){

        var newAppResourceUserProfiles = [];

        _.forEach(appResourceUserProfiles, function(appResourceUserProfile) {
            var newAppResourceUserProfile = {
                "Name": appResourceUserProfile.name ? appResourceUserProfile.name : "",
                "Device Type": appResourceUserProfile.deviceType ? appResourceUserProfile.deviceType.name : ""
            };

            newAppResourceUserProfiles.push(newAppResourceUserProfile);
        });

        return newAppResourceUserProfiles;
    }
})
.service('MapDownloadResourceAuditToExport', function($filter) {

    this.convert = function(downloadResourceAudits){

        var newDownloadResourceAudits = [];

        _.forEach(downloadResourceAudits, function(downloadResourceAudit) {
            var newDownloadResourceAudit = {
                "Download Date": downloadResourceAudit.downloadDate ? $filter('date')(downloadResourceAudit.downloadDate, 'MM-dd-yyyy hh:mm:ss') : "",
                "Serial Number": downloadResourceAudit.serialNumber ? downloadResourceAudit.serialNumber : "",
                "Type Name": downloadResourceAudit.typeName ? downloadResourceAudit.typeName : "",
                "Resource Name": downloadResourceAudit.appResourceName ? downloadResourceAudit.appResourceName : "",
                "Resource Type": downloadResourceAudit.appResourceType ? downloadResourceAudit.appResourceType : "",
                "Status": downloadResourceAudit.status ? downloadResourceAudit.status : "",
                "Comments": downloadResourceAudit.comments ? downloadResourceAudit.comments : ""
            };

            newDownloadResourceAudits.push(newDownloadResourceAudit);
        });

        return newDownloadResourceAudits;
    }
});
