'use strict';

angular.module('vmaxmanagerApp')
    .controller('LoginController', function ($rootScope, $scope, $state, $timeout, Auth, Principal) {
        $scope.user = {};
        $scope.errors = {};

        $scope.rememberMe = true;
        $timeout(function (){angular.element('[ng-model="username"]').focus();});
        $scope.login = function (event) {
            event.preventDefault();
            Auth.login({
                username: $scope.username,
                password: $scope.password,
                rememberMe: $scope.rememberMe
            }).then(function () {
                $scope.authenticationError = false;
                $scope.authenticationActivatedError = false;
                Principal.identity().then(function(account) {
                	$scope.account = account;
                	if(account.passwordChange){
                		$scope.password = '';
                		$('#changePassword').modal('show');
                		$('#changePassword').on('hidden.bs.modal', function () {
	                		if($scope.success){
	                			$scope.redirect();
	                		}else{
	                			$scope.success = false;
	                			Auth.logout();
	                		}
                		});
                	}else{
                		$scope.redirect();
                	}
                });
            }).catch(function (response) {
            	if(response.data.error_description && response.data.error_description=='NoActivated'){
            		$scope.authenticationActivatedError = true;
            		$scope.authenticationError = false;
            	}else{
            		$scope.authenticationError = true;
            		$scope.authenticationActivatedError = false;
            	}
            });
        };
        
        $scope.redirect = function(){
        	$state.go('fleet');
       };
  });
