'use strict';

angular.module('vmaxmanagerApp')
    .controller('FleetDetailController', function ($scope, $rootScope, $stateParams, entity, Fleet, Server, Customer, SalesRegion) {
        $scope.fleet = entity;
        $scope.load = function (id) {
            Fleet.get({id: id}, function(result) {
                $scope.fleet = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:fleetUpdate', function(event, result) {
            $scope.fleet = result;
        });
        $scope.$on('$destroy', unsubscribe);
 });
