(function() {
    'use strict';
    angular
        .module('vmaxmanagerApp')
        .factory('SettingsInstance', SettingsInstance);

    SettingsInstance.$inject = ['$resource'];

    function SettingsInstance ($resource) {
        var resourceUrl =  'api/settings-instances/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getSettingsInstanceByTypeSettingsAndAndSoftwareGroup':
                {
                    method:'GET',
                    isArray: true,
                    url:'api/getSettingsInstanceByTypeSettingsAndAndSoftwareGroup?typeSettings=:typeSettings&softwareGroup=:softwareGroup'
                },
              'getSettingsInstanceByDeviceType': 
              {
                	method:'GET',
                	isArray: true,
                	url:'api/getSettingsInstanceByDeviceType?deviceType=:deviceType'
             }, 
            'existSettingsInstanceAppliedToTypeSettingsId':
            {
                method:'GET',
                isArray: false,
                url:'api/existSettingsInstanceAppliedToTypeSettingsId?typeSettings=:typeSettings&id=:id'
            }
        });
    }
})();
