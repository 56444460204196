    "use strict";
    angular.module("vmaxmanagerApp")
        .constant("BulkConstants", {
            "device" : {
                "provider" : "provider",
                "deviceId" : "deviceId",
                "providerId" : "providerId",
                "simId" : "simId",
                "ipAddress" : "ipAddress",
                "provisionDate" : "provisionDate",
                "status" : "status",
                "notes" : "notes",
                "radioTypes" : {
                    "manual" : "manual",
                    "file" : "fromFile"
                }
            },
            "errors" : {
                "minimumTwoRows" : "The file minimum must have two rows",
                "minimumTwoColumns" : "The header minimum must have two columns",
                "noEndCommaSeparator" : "The header must not end with a comma separator",
                "noEmptyValueBetweenCommas" : "The header must not get an empty value between commas separator",
                "fileTypeError" : "The file must be CSV (Comma-Separated Values) type",
                "noSerialNumberSelected" : "There is not Serial Number selected",
                "noDevicesSelected" : "You must selected at least one device"
            }
        });
