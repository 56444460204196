'use strict';
angular.module('vmaxmanagerApp').factory('SlotTypeEnum', function($resource) {
    return $resource('app/rest/slotTypeEnum/:id', {}, {
        'query' : {
            method : 'GET',
            isArray : true
        },
        'get' : {
            method : 'GET'
        }
    });
});
