'use strict';

angular.module('vmaxmanagerApp').controller('Ds2Controller',
    ['$scope', '$state', '$q', 'AppDownloadsService', 'AppResourceDownloadService', 'deviceType', '$window', 'UrlService',
        function($scope, $state, $q, AppDownloadsService, AppResourceDownloadService, deviceType, $window, UrlService) {
            $scope.captchaResponse = null;
            $scope.showResponseContainer = false;
            $scope.appResourcesList = null;
            $scope.deviceTypeId = null;
            $scope.hasProfiles = null;
            $scope.selectedProfile = null;
            $scope.profiles = null;
            $scope.appResourceUserProfile = null;
            $scope.deviceType = deviceType;

            $q.all([$scope.deviceType.$promise]).then(function() {
                if($scope.deviceType.id){
                    $scope.deviceTypeId = $scope.deviceType.id;
                    $scope.profiles = AppDownloadsService.getProfilesByDeviceTypeId({deviceTypeId: $scope.deviceTypeId});
                    $q.all([$scope.profiles.$promise]).then(function() {
                        if ($scope.profiles.length > 0) {
                            $scope.hasProfiles = true;
                        }
                    });
                }
            });

            $scope.changeProfile = function () {
                if ($scope.selectedProfile != null) {
                    $scope.showResponseContainer = false;
                    $scope.appResourcesList = null;
                }
            };

            $scope.serialNumberChange = function () {
                $scope.showResponseContainer = false;
                $scope.appResourcesList = null;
            };

            $scope.onSuccess = function (result){
                $scope.appResourcesList = result
                $scope.showResponseContainer = true;
                $scope.captchaResponse = null;
                grecaptcha.reset();
                $scope.stopDownloads = false;
            };

            $scope.onError = function (result){
                $scope.showResponseContainer = false;
                $scope.captchaResponse = null;
                grecaptcha.reset();
                $scope.stopDownloads = false;
            };

            $scope.buildAuditEntity = function (status) {
                var auditEntity = {
                    serialNumber: $scope.serialNumber,
                    appResourceId: $scope.currentAppResource.resourceId,
                    profileId: $scope.selectedProfile ? $scope.selectedProfile.id : null,
                    status: status
                }

                return auditEntity;
            };

            $scope.onDownloadSuccess = function (headers){
                var willBeDownloadable = headers['x-willbedownloadable'];
                if (willBeDownloadable != null && willBeDownloadable != undefined) {
                    if (willBeDownloadable == false || willBeDownloadable == "false") {
                        if ($scope.appResourcesList != null) {
                            $scope.appResourcesList.forEach(function(resource){
                                if (resource.resourceId == $scope.currentAppResource.resourceId) {
                                    resource.downloadable = false;
                                }
                            });
                        }
                    }
                }

                var auditEntity = $scope.buildAuditEntity('COMPLETED');
                AppDownloadsService.updateDownloadsAudit(auditEntity);
                $scope.stopDownloads = false;
            };

            $scope.onDownloadError = function () {
                var auditEntity = $scope.buildAuditEntity('ERROR');
                AppDownloadsService.updateDownloadsAudit(auditEntity);
                $scope.stopDownloads = false;
            };

            $scope.search = function() {
                var downloadRequest = {
                        serialNumber: $scope.serialNumber,
                        typeId: $scope.deviceTypeId,
                        captchaResponse: $scope.captchaResponse,
                        hasProfile: $scope.hasProfiles ? $scope.hasProfiles : false,
                        profileId: $scope.hasProfiles? $scope.selectedProfile.id : 0
                    };
                AppDownloadsService.getResourcesBySerialNumber(downloadRequest, $scope.onSuccess, $scope.onError);
            };

            var onSuccessDownloadValidation = function(resource){
                return function(resultPromise) {
                    resource.downloadable = resultPromise.downloadable;
                };
            }

            $scope.downloadResource = function(appResource) {
                // Remove the alerts.
                $scope.alerts = [];

                // Identify the profile id.
                var profileId = $scope.selectedProfile ? $scope.selectedProfile.id : null;

                // Do the download petition.
                $scope.currentAppResource = appResource;
                if (appResource.downloadable) {
                    if(appResource.downloadType === "S3"){
                        $scope.stopDownloads = true;
                        AppResourceDownloadService.downloadFile('api/downloads/' + appResource.id + '/' + appResource.resourceId
                        + '/' + appResource.resourceId, $scope.onDownloadSuccess, $scope.onDownloadError);
                    }else{
                        if(appResource.url && UrlService.validURL(appResource.url)){
                            var auditEntity = $scope.buildAuditEntity('COMPLETED');
                            AppDownloadsService.updateDownloadsAudit(auditEntity);
                            $window.location.href = appResource.url;
                            AppDownloadsService.getDownloadableValidation({serialNumber : $scope.serialNumber, resourceId : appResource.resourceId}, onSuccessDownloadValidation(appResource));
                        }else{
                            var auditEntity = $scope.buildAuditEntity('ERROR');
                            AppDownloadsService.updateDownloadsAudit(auditEntity);
                        }
                    }
                }
            };
}]);
