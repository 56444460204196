'use strict';

angular.module('vmaxmanagerApp')
.factory('BulkService', function (BulkConstants) {

    var fileContent = {};
    var fileHeaders = [];
    var devicesSelected = [];
    var responseConfirmation = [];
    var devicesToUpdate = [];
    var serialNumber = null;
    var operationDetailFromFile = {};

    var resetAll = function () {
        fileContent = {};
        fileHeaders = [];
        responseConfirmation = [];
        devicesToUpdate = [];
        serialNumber = null;
        operationDetailFromFile = {};
    }

    var setFileContent = function(content){
        fileContent = content;
    }

    var getFileContent = function(){
        return fileContent;
    }

    var resetFileContent = function(){
        fileContent = {};
    }

    var setFileHeader = function(header){
        fileHeaders = header;
    }

    var getFileHeader = function(){
        return fileHeaders;
    }

    var resetFileHeader = function(){
        fileHeaders = [];
    }

    var setDevicesSelected = function(deviceSelectedList){
        devicesSelected = deviceSelectedList;
    }

    var getDevicesSelected = function(){
        return devicesSelected;
    }

    var resetDevicesSelected = function(){
        devicesSelected = [];
    }
    var setResponseConfirmation = function(response){
        responseConfirmation = response;
    }

    var getResponseConfirmation = function(){
        return responseConfirmation;
    }

    var resetResponseConfirmation = function(){
        responseConfirmation = [];
    }

    var setSerialNumber = function(serial){
        serialNumber = serial;
    }

    var getSerialNumber = function(){
        return serialNumber;
    }

    var resetSerialNumber = function(){
        serialNumber = null;
    }

    var setDevicesToUpdate = function(devices){
        devicesToUpdate = devices;
    }

    var getDevicesToUpdate = function(){
        return devicesToUpdate;
    }

    var resetDevicesToUpdate = function(){
        devicesToUpdate = [];
    }

    var setOperationDetailFromFile = function(operationDetail){
        operationDetailFromFile = operationDetail;
    }

    var getOperationDetailFromFile = function(){
        return operationDetailFromFile;
    }

    var resetOperationDetailFromFile = function(){
        operationDetailFromFile = [];
    }

    /*
    * Build response for bulk from file process.
    */
    var buildResponse = function(){

        var deviceSelectedList = getDevicesSelected();
        var resultConfirmation = getResponseConfirmation();

        var serialNumber = getSerialNumber();

        if(serialNumber){

            var headers = getFileHeader();

            var indexColumn = findValueOnTheColumn(headers, serialNumber.name);

            if(indexColumn !== -1){

                var response = [];
                var sizeFileContent = getFileContent().length;
                var content = getFileContent().splice(1, sizeFileContent);

                var serialNumberList = getSerialNumbersList(content, indexColumn);

               _.each(serialNumberList, function(serialNumberRow, indexRow){

                    var objectResponse = {};

                    objectResponse["serialNumber"] = serialNumberRow;
                    objectResponse["error"] = "";

                    _.each(resultConfirmation, function(result){

                        var value = result.value;

                        if(result.type === "file"){
                            var indexColumnBody = findValueOnTheColumn(headers, result.value);

                            if(indexColumnBody !== -1){
                                var columnValue = content[indexRow].split(",")[indexColumnBody];
                                value = columnValue;
                            }else{
                                response.push({"serialNumber" : serialNumber,
                                               "error" : "The column "+result.columnId+" was not found in the CSV file"});
                                return;
                            }
                        }

                        switch(result.columnId){
                            case BulkConstants.device.provider:
                                objectResponse["provider"] = value;
                                break;
                            case BulkConstants.device.deviceId:
                                objectResponse["deviceId"] = value;
                                break;
                            case BulkConstants.device.providerId:
                                objectResponse["providerId"] = value;
                                break;
                            case BulkConstants.device.simId:
                                objectResponse["simId"] = value;
                                break;
                            case BulkConstants.device.ipAddress:
                                objectResponse["ipAddress"] = value;
                                break;
                            case BulkConstants.device.provisionDate:
                                var provisionDate = moment(value, ["YYYY-MM-DD HH:mm:ss", "YYYY/MM/DD HH:mm:ss" ]);
                                if (provisionDate.isValid()) {
                                    objectResponse["provisionDate"] = provisionDate;
                                } else {
                                    objectResponse["error"] = "The value " + value + " is not a valid date. The valid format must be YYYY-MM-DD HH:mm:ss";
                                }
                                break;
                            case BulkConstants.device.status:
                                objectResponse["status"] = value;
                                break;
                            case BulkConstants.device.notes:
                                objectResponse["notes"] = value;
                                break;
                        }
                    });

                    response.push(objectResponse);
                });
            }else{
                response.push({"serialNumber" : serialNumber,
                               "error" : "The Serial Number "+serialNumber+" was not found in the CSV file"});
            }
        }else{
            response.push({"serialNumber" : serialNumber,
                           "error" : "There is not Serial Number selected"});
        }

        return response;
    }

    var buildManualDataSet = function () {

        var deviceSelectedList = getDevicesSelected();
        var resultConfirmation = getResponseConfirmation();

        var resultDataSet = [];
        _.each(deviceSelectedList, function(deviceSelected){
            var updateObject = {};
            updateObject['serialNumber'] = deviceSelected;
            _.each(resultConfirmation, function(updateField){
                var fieldName = updateField['columnId'];

                var fieldValue = null;
                var valueTemp = !_.isNull(updateField['internalValue']) ? updateField['internalValue'] : updateField['value'];

                if(updateField.columnId === "provisionDate"){
                    fieldValue = moment(valueTemp, "MM/DD/YYYY");

                }else{
                    fieldValue = valueTemp;
                }

                updateObject[fieldName] = fieldValue;
            });
            resultDataSet.push(updateObject);
        });
        return resultDataSet;
    }

    var findValueOnTheColumn = function(headers, value){

        var indexColumn = -1;

        _.each(headers, function(header, indexHeader){
            if(header.name == value){
                indexColumn = indexHeader;
                return false;
            }
        });

        return indexColumn;
    }

    var getSerialNumbersList = function(content, indexColumn){

        var serialNumberRows = [];

        _.each(content, function(row){
            var columnValue = row.split(",")[indexColumn];

            if(columnValue){
                serialNumberRows.push(columnValue);
            }
        });

        return serialNumberRows;
    }

    return {
        resetAll : resetAll,
        setFileContent : setFileContent,
        getFileContent : getFileContent,
        resetFileContent : resetFileContent,
        setFileHeader : setFileHeader,
        getFileHeader : getFileHeader,
        resetFileHeader : resetFileHeader,
        setDevicesSelected : setDevicesSelected,
        getDevicesSelected : getDevicesSelected,
        resetDevicesSelected : resetDevicesSelected,
        setResponseConfirmation : setResponseConfirmation,
        getResponseConfirmation : getResponseConfirmation,
        resetResponseConfirmation : resetResponseConfirmation,
        setSerialNumber : setSerialNumber,
        getSerialNumber : getSerialNumber,
        resetSerialNumber : resetSerialNumber,
        setDevicesToUpdate : setDevicesToUpdate,
        getDevicesToUpdate : getDevicesToUpdate,
        resetDevicesToUpdate : resetDevicesToUpdate,
        setOperationDetailFromFile : setOperationDetailFromFile,
        getOperationDetailFromFile : getOperationDetailFromFile,
        resetOperationDetailFromFile : resetOperationDetailFromFile,
        buildResponse : buildResponse,
        buildManualDataSet : buildManualDataSet
    }
})
.factory('BulkDeviceStatus', function($resource) {
    return $resource('api/bulk/deviceStatus/', {}, {
        'get': {
            method: 'GET',
            isArray: true
        }
    });
})
.factory('BulkUpdateDevices', function($resource) {
    return $resource('api/bulk/updateDevices', {}, {
        'update': { method: 'PUT' }
    });
})
.factory('BulkAllSerialNumbers', function($resource) {
    return $resource('api/bulk/getAllDevicesSerialNumbers', {}, {
        'get': {
            method: 'GET',
            isArray: true
        }
    });
});
