'use strict';

angular.module('vmaxmanagerApp')
    .config(function($stateProvider) {
        $stateProvider
            .state('device', {
                parent: 'entity',
                url: '/devices',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.device.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/device/devices.html',
                        controller: 'DeviceController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('device');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.device.home.title'
                }
            })
            .state('device.detail', {
                parent: 'device',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.device.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/device/device-detail.html',
                        controller: 'DeviceDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('device');
                        $translatePartialLoader.addPart('license');
                        $translatePartialLoader.addPart('settingsInstance');
                        $translatePartialLoader.addPart('fleet');
                        $translatePartialLoader.addPart('server');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'DeviceStatusMonitoring', function($stateParams, DeviceStatusMonitoring) {
                        return DeviceStatusMonitoring.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    localDateDeviceSettingRequestAudit: ['$stateParams', 'DeviceSettingsRequestAudit', function($stateParams, DeviceSettingsRequestAudit) {
                       return DeviceSettingsRequestAudit.get({
                           id: $stateParams.id
                       }).$promise;
                   }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'device'
                }
            })
            .state('device.new', {
                parent: 'device',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/device/device-dialog.html',
                        controller: 'DeviceDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('device');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
                            providerId: null,
                            deviceId: null,
                            simId: null,
                            salesOrder: null,
                            expirationDate: null,
                            provisionDate: null,
                            status: null,
                            ipAddress: null,
                            notes: null,
                            id: null
                        };
                    }],
                    statusWorkflow: [function() {
                        return null;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'device'
                }
            })
            .state('device.edit', {
                parent: 'device',
                url: '/{id}/edit',
                params: {
                    isEdit: true
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/device/device-dialog.html',
                        controller: 'DeviceDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('device');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Device', function($stateParams, Device) {
                        return Device.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    statusWorkflow: ['DeviceStatusWorkflow', 'entity', function(DeviceStatusWorkflow, entity) {
                        return DeviceStatusWorkflow.query({
                            status: entity.status
                        }).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'device'
                }
            })
            .state('device.delete', {
                parent: 'device',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/device/device-delete-dialog.html',
                        controller: 'DeviceDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['Device', function(Device) {
                                return Device.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('device', null, {
                            reload: true
                        });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('device.settings-view', {
                parent: 'device',
                url: '/{deviceId}/view-device-setting/{settingsId}',
                params: {
                    parentState: null,
                    id: null,
                    deviceId: null,
                    deviceTypeId: null,
                    settingsId: null,
                    showChangeSettingsButton: true
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    headerLabel: 'vmaxmanagerApp.settingsInstance.process.viewSettings',
                    hideButtons: false,
                    isView: true
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        $translatePartialLoader.addPart('device');
                        $translatePartialLoader.addPart('license');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({
                            id: $stateParams.settingsId
                        }).$promise;
                    }],
                    licenseEntity: function() {
                        return null;
                    },
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                        return $stateParams.deviceId ? Device.get({
                            id: $stateParams.deviceId
                        }).$promise : null;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.settingsInstance.process.viewSettings',
                    parent: 'device'
                }
            })
            .state('device.settings-new', {
                parent: 'device',
                url: '/{deviceId}/new-device-setting',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.settingsInstance.new.title',
                    headerLabel: 'vmaxmanagerApp.settingsInstance.process.newSettings',
                    hideButtons: true,
                    showChangeSettingsButton: true,
                    isNew: true
                },
                params: {
                    parentState: null,
                    id: null,
                    deviceId: null,
                    deviceTypeId: null,
                    settingsId: null,
                    settingsTemplateId: null
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', function($stateParams) {
                        return {
                            id: null,
                            name: null,
                            notes: null,
                            template: null,
                            settingsTemplate: {
                                id: $stateParams.settingsTemplateId,
                                deviceType: {
                                    id: $stateParams.deviceTypeId
                                },
                                typeEntitySettingsEnum: 'DEVICE'
                            }
                        };
                    }],
                    licenseEntity: function() {
                        return null;
                    },
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                        return $stateParams.deviceId ? Device.get({
                            id: $stateParams.deviceId
                        }).$promise : null;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'device.settings-view'
                }
            })
            .state('device.settings-edit', {
                parent: 'device',
                url: '/{deviceId}/edit-device-settings/{settingsId}',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.settingsInstance.edit.title',
                    headerLabel: 'vmaxmanagerApp.settingsInstance.process.editSettings',
                    hideButtons: true,
                    showChangeSettingsButton: true,
                    isEdit: true
                },
                params: {
                    parentState: null,
                    id: null,
                    deviceId: null,
                    deviceTypeId: null,
                    settingsId: null
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({
                            id: $stateParams.settingsId
                        }).$promise;
                    }],
                    licenseEntity: function() {
                        return null;
                    },
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                        return $stateParams.deviceId ? Device.get({
                            id: $stateParams.deviceId
                        }).$promise : null;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'device.settings-view'
                }
            })
            .state('device.settings-clone', {
                parent: 'device',
                url: '/{deviceId}/clone-device-setting',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.settingsInstance.clone.title',
                    headerLabel: 'vmaxmanagerApp.settingsInstance.process.cloneSettings',
                    hideButtons: true,
                    showChangeSettingsButton: true,
                    isClone: true
                },
                params: {
                    parentState: null,
                    id: null,
                    deviceId: null,
                    deviceTypeId: null,
                    settingsId: null
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({
                            id: $stateParams.settingsId
                        }).$promise;
                    }],
                    licenseEntity: function() {
                        return null;
                    },
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                        return $stateParams.deviceId ? Device.get({
                            id: $stateParams.deviceId
                        }).$promise : null;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.clone',
                    parent: 'device.settings-view'
                }
            })
            .state('device.settings-change', {
                parent: 'device',
                url: '/{deviceId}/change-device-settings',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.settingsInstance.edit.title',
                    headerLabel: 'vmaxmanagerApp.settingsInstance.process.changeSettings',
                    hideButtons: true,
                    showChangeSettingsButton: true,
                    isEdit: true,
                    isChangeSettings: true
                },
                params: {
                    parentState: null,
                    id: null,
                    deviceId: null,
                    deviceTypeId: null,
                    settingsId: null
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({
                            id: $stateParams.settingsId
                        }).$promise;
                    }],
                    licenseEntity: function() {
                        return null;
                    },
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                        return $stateParams.deviceId ? Device.get({
                            id: $stateParams.deviceId
                        }).$promise : null;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.change',
                    parent: 'device.settings-view'
                }
            });
    });
