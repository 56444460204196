'use strict';

angular.module('vmaxmanagerApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('ds2', {
                parent: 'downloads',
                url: '/ds',
                hideMenu: true,
                downloadsView: true,
                data: {
                    authorities: [],
                    pageTitle: 'title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/downloads/ds2/ds2.html',
                        controller: 'Ds2Controller'
                    },
                     'toolbar@' : {
                         controller: null,
                         templateUrl: null,
                     },
                     'menu@' : {
                         controller: null,
                         templateUrl: null,
                     }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    deviceType: ['AppDownloadsService', function(AppDownloadsService) {
                        return AppDownloadsService.getDeviceTypeByName({name: 'DS2'}).$promise;
                    }]
                },
                onEnter: function(){
                	$('.modal-backdrop').remove();
                }
            });
    });
