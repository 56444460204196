'use strict';

angular.module('vmaxmanagerApp')
    .directive('fileReaderComponent', function($translate, $translatePartialLoader, $filter, BulkConstants) {

        var validCsvFile = function(scope, contents) {

            var contentRows = contents.split('\n');
            var rows = contentRows.length;
            var header = contentRows[0].trim();
            var contentHeader = header.split(',');
            var countHeader = contentHeader.length;
            var errors = [];

//          Validate the header
            if(rows < 2 || countHeader < 2 || header.substr(header.length - 1) === ',' || contentHeader.indexOf("") !== -1){
                if(rows < 2){
                    errors.push(BulkConstants.errors.minimumTwoRows);
                }
                if(countHeader < 2){
                    errors.push(BulkConstants.errors.minimumTwoColumns);
                }
                if(header.substr(header.length - 1) === ','){
                    errors.push(BulkConstants.errors.noEndCommaSeparator);
                }
                if(contentHeader.indexOf("") !== -1){
                    errors.push(BulkConstants.errors.noEmptyValueBetweenCommas);
                }
            }else {
//              Validate the content
                for(var i = 1; i < contentRows.length; i++){
                    var countColumn = contentRows[i].split(",").length;
                    if(countHeader < countColumn || countHeader > countColumn){
                        errors.push("The row #" + (i + 1) + " has " + countColumn + " columns but the header has " + countHeader + " columns");
                    }
                }
            }

            buildResponse(scope, errors, contentHeader, contentRows);

        };

        var buildHeader = function(header){

            var availableOptions = [];

            for(var i = 0; i < header.length; i++){
                availableOptions.push({id: i, name: header[i]});
            }

            return availableOptions;
        }

        var buildResponse = function(scope, errors, contentHeader, contentRows){
            if(errors.length == 0){

                var headers = buildHeader(contentHeader);

                scope.$apply(function () {
                    scope.fileReader = contentRows;
                    scope.fileHeaders = headers;
                });
            }else{
                scope.$apply(function () {
                    scope.fileErrors = errors;
                });
            }
        }

        return {
            scope: {
                fileReader:"=",
                fileErrors:"=",
                fileHeaders:"="
            },
            link: function(scope, element) {
                $(element).on('click', function(changeEvent) {
                    this.value = null;
                });

                $(element).on('change', function(changeEvent) {

                    var errors = [];

                    scope.fileErrors = [];
                    scope.fileHeaders = [];
                    var files = changeEvent.target.files;

                    if(files && files.length > 0){
                        var fileTypeIndex = files[0].name.lastIndexOf(".");
                        var fileType = files[0].name.substr(fileTypeIndex, files[0].name.length);

                        if(files[0].type !== "application/vnd.ms-excel" && fileType !== ".csv"){
                            errors.push(BulkConstants.errors.fileTypeError);
                            buildResponse(scope, errors);
                        }
                    }

                    if (files.length && errors.length == 0) {
                        var r = new FileReader();
                        r.onload = function(e) {
                            var contents = e.target.result;
                            validCsvFile(scope, contents);
                        };
                        r.readAsText(files[0]);
                    }else{
                        scope.$apply(function () {
                            scope.fileReader = [];
                        });
                    }
                });
            }
        };
    });
