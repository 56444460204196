(function() {
    'use strict';
    angular
        .module('vmaxmanagerApp')
        .factory('AppResource', AppResource);

    AppResource.$inject = ['$resource'];

    function AppResource ($resource) {
        var resourceUrl =  'api/app-resources/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'filterQuery': { method: 'POST', url: 'api/app-resources/query', isArray:true },
            'filterVars': { method: 'GET', url: 'api/app-resources/info', isArray:true }
        });
    }
})();
