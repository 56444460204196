'use strict';

angular.module('vmaxmanagerApp')
    .controller('BulkDeviceOperationDetailsController', function ($scope, BulkService, providersList, statusList, $state, DateUtils, $translate, BulkConstants) {

        $scope.headers = BulkService.getFileHeader();

        $scope.providers = providersList;
        $scope.deviceStatutes = statusList;

        $scope.operationDetail = BulkService.getOperationDetailFromFile();

        $scope.isFromFile = function(radioType){
            return radioType && radioType == 'file';
        }

        $scope.isManual = function(radioType){
            return radioType && radioType == 'manual';
        }

        $scope.disableRadioFile = function(radioType){
            return !radioType || radioType != 'file';
        }

        $scope.disableRadioManual = function(radioType){
            return !radioType || radioType != 'manual';
        }

        $scope.goToNextStep = function(){
            var response = buildResponse();
            BulkService.setResponseConfirmation(response);
            BulkService.setOperationDetailFromFile($scope.operationDetail);
            $state.go("bulkDevice.confirmation", {parentState: $state.current.name});
        }

        $scope.checkingValidationForm = function(){

            if(!$scope.operationDetail){
                return true;
            }

            if($scope.operationDetail.provider && $scope.operationDetail.provider.checkbox && ($scope.operationDetail.provider.file || $scope.operationDetail.provider.manual)
                || $scope.operationDetail.deviceId && $scope.operationDetail.deviceId.checkbox && $scope.operationDetail.deviceId.file
                || $scope.operationDetail.providerId && $scope.operationDetail.providerId.checkbox && $scope.operationDetail.providerId.file
                || $scope.operationDetail.simId && $scope.operationDetail.simId.checkbox && $scope.operationDetail.simId.file
                || $scope.operationDetail.ipAddress && $scope.operationDetail.ipAddress.checkbox && $scope.operationDetail.ipAddress.file
                || $scope.operationDetail.provisionDate && $scope.operationDetail.provisionDate.checkbox && ($scope.operationDetail.provisionDate.file || $scope.operationDetail.provisionDate.manual)
                || $scope.operationDetail.deviceStatus && $scope.operationDetail.deviceStatus.checkbox && ($scope.operationDetail.deviceStatus.file || $scope.operationDetail.deviceStatus.manual)
                || $scope.operationDetail.notes && $scope.operationDetail.notes.checkbox && $scope.operationDetail.notes.file){
                return false;
            }else{
                return true;
            }
        }

        var buildResponse = function(){
            var response = [];

            if(!$scope.operationDetail){
                return response;
            }

            if($scope.operationDetail.provider && $scope.operationDetail.provider.checkbox){
                if($scope.operationDetail.provider.radio == "manual" && $scope.operationDetail.provider.manual){
                    response.push( {"type" : $scope.operationDetail.provider.radio,
                                    "value" : $scope.operationDetail.provider.manual.name,
                                    "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelManual'),
                                    "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.provider'),
                                    "columnId" : BulkConstants.device.provider});
                }
                if($scope.operationDetail.provider.radio == "file" && $scope.operationDetail.provider.file){
                    response.push( {"type" : $scope.operationDetail.provider.radio,
                                    "value" : $scope.operationDetail.provider.file.name,
                                    "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                    "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.provider'),
                                    "columnId" : BulkConstants.device.provider});
                }
            }
            if($scope.operationDetail.deviceId && $scope.operationDetail.deviceId.checkbox && $scope.operationDetail.deviceId.file){
                response.push( {"type" : "file",
                                "value" : $scope.operationDetail.deviceId.file.name,
                                "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.deviceId'),
                                "columnId" : BulkConstants.device.deviceId});
            }
            if($scope.operationDetail.providerId && $scope.operationDetail.providerId.checkbox && $scope.operationDetail.providerId.file){
                response.push( {"type" : "file",
                                "value" : $scope.operationDetail.providerId.file.name,
                                "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.providerId'),
                                "columnId" : BulkConstants.device.providerId});
            }
            if($scope.operationDetail.simId && $scope.operationDetail.simId.checkbox && $scope.operationDetail.simId.file){
                response.push( {"type" : "file",
                                "value" : $scope.operationDetail.simId.file.name,
                                "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.simId'),
                                "columnId" : BulkConstants.device.simId});
            }
            if($scope.operationDetail.ipAddress && $scope.operationDetail.ipAddress.checkbox && $scope.operationDetail.ipAddress.file){
                response.push( {"type" : "file",
                                "value" : $scope.operationDetail.ipAddress.file.name,
                                "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.ipAddress'),
                                "columnId" : BulkConstants.device.ipAddress});
            }
            if($scope.operationDetail.provisionDate && $scope.operationDetail.provisionDate.checkbox){
                if($scope.operationDetail.provisionDate.radio == "manual" && $scope.operationDetail.provisionDate.manual){
                    response.push( {"type" : $scope.operationDetail.provisionDate.radio,
                                    "value" : $scope.operationDetail.provisionDate.manual,
                                    "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelManual'),
                                    "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.provisionDate'),
                                    "columnId" : BulkConstants.device.provisionDate});
                }
                if($scope.operationDetail.provisionDate.radio == "file" && $scope.operationDetail.provisionDate.file){
                    response.push( {"type" : $scope.operationDetail.provisionDate.radio,
                                    "value" : $scope.operationDetail.provisionDate.file.name,
                                    "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                    "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.provisionDate'),
                                    "columnId" : BulkConstants.device.provisionDate});
                }
            }
            if($scope.operationDetail.deviceStatus && $scope.operationDetail.deviceStatus.checkbox){
                if($scope.operationDetail.deviceStatus.radio == "manual" && $scope.operationDetail.deviceStatus.manual){
                    response.push( {"type" : $scope.operationDetail.deviceStatus.radio,
                                    "value" : $scope.operationDetail.deviceStatus.manual,
                                    "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelManual'),
                                    "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.deviceStatus'),
                                    "columnId" : BulkConstants.device.status});
                }
                if($scope.operationDetail.deviceStatus.radio == "file" && $scope.operationDetail.deviceStatus.file){
                    response.push( {"type" : $scope.operationDetail.deviceStatus.radio,
                                    "value" : $scope.operationDetail.deviceStatus.file.name,
                                    "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                    "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.deviceStatus'),
                                    "columnId" : BulkConstants.device.status});
                }
            }
            if($scope.operationDetail.notes && $scope.operationDetail.notes.checkbox && $scope.operationDetail.notes.file){
                response.push( {"type" : "file",
                                "value" : $scope.operationDetail.notes.file.name,
                                "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.notes'),
                                "columnId" : BulkConstants.device.notes});
            }

            return response;
        }

    });
