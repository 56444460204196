'use strict';

angular.module('vmaxmanagerApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('fleet', {
                parent: 'entity',
                url: '/fleets',
                data: {
                	authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.fleet.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/fleet/fleets.html',
                        controller: 'FleetController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fleet');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.fleet.home.title'
                }
            })
            .state('fleet.detail', {
                parent: 'fleet',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.fleet.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/fleet/fleet-detail.html',
                        controller: 'FleetDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fleet');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Fleet', function($stateParams, Fleet) {
                        return Fleet.get({id : $stateParams.id}).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'fleet'
                }
            })
            .state('fleet.new', {
                parent: 'fleet',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.fleet.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/fleet/fleet-dialog.html',
                        controller: 'FleetDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fleet');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
                                    fleetId: null,
                                    description: null,
                                    adminEmail: null,
                                    notes: null,
                                    isActive: true,
                                    deploymentType: null,
                                    contactName: null,
                                    contactPhone: null,
                                    contactEmail: null,
                                    soldName: null,
                                    soldEmail: null,
                                    id: null
                                };;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'fleet'
                }
            })
            .state('fleet.edit', {
                parent: 'fleet',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.fleet.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/fleet/fleet-dialog.html',
                        controller: 'FleetDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fleet');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Fleet', function($stateParams, Fleet) {
                        return Fleet.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'fleet'
                }

            })
            .state('fleet.delete', {
                parent: 'fleet',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/fleet/fleet-delete-dialog.html',
                        controller: 'FleetDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['Fleet', function(Fleet) {
                                return Fleet.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('fleet', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    });
