'use strict';

angular.module('vmaxmanagerApp').controller('CustomerDialogController',
    ['$scope', '$stateParams', 'entity', 'Customer', '$state',
        function($scope, $stateParams, entity, Customer, $state) {

        $scope.customer = entity;
        $scope.load = function(id) {
            Customer.get({id : id}, function(result) {
                $scope.customer = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:customerUpdate', result);
            $state.go('customer');
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.customer.id != null) {
                Customer.update($scope.customer, onSaveSuccess, onSaveError);
            } else {
                Customer.save($scope.customer, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $state.go('customer');
        };
}]);
