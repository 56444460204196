'use strict';

angular.module('vmaxmanagerApp')
    .controller('SlotTypeController', function ($scope, $state, SlotType, ParseLinks) {

        $scope.slotTypes = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.loadAll = function() {
            SlotType.query({page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.slotTypes.push(result[i]);
                }
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.slotTypes = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.slotType = {
                feature: null,
                requiresData: null,
                requiresSlot: null,
                slotIcon: null,
                slotType: null,
                requiresDevice: null,
                requiresSoftwareVersion: null,
                id: null
            };
        };

        $scope.getSoftwareLicense = function (slotType) {
            var softwareLicense;
            if (!_.isEmpty(slotType)) {
                if (!_.isEmpty(slotType.softwareLicense)) {
                       softwareLicense = slotType.softwareLicense.softwareVersion;
                }
            }
            return softwareLicense;
        };
    });
