'use strict';

angular.module('vmaxmanagerApp')
	.controller('DeviceDeleteController', function($scope, $uibModalInstance, $translate, entity, Device) {

        if (!_.isEmpty(entity)) {
            $scope.device = entity;
            if (!_.isEmpty($scope.device.status)) {
                if ($scope.device.status == 'ASSIGNED' || $scope.device.status == 'NON_RENEWAL') {
                    $scope.isDeviceAssigned = true;
                    $scope.modalMessage = $translate.instant('vmaxmanagerApp.device.delete.warning');
                } else {
                    $scope.isDeviceAssigned = false;
                    $scope.modalMessage = $translate.instant('vmaxmanagerApp.device.delete.question', { id: $scope.device.deviceId } );
                }
            }
        }

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            Device.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    });
