'use strict';
angular.module('vmaxmanagerApp')
    .controller('ServerController', function ($scope, $state, Server, ParseLinks) {

        $scope.servers = [];
        $scope.predicate = 'name';
        $scope.reverse = true;
        $scope.page = 0;

        //filter configs
        $scope.filterConfig = {
            inputGroupClass: '',
            btnClass: 'btn-raised'
        };

        $scope.loadAll = function() {
        	var pageable = {page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'name']};
            Server.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.servers.push(result[i]);
                }
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.servers = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();
        $scope.fields = Server.filterVars();

        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.server = {
                name: null,
                url: null,
                ipAddress: null,
                id: null
            };
        };
    });
