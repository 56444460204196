'use strict';

angular.module('vmaxmanagerApp')
    .controller('BulkDeviceResultsController', function ($scope, updateResponse) {
        $scope.updateResponse = updateResponse;
        $scope.showUpdatedDevices = false;
        $scope.showErrorsDevices = false;
        $scope.updatedDevicesLength = 0;
        $scope.errorDevicesLength = 0;
        $scope.isCollapseUpdatedDevicesContainer = false;
        $scope.isCollapseErrorDevicesContainer = false;
        $scope.currentUpdatedDevicesPage = 1;
        $scope.currentErrorDevicesPage = $scope.currentUpdatedDevicesPage;
        $scope.numPerPage = 10;

        if (!_.isEmpty($scope.updateResponse)) {
            $scope.updatedDevices = $scope.updateResponse.updatedDevices;
            $scope.DevicesWithError = $scope.updateResponse.errors;

            if (!_.isEmpty($scope.updatedDevices)) {
                $scope.updatedDevicesLength = $scope.updatedDevices.length;
                if ($scope.updatedDevicesLength > 0) {
                    $scope.showUpdatedDevices = true;
                    $scope.showProviderCol = !_.isEmpty($scope.updatedDevices[0].provider);
                    $scope.showDeviceIdCol = !_.isEmpty($scope.updatedDevices[0].deviceId);
                    $scope.showProviderIdCol = !_.isEmpty($scope.updatedDevices[0].providerId);
                    $scope.showSimIdCol = !_.isEmpty($scope.updatedDevices[0].simId);
                    $scope.showIpAddressCol = !_.isEmpty($scope.updatedDevices[0].ipAddress);
                    $scope.showProvisionDateCol = !_.isEmpty($scope.updatedDevices[0].provisionDate);
                    $scope.showStatusCol = !_.isEmpty($scope.updatedDevices[0].status);
                    $scope.showNotesCol = !_.isEmpty($scope.updatedDevices[0].notes);
                }
            }

            if (!_.isEmpty($scope.DevicesWithError)) {
                $scope.errorDevicesLength = $scope.DevicesWithError.length;
                if ($scope.errorDevicesLength > 0) {
                    $scope.showErrorsDevices = true;
                }
            }
        }

        $scope.updatedDevicesPaginate = function(value) {
            var begin, end, index;
            begin = ($scope.currentUpdatedDevicesPage - 1) * $scope.numPerPage;
            end = begin + $scope.numPerPage;
            index = $scope.updatedDevices.indexOf(value);
            return (begin <= index && index < end);
        };

        $scope.errorDevicesPaginate = function(value) {
            var begin, end, index;
            begin = ($scope.currentErrorDevicesPage - 1) * $scope.numPerPage;
            end = begin + $scope.numPerPage;
            index = $scope.DevicesWithError.indexOf(value);
            return (begin <= index && index < end);
        };

        $scope.updatedDevicesButtonClick = function () {
            $scope.isCollapseUpdatedDevicesContainer = !angular.element('#collapseUpdatedDevices').hasClass('in');
        };

        $scope.removeDevicesButtonClick = function () {
            $scope.isCollapseErrorDevicesContainer = !angular.element('#collapseRemoveDevices').hasClass('in');
        };
    });
