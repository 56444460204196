'use strict';

angular.module('vmaxmanagerApp')
    .controller('DeviceController', function ($scope, $state, Device,DeviceWithoutQuarantine, ParseLinks, UtcDateString) {
        $scope.devices = [];
        $scope.predicate = 'providerId';
        $scope.reverse = true;
        $scope.sorts = ["deviceId,asc"];
        $scope.page = 0;
        $scope.per_page = 20;
        $scope.showQuarantine = false;
        $scope.isAllDevicesSelected = false;
        $scope.bulkSelectedDevicesList = [];
        $scope.inBulkAllSelectionProcess = false;
        $scope.showCheck = false;
        $scope.showAllFields = false;

        //filter configs
        $scope.filterConfig = {
            inputGroupClass: '',
            btnClass: 'btn-raised'
        };

        $scope.filterInitialRules = {
            'operator' : 'and',
            'rules' : [{condition: '!=', field:{name: 'status', type:"text"}, data:'QUARANTINE'}]
        };

        $scope.loadAll = function(isScroll) {
            if (!_.isBoolean(isScroll) && !isScroll) {
                $scope.devices = [];
            }

            var pageable = {page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']};
            Device.filterQuery({pageable:pageable, filters:$scope.queryObject, hideQuarantined: !$scope.showQuarantine}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    var device = result[i];
                    device.checked = $scope.isAllDevicesSelected;

                    if (device.provisionDate) {
                        var provisionDate = new Date(UtcDateString.createString(device.provisionDate));
                        device.provisionDate =  provisionDate;
                    }
                    $scope.devices.push(device);
                    if ($scope.isAllDevicesSelected) {
                        $scope.bulkSelectedByDevice(device)
                    }
                }
            });
        };

        Device.filterVars({}, function(data) {
            var f = [];
            angular.forEach(data, function(d) {
                f.push(d);
            });
            $scope.fields = f;
            $scope.loadAll();
        });

    	var setLicensesToScope = function(result, headers){
    		_.each(result,function(item){
    			$scope.devices.push(item);
    		});
    		$scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
        };

        $scope.reset = function() {
            $scope.page = 0;
            $scope.devices = [];
            $scope.loadAll();
        };

        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll(true);
        };


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.getDevices = function(){
        	if($scope.showQuarantine){
        		$scope.classAction = 'btn-danger';
        		Device.query({}, setLicensesToScope);
            }else{
            	$scope.classAction = null;
            	DeviceWithoutQuarantine.query({}, setLicensesToScope);
            }
        };


        $scope.clear = function () {
            $scope.device = {
                providerId: null,
                deviceId: null,
                simId: null,
                salesOrder: null,
                expirationDate: null,
                provisionDate: null,
                status: null,
                ipAddress: null,
                notes: null,
                id: null
            };
        };

        $scope.disabledSettings = function(status){
            return status !== 'ASSIGNED' && status !== 'NON_RENEWAL';
        };

        /**
        * This method handle the selection of the one device.
        */
        $scope.bulkSelectedByDevice = function(device){
            var deviceIndexInBulkList = _.indexOf($scope.bulkSelectedDevicesList, device.serialNumber);
            if (device.checked) {
                if (deviceIndexInBulkList === -1) {
                    // Add to Bulk List.
                    $scope.bulkSelectedDevicesList.push(device.serialNumber);
                }
            } else {
                $scope.bulkSelectedDevicesList.splice( deviceIndexInBulkList, 1 );
            }

            if (!$scope.inBulkAllSelectionProcess) {
                if($scope.bulkSelectedDevicesList.length === $scope.devices.length && $scope.bulkSelectedDevicesList.length > 0){
                    $scope.isAllDevicesSelected = true;
                } else if($scope.bulkSelectedDevicesList.length > 0){
                    $scope.isAllDevicesSelected = false;
                } else {
                    $scope.isAllDevicesSelected = false;
                }
            }
        };

        /**
        * This method check or uncheck all devices.
        */
        $scope.bulkSelectAllDevices = function(){
            if (!$scope.inBulkAllSelectionProcess) {
                $scope.inBulkAllSelectionProcess = true;
                _.each($scope.devices,function(device){
                    device.checked = $scope.isAllDevicesSelected;
                    $scope.bulkSelectedByDevice(device);
                });
            }
            $scope.inBulkAllSelectionProcess = false;
        };
    });
