'use strict';

angular.module('vmaxmanagerApp')
    .controller('ProvidersDetailController', function ($scope, $rootScope, $stateParams, entity, Providers) {
        $scope.providers = entity;
        $scope.load = function (id) {
            Providers.get({id: id}, function(result) {
                $scope.providers = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:providersUpdate', function(event, result) {
            $scope.providers = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
