'use strict';

angular.module('vmaxmanagerApp')
    .factory('DeviceType', function ($resource, DateUtils) {
        return $resource('api/deviceTypes/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findDeviceTypeAvailableToLicense':
            {
                method:'GET',
                isArray: true,
                url : 'api/deviceTypes/findDeviceTypeAvailableToLicense?licenseId=:licenseId'
            },
            'findDeviceTypeAssignedToLicense':
            {
                method:'GET',
                isArray: true,
                url : 'api/deviceTypes/findDeviceTypeAssignedToLicense?licenseId=:licenseId'
            },
            'findDeviceTypeWithSettingsTemplate':
            {
                method:'GET',
                isArray: true,
                url : 'api/deviceTypes/findDeviceTypeWithSettingsTemplate'
            }
        });
    });
