'use strict';

angular.module('vmaxmanagerApp').controller('DeviceDialogController',
    ['$scope', '$state', '$stateParams','entity', 'Device', 'Fleet', 'DeviceType', 'Providers', 'ParseLinks', 'DeviceStatus', 'License', '$translate', 'statusWorkflow', 'DateUtils',
        function($scope, $state, $stateParams, entity, Device, Fleet, DeviceType, Providers, ParseLinks, DeviceStatus, License, $translate, statusWorkflow, DateUtils) {

    	/**set max Input Date.**/
        $scope.loadDateInput = function(){
            $("#dateInput").attr({"max" : 9999+'-'+12+'-'+31});
        };

        $scope.message={errors:{
            delete:'The Device could not be deleted. Please verify it is not assigned to any License and try again',
            unknown:'Unexpected error, please contact to support'
        }};

    	$scope.devices = [];

        $scope.load = function(id) {
            if (id != null) {
                Device.get({id : id}, function(result) {
                    $scope.device = result;
                    if ($scope.device && $scope.device.provisionDate) {
                        $scope.pickerDate = new Date($scope.device.provisionDate.getFullYear(), $scope.device.provisionDate.getMonth(), $scope.device.provisionDate.getDate(),
                        $scope.device.provisionDate.getHours(), $scope.device.provisionDate.getMinutes(), $scope.device.provisionDate.getSeconds());
                    }
                });
            }
        };

        if ($stateParams.id) {
            $scope.load($stateParams.id);
        }

        $scope.statusWorkflowList = statusWorkflow;
        $scope.isDisabledStatusWorkflowList = statusWorkflow ? statusWorkflow.length <= 1 : true;
        $scope.fleets = Fleet.query();
        $scope.devicetypes = DeviceType.query();
        $scope.providerss = Providers.query();
        $scope.licenses = License.query();
        $scope.deviceStatus = DeviceStatus.query();

        $scope.sorts = ["deviceId,asc"];
        $scope.page = 0;
        $scope.per_page = 20;
        $scope.showSettings = false;
        $scope.isAnylicensePublish = false;
        $scope.isEdit = $stateParams.isEdit ? $stateParams.isEdit : false;
        $scope.loadDateInput();



        $scope.removeFieldValueByError = function(errorCode) {
            switch(errorCode) {
                case 'serialNumberError':
                    $scope.device.serialNumber = "";
                    break;
                case 'deviceIdError':
                    $scope.device.deviceId = "";
                    break;
                default:
                    break;
            }
        }

        var onSaveSuccess = function (result) {
            $scope.isSaving = false;
        	if(result.error != null){
        	    $scope.removeFieldValueByError(result.error);
        		$translate('vmaxmanagerApp.device.' + result.error).then(function(deviceIdError) {
        			bootbox.alert(deviceIdError);
        		});
        	}else{
        		if($scope.addOther){
        			$scope.reset();
        			$scope.clear();
        		}else{
        			$scope.$emit('vmaxmanagerApp:deviceUpdate', result);
        			$state.go('device');
        		}
        	}
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
            if(result.error != null){
                $scope.removeFieldValueByError(result.error);
        		$translate('vmaxmanagerApp.device.' + result.error).then(function(deviceIdError) {
        			bootbox.alert(deviceIdError);
        		});
        	}
        };

        $scope.create = function () {
            $scope.isSaving = true;
            if ($scope.pickerDate) {
                $scope.device.provisionDate = new Date(moment.utc($scope.pickerDate).format("YYYY-MM-DDTHH:mm:ss"));
            }

            $scope.device.isProvidedByCustomer = $scope.device.isProvidedByCustomer ? $scope.device.isProvidedByCustomer : false;
            if ($scope.device.id != null) {
                $scope.validateLicenseStatus();
                $scope.isEdit = true;
                Device.update($scope.device, onSaveSuccess, onSaveError);
            } else {
                Device.save($scope.device, onSaveSuccess, onSaveError);
            }
        };

        $scope.update = function (id) {
            Device.get({id: id}, function(result) {
                $scope.device = result;
                $scope.validateLicenseStatus();
                $scope.selectedProvider();
                $scope.isEdit = true;
                $('#saveDeviceModal').modal('show');
            });
        };

        $scope.reset = function() {
        };

        $scope.clear = function() {
        	if($scope.addOther){
        		$scope.device = { providers: { id: $scope.device.providers.id
        			},deviceType: { id: $scope.device.deviceType.id
        			},providerId: null
        			, deviceId: null
        			, simId: null
        			, provisionDate: $scope.device.provisionDate != null ? new Date($scope.device.provisionDate) : null
        			, status: $scope.device.status
        			, id: null
        			, ipAddress: null
        			, notes: null
        		};
        	}else{
        		 $scope.device = { providerId: null, deviceId: null, simId: null, provisionDate: null, status: null, id: null, ipAddress: null, notes: null };
        		 $scope.deviceIdPlaceholder = '';
                 $scope.providerIdPlaceholder = '';
        	}

            $scope.showSettings = false;
            $scope.isAnylicensePublish = false;
            $scope.isEdit = false;
            $scope.addOther = false;
            $scope.loadDateInput();
        };

        $scope.cancel = function(result) {
        	$state.go('deviceType');
        };

        /*
         * selected provider.
         * @author jmontoya
         */
        $scope.selectedProvider = function() {
        	$scope.deviceIdPlaceholder = '';
        	$scope.providerIdPlaceholder = '';

        	if($scope.device.deviceType){
        	    $scope.selectedDeviceType();
        	}
        };

        /**
         * selected DeviceType.
         * @author jmontoya
         */
        $scope.selectedDeviceType = function(){

            var deviceType = [];

            if($scope.device && $scope.device.deviceType){
                deviceType = _.where($scope.devicetypes, {
                    'id' : $scope.device.deviceType.id
                });

                $scope.deviceIdPlaceholder = deviceType && deviceType[0] && deviceType[0].deviceIdLabel ? deviceType[0].deviceIdLabel : "";
            }


        	//Primero se selecciona el proveedor, se filtra la lista de deviceType y
        	//con esta lista filtrada se obtiene la marca de agua para que sea guía para el usuario.
        	if($scope.device.providers && deviceType.length > 0){
        		$scope.providerIdPlaceholder = $scope.getProviderIdByDeviceType(deviceType[0].id,$scope.device.providers.id);
        	}
        };


        /**Get provderId label**/
        $scope.getProviderIdByDeviceType = function(deviceId,providerId){

        	var providerIdLbl = '';
        	switch (deviceId) {
        	  case 1:
        		  if(providerId==1){
        			 providerIdLbl = "MEID";
        		  }
        	    break;
        	  case 2:
        		  if(providerId==1){
        			  providerIdLbl = "MEID(MSN)";
        		  }else if(providerId==3){
        			  providerIdLbl = "MEID";
        		  }
        	    break;
        	  case 3:
        		  if(providerId==1){
        			  providerIdLbl = "ESN";
        		  }else if(providerId==2){
        			  providerIdLbl = "ESN";
        		  }else if(providerId==3){
        			  providerIdLbl = "IMEI";
        		  }else if(providerId==4){
        			  providerIdLbl = "IMEI";
        		  }
        	    break;
        	  case 4:
        		  if(providerId==1){
        			  providerIdLbl = "ESN";
        		  }
          	    break;
        	  case 5:
        		  if(providerId==1){
        			  providerIdLbl = "IMEI";
        		  }
          	    break;
        	  default:
        	     return providerIdLbl;
        	    break;
        	}
        	return providerIdLbl;
        }

        $scope.updateProvisionDate = function () {
         $scope.device.provisionDate = moment.utc($scope.pickerDate).format("YYYY-MM-DDTHH:mm:ss[Z]");
        }

        /**
         * validate lincense
         * @author jmontoya
         */
        $scope.validateLicenseStatus = function(){
        	var arrayLicense = $scope.device.licenses;
        	if(arrayLicense != null && arrayLicense.length > 0){
        		$scope.showSettings = $scope.device.deviceType.requiresSettings == true;
        		var licensePublished = _.where(arrayLicense, {
        			'licenseStatus': 'PUBLISHED'
        		});
        		$scope.isAnylicensePublish = licensePublished.length > 0;
        		if($scope.showSettings)$scope.loadSettings();
        	}else{
        		$scope.showSettings = false;
        		$scope.isAnylicensePublish = false;
        	}
        };
}]);
