'use strict';

angular.module('vmaxmanagerApp')
    .constant("ENTITY_AUDIT_TYPE", {
    	"device":"com.seon.vmaxmanager.domain.Device",
    	"license":"com.seon.vmaxmanager.domain.License",
    	"fleet":"com.seon.vmaxmanager.domain.Fleet",
    	"settings-instance":"com.seon.vmaxmanager.domain.SettingsInstance",
    	"salesRegion":"com.seon.vmaxmanager.domain.SalesRegion",
    	"customer":"com.seon.vmaxmanager.domain.Customer",
    	"user-management":"com.seon.vmaxmanager.domain.User",
    	"app-resource":"com.seon.vmaxmanager.domain.AppResource",
    	"app-resource-user-profile":"com.seon.vmaxmanager.domain.AppResourceUserProfile"
    })
    .controller('EntityAuditController', function ($scope, $filter, $uibModal, EntityAuditService, AlertService, ObjectDiff, $state, $stateParams, ENTITY_AUDIT_TYPE) {

        $scope.entities = [];
        $scope.audits = [];
        $scope.limits = [25, 50, 100, 200];
        $scope.limit = 25;
        $scope.orderType = "desc";
        $scope.filter = {};

        var findByEntity = function(entityType){
            EntityAuditService.findByEntity(entityType, $scope.limit, $scope.orderType).then(function (data) {
                $scope.audits = data.map(function(it){
                    it.entityValue = JSON.parse(it.entityValue);
                    it.entityId = it.entityId.toString();
                    return it;
                });
                $scope.loading = false;
            }, function(){
                $scope.loading = false;
            });
        }

        EntityAuditService.findAllAudited().then(function (data) {
            $scope.entities = data;
            if($stateParams.entityName && $stateParams.entityId){
                var entity = ENTITY_AUDIT_TYPE[$stateParams.entityName];
                if (entity) {
                    $scope.qualifiedName = entity;
                    findByEntity(entity);
                    $scope.filter.entityId = $stateParams.entityId;
                }
            }
        });

        $scope.loading = false;
        $scope.loadChanges = function () {
            $scope.loading = true;
            $scope.filter = {};
            findByEntity($scope.qualifiedName);
        };

        $scope.getEntityName = function (qualifiedName) {
            if (qualifiedName) {
                var splits = qualifiedName.split(".");
                return splits[splits.length - 1];
            }
            else return null;
        };

        $scope.format = function(val){
            if(val)
                return ObjectDiff.objToJsonView(val);
            else return '';
        };

        $scope.isObject = function(val){
            return (val && (typeof val) == 'object');
        };
        $scope.isDate = function(key){
            return (key && key.indexOf("Date") != -1);
        };

        $scope.openChange = function(audit){

            if(audit.commitVersion < 2){
                AlertService.warning("There is no previous version available for this entry.\nThis is the first" +
                    " audit entry captured for this object");
            } else {
                EntityAuditService.getPrevVersion(audit.entityType, audit.entityId, audit.commitVersion).then(function (data) {
                    var previousVersion = JSON.parse(data.entityValue),
                        currentVersion = audit.entityValue;
                    var diff = ObjectDiff.diffOwnProperties(previousVersion, currentVersion);

                    $uibModal.open({
                        templateUrl: 'scripts/app/admin/entityAudit/entityAudit.detail.html',
                        controller: 'AuditDetailModalCtrl',
                        size: 'lg',
                        resolve: {
                            diff: function () {
                                return diff;
                            },
                            audit: function () {
                                return audit;
                            }
                        }
                    });
                });
            }
        };

        $scope.onEntityChange = function () {
            $scope.audits = [];
            $scope.limit = 25;
            $scope.orderType = "desc";
            $scope.filter = {};
        };

        function convertDates(obj) {
            for(var key in obj) {
                if (obj.hasOwnProperty(key) && obj[key]) {
                    if (key.indexOf("Date") != -1 && (obj[key] instanceof Date || Object.prototype.toString.call(obj[key]) === '[object Date]' || (new Date(obj[key]) !== "Invalid Date" && !isNaN(new Date(obj[key]))))) {
                        obj[key] = $filter('date')(obj[key]);
                    }
                }
            }
            return obj;
        }

        /**
         * This method handles the parent breadcrumb for external calls.
         */
        $scope.dynamicParentBreadcrumb = function() {
            if ($stateParams.entityName) {
                return $stateParams.entityName
            } else {
                return '';
            }
        };
    });
