(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('settings-instance', {
            parent: 'entity',
            url: '/settings-instance',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.settingsInstance.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/settingsInstance/settings-instances.html',
                    controller: 'SettingsInstanceController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settingsInstance');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            },
            ncyBreadcrumb: {
                label: 'vmaxmanagerApp.settingsInstance.home.title'
            }
        })
        .state('settings-instance-detail', {
            parent: 'settings-instance',
            url: '/{id}/view',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.settingsInstance.detail.title',
                headerLabel:'vmaxmanagerApp.settingsInstance.process.viewSettings',
                hideButtons: true,
                showChangeSettingsButton: true,
                isView: true
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                    controller: 'SettingsInstanceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settingsInstance');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                    return SettingsInstance.get({id : $stateParams.id}).$promise;
                }],
                licenseEntity: function () { return null; },
                deviceEntity: function () { return null; }
            },
             ncyBreadcrumb: {
               label: 'entity.action.view',
               parent: 'settings-instance'
           }
        })
        .state('settings-instance.new', {
            parent: 'settings-instance',
            url: '/new',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.settingsInstance.new.title',
                headerLabel: 'vmaxmanagerApp.settingsInstance.process.newSettings',
                hideButtons: true,
                showChangeSettingsButton: true,
                isNew: true
            },
            params: {
                parentState: null,
                id: null,
                deviceId: null,
                deviceTypeId: null
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                    controller: 'SettingsInstanceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settingsInstance');
                    return $translate.refresh();
                }],
                entity: function () {
                            return {
                                name: null,
                                notes: null,
                                template: null,
                                id: null
                            };
                },
                licenseEntity: function () { return null; },
                deviceEntity: function () { return null; }
            },
            ncyBreadcrumb: {
              label: 'entity.action.new',
              parent: 'settings-instance'
          }
        })
        .state('settings-instance.edit', {
            parent: 'settings-instance',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.settingsInstance.edit.title',
                headerLabel: 'vmaxmanagerApp.settingsInstance.process.editSettings',
                hideButtons: true,
                showChangeSettingsButton: true,
                isEdit: true
            },
            params: {
                parentState: null,
                id: null,
                deviceId: null,
                deviceTypeId: null
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                    controller: 'SettingsInstanceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settingsInstance');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                    return SettingsInstance.get({id : $stateParams.id}).$promise;
                }],
                licenseEntity: function () { return null; },
                deviceEntity: function () { return null; }
            },
            ncyBreadcrumb: {
               label: 'entity.action.edit',
               parent: 'settings-instance'
           }
        })
        .state('settings-instance.clone', {
            parent: 'settings-instance',
            url: '/{deviceId}/clone',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.settingsInstance.clone.title',
                headerLabel: 'vmaxmanagerApp.settingsInstance.process.cloneSettings',
                hideButtons: true,
                showChangeSettingsButton: true,
                isClone: true
            },
            params: {
                parentState: null,
                id: null,
                deviceId: null,
                deviceTypeId: null
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                    controller: 'SettingsInstanceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settingsInstance');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                    return SettingsInstance.get({id : $stateParams.id}).$promise;
                }],
                licenseEntity: function () { return null; },
                deviceEntity: function () { return null; }
            },
             ncyBreadcrumb: {
               label: 'entity.action.clone',
               parent: 'settings-instance'
           }
        })
        .state('settings-instance.delete', {
            parent: 'settings-instance',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
            },
            ncyBreadcrumb: {
                label: 'vmaxmanagerApp.settingsInstance.home.title',
                parent: 'entity'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-delete-dialog.html',
                    controller: 'SettingsInstanceDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['SettingsInstance', function(SettingsInstance) {
                            return SettingsInstance.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('settings-instance', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('settings-instance.settings-change', {
            parent: 'settings-instance',
            url: '/{deviceId}/change-settings',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.settingsInstance.edit.title',
                headerLabel:'vmaxmanagerApp.settingsInstance.process.changeSettings',
                hideButtons: true,
                showChangeSettingsButton: true,
                isEdit: true,
                isChangeSettings: true
            },
            params: {
                parentState: null,
                id: null,
                deviceId: null,
                deviceTypeId: null,
                settingsId: null
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                    controller: 'SettingsInstanceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settingsInstance');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                    return SettingsInstance.get({id : $stateParams.id}).$promise;
                }],
                licenseEntity: function () { return null; },
                deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                    return $stateParams.deviceId? Device.get({id : $stateParams.deviceId}).$promise: null;
                }]
            },
            ncyBreadcrumb: {
               label: 'entity.action.change',
               parent: 'settings-instance'
           }
        });
    }
})();
