'use strict';

angular.module('vmaxmanagerApp')
.service('Timezone', function () {
    var service = {
        getTimezones : getTimezones
    };

    return service;

    var timezones = [];

    function loadTimezone () {
        var tz = [];
        _.forEach( moment.tz.names(), function(timezone) {
            var timezoneId =  timezone;
            var zone = moment.tz(timezone).format("Z");
            var timezoneDescription = "(GTM" + zone + ") - " + timezoneId;
            tz.push({name: timezoneId, description: timezoneDescription});
        });
        timezones = tz;
    };

    function getTimezones() {
        if (!timezones) {
            loadTimezone();
        }

        return  timezones;
    }
});
