(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('AppResourceDialogController', AppResourceDialogController);

    AppResourceDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'AppResource', 'DeviceType', 'allDeviceStatus',
                                            'profiles', 'AppResourceProfilerList', '$state', 'AppResourceUserProfileByResource'];

    function AppResourceDialogController ($timeout, $scope, $stateParams, entity, AppResource, DeviceType, allDeviceStatus,
                                            profiles, AppResourceProfilerList, $state, AppResourceUserProfileByResource) {
        var vm = this;

        vm.appResource = entity;
        vm.save = save;
        vm.devicetypes = DeviceType.query();
        vm.profiles = profiles;

        vm.deviceStatusModel = [];

        var status = [];
        var statusModel = [];

        vm.profilesModel = [];

        var profilesToImportData = [];
        var profilesToImportModel = [];

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function save () {
            vm.isSaving = true;
            var allStatus = vm.joinDeviceStatusModel(vm.deviceStatusModel);
            vm.appResource.statusToDownload = allStatus;
            if (vm.appResource.id !== null) {
                AppResource.update(vm.appResource, onSaveSuccess, onSaveError);
            } else {
                AppResource.save(vm.appResource, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            var listResources = [];

            _.each(vm.profileModel, function(data){
                listResources.push({id:data.id, name: data.label});
            });

            vm.appResource.id = result.id;

            var resource = {
                appResource : vm.appResource,
                listResourceUserProfile : listResources,
                id : null
            };

            AppResourceProfilerList.saveList(resource, onSaveSuccessResourceUser, onSaveErrorResourceUser);

            $scope.$emit('vmaxmanagerApp:appResourceUpdate', result);

            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function onSaveSuccessResourceUser (result) {
            vm.isSaving = false;
            $state.go("app-resource");
        }

        function onSaveErrorResourceUser () {
            vm.isSaving = false;
            $state.go("app-resource");
        }

        var transformModelFromDeviceStatus = function(allStatus, deviceStatus){
            for(var i=0; i < allStatus.length; i++){
                if(deviceStatus){
                    for(var j=0; j < deviceStatus.length; j++){
                        if(allStatus[i] == deviceStatus[j]){
                            statusModel.push({id: i, label:allStatus[i]});
                        }
                    }
                }
                status.push({id: i, label:allStatus[i]});
            }
            vm.deviceStatusData = status;
            vm.deviceStatusModel = statusModel;
        }

        var bindResourceStatus = function(){
            if(allDeviceStatus){
                if (vm.appResource.id !== null) {
                    if(vm.appResource.statusToDownload){
                        var statusProviderFromEntity = vm.appResource.statusToDownload.split(",");
                        transformModelFromDeviceStatus(allDeviceStatus, statusProviderFromEntity);
                    }else{
                        transformModelFromDeviceStatus(allDeviceStatus, null);
                    }
                }else{
                    transformModelFromDeviceStatus(allDeviceStatus);
                }
            }
        }

        vm.joinDeviceStatusModel = function(deviceStatusModel){
            var joinStatus = "";
            for(var i=0; i<deviceStatusModel.length; i++){
                if(i == deviceStatusModel.length - 1){
                    joinStatus += deviceStatusModel[i].label;
                } else {
                    joinStatus += deviceStatusModel[i].label + ",";
                }
            }
            return joinStatus;
        }

        var transformModelFromProfiles = function(allProfiles){
            _.each(allProfiles, function(profile){
                if(profile.assigned){
                    profilesToImportModel.push({id: profile.userProfile.id, label:profile.userProfile.name});
                }
                profilesToImportData.push({id: profile.userProfile.id, label:profile.userProfile.name});
            });
            vm.profilesData = profilesToImportData;
            vm.profileModel = profilesToImportModel;
        }

        vm.bindProfiles = function(profiles){
            if(profiles){
                transformModelFromProfiles(profiles);
            }
        }


        bindResourceStatus();
        vm.bindProfiles(profiles);

        vm.onSuccessChangeDeviceType = function (result) {
            if(result != null && result.length > 0) {
                vm.profiles = result;
                vm.bindProfiles(result);
            } else {
                vm.profiles = null;
            }
        }

        vm.loadProfilesByDeviceTypeId = function(){
            vm.profilesData = [];
            vm.profileModel = [];
            profilesToImportData = [];
            profilesToImportModel = [];
            if (vm.appResource.deviceType !== null) {
                AppResourceUserProfileByResource.getAppResourceUserProfilesByDeviceTypeId(
                    {id : vm.appResource.deviceType.id}, vm.onSuccessChangeDeviceType);
            }
        }
    }
})();
