'use strict';

angular.module('vmaxmanagerApp')
    .factory('User', function ($resource) {
        return $resource('api/users/:login', {}, {
                'query': {method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data;
                    }
                },
                'save': { method:'POST' },
                'update': { method:'PUT' },
                'delete':{ method:'DELETE'},
                'filterQuery': { method: 'POST', url: 'api/users/query', isArray:true },
                'filterVars': { method: 'GET', url: 'api/users/info', isArray:true }
            });
        });
