'use strict';

angular.module('vmaxmanagerApp')
    .controller('BulkDeviceOperationDetailsManualController', function($scope, $state, $translate, entity, BulkConstants, BulkService, DateUtils, providersList, statusList) {
        BulkService.resetResponseConfirmation();
        $scope.providersList = providersList;
        $scope.statusList = statusList;
        $scope.fieldsValuesSet = entity;
        $scope.checkedFieldsSet = {
                        provider: !_.isEmpty($scope.fieldsValuesSet.provider),
                        provisionDate: !_.isEmpty($scope.fieldsValuesSet.provisionDate),
                        status: !_.isEmpty($scope.fieldsValuesSet.status)
                    };
        /*
        * Check which fields were selected.
        */
        $scope.checkFields = function() {
            var op = $scope.checkedFieldsSet;
            if (!op.provider) {
                $scope.fieldsValuesSet.provider = null;
            }
            if (!op.provisionDate) {
                $scope.fieldsValuesSet.provisionDate = null;
            }
            if (!op.status) {
                $scope.fieldsValuesSet.status = null;
            }
            if (!op.provider && !op.provisionDate && !op.status) {
                $scope.isFormDisabled = true;
            } else {
                $scope.isFormDisabled = null;
            }
        };

        $scope.checkFields();

        /*
        * Go to the next step.
        */
        $scope.goToNextStep = function() {
            var updateFields = buildUpdateFields();
            BulkService.setResponseConfirmation(updateFields);
            $state.go("bulkDevice.confirmation-manual");
        }

        /*
        * Builds the data set with the fields to update.
        */
        var buildUpdateFields = function() {
            var response = [];

            if (!$scope.checkedFieldsSet) {
                return response;
            }

            if ($scope.fieldsValuesSet.provider && $scope.checkedFieldsSet.provider) {
                var item = buildUpdateItem(null,
                                           $scope.fieldsValuesSet.provider.name,
                                           $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.provider'),
                                           BulkConstants.device.provider);
                response.push(item);
            }
            if ($scope.fieldsValuesSet.provisionDate && $scope.checkedFieldsSet.provisionDate) {
                var item = buildUpdateItem($scope.fieldsValuesSet.provisionDate,
                                           $scope.fieldsValuesSet.provisionDate,
                                           $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.provisionDate'),
                                           BulkConstants.device.provisionDate);
                response.push(item);
            }
            if ($scope.fieldsValuesSet.status && $scope.checkedFieldsSet.status) {
                var item = buildUpdateItem(null,
                                           $scope.fieldsValuesSet.status,
                                           $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.deviceStatus'),
                                           BulkConstants.device.status);
                response.push(item);
            }

            return response;
        };

        /*
        * Builds the field to update.
        */
        var buildUpdateItem = function(internalValue, value, columnName, columnId) {
            return {
                "type": 'manual',
                "internalValue": internalValue,
                "value": value,
                "label": $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelManual'),
                "columnName": columnName,
                "columnId": columnId
            };
        };

    });
