'use strict';

angular.module('vmaxmanagerApp').controller('ServerDialogController',
    ['$scope', '$stateParams', 'entity', 'Server', '$state',
        function($scope, $stateParams, entity, Server, $state) {

        $scope.server = entity;
        $scope.load = function(id) {
            Server.get({id : id}, function(result) {
                $scope.server = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:serverUpdate', result);
            $state.go('server');
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.server.id != null) {
                Server.update($scope.server, onSaveSuccess, onSaveError);
            } else {
                Server.save($scope.server, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $state.go('server');;
        };
}]);
