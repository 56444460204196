(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('AppResourceController', AppResourceController);

    AppResourceController.$inject = ['$scope', '$state', 'AppResource', 'ParseLinks', 'AlertService'];

    function AppResourceController ($scope, $state, AppResource, ParseLinks, AlertService) {
        var vm = this;

        vm.appResources = [];
        vm.loadPage = loadPage;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reverse = true;

        //filter configs
        vm.filterConfig = {
            inputGroupClass: '',
            btnClass: 'btn-raised'
        };

        vm.fields = AppResource.filterVars();

        loadAll();

        function loadAll () {

            var pageable = {page: vm.page, size: 40, sort: sort()};

            AppResource.filterQuery({pageable:pageable, filters:vm.queryObject}, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.appResources.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.error);
            }
        }

        vm.refresh = function () {
            vm.page = 0;
            vm.appResources = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }
    }
})();
