'use strict';

angular.module('vmaxmanagerApp')
    .factory('SettingsTemplate', function ($resource) {
        return $resource('api/settingsTemplates/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getSettingsTemplatesByTypeSettingsAndSoftwareGroup': {
                 method:'GET',
                 isArray: true,
                 url:'api/getSettingsTemplatesByTypeSettingsAndSoftwareGroup?typeSettings=:typeSettings&softwareGroup=:softwareGroup'
            },
            'getSettingsTemplatesByTypeSettingsAndDeviceType': {
                 method:'GET',
                 isArray: true,
                 url:'api/getSettingsTemplatesByTypeSettingsAndDeviceType?typeSettings=:typeSettings&deviceType=:deviceType'
            },
            'getSettingsTemplatesByDeviceType': {
                 method:'GET',
                 isArray: true,
                 url:'api/getSettingsTemplatesByDeviceType/:deviceType'
            }
        });
    });
