'use strict';

angular.module('vmaxmanagerApp')
    .controller('LicenseController', function ($scope, $state, License, ParseLinks, LicenseService, DownloadService, AlertService, $filter) {

        $scope.licenses = [];
        $scope.sorts = ["fleet.fleetId,asc","expiryDate,desc","id,asc"];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.showExpired = false;

        //filter configs
        $scope.filterConfig = {
            inputGroupClass: '',
            btnClass: 'btn-raised'
        };

        $scope.filterInitialRules = {
            'operator' : 'and',
            'rules' : [{condition: '!=', field:{name: 'licenseStatus', type:"text"}, data:'EXPIRED'}]
        };

        $scope.loadAll = function() {
        	var pageable = {page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']};
            License.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.licenses.push(result[i]);
                }
            });
        };

        $scope.reset = function() {
            $scope.page = 0;
            $scope.licenses = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };

        License.filterVars({}, function(data) {
            var f = [];
            angular.forEach(data, function(d) {
                f.push(d);
            });
            $scope.fields = f;
            $scope.loadAll();
        });

        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.license = {
                slots: null,
                slotUsed: null,
                salesOrder: null,
                previousSalesOrder: null,
                startDate: null,
                duration: null,
                expiryDate: null,
                dataAmount: null,
                chargeType: null,
                licenseStatus: null,
                notes: null,
                id: null
            };
        };

        /**
         * method is invoked when going to export one or many licenses.
         *
         * @author jmontoya
         */
        $scope.export = function(license) {
            $scope.license = License.get({id: license.id},function(){
                if(license.licenseStatus === 'DRAFT'){
                    // modal confirmation download licenses
                    LicenseService.downloadLicenseModal($scope, LicenseService.message.export, $scope.license, 'export', LicenseService.modalTitle.other);
                }else{
                    DownloadService.downloadFile('api/exportLicense/' + license.fleet.id + '/' + license.fleet.fleetId + '/' + license.id);
                }
            });
        };

        /**
         * Method call when action export is confirm.
         *
         * @author jmontoya
         */
        $scope.exportModalMethod = function(license) {
            $scope.license.licenseStatus = 'PUBLISHED';
            License.save($scope.license, function () {
                $scope.refresh();
                var downloadFile = $("input[name='downloadFile']:checked").val();
                if(downloadFile){
                    DownloadService.downloadFile('api/exportLicense/' + license.fleet.id + '/' + license.fleet.fleetId + '/' + license.id);
                }
            });
        }

        /***********************************************************************
         * @author wmontalvo [gmendoza] has to put his implementation here.
         **********************************************************************/
        $scope.upload=function(license){
            LicenseService.downloadLicenseModal($scope, LicenseService.message.upload, license, 'upload', LicenseService.modalTitle.upload);
        };

        /***********************************************************************
         * @author wmontalvo if a license is in PUBLISHED status then it has to
         *         change the status to CANCELLED.
         **********************************************************************/
        $scope.cancelLicense = function(license){
            // Check if a license is in PUBLISHED status then it has to change
            // the status to CANCELLED.
            if(license.licenseStatus==='PUBLISHED'){
                $scope.license = License.get({id: license.id},function(license){
                    if(license.devices.length > 0){
                        AlertService.error($filter('translate')('vmaxmanagerApp.license.cannotCancelBecauseDevicesAssigned'));
                    }else{
                        // modal confirmation download licenses
                        var messages = LicenseService.message.cancel;
                        LicenseService.downloadLicenseModal($scope, messages, license, 'cancel', LicenseService.modalTitle.cancel);
                    }
                });
            }
        };

        /**
         * Method call when action cancel is confirm.
         *
         * @author jmontoya
         */
        $scope.cancelandExport = function(license){
            $scope.license = License.get({id: license.id},function(){
                $scope.license.licenseStatus = 'CANCELLED';
                License.save($scope.license, function () {
                    $scope.refresh();
                    var downloadFile = $("input[name='downloadFile']:checked").val();
                    if(downloadFile){
                        DownloadService.downloadFile('api/exportLicense/' + license.fleet.id + '/' + license.fleet.fleetId + '/' + license.id);
                    }
                });
            });
        };

        /**
         * Method call when upload action is confirmed for a  cloud license.
         */
        $scope.uploadLicense = function(license){
              license.licenseStatus = "PUBLISHED";
              License.save(license);
        };

        /**
        * Edit settings instance from license summary.
        */
        $scope.editSettings = function(evt, licenseId, settingsInstanceId) {
            if (licenseId && settingsInstanceId) {
                $state.go('license.edit.settings-view', {id: licenseId, settingsId: settingsInstanceId, parentState: 'license'});
            }
            evt.stopPropagation();
        }

    });
