'use strict';

angular.module('vmaxmanagerApp')
    .controller('ResetFinishController', function ($scope, $stateParams, $timeout, Auth, Parameters) {

        $scope.keyMissing = $stateParams.key === undefined;
        $scope.doNotMatch = null;
        $scope.validationPassword = Parameters.getRegularExpressionValidationPassword();
        $scope.resetAccount = {};
        $timeout(function (){angular.element('[ng-model="resetAccount.password"]').focus();});

        $scope.finishReset = function() {
            if ($scope.resetAccount.password !== $scope.confirmPassword) {
                $scope.doNotMatch = 'ERROR';
            } else {
                Auth.resetPasswordFinish({key: $stateParams.key, newPassword: $scope.resetAccount.password}).then(function () {
                    $scope.success = 'OK';
                    $scope.doNotMatch = null;
                }).catch(function (response) {
                    $scope.success = null;
                    $scope.doNotMatch = null;
                    $scope.error = 'ERROR';
                });
            }

        };
    });
