'use strict';

angular.module('vmaxmanagerApp')
    .factory('Device', function ($resource, DateUtils) {
        return $resource('api/devices/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    if (data.expirationDate) {
                        data.expirationDate = new Date(data.expirationDate);
                    }
                    if (data.provisionDate) {
                        data.provisionDate = new Date(data.provisionDate);
                    }
                    return data;
                }
            },
            'filterQuery': { method: 'POST', url: 'api/devices/query', isArray:true },
            'filterVars': { method: 'GET', url: 'api/devices/info', isArray:true },
            'update': {method:'PUT'}
        });
    })
    .factory('AllDeviceStatus', function ($resource) {
        return $resource('api/devices/findAllDeviceStatuses', {}, {
            'query' : { method: 'GET', isArray: true}
        });
    })
    .factory('DeviceStatusWorkflow', function ($resource) {
        return $resource('api/devices/getDeviceStatusWorkflowList/:status', {}, {
            'query' : { method: 'GET', isArray: true}
        });
    })
    .factory('DeviceWithoutQuarantine', function ($resource) {
        return $resource('api/devices/devicesWithoutQuarantine/:id', {}, {
            'query': { method: 'POST', isArray: true}
        });
    })
    .factory('DeviceStatusMonitoring', function ($resource, DateUtils) {
        return $resource('api/devices/statusMonitoring/:id', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.expirationDate = !_.isEmpty(data.expirationDate) ? new Date(data.expirationDate) : null;
                    data.provisionDate =  !_.isEmpty(data.provisionDate) ?  moment(new Date(data.provisionDate)).format('MM/DD/YYYY HH:mm:ss') : null;
                    if (!_.isEmpty(data.licenses) && _.isArray(data.licenses)) {
                        _.each(data.licenses, function(license) {
                            license.startDate = !_.isEmpty(license.startDate) ? moment (license.startDate).format('MM/DD/YYYY') : null;
                            license.expiryDate = !_.isEmpty(license.expiryDate) ? moment (license.expiryDate).format('MM/DD/YYYY') : null;
                        });
                    }
                    return data;
                }
            }
        });
    });
