'use strict';

angular.module('vmaxmanagerApp')
       .factory('Commander', function ($http) {
    return {
        getFleets: function(serverId, callback){
            $http.get('app/rest/commander/fleets/' + serverId).
            success(function(data, status, headers, config) {
                callback(null, data);
            }).
            error(function(data, status, headers, config) {
                callback({data: data, status: status});
            });
        },
        getYards: function(serverId, fleetId, callback){
            $http.get('app/rest/commander/yards/' + serverId + '/' + fleetId).
            success(function(data, status, headers, config) {
                callback(null, data);
            }).
            error(function(data, status, headers, config) {
                callback({data: data, status: status});
            });
        },
        getTaskSummaryList: function(serverId, fleetId, yardId, callback){
            var url = 'app/rest/commander/tasks/' + serverId +'/'+ fleetId;
            if(yardId) {
                url = url + '?yardId=' + yardId;
            }
            $http.get(url).
            success(function(data, status, headers, config) {
                callback(null, data);
            }).
            error(function(data, status, headers, config) {
                callback({data: data, status: status});
            });
        },
        getTaskList: function(serverId, fleetId, yardId, vehicleId, callback){
            $http.get('app/rest/commander/task/' + serverId + '/' + fleetId+ '/' + yardId + '/' + vehicleId).
            success(function(data, status, headers, config) {
                callback(null, data);
            }).
            error(function(data, status, headers, config) {
                callback({data: data, status: status});
            });
        }
    };
});
