'use strict';

angular.module('vmaxmanagerApp')
	.controller('BulkDeviceConfirmationNextController', function($scope, $state, $uibModalInstance, $stateParams, BulkService) {

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.continueWorkflow = function() {
            var result;
            if ( $state.current.parent == 'bulkDevice.confirmation-manual') {
                result = BulkService.buildManualDataSet();
            } else {
                result = BulkService.buildResponse();
            }

            if ( !_.isNull(result) ) {
                BulkService.setDevicesToUpdate(result);
                $state.go("bulkDevice.results", $uibModalInstance.close());
            }
        }

    });
