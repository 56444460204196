'use strict';

angular.module('vmaxmanagerApp').controller('LicenseDialogController',
    ['$scope', '$stateParams', 'entity', 'License', 'LicenseStatus', 'ChargeType', '$q', 'Software', 'SlotType', 'Fleet', 'SoftwareLicense', 'SettingsInstance', '$state', 'LicenseService', 'DownloadService',
        function($scope, $stateParams, entity, License,  LicenseStatus, ChargeType, $q, Software, SlotType, Fleet, SoftwareLicense, SettingsInstance, $state, LicenseService, DownloadService) {

        var checkStartDate = function(){
            var now = entity.startDate;

            if(entity.startDate && moment(entity.startDate).isValid()){
                entity.startDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),  now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
            }
        }

        checkStartDate();

        $scope.license = entity;
        $scope.isEdit = false;
        $scope.addOther = false;

        $scope.licenseStatus = LicenseStatus.query();
        $scope.chargeTypes = ChargeType.query();
        $scope.fleets = Fleet.query();
        $scope.licenseDurations = [
            {id: 1, name: "1 Month"},
            {id: 3, name: "3 Months"},
            {id: 6, name: "6 Months"},
            {id: 12, name: "12 Months"},
            {id: 0, name: "Custom (Months)"},
        ];

        $q.all([$scope.license.$promise]).then(function() {
            if($scope.license.id){
                $q.all([$scope.licenseStatus.$promise, $scope.chargeTypes.$promise, $scope.fleets.$promise]).then(function() {
                    $scope.isEdit = true;
                    $scope.isNotDraft = $scope.license.licenseStatus !== 'DRAFT';
                    $scope.setDuration();
                    $scope.license.startDate = new Date($scope.license.startDate);
                    var chargeType = _.filter($scope.chargeTypes, function(chargeType){ return chargeType.name == $scope.license.chargeType; });
                    $scope.license.chargeType = chargeType[0].id;
                    $scope.requiredData = $scope.license.slotType.requiresData;
                    $scope.software = $scope.license.slotType.software;
                    $scope.selectedFleet();
                });
            }
        });

        $scope.setDuration = function(){
            if($scope.license.duration != 1 && $scope.license.duration != 3 && $scope.license.duration != 6 && $scope.license.duration != 12){
                $scope.license.customMonth = $scope.license.duration;
                $scope.license.duration = 0;
            }
            $scope.durationChange();
        };

        /**
         * duration custom change.
         * @author jmontoya
         */
        $scope.durationChange = function(){
            $scope.requiredCustom = $scope.license.duration == 0;
            if(!$scope.requiredCustom){
                $scope.license.customMonth = null;
            }else{
                $scope.editForm.$invalid = $scope.license.customMonth == null;
            }
        };

        /**
         * selected Fleet.
         * @author jmontoya
         */
        $scope.selectedFleet = function() {
            var fleet = _.where($scope.fleets, { 'id' : $scope.license.fleet.id } );
            var isCloud =  fleet[0] && fleet[0].deploymentType == 'CLOUD';

            $scope.showAndRequiredSoftwareVersion($scope.license.slotType);

            Software.query(function(result){
                if(isCloud){
                    $scope.softwares = _.filter(result, function(item){
                        return item.cloud == isCloud;
                    });
                }else{
                    $scope.softwares = _.filter(result, function(item){
                        return item.local == !isCloud;
                    });
                }
                if($scope.isEdit){
                    $scope.selectedSoftware();
                }
            });
        };

        /**
         * selected software.
         * @author jmontoya
         */
        $scope.selectedSoftware = function(){
            $scope.slottypes = {};
            SlotType.query(function(result){
                $scope.slottypes = _.filter(result, function(item){
                    return item.software.id == $scope.software.id;
                });

                _.each($scope.slottypes, function(slottype){
                    if(slottype.feature != null && slottype.feature != ''){
                        slottype.slotTypeName = slottype.slotType + ' - ' +  slottype.feature;
                    }else{
                        slottype.slotTypeName = slottype.slotType ;
                    }
                });
                if($scope.isEdit){
                    $scope.selectedSlotType();
                }
            });
        };

        /**
         * selected slotType.
         * @author jmontoya
         */
        $scope.selectedSlotType = function(){
            if($scope.license.slotType){
                var slot = _.where($scope.slottypes, { "id": $scope.license.slotType.id });
                if(slot[0]){
                    $scope.requiredData =  slot[0].requiresData;
                    if(!$scope.requiredData){
                        $scope.license.dataAmount = null;
                    }
                    $scope.showAndRequiredSlot(slot[0]);
                    $scope.showAndRequiredSoftwareVersion(slot[0]);
                }else{
                    $scope.editForm.$invalid = true;
                }
                //Settings options
                if($scope.license.slotType.slotType === 'APP') {
                    $scope.settings = SettingsInstance.getSettingsInstanceByTypeSettingsAndAndSoftwareGroup({typeSettings:'SOFTWARE', softwareGroup:$scope.license.slotType.software.softwareGroup.id});
                    if ($stateParams.settingsId && $scope.license.id) {
                        if (parseInt($scope.license.settingsInstance.id) != parseInt($stateParams.settingsId)) {
                            $q.all([$scope.settings.$promise]).then(function() {
                                _.each($scope.settings, function(settingsInstance){
                                    if(parseInt(settingsInstance.id) == parseInt($stateParams.settingsId)){
                                        $scope.license.settingsInstance = settingsInstance;
                                    }
                                });
                            });
                        }
                    }
                } else {
                    $scope.license.settingsInstance = null;
                }

            }
        };

        /**
         * show and required slot
         * @author jmontoya
         */
        $scope.showAndRequiredSlot = function(slot){
            if(slot && slot.requiresSlot){
                $scope.showSlot = true;
            }else{
                $scope.showSlot = false;
                $scope.license.slots = null;
            }
        };

        /**
         * show and required slot
         * @author jcardona
         */
        $scope.showAndRequiredSoftwareVersion = function(slot){

            $scope.softwarelicenses = {};
            $scope.showRequiresSoftwareVersion = false;
            $scope.requiresSoftwareVersion = false;

            if(slot){
                var software = _.where($scope.softwares,{"id" : $scope.software.id})
                if(slot.slotType == 'APP' && software.length> 0){
                    $scope.softwarelicenses = SoftwareLicense.getSoftwareLicensesByGroupId({softwaregroupid : software[0].softwareGroup.id});
                    if($scope.license && $scope.license.fleet){
                        var fleetSelected = $scope.fleets.filter(function(fleet){
                            return fleet.id == $scope.license.fleet.id;
                        });
                        if(fleetSelected && fleetSelected[0] && fleetSelected[0].deploymentType === "ONSITE"){
                            $scope.showRequiresSoftwareVersion = true;
                            $scope.requiresSoftwareVersion = true;
                        }
                    }
                }
            }
        };

        $scope.create = function () {
             // Prevent save license with expiration date minor to today date.
             if($scope.license.startDate!==''){
                 // Get start date of the license
                 var startingDate = new Date($scope.license.startDate);
                 // Get the license month duration
                 var monthsExpiration = $scope.license.duration != 0 ? $scope.license.duration : $scope.license.customMonth;
                 // Build the expiration date
                 var expirationDate = new Date(new Date(startingDate).setMonth(startingDate.getMonth() + monthsExpiration));
                 if(expirationDate < $scope.today){
                     AlertModalsService.error($filter('translate')('vmaxmanagerApp.license.licenseExpiresEarlierThanToday'));
                     return;
                 }
             }

             //Selected custom duration.
             if($scope.license.duration == 0){
                 $scope.license.duration = $scope.license.customMonth;
             }

             if(!$scope.license.id){
                 // To set the status in draft.
                 $scope.license.licenseStatus = 'DRAFT';
             }

             // Verify if the Slots are at least the minimum by checking the number of devices assigned.
             if($scope.isEdit === true && $scope.license.slots && $scope.license.devices.length > $scope.license.slots){
                 AlertModalsService.error($filter('translate')('vmaxmanagerApp.license.slotsLessThanDevices'));
                 return;
             }

             if($scope.isEdit === true && $scope.license.licenseStatus === 'PUBLISHED') {
                 LicenseService.downloadLicenseModal($scope, LicenseService.message.update, $scope.license, 'edit', LicenseService.modalTitle.other);
             }else{
                 License.save($scope.license, function () {
                     if(!$scope.addOther){
                         $state.go('license');
                     } else {
                         $scope.clear();
                     }
                 });
             }
         };

        $scope.clear = function () {
            $scope.license = {
                fleet:{
                   id: $scope.license.fleet ? $scope.license.fleet.id : null
                },
                slots: null,
                salesOrder: $scope.license.salesOrder,
                previousSalesOrder: $scope.license.previousSalesOrder,
                startDate: new Date($scope.license.startDate),
                requiresData: null,
                dataAmount: null,
                chargeType: $scope.license.chargeType,
                slotType: null,
                notes: null,
                licenseStatus: $scope.license.licenseStatus,
                duration: $scope.license.duration,
                customMonth:$scope.license.customMonth,
                id: null
            };
            $scope.setDuration($scope.license.duration);
            $scope.isEdit=false;
            $scope.addOther=false;
            $scope.isNotDraft=false;
            $scope.softwarelicenses = {};
            $scope.requiredData = false;
            $scope.selectedFleet();
        };

        /**
         * Method call when action update is confirm.
         *
         * @author jmontoya
         */
        $scope.updateAndExport = function (license){
            $scope.license = license;
            License.save($scope.license, function () {
                $('#saveLicenseModal').modal('hide');
                var downloadFile = $("input[name='downloadFile']:checked").val();
                if(downloadFile){
                    DownloadService.downloadFile('api/exportLicense/' + license.fleet.id + '/' + license.fleet.fleetId + '/' + license.id);
                }
                $state.go('license');
            });
        };

        /**
        * Edit settings instance from license edit.
        */
        $scope.editSettings = function(evt) {
            if ($scope.license !== null && $scope.license.id !== null && $scope.license.id !== undefined && $scope.license.settingsInstance) {
                $state.go('license.edit.settings-view', {id: $scope.license.id, settingsId: $scope.license.settingsInstance.id, parentState: 'license.edit'});
            }
            evt.stopPropagation();
        }
}]);
