'use strict';

angular.module('vmaxmanagerApp')
    .service('DateUtils', function ($filter) {

    this.convertLocaleDateToServer = function(date, timezone) {
        if (date) {
            if(timezone){
                return $filter('date')(date, 'MM/dd/yyyy', timezone);
            }else{
                return $filter('date')(date, 'MM/dd/yyyy');
            }
        } else {
            return null;
        }
    };

    this.convertLocaleDateFromServer = function(date) {
        if (date) {
            var dateString = date.split("-");
            return new Date(dateString[0], dateString[1] - 1, dateString[2]);
        }
        return null;
    };

    this.convertDateTimeFromServer = function(date) {
        if (date) {
            return new Date(date);
        } else {
            return null;
        }
    }

    // common date format for all date input fields
    this.dateformat = function() {
        return 'MM/dd/yyyy';
    }
})
.filter('parseDateFormat', ['DateUtils', function (DateUtils) {
    return function (input) {
        return DateUtils.convertLocaleDateToServer(input, "UTC");
    }
}])
.filter('parseLocalFormat', [function () {
    return function(d) {
        if (!d) return '';

        var dateMoment = moment(d).toLocaleString();

        var date = moment(dateMoment).format('YYYY-MM-DD HH:mm:ss');

        var d = date + " UTC";

        var dateFinal = new Date(d);

        return moment(dateFinal).format('YYYY-MM-DD HH:mm:ss');
    }
}]);
