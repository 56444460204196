'use strict';

angular.module('vmaxmanagerApp')
    .factory('AssignDeviceService', function ($http) {

    	/**
    	 * Method that retrieves the Devices available to be assigned to the License.
    	 * @author william.cornejo
    	 */
        var findAvailableToLicense = function(licenseId, deviceTypeId, callback) {
        	var params;
        	if (deviceTypeId) {
        		params = '?licenseId=' + licenseId +'&deviceTypeId=' + deviceTypeId;
        	} else {
        		params = '?licenseId=' + licenseId;
        	}
            var req = {
                method : 'GET',
                url : 'api/devices/findAvailableToLicense' + params,
                headers : {
                    'Content-Type' : 'application/json'
                }
            };
            $http(req).success(function(response, status, headers, config) {
                var result = response;
                if (callback) {
                    callback(result, headers);
                }
                return result;
            }).error(function(data, status, headers, config) {
                console.log(data);
                // called asynchronously if an error occurs or server returns response with an error status.
            });
        };

        /**
         * Finds the Devices that have already been assigned to a License
         * @author william.cornejo
         */
        var findAssignedByLicense = function(licenseId,deviceTypeId,callback){

        	var params;
        	if (deviceTypeId) {
        		params = '?licenseId=' + licenseId +'&deviceTypeId=' + deviceTypeId;
        	} else {
        		params = '?licenseId=' + licenseId;
        	}
        	var req = {
                method : 'GET',
                url : 'api/devices/findAssignedByLicense' + params,
                headers : {
                    'Content-Type' : 'application/json'
                }
            };
            $http(req).success(function(response, status, headers, config) {
                var result = response;
                if (callback) {
                    callback(result, headers);
                }
                return result;
            }).error(function(data, status, headers, config) {
                console.log(data);
                // called asynchronously if an error occurs or server returns response with an error status.
            });
        };

        /**
         * Assign devices to the License
         * @author william.cornejo
         */
        var assignDevicesToLicense = function(assignDeviceDto, callback){
        	var req = {
                method : 'PUT',
                url : 'api/devices/assignDevicesToLicense/',
                headers : {
                    'Content-Type' : 'application/json'
                },
                data : assignDeviceDto
            };
            $http(req).success(function(response, status, headers, config) {
                var result = response;
                if (callback) {
                    callback(result, headers);
                }
                return result;
            }).error(function(data, status, headers, config) {
                console.log(data);
                // called asynchronously if an error occurs or server returns response with an error status.
            });
        };

        /**
         * Unassigns a Device from a License
         * @author william.cornejo
         */
        var unAssignDeviceToLicense = function(unAssignDeviceDto, callback){
        	var req = {
                    method : 'PUT',
                    url : 'api/devices/unAssignDeviceToLicense/',
                    headers : {
                        'Content-Type' : 'application/json'
                    },
                    data : unAssignDeviceDto
                };
                $http(req).success(function(response, status, headers, config) {
                    var result = response;
                    if (callback) {
                        callback(result, headers);
                    }
                    return result;
                }).error(function(data, status, headers, config) {
                    console.log(data);
                    // called asynchronously if an error occurs or server returns response with an error status.
                });
        }

        /**
         * Retrieves the possible Device Statuses
         * @author william.cornejo
         */
        var findDeviceStatuses = function(callback){
        	var req = {
                    method : 'GET',
                    url : 'api/devices/findDeviceStatuses/',
                    headers : {
                        'Content-Type' : 'application/json'
                    }
                };
                $http(req).success(function(response, status, headers, config) {
                    var result = response;
                    if (callback) {
                        callback(result, headers);
                    }
                    return result;
                }).error(function(data, status, headers, config) {
                    console.log(data);
                    callback(undefined, headers, data)
                });
        }

        return {
        	findAvailableToLicense : function(licenseId, deviceTypeId, callback) {
        		findAvailableToLicense(licenseId, deviceTypeId, callback);
            },
            findAssignedByLicense : function(licenseId,deviceTypeId,callback) {
            	findAssignedByLicense(licenseId, deviceTypeId, callback);
            },
            assignDevicesToLicense : function(assignDeviceDto, callback){
            	assignDevicesToLicense(assignDeviceDto, callback)
            },
            unAssignDeviceToLicense : function(unAssignDeviceDto, callback){
            	unAssignDeviceToLicense(unAssignDeviceDto, callback);
            },
            findDeviceStatuses : function(callback){
            	findDeviceStatuses(callback);
            }
        };

    });
