'use strict';

angular.module('vmaxmanagerApp')
    .controller('EmailBounceController', function ($scope, $state, EmailBounce, ParseLinks) {

        $scope.emailBounces = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.loadAll = function() {
            EmailBounce.query({page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.emailBounces.push(result[i]);
                }
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.emailBounces = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.emailBounce = {
                messageId: null,
                timestamp: null,
                bounceType: null,
                bounceSubType: null,
                recipients: null,
                isComplaint: null,
                id: null
            };
        };
    });
