'use strict';

angular.module('vmaxmanagerApp').controller('SlotTypeDialogController',
    ['$scope', '$state', 'entity', 'SlotType', 'Software', 'SlotTypeEnum', 'Feature', 'SoftwareLicense',
        function($scope, $state, entity, SlotType, Software, SlotTypeEnum, Feature, SoftwareLicense) {

        $scope.slotType = entity;
        $scope.softwares = Software.query();
        $scope.software_licenses = SoftwareLicense.query();
        $scope.slotTypeEnum =  SlotTypeEnum.query();
        $scope.features = Feature.query();
        $scope.isFeature = false;
        $scope.load = function(id) {
            SlotType.get({id : id}, function(result) {
                $scope.slotType = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:slotTypeUpdate', result);
            $scope.isSaving = false;
            $state.go('slotType');
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.slotType.id != null) {
                SlotType.update($scope.slotType, onSaveSuccess, onSaveError);
            } else {
                SlotType.save($scope.slotType, onSaveSuccess, onSaveError);
            }
        };

        $scope.slotTypeChange = function() {
        	$scope.isFeature = $scope.slotType.slotType == 'FEATURE';
        	$('#featureList').prop('required', $scope.isFeature);
        	if(!$scope.isFeature){
        		$scope.slotType.feature = null;
        	}else{
        		$scope.editForm.$invalid = true;
        	}
        };

        $scope.requiresDeviceChange = function() {

        	if($scope.slotType && $scope.slotType.requiresDevice){
        		$scope.slotType.requiresSlot = true;
        		}
        };
}]);
