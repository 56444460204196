(function() {
    'use strict';
    angular
        .module('vmaxmanagerApp')
        .factory('AppResourceProfiler', AppResourceProfiler)
        .factory('AppResourceProfilerList', AppResourceProfilerList);

    AppResourceProfiler.$inject = ['$resource'];

    function AppResourceProfiler ($resource) {
        var resourceUrl =  'api/app-resource-profilers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method:'PUT'
            },
            'getAppResourceProfilerByProfileId': {
                method:'GET',
                isArray: true,
                url:'api/getAppResourceProfilerByProfileId/:id'
            }
        });
    }

    function AppResourceProfilerList ($resource) {
        var resourceUrl =  'api/appResourceProfilersList/:id';

        return $resource(resourceUrl, {}, {
            'saveList': { method: 'POST', isArray: true}
        });
    }
})();
