'use strict';

angular.module('vmaxmanagerApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('license', {
                parent: 'entity',
                url: '/license',
                data: {
                	authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.license.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/license/licenses.html',
                        controller: 'LicenseController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('license');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.license.home.title'
                }
            })
            .state('license.detail', {
                parent: 'license',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.license.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/license/license-detail.html',
                        controller: 'LicenseDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('license');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'License', function($stateParams, License) {
                        return License.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'license'
                }
            })
            .state('license.new', {
                parent: 'license',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/license/license-dialog.html',
                        controller: 'LicenseDialogController'
                    }
                },
                 resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('license');
                            return $translate.refresh();
                        }],
                    entity: function() {
                        return {
                           slots: null,
                           slotUsed: null,
                           salesOrder: null,
                           previousSalesOrder: null,
                           startDate: null,
                           duration: null,
                           expiryDate: null,
                           dataAmount: null,
                           chargeType: null,
                           licenseStatus: null,
                           notes: null,
                           id: null
                       };
                    }
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'license'
                }
            })
            .state('license.edit', {
                parent: 'license',
                url: '/{id}/edit',
                params: {
                    settingsId: null,
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/license/license-dialog.html',
                        controller: 'LicenseDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('license');
                            return $translate.refresh();
                        }],
                        entity: ['$stateParams', 'License', function($stateParams, License) {
                            return License.get({id : $stateParams.id}).$promise;
                        }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'license'
                }
            })
            .state('license.delete', {
                parent: 'license',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/license/license-delete-dialog.html',
                        controller: 'LicenseDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['License', function(License) {
                                return License.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                            $state.go('license', null, { reload: true });
                        }, function() {
                            $state.go('^');
                        })
                }]
            })
            .state('license.device', {
                abstract: false,
                parent: 'license'
            })
            .state('license.device.assign', {
                parent: 'license',
                url: '/assign/device/{id}',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/license/license-assign-device.html',
                        controller: 'LicenseAssignDeviceController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('license');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'License', function($stateParams, License) {
                        return License.get({id : $stateParams.id}).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.license.devices.title',
                    parent: 'license'
                }
            })
            // Dynamic settings instance calls.
            .state('license.edit.settings-view', {
                parent: 'entity',
                url: '/{id}/view-settings/{settingsId}',
                params: {
                    parentState: null,
                    settingsId: null,
                    deviceId: null,
                    deviceTypeId: null,
                    license: null,
                    device: null,
                    settingsTemplateId: null,
                    softwareOrDeviceId: null,
                    typeEntitySettingsEnum: null,
                    showChangeSettingsButton: null
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    headerLabel:'vmaxmanagerApp.settingsInstance.process.viewSettings',
                    hideButtons: false,
                    isView: true
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({id : $stateParams.settingsId}).$promise;
                    }],
                    licenseEntity: ['$stateParams', 'License', function($stateParams, License) {
                      return $stateParams.id? License.get({id : $stateParams.id}).$promise: null;
                    }],
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                      return $stateParams.deviceId? Device.get({id : $stateParams.deviceId}).$promise: null;
                    }]
                },
                ncyBreadcrumb: {
                   label: 'vmaxmanagerApp.settingsInstance.process.viewSettings',
                   parent: function ($scope) {
                        return $scope.dynamicParentBreadcrumb();
                   }
               }
            })
            .state('license.edit.settings-edit', {
                parent: 'entity',
                url: '/{id}/edit-settings/{settingsId}',
                params: {
                    parentState: null,
                    settingsId: null,
                    deviceId: null,
                    deviceTypeId: null,
                    showChangeSettingsButton: null
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    headerLabel:'vmaxmanagerApp.settingsInstance.process.editSettings',
                    hideButtons: true,
                    isEdit: true
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({id : $stateParams.settingsId}).$promise;
                    }],
                    licenseEntity: ['$stateParams', 'License', function($stateParams, License) {
                      return $stateParams.id? License.get({id : $stateParams.id}).$promise: null;
                    }],
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                      return $stateParams.deviceId? Device.get({id : $stateParams.deviceId}).$promise: null;
                    }]
                },
                ncyBreadcrumb: {
                   label: 'vmaxmanagerApp.settingsInstance.process.editSettings',
                   parent: function ($scope) {
                        return $scope.dynamicParentBreadcrumb();
                   }
               }
            })
            .state('license.edit.settings-new', {
                parent: 'entity',
                url: '/{id}/new-settings',
                params: {
                    parentState: null,
                    settingsId: null,
                    deviceId: null,
                    deviceTypeId: null,
                    settingsTemplateId: null,
                    softwareOrDeviceId: null,
                    typeEntitySettingsEnum: null,
                    showChangeSettingsButton: null
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    headerLabel:'vmaxmanagerApp.settingsInstance.process.newSettings',
                    hideButtons: true,
                    isNew: true,
                    isNewFromLicense: true
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', function($stateParams) {
                            var newSettingsTemplate = {};
                            switch($stateParams.typeEntitySettingsEnum) {
                                case "DEVICE":
                                    newSettingsTemplate = {
                                        id: $stateParams.settingsTemplateId,
                                        deviceType: {
                                            id:$stateParams.softwareOrDeviceId
                                        },
                                        typeEntitySettingsEnum: $stateParams.typeEntitySettingsEnum
                                    };
                                    break;
                                case "SOFTWARE":
                                    newSettingsTemplate = {
                                        id: $stateParams.settingsTemplateId,
                                        softwareGroup: {
                                            id:$stateParams.softwareOrDeviceId
                                        },
                                        typeEntitySettingsEnum: $stateParams.typeEntitySettingsEnum
                                    };
                                    break;
                            }

                            return {
                                    id: null,
                                    name: null,
                                    notes: null,
                                    template: null,
                                    settingsTemplate: newSettingsTemplate
                             };
                    }],
                    licenseEntity: ['$stateParams', 'License', function($stateParams, License) {
                      return $stateParams.id? License.get({id : $stateParams.id}).$promise: null;
                    }],
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                      return $stateParams.deviceId? Device.get({id : $stateParams.deviceId}).$promise: null;
                    }]
                },
                ncyBreadcrumb: {
                   label: 'vmaxmanagerApp.settingsInstance.process.newSettings',
                   parent: function ($scope) {
                        return $scope.dynamicParentBreadcrumb();
                   }
               }
            })
            .state('license.edit.settings-clone', {
                parent: 'entity',
                url: '/{id}/clone-settings/{settingsId}',
                params: {
                    parentState: null,
                    settingsId: null,
                    deviceId: null,
                    deviceTypeId: null,
                    showChangeSettingsButton: null
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    headerLabel: 'vmaxmanagerApp.settingsInstance.process.cloneSettings',
                    hideButtons: true,
                    isClone: true
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({id : $stateParams.settingsId}).$promise;
                    }],
                    licenseEntity: ['$stateParams', 'License', function($stateParams, License) {
                      return $stateParams.id? License.get({id : $stateParams.id}).$promise: null;
                    }],
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                      return $stateParams.deviceId? Device.get({id : $stateParams.deviceId}).$promise: null;
                    }]
                },
                ncyBreadcrumb: {
                   label: 'vmaxmanagerApp.settingsInstance.process.cloneSettings',
                   parent: function ($scope) {
                        return $scope.dynamicParentBreadcrumb();
                   }
               }
            })
            .state('license.edit.settings-change', {
                parent: 'entity',
                url: '/{id}/change-settings',
                params: {
                    parentState: null,
                    settingsId: null,
                    deviceId: null,
                    deviceTypeId: null,
                    showChangeSettingsButton: null
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    headerLabel:'vmaxmanagerApp.settingsInstance.process.changeSettings',
                    hideButtons: true,
                    isChangeSettings: true
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({id : $stateParams.settingsId}).$promise;
                    }],
                    licenseEntity: ['$stateParams', 'License', function($stateParams, License) {
                      return $stateParams.id? License.get({id : $stateParams.id}).$promise: null;
                    }],
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                      return $stateParams.deviceId? Device.get({id : $stateParams.deviceId}).$promise: null;
                    }]
                },
                ncyBreadcrumb: {
                   label: 'vmaxmanagerApp.settingsInstance.process.changeSettings',
                   parent: function ($scope) {
                        return $scope.dynamicParentBreadcrumb();
                   }
               }
            })
            .state('license.device.assign.setting', {
                parent: 'license.device.assign',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                params: {
                    deviceType: null,
                    assignDevice: null
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/license/license-assign-device-setting.html',
                        controller: 'LicenseAssignDeviceSettingController',
                        size: 'md',
                        resolve : {
                            settings : ['SettingsInstance', function(SettingsInstance){
                                var setting = SettingsInstance.getSettingsInstanceByDeviceType({deviceType:$stateParams.deviceType}).$promise;
                                return setting;
                            }],
                            settingsTemplate : ['SettingsTemplate', function(SettingsTemplate){
                                var settingsTemplate = SettingsTemplate.getSettingsTemplatesByDeviceType({deviceType:$stateParams.deviceType}).$promise;
                                return settingsTemplate;
                            }],
                            license : ['License', function(License){
                                var license = License.get({id : $stateParams.id});
                                return license;
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('license.device.assign', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('license.device.assign.unAssignSetting', {
                parent: 'license.device.assign',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                params: {
                    deviceStatus: null,
                    assignDevice: null
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/license/license-unassign-device-setting.html',
                        controller: 'LicenseUnassignDeviceSettingController',
                        size: 'md',
                        resolve : {
                            settings : ['SettingsInstance', function(SettingsInstance){
                                var setting = SettingsInstance.getSettingsInstanceByDeviceType({deviceType:$stateParams.deviceType}).$promise;
                                return setting;
                            }],
                            license : ['License', function(License){
                                var license = License.get({id : $stateParams.id});
                                return license;
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('license.device.assign', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
    });
