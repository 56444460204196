(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('DownloadResourcesAuditController', DownloadResourcesAuditController);

    DownloadResourcesAuditController.$inject = ['$scope', '$state', 'DownloadResourcesAudit', 'ParseLinks', 'AlertService', '$filter',  '$translate'];

    function DownloadResourcesAuditController ($scope, $state, DownloadResourcesAudit, ParseLinks, AlertService, $filter, $translate) {
        var vm = this;

        vm.downloadResourcesAudits = [];
        vm.page = 0;
        vm.reverse = true;
        vm.appResource = {};
        vm.searchTooltip = $translate.instant('vmaxmanagerApp.downloadResourcesAudit.tooltip.search');;

        vm.filter =  {};

        function loadAll(filter) {

            if(!_.isEmpty(filter)){
                DownloadResourcesAudit.query({
                    page: vm.page,
                    size: 50,
                    sort: sort(),
                    dateFrom : filter.dateFrom,
                    dateTo : filter.dateTo,
                    deviceType : filter.deviceType,
                    serialNumber : filter.serialNumber
                }, onSuccess, onError);
            }else{
                 DownloadResourcesAudit.query({
                     page: vm.page,
                     size: 50,
                     sort: sort()
                 }, onSuccess, onError);
             }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.downloadResourcesAudits.push(data[i]);
                }
                vm.change();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.reset = function() {
            vm.page = 0;
            vm.downloadResourcesAudits = [];
            loadAll(vm.filter);
        }

        vm.loadPage = function(page) {
            vm.page = page;
            loadAll(vm.filter);
        }

        vm.change = function() {
            if(vm.appResource.dateTo < vm.appResource.dateFrom){
                vm.appResource.dateTo = null;
            }
            if(vm.appResource.dateFrom){
                vm.minDate = vm.appResource.dateFrom;
            }
         }

         vm.search = function(){

            var dateFormat = 'yyyy-MM-dd';
            var fromDate = null;
            var toDate = null;
            vm.page = 0;
            vm.downloadResourcesAudits = [];

            if(vm.appResource.dateFrom){
                fromDate = $filter('date')(vm.appResource.dateFrom, dateFormat);
            }

            if(vm.appResource.dateTo){
                vm.appResource.dateTo.setDate(vm.appResource.dateTo.getDate() + 1);
                toDate = $filter('date')(vm.appResource.dateTo, dateFormat);
            }

            vm.filter =  {
                dateFrom : fromDate,
                dateTo : toDate,
                deviceType : vm.appResource.deviceType,
                serialNumber : vm.appResource.serialNumber
            };

            if(vm.appResource.dateTo){
                vm.appResource.dateTo.setDate(vm.appResource.dateTo.getDate() - 1);
            }

            loadAll(vm.filter);

         }

         vm.today = function () {
             var today = new Date();
             vm.appResource.dateTo = new Date(today.getFullYear(), today.getMonth(), today.getDate());
         };

         vm.previousMonth = function () {
             var fromDate = new Date();
             if (fromDate.getMonth() === 0) {
                 fromDate = new Date(fromDate.getFullYear() - 1, 0, fromDate.getDate());
             } else {
                 fromDate = new Date(fromDate.getFullYear(), fromDate.getMonth() - 1, fromDate.getDate());
             }

             vm.appResource.dateFrom = fromDate;
         };

         vm.today();
         vm.previousMonth();
         vm.search();
    }
})();
