'use strict';

angular.module('vmaxmanagerApp')
    .controller('ServerDetailController', function ($scope, $rootScope, $stateParams, entity, Server) {
        $scope.server = entity;
        $scope.load = function (id) {
            Server.get({id: id}, function(result) {
                $scope.server = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:serverUpdate', function(event, result) {
            $scope.server = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
