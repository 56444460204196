'use strict';

angular.module('vmaxmanagerApp')
    .controller('CommanderController', function($scope, $rootScope, Commander, Server) {
    
   $scope.servers = Server.query();
   
   /**Data form UI**/
   $scope.data = {
        fleetId : '',
        yardId : '',
        serverId : ''
    };

    /** Event on server change*/
    $scope.onServerChange = function(serverId) {
        if (serverId) {
            Commander.getFleets(serverId, function(error, response) {
                if (error){
                	$scope.clearAll();
                	return bootbox.alert('Error getting fleets \n (Error ' + error.status + '):\n' + 'Please check url server.');	
                }
                $scope.clearSummary();
                $scope.fleets = response;
            });
        } else {
            $scope.fleets = '';
            $scope.data.fleetId = '';
            $scope.yards = '';
            $scope.data.yardId = '';
            $scope.clearSummary();
        }
    };

    
    /**Event on Fleet Change**/
    $scope.onFleetChange = function(serverId, fleetId) {
        if (fleetId) {
            Commander.getYards(serverId, fleetId, function(error, response) {
                if (error){
                	$scope.clearAll();
                	return bootbox.alert('Error getting yards (Error ' + error.status + '):\n' + 'Please check url server.');	
                }
                $scope.clearSummary();
                $scope.yards = response;
            });
        } else {
            $scope.yards = '';
            $scope.data.yardId = '';
        }
    };

    /**Get task from service**/
    $scope.monitorTasks = function(isRefresh) {
        if(!isRefresh){
            $scope.taskDetailList = null;
        }
        Commander.getTaskSummaryList($scope.data.serverId, $scope.data.fleetId, $scope.data.yardId, function(error, response) {
            if (error)
            {
            	$scope.clearAll();
            	return bootbox.alert('Error getting tasks summary (Error ' + error.status + '):\n' + 'Please check url server.');	
            	return;
            }
            $scope.taskSummaryList = response;
        });
    };

    /**Refresh query from service**/
    $scope.refresh = function() {
        $scope.monitorTasks(true);
        if($scope.taskDetailList && $scope.taskDetailList.length > 0) {
            $scope.taskDetails($scope.selectedYard, $scope.selectedVehicle);
        }
    };
    
    
    /**Clear All components **/
    $scope.clearAll = function() {
    	$scope.fleets = '';
        $scope.data.fleetId = '';
        $scope.yards = '';
        $scope.data.yardId = '';
        $scope.clearSummary();
        $scope.servers = Server.query();
    };
    
    /**Clear summary task and detail task **/
    $scope.clearSummary = function() {
        $scope.taskSummaryList = null;
        $scope.taskDetailList = null;
    };
    

    /**Get taskDetail of bus selected**/
    $scope.taskDetails = function(yardId, vehicleId) {
        $scope.selectedYard = yardId;
        $scope.selectedVehicle = vehicleId;
        Commander.getTaskList($scope.data.serverId, $scope.data.fleetId, yardId, vehicleId, function(error, response) {
            if (error){
            	$scope.clearAll();
            	return bootbox.alert('Error getting tasks detail (Error ' + error.status + '):\n' + 'Please check url server.');	
            }
            $scope.taskDetailList = response;
        });
    };
});
