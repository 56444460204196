(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('AppResourceUserProfileDeleteController',AppResourceUserProfileDeleteController);

    AppResourceUserProfileDeleteController.$inject = ['$uibModalInstance', 'entity', 'AppResourceUserProfile', 'associatedResources'];

    function AppResourceUserProfileDeleteController($uibModalInstance, entity, AppResourceUserProfile, associatedResources) {
        var vm = this;

        vm.appResourceUserProfile = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.associatedResourcesToProfile = associatedResources;
        vm.hasAssociatedResources = false;
        if (associatedResources !== null && associatedResources !== undefined && associatedResources.length > 0) {
            vm.hasAssociatedResources = true;
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AppResourceUserProfile.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
