'use strict';

angular.module('vmaxmanagerApp')
    .service('UtcDateString', function ($filter) {

    this.createString = function(dateString) {
        if (dateString) {
            var utcString = dateString.substring(5,7) + '/' + dateString.substring(8,10) + '/' + dateString.substring(0,4)
            + ' ' +  dateString.substring(11,13) + ':' + dateString.substring(14,16) + ':' + dateString.substring(17,19) + ' UTC';
            return utcString;
        } else {
            return null;
        }
    }
});
