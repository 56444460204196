'use strict';

angular.module('vmaxmanagerApp', ['LocalStorageModule', 'tmh.dynamicLocale', 'pascalprecht.translate',
    'ngResource', 'ngCookies', 'ngAria', 'ngCacheBuster', 'ngFileUpload', 'seon.settingsConfigurator',
    // jhipster-needle-angularjs-add-module JHipster will add new module here
    'ds.objectDiff', 'ncy-angular-breadcrumb','ui.bootstrap', 'ui.router',  'infinite-scroll', 'angular-loading-bar', 'wo.grecaptcha','seon.queryBuilder',
    'angularjs-dropdown-multiselect', 'ui.select', 'ngSanitize'])

    .run(function ($rootScope, $location, $window, $http, $state, $translate, $modalStack, Language, Auth, Principal, ENV, VERSION,USER_ROLES) {
        // update the window title using params in the following
        // precendence
        // 1. titleKey parameter
        // 2. $state.$current.data.pageTitle (current state page title)
        // 3. 'global.title'

        $rootScope.modalStack = $modalStack;
        $rootScope.$state = $state;

    	$rootScope.message={
                'delete':{
                    500:'This {0} has related {1}(s). Cannot be Deleted.',
                    406:'You cannot delete yourself.'
                        },
                'create':{
                    500:'Cannot create this {0}. There is a {0} with same name.'
                },
                IntegrityViolation:'org.springframework.dao.DataIntegrityViolationException',
                generalError:'The server cannot respond due to technical problems.\r\nPlease contact Seon at 1877.630.7366.',
                loginAlreadyUse:'Login name already registered. Please choose another one.',
                emailAlreadyUse:'E-mail is already in use, Please choose another one.',
                createUserSuccess: 'The user was created successfully',
                error: {
        			"deviceAssignNotEnoughSlots" : "Not enough slots available to assign more devices"
        		}

        };

        $rootScope.roles = {
            	'ROLE_SYSTEM_ADMIN' : 'systemAdmin',
            	'ROLE_OPERATIONS' : 'operations',
            	'ROLE_OPERATIONS_ADMIN':'operationsAdmin'
         };




        var updateTitle = function(titleKey) {
            if (!titleKey && $state.$current.data && $state.$current.data.pageTitle) {
                titleKey = $state.$current.data.pageTitle;
            }
            $translate(titleKey || 'global.title').then(function (title) {
                $window.document.title = title;
            });
        };

        $rootScope.ENV = ENV;
        $rootScope.VERSION = VERSION;
        $rootScope.userRoles = USER_ROLES;
        $rootScope.YEAR = new Date().getFullYear();
        $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams) {
            $rootScope.toState = toState;
            $rootScope.toStateParams = toStateParams;

            if (Principal.isIdentityResolved()) {
                Auth.authorize();
            }


            // Update the language
            Language.getCurrent().then(function (language) {
                $translate.use(language);
            });

        });



        $rootScope.$on('$stateChangeSuccess',  function(event, toState, toParams, fromState, fromParams) {
            var titleKey = 'global.title' ;

            // Remember previous state unless we've been redirected to login or we've just
            // reset the state memory after logout. If we're redirected to login, our
            // previousState is already set in the authExpiredInterceptor. If we're going
            // to login directly, we don't want to be sent to some previous state anyway
            if (toState.name != 'login' && $rootScope.previousStateName) {
              $rootScope.previousStateName = fromState.name;
              $rootScope.previousStateParams = fromParams;
            }

            // angular.element('.content-wrapper').toggleClass('menu-hidden', (toState.name == 'login'));

            // $rootScope.showTitleAndMenu = (toState.name != 'login');
            // if (toState.name == 'login') {
            //     console.log('removing menu')
            //     // angular.element('#menu').remove();
            // }

            // Set the page title key to the one configured in state or use default one
            if (toState.data.pageTitle) {
                titleKey = toState.data.pageTitle;
            }
            updateTitle(titleKey);
            // console.log("state change success", toState.name, $rootScope.showTitleAndMenu)
        });

        // if the current translation changes, update the window title
        $rootScope.$on('$translateChangeSuccess', function() { updateTitle(); });


        $rootScope.back = function() {
            // If previous state is 'activate' or do not exist go to 'home'
            if ($rootScope.previousStateName === 'activate' || $state.get($rootScope.previousStateName) === null) {
                $state.go('home');
            } else {
                $state.go($rootScope.previousStateName, $rootScope.previousStateParams);
            }
        };
    })
    .config(function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider, $translateProvider, $breadcrumbProvider, tmhDynamicLocaleProvider, httpRequestInterceptorCacheBusterProvider, AlertServiceProvider) {
        // uncomment below to make alerts look like toast
        AlertServiceProvider.showAsToast(true);

        //Cache everything except rest api requests
        httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*api.*/, /.*protected.*/], true);

        $urlRouterProvider.otherwise('/');
        $stateProvider.state('site', {
            'abstract': true,
            views: {
                'toolbar@': {
                    templateUrl: 'scripts/components/menu/toolbar.html',
                    controller: 'MenuCtrl'
                },
                'menu@' : {
                    templateUrl: 'scripts/components/menu/menu.html',
                    controller: 'MenuCtrl'
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('error');
                }]
            }
        });

        $httpProvider.interceptors.push('errorHandlerInterceptor');
        $httpProvider.interceptors.push('authExpiredInterceptor');
        $httpProvider.interceptors.push('authInterceptor');
        $httpProvider.interceptors.push('notificationInterceptor');
        $httpProvider.interceptors.push('entityAuditInterceptor');
        // jhipster-needle-angularjs-add-interceptor JHipster will add new application interceptor here

        // Initialize angular-translate
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: 'i18n/{lang}/{part}.json'
        });

        $translateProvider.preferredLanguage('en');
        $translateProvider.useCookieStorage();
        $translateProvider.useSanitizeValueStrategy('escaped');
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

        tmhDynamicLocaleProvider.localeLocationPattern('bower_components/angular-i18n/angular-locale_{{locale}}.js');
        tmhDynamicLocaleProvider.useCookieStorage();
        tmhDynamicLocaleProvider.storageKey('NG_TRANSLATE_LANG_KEY');

        $breadcrumbProvider.setOptions({
            templateUrl: 'scripts/components/menu/_breadcrumb.html'
            // prefixStateName: 'home',
            // template: '<span ng-repeat="step in steps"> > {{step.ncyBreadcrumbLabel}}</span>'
        });
        angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 250);
    })
    // Initialize material design
    .config(function () {
        $.material.init();
    })
    // jhipster-needle-angularjs-add-config JHipster will add new application configuration here
    .config(['$urlMatcherFactoryProvider', function($urlMatcherFactory) {
        $urlMatcherFactory.type('boolean', {
            name : 'boolean',
            decode: function(val) { return val == true ? true : val == "true" ? true : false },
            encode: function(val) { return val ? 1 : 0; },
            equals: function(a, b) { return this.is(a) && a === b; },
            is: function(val) { return [true,false,0,1].indexOf(val) >= 0 },
            pattern: /bool|true|0|1/
        });
    }])
    .config(function($grecaptchaProvider) {
        $grecaptchaProvider.set({
            sitekey: '6LfZ7CcTAAAAAMz0-knKbf8T6JEdx8mLygyWu2CV',
            theme: 'Light',
            type: 'image',
            size: 'NORMAL'
        })
        .setType('image');
    }).config(function(uiSelectConfig) {
        uiSelectConfig.theme = 'bootstrap';
        uiSelectConfig.appendToBody = true;
    });

angular.module('vmaxmanagerApp').filter('selectHighlight', function($sce) {
    return function(text, phrase) {
        if (phrase && text) text = text.replace(new RegExp('('+phrase+')', 'gi'), '<span class="select-highlight">$1</span>')
            return $sce.trustAsHtml(text)
    }
});

angular.module('vmaxmanagerApp').filter('momentDateTimeFormat', function($sce) {
    return function(d) {
        if (!d) return '';
        return moment(d).format('MM/DD/YYYY HH:mm:ss');
    }
});

angular.module('vmaxmanagerApp').filter('momentDateFormat', function($sce) {
    return function(d) {
        if (!d) return '';
        return moment(d).format('MM/DD/YYYY');
    }
});
