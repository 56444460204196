'use strict';

angular.module('vmaxmanagerApp').directive('mmenu', mmenuDirective);

mmenuDirective.$inject = ['$window'];

function mmenuDirective($window) {
	return {
        restrict : 'A',
        link : function(scope, element, attrs) {
            jQuery(element).mmenu({
                // "slidingSubmenus": false,
            	// offCanvas    : false,
				"navbar" : {
					add: false
				},
				"extensions": [
					"pagedim-black",
					"widescreen"
				]
			}, {
                // clone: true;
                // "className": {
                //     "vertical": "sneroll",
                //     "divider": 'menu-divider'
                // },
                "offCanvas": {
                    "pageSelector": ".content-wrapper"
                }
            });

            angular.element($window).bind('resize', function(){

            	scope.width = $window.innerWidth;


            	// scope.$digest();
            });
        }
    };
}
