(function() {
    'use strict';
    angular
        .module('vmaxmanagerApp')
        .factory('ExpandableService', ExpandableService);

    ExpandableService.$inject = ['$resource'];

    function ExpandableService ($resource) {
        var resourceUrl =  'api/interface/expandable';

        return $resource(resourceUrl, {}, {
            'getSincronizeExpandableDeviceType':
                {
                    method:'GET',
                    isArray: false,
                    url:'api/interface/expandable/getSincronizeExpandableDeviceType?deviceTypeId=:deviceTypeId'
                }
        });
    }
})();
