'use strict';

angular.module('vmaxmanagerApp', ['LocalStorageModule', 'tmh.dynamicLocale', 'pascalprecht.translate',
    'ngResource', 'ngCookies', 'ngAria', 'ngCacheBuster', 'ngFileUpload', 'seon.settingsConfigurator',
    // jhipster-needle-angularjs-add-module JHipster will add new module here
    'ds.objectDiff', 'ncy-angular-breadcrumb','ui.bootstrap', 'ui.router',  'infinite-scroll', 'angular-loading-bar', 'wo.grecaptcha','seon.queryBuilder',
    'angularjs-dropdown-multiselect', 'ui.select', 'ngSanitize'])

    .run(["$rootScope", "$location", "$window", "$http", "$state", "$translate", "$modalStack", "Language", "Auth", "Principal", "ENV", "VERSION", "USER_ROLES", function ($rootScope, $location, $window, $http, $state, $translate, $modalStack, Language, Auth, Principal, ENV, VERSION,USER_ROLES) {
        // update the window title using params in the following
        // precendence
        // 1. titleKey parameter
        // 2. $state.$current.data.pageTitle (current state page title)
        // 3. 'global.title'

        $rootScope.modalStack = $modalStack;
        $rootScope.$state = $state;

    	$rootScope.message={
                'delete':{
                    500:'This {0} has related {1}(s). Cannot be Deleted.',
                    406:'You cannot delete yourself.'
                        },
                'create':{
                    500:'Cannot create this {0}. There is a {0} with same name.'
                },
                IntegrityViolation:'org.springframework.dao.DataIntegrityViolationException',
                generalError:'The server cannot respond due to technical problems.\r\nPlease contact Seon at 1877.630.7366.',
                loginAlreadyUse:'Login name already registered. Please choose another one.',
                emailAlreadyUse:'E-mail is already in use, Please choose another one.',
                createUserSuccess: 'The user was created successfully',
                error: {
        			"deviceAssignNotEnoughSlots" : "Not enough slots available to assign more devices"
        		}

        };

        $rootScope.roles = {
            	'ROLE_SYSTEM_ADMIN' : 'systemAdmin',
            	'ROLE_OPERATIONS' : 'operations',
            	'ROLE_OPERATIONS_ADMIN':'operationsAdmin'
         };




        var updateTitle = function(titleKey) {
            if (!titleKey && $state.$current.data && $state.$current.data.pageTitle) {
                titleKey = $state.$current.data.pageTitle;
            }
            $translate(titleKey || 'global.title').then(function (title) {
                $window.document.title = title;
            });
        };

        $rootScope.ENV = ENV;
        $rootScope.VERSION = VERSION;
        $rootScope.userRoles = USER_ROLES;
        $rootScope.YEAR = new Date().getFullYear();
        $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams) {
            $rootScope.toState = toState;
            $rootScope.toStateParams = toStateParams;

            if (Principal.isIdentityResolved()) {
                Auth.authorize();
            }


            // Update the language
            Language.getCurrent().then(function (language) {
                $translate.use(language);
            });

        });



        $rootScope.$on('$stateChangeSuccess',  function(event, toState, toParams, fromState, fromParams) {
            var titleKey = 'global.title' ;

            // Remember previous state unless we've been redirected to login or we've just
            // reset the state memory after logout. If we're redirected to login, our
            // previousState is already set in the authExpiredInterceptor. If we're going
            // to login directly, we don't want to be sent to some previous state anyway
            if (toState.name != 'login' && $rootScope.previousStateName) {
              $rootScope.previousStateName = fromState.name;
              $rootScope.previousStateParams = fromParams;
            }

            // angular.element('.content-wrapper').toggleClass('menu-hidden', (toState.name == 'login'));

            // $rootScope.showTitleAndMenu = (toState.name != 'login');
            // if (toState.name == 'login') {
            //     console.log('removing menu')
            //     // angular.element('#menu').remove();
            // }

            // Set the page title key to the one configured in state or use default one
            if (toState.data.pageTitle) {
                titleKey = toState.data.pageTitle;
            }
            updateTitle(titleKey);
            // console.log("state change success", toState.name, $rootScope.showTitleAndMenu)
        });

        // if the current translation changes, update the window title
        $rootScope.$on('$translateChangeSuccess', function() { updateTitle(); });


        $rootScope.back = function() {
            // If previous state is 'activate' or do not exist go to 'home'
            if ($rootScope.previousStateName === 'activate' || $state.get($rootScope.previousStateName) === null) {
                $state.go('home');
            } else {
                $state.go($rootScope.previousStateName, $rootScope.previousStateParams);
            }
        };
    }])
    .config(["$stateProvider", "$urlRouterProvider", "$httpProvider", "$locationProvider", "$translateProvider", "$breadcrumbProvider", "tmhDynamicLocaleProvider", "httpRequestInterceptorCacheBusterProvider", "AlertServiceProvider", function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider, $translateProvider, $breadcrumbProvider, tmhDynamicLocaleProvider, httpRequestInterceptorCacheBusterProvider, AlertServiceProvider) {
        // uncomment below to make alerts look like toast
        AlertServiceProvider.showAsToast(true);

        //Cache everything except rest api requests
        httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*api.*/, /.*protected.*/], true);

        $urlRouterProvider.otherwise('/');
        $stateProvider.state('site', {
            'abstract': true,
            views: {
                'toolbar@': {
                    templateUrl: 'scripts/components/menu/toolbar.html',
                    controller: 'MenuCtrl'
                },
                'menu@' : {
                    templateUrl: 'scripts/components/menu/menu.html',
                    controller: 'MenuCtrl'
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('error');
                }]
            }
        });

        $httpProvider.interceptors.push('errorHandlerInterceptor');
        $httpProvider.interceptors.push('authExpiredInterceptor');
        $httpProvider.interceptors.push('authInterceptor');
        $httpProvider.interceptors.push('notificationInterceptor');
        $httpProvider.interceptors.push('entityAuditInterceptor');
        // jhipster-needle-angularjs-add-interceptor JHipster will add new application interceptor here

        // Initialize angular-translate
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: 'i18n/{lang}/{part}.json'
        });

        $translateProvider.preferredLanguage('en');
        $translateProvider.useCookieStorage();
        $translateProvider.useSanitizeValueStrategy('escaped');
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

        tmhDynamicLocaleProvider.localeLocationPattern('bower_components/angular-i18n/angular-locale_{{locale}}.js');
        tmhDynamicLocaleProvider.useCookieStorage();
        tmhDynamicLocaleProvider.storageKey('NG_TRANSLATE_LANG_KEY');

        $breadcrumbProvider.setOptions({
            templateUrl: 'scripts/components/menu/_breadcrumb.html'
            // prefixStateName: 'home',
            // template: '<span ng-repeat="step in steps"> > {{step.ncyBreadcrumbLabel}}</span>'
        });
        angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 250);
    }])
    // Initialize material design
    .config(function () {
        $.material.init();
    })
    // jhipster-needle-angularjs-add-config JHipster will add new application configuration here
    .config(['$urlMatcherFactoryProvider', function($urlMatcherFactory) {
        $urlMatcherFactory.type('boolean', {
            name : 'boolean',
            decode: function(val) { return val == true ? true : val == "true" ? true : false },
            encode: function(val) { return val ? 1 : 0; },
            equals: function(a, b) { return this.is(a) && a === b; },
            is: function(val) { return [true,false,0,1].indexOf(val) >= 0 },
            pattern: /bool|true|0|1/
        });
    }])
    .config(["$grecaptchaProvider", function($grecaptchaProvider) {
        $grecaptchaProvider.set({
            sitekey: '6LfZ7CcTAAAAAMz0-knKbf8T6JEdx8mLygyWu2CV',
            theme: 'Light',
            type: 'image',
            size: 'NORMAL'
        })
        .setType('image');
    }]).config(["uiSelectConfig", function(uiSelectConfig) {
        uiSelectConfig.theme = 'bootstrap';
        uiSelectConfig.appendToBody = true;
    }]);

angular.module('vmaxmanagerApp').filter('selectHighlight', ["$sce", function($sce) {
    return function(text, phrase) {
        if (phrase && text) text = text.replace(new RegExp('('+phrase+')', 'gi'), '<span class="select-highlight">$1</span>')
            return $sce.trustAsHtml(text)
    }
}]);

angular.module('vmaxmanagerApp').filter('momentDateTimeFormat', ["$sce", function($sce) {
    return function(d) {
        if (!d) return '';
        return moment(d).format('MM/DD/YYYY HH:mm:ss');
    }
}]);

angular.module('vmaxmanagerApp').filter('momentDateFormat', ["$sce", function($sce) {
    return function(d) {
        if (!d) return '';
        return moment(d).format('MM/DD/YYYY');
    }
}]);

/* jshint quotmark: false */
"use strict";
// DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
angular.module("vmaxmanagerApp")

.constant("ENV", "prod")

.constant("VERSION", "1.10.2")

.constant("USER_ROLES", {
	"all": "*",
	"admin": "ROLE_SYSTEM_ADMIN",
	"user": "ROLE_OPERATIONS",
	"operator": "ROLE_OPERATIONS_ADMIN"
})

;
'use strict';

angular.module('vmaxmanagerApp')
    .factory('Auth', ["$rootScope", "$state", "$q", "$translate", "Principal", "AuthServerProvider", "Account", "Register", "Activate", "Password", "PasswordResetInit", "PasswordResetFinish", "Tracker", function Auth($rootScope, $state, $q, $translate, Principal, AuthServerProvider, Account, Register, Activate, Password, PasswordResetInit, PasswordResetFinish, Tracker) {
        return {
            login: function (credentials, callback) {
                var cb = callback || angular.noop;
                var deferred = $q.defer();

                AuthServerProvider.login(credentials).then(function (data) {
                    // retrieve the logged account information
                    Principal.identity(true).then(function(account) {
                        // After the login the language will be changed to
                        // the language selected by the user during his registration
                        $translate.use(account.langKey).then(function(){
                            $translate.refresh();
                        });
                        Tracker.sendActivity();
                        deferred.resolve(data);
                    });
                    return cb();
                }).catch(function (err) {
                    this.logout();
                    deferred.reject(err);
                    return cb(err);
                }.bind(this));

                return deferred.promise;
            },

            logout: function () {
                AuthServerProvider.logout();
                Principal.authenticate(null);
                // Reset state memory
                $rootScope.previousStateName = undefined;
                $rootScope.previousStateNameParams = undefined;
            },

            authorize: function(force) {
                return Principal.identity(force)
                    .then(function() {
                        var isAuthenticated = Principal.isAuthenticated();

                        // an authenticated user can't access to login and register pages
                        if (isAuthenticated && $rootScope.toState.parent === 'account' && ($rootScope.toState.name === 'login' || $rootScope.toState.name === 'register')) {
                            $state.go('home');
                        }

                        if ($rootScope.toState.data.authorities && $rootScope.toState.data.authorities.length > 0 && !Principal.hasAnyAuthority($rootScope.toState.data.authorities)) {
                            if (isAuthenticated) {
                                // user is signed in but not authorized for desired state
                                $state.go('accessdenied');
                            }
                            else {
                                // user is not authenticated. stow the state they wanted before you
                                // send them to the signin state, so you can return them when you're done
                                $rootScope.previousStateName = $rootScope.toState;
                                $rootScope.previousStateNameParams = $rootScope.toStateParams;

                                // now, send them to the signin state so they can log in
                                $state.go('login');
                            }
                        }
                    });
            },
            createAccount: function (account, callback) {
                var cb = callback || angular.noop;

                return Register.save(account,
                    function () {
                        return cb(account);
                    },
                    function (err) {
                        this.logout();
                        return cb(err);
                    }.bind(this)).$promise;
            },

            updateAccount: function (account, callback) {
                var cb = callback || angular.noop;

                return Account.save(account,
                    function () {
                        return cb(account);
                    },
                    function (err) {
                        return cb(err);
                    }.bind(this)).$promise;
            },

            activateAccount: function (key, callback) {
                var cb = callback || angular.noop;

                return Activate.get(key,
                    function (response) {
                        return cb(response);
                    },
                    function (err) {
                        return cb(err);
                    }.bind(this)).$promise;
            },

            changePassword: function (newPassword, callback) {
                var cb = callback || angular.noop;

                return Password.save(newPassword, function () {
                    return cb();
                }, function (err) {
                    return cb(err);
                }).$promise;
            },

            resetPasswordInit: function (mail, callback) {
                var cb = callback || angular.noop;

                return PasswordResetInit.save(mail, function() {
                    return cb();
                }, function (err) {
                    return cb(err);
                }).$promise;
            },

            resetPasswordFinish: function(keyAndPassword, callback) {
                var cb = callback || angular.noop;

                return PasswordResetFinish.save(keyAndPassword, function () {
                    return cb();
                }, function (err) {
                    return cb(err);
                }).$promise;
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('Principal', ["$q", "Account", "Tracker", function Principal($q, Account, Tracker) {
        var _identity,
            _authenticated = false;

        return {
            isIdentityResolved: function () {
                return angular.isDefined(_identity);
            },
            isAuthenticated: function () {
                return _authenticated;
            },
            isInRole: function (role) {
                if (!_authenticated || !_identity || !_identity.authorities) {
                    return false;
                }

                return _identity.authorities.indexOf(role) !== -1;
            },
            hasAuthority: function (authority) {
                if (!_authenticated) {
                    return $q.when(false);
                }

                return this.identity().then(function(_id) {
                    return _id.authorities && _id.authorities.indexOf(authority) !== -1;
                }, function(err){
                    return false;
                });
            },
            hasAnyAuthority: function (authorities) {
                if (!_authenticated || !_identity || !_identity.authorities) {
                    return false;
                }

                for (var i = 0; i < authorities.length; i++) {
                    if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                        return true;
                    }
                }

                return false;
            },
            authenticate: function (identity) {
                _identity = identity;
                _authenticated = identity !== null;
            },
            identity: function (force) {
                var deferred = $q.defer();

                if (force === true) {
                    _identity = undefined;
                }

                // check and see if we have retrieved the identity data from the server.
                // if we have, reuse it by immediately resolving
                if (angular.isDefined(_identity)) {
                    deferred.resolve(_identity);

                    return deferred.promise;
                }

                // retrieve the identity data from the server, update the identity object, and then resolve.
                Account.get().$promise
                    .then(function (account) {
                        _identity = account.data;
                        _authenticated = true;
                        deferred.resolve(_identity);
                        Tracker.connect();
                    })
                    .catch(function() {
                        _identity = null;
                        _authenticated = false;
                        deferred.resolve(_identity);
                    });
                return deferred.promise;
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .directive('hasAnyAuthority', ['Principal', function (Principal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var setVisible = function () {
                        element.removeClass('hidden');
                    },
                    setHidden = function () {
                        element.addClass('hidden');
                    },
                    defineVisibility = function (reset) {
                        var result;
                        if (reset) {
                            setVisible();
                        }

                        result = Principal.hasAnyAuthority(authorities);
                        if (result) {
                            setVisible();
                        } else {
                            setHidden();
                        }
                    },
                    authorities = attrs.hasAnyAuthority.replace(/\s+/g, '').split(',');

                if (authorities.length > 0) {
                    defineVisibility(true);
                    
                    scope.$watch(function(scope) {
                        return Principal.isAuthenticated();
                    }, function(newValue) {
                        defineVisibility(true);
                    });
                }
            }
        };
    }])
    .directive('hasAuthority', ['Principal', function (Principal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var setVisible = function () {
                        element.removeClass('hidden');
                    },
                    setHidden = function () {
                        element.addClass('hidden');
                    },
                    defineVisibility = function (reset) {

                        if (reset) {
                            setVisible();
                        }

                        Principal.hasAuthority(authority)
                            .then(function (result) {
                                if (result) {
                                    setVisible();
                                } else {
                                    setHidden();
                                }
                            });
                    },
                    authority = attrs.hasAuthority.replace(/\s+/g, '');

                if (authority.length > 0) {
                    defineVisibility(true);

                    scope.$watch(function(scope) {
                        return Principal.isAuthenticated();
                    }, function(newValue) {
                        defineVisibility(true);
                    });
                }
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('Account', ["$resource", function Account($resource) {
        return $resource('api/account', {}, {
            'get': { method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function(response) {
                        // expose response
                        return response;
                    }
                }
            }
        });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('Activate', ["$resource", function ($resource) {
        return $resource('api/activate', {}, {
            'get': { method: 'GET', params: {}, isArray: false}
        });
    }]);



'use strict';

angular.module('vmaxmanagerApp')
    .factory('Password', ["$resource", function ($resource) {
        return $resource('api/account/change_password', {}, {
        });
    }]);

angular.module('vmaxmanagerApp')
    .factory('PasswordResetInit', ["$resource", function ($resource) {
        return $resource('api/account/reset_password/init', {}, {
        })
    }]);

angular.module('vmaxmanagerApp')
    .factory('PasswordResetFinish', ["$resource", function ($resource) {
        return $resource('api/account/reset_password/finish', {}, {
        })
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('Register', ["$resource", function ($resource) {
        return $resource('api/register', {}, {
        });
    }]);



/* globals $ */
'use strict';

angular.module('vmaxmanagerApp')
    .directive('showValidation', function() {
        return {
            restrict: 'A',
            require: 'form',
            link: function (scope, element) {
                element.find('.form-group').each(function() {
                    var $formGroup = $(this);
                    var $inputs = $formGroup.find('input[ng-model],textarea[ng-model],select[ng-model]');

                    if ($inputs.length > 0) {
                        $inputs.each(function() {
                            var $input = $(this);
                            scope.$watch(function() {
                                return $input.hasClass('ng-invalid') && $input.hasClass('ng-dirty');
                            }, function(isInvalid) {
                                $formGroup.toggleClass('has-error', isInvalid);
                            });
                        });
                    }
                });
            }
        };
    });

/* globals $ */
'use strict';

angular.module('vmaxmanagerApp')
    .directive('maxbytes', ["$q", function ($q) {
        function endsWith(suffix, str) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        }

        function paddingSize(base64String) {
            if (endsWith('==', base64String)) {
                return 2;
            }
            if (endsWith('=', base64String)) {
                return 1;
            }
            return 0;
        }

        function numberOfBytes(base64String) {
            return base64String.length / 4 * 3 - paddingSize(base64String);
        }

        return {
            restrict: 'A',
            require: '?ngModel',
            link: function (scope, element, attrs, ngModel) {
                if (!ngModel) return;

                ngModel.$validators.maxbytes = function (modelValue) {
                    return ngModel.$isEmpty(modelValue) || numberOfBytes(modelValue) <= attrs.maxbytes;
                };
            }
        };
    }]);

/* globals $ */
'use strict';

angular.module('vmaxmanagerApp')
    .directive('minbytes', ["$q", function ($q) {
        function endsWith(suffix, str) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        }

        function paddingSize(base64String) {
            if (endsWith('==', base64String)) {
                return 2;
            }
            if (endsWith('=', base64String)) {
                return 1;
            }
            return 0;
        }

        function numberOfBytes(base64String) {
            return base64String.length / 4 * 3 - paddingSize(base64String);
        }

        return {
            restrict: 'A',
            require: '?ngModel',
            link: function (scope, element, attrs, ngModel) {
                if (!ngModel) return;

                ngModel.$validators.minbytes = function (modelValue) {
                    return ngModel.$isEmpty(modelValue) || numberOfBytes(modelValue) >= attrs.minbytes;
                };
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["uibPagerConfig", function (uibPagerConfig) {
        uibPagerConfig.itemsPerPage = 20;
        uibPagerConfig.previousText = '«';
        uibPagerConfig.nextText = '»';
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["uibPaginationConfig", function (uibPaginationConfig) {
        uibPaginationConfig.itemsPerPage = 20;
        uibPaginationConfig.maxSize = 5;
        uibPaginationConfig.boundaryLinks = true;
        uibPaginationConfig.firstText = '«';
        uibPaginationConfig.previousText = '‹';
        uibPaginationConfig.nextText = '›';
        uibPaginationConfig.lastText = '»';
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('AuditsService', ["$http", function ($http) {
        return {
            findAll: function () {
                return $http.get('api/audits/').then(function (response) {
                    return response.data;
                });
            },
            findByDates: function (fromDate, toDate) {

                var formatDate =  function (dateToFormat) {
                    if (dateToFormat !== undefined && !angular.isString(dateToFormat)) {
                        return dateToFormat.getYear() + '-' + dateToFormat.getMonth() + '-' + dateToFormat.getDay();
                    }
                    return dateToFormat;
                };

                return $http.get('api/audits/', {params: {fromDate: formatDate(fromDate), toDate: formatDate(toDate)}}).then(function (response) {
                    return response.data;
                });
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('LogsService', ["$resource", function ($resource) {
        return $resource('api/logs', {}, {
            'findAll': { method: 'GET', isArray: true},
            'changeLevel': { method: 'PUT'}
        });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('ConfigurationService', ["$rootScope", "$filter", "$http", function ($rootScope, $filter, $http) {
        return {
            get: function() {
                return $http.get('configprops').then(function (response) {
                    var properties = [];
                    angular.forEach(response.data, function (data) {
                        properties.push(data);
                    });
                    var orderBy = $filter('orderBy');
                    return orderBy(properties, 'prefix');
                });
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('MonitoringService', ["$rootScope", "$http", function ($rootScope, $http) {
        return {
            getMetrics: function () {
                return $http.get('metrics/metrics').then(function (response) {
                    return response.data;
                });
            },

            checkHealth: function () {
                return $http.get('health').then(function (response) {
                    return response.data;
                });
            },

            threadDump: function () {
                return $http.get('dump').then(function (response) {
                    return response.data;
                });
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('authInterceptor', ["$rootScope", "$q", "$location", "localStorageService", function ($rootScope, $q, $location, localStorageService) {
        return {
            // Add authorization token to headers
            request: function (config) {
                config.headers = config.headers || {};
                var token = localStorageService.get('token');

                if (token && token.expires_at && token.expires_at > new Date().getTime()) {
                    config.headers.Authorization = 'Bearer ' + token.access_token;
                }

                return config;
            }
        };
    }])
    .factory('authExpiredInterceptor', ["$rootScope", "$q", "$injector", "localStorageService", function ( $rootScope, $q, $injector, localStorageService) {
        return {
            responseError: function (response) {
                // token has expired
                if (response.status === 401 && (response.data.error == 'invalid_token' || response.data.error == 'Unauthorized')) {
                    $rootScope.modalStack.dismissAll('error');
                    localStorageService.remove('token');
                    var Principal = $injector.get('Principal');
                    if (Principal.isAuthenticated()) {
                        var Auth = $injector.get('Auth');
                        Auth.authorize(true);
                    }
                }
                return $q.reject(response);
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('errorHandlerInterceptor', ["$q", "$rootScope", function ($q, $rootScope) {
        return {
            'responseError': function (response) {
                if (!(response.status == 401 && response.data.path && response.data.path.indexOf("/api/account") == 0 )){
	                $rootScope.$emit('vmaxmanagerApp.httpError', response);
	            }
                return $q.reject(response);
            }
        };
    }]);
 'use strict';

angular.module('vmaxmanagerApp')
    .factory('notificationInterceptor', ["$q", "AlertService", function ($q, AlertService) {
        return {
            response: function(response) {
                var alertKey = response.headers('X-vmaxmanagerApp-alert');
                if (angular.isString(alertKey)) {
                    AlertService.success(alertKey, { param : response.headers('X-vmaxmanagerApp-params')});
                }
                return response;
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .directive('activeMenu', ["$translate", "$locale", "tmhDynamicLocale", function($translate, $locale, tmhDynamicLocale) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var language = attrs.activeMenu;

                scope.$watch(function() {
                    return $translate.use();
                }, function(selectedLanguage) {
                    if (language === selectedLanguage) {
                        tmhDynamicLocale.set(language);
                        element.addClass('active');
                    } else {
                        element.removeClass('active');
                    }
                });
            }
        };
    }])
    .directive('activeLink', ["location", function(location) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var clazz = attrs.activeLink;
                var path = attrs.href;
                path = path.substring(1); //hack because path does bot return including hashbang
                scope.location = location;
                scope.$watch('location.path()', function(newPath) {
                    if (path === newPath) {
                        element.addClass(clazz);
                    } else {
                        element.removeClass(clazz);
                    }
                });
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('NavbarController', ["$scope", "$location", "$state", "Auth", "Principal", "ENV", function ($scope, $location, $state, Auth, Principal, ENV) {
        $scope.isAuthenticated = Principal.isAuthenticated;
        $scope.$state = $state;
        $scope.isInRole = Principal.isInRole;
        $scope.inProduction = ENV === 'prod';

        $scope.logout = function () {
            Auth.logout();
            $state.go('home');
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('User', ["$resource", function ($resource) {
        return $resource('api/users/:login', {}, {
                'query': {method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data;
                    }
                },
                'save': { method:'POST' },
                'update': { method:'PUT' },
                'delete':{ method:'DELETE'},
                'filterQuery': { method: 'POST', url: 'api/users/query', isArray:true },
                'filterVars': { method: 'GET', url: 'api/users/info', isArray:true }
            });
        }]);

'use strict';

angular.module('vmaxmanagerApp').controller('MenuCtrl', MenuCtrl);

MenuCtrl.$inject = ['$scope', '$rootScope', '$location', '$state', 'Auth', 'Principal', 'ENV'];

function MenuCtrl($scope, $rootScope, $location, $state, Auth, Principal, ENV) {
	var scp = $scope;

	scp.isAuthenticated = Principal.isAuthenticated;
	scp.$state = $state;
	scp.isInRole = Principal.isInRole;
	scp.inProduction = ENV === 'prod';

	var menu = jQuery('#menu').data('mmenu');
	console.log(menu)


	Principal.identity().then(function(account) {
	    $scope.account = account;
	    if($scope.account){
            if (_.isEmpty($scope.account.firstName) && _.isEmpty($scope.account.lastName)) {
	            $rootScope.userName = $scope.account.login;
            }  else {
                var firstName = _.isEmpty($scope.account.firstName) ? '' : $scope.account.firstName;
                var lastName = _.isEmpty($scope.account.lastName) ? '' : $scope.account.lastName;
                $rootScope.userName = firstName + ' ' + lastName;
            }
	    }
	});

	scp.logout = function () {
		var api = jQuery("#menu").data( "mmenu" );
		api.close();
		Auth.logout();
		$state.go('login');
	};

	scp.showMenu = function() {
		console.log($rootScope.showTitleAndMenu);
		return $rootScope.showTitleAndMenu;
	}


	scp.toggleMenu = function() {
		angular.element('.mm-slideout').toggleClass('menu-closed')
	}



}
'use strict';

angular.module('vmaxmanagerApp').directive('mmenu', mmenuDirective);

mmenuDirective.$inject = ['$window'];

function mmenuDirective($window) {
	return {
        restrict : 'A',
        link : function(scope, element, attrs) {
            jQuery(element).mmenu({
                // "slidingSubmenus": false,
            	// offCanvas    : false,
				"navbar" : {
					add: false
				},
				"extensions": [
					"pagedim-black",
					"widescreen"
				]
			}, {
                // clone: true;
                // "className": {
                //     "vertical": "sneroll",
                //     "divider": 'menu-divider'
                // },
                "offCanvas": {
                    "pageSelector": ".content-wrapper"
                }
            });

            angular.element($window).bind('resize', function(){

            	scope.width = $window.innerWidth;


            	// scope.$digest();
            });
        }
    };
}

'use strict';

angular.module('vmaxmanagerApp')
    .factory('User', ["$resource", function ($resource) {
        return $resource('api/users/:login', {}, {
                'query': {method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data;
                    }
                },
                'save': { method:'POST' },
                'update': { method:'PUT' },
                'delete':{ method:'DELETE'},
                'filterQuery': { method: 'POST', url: 'api/users/query', isArray:true },
                'filterVars': { method: 'GET', url: 'api/users/info', isArray:true }
            });
        }]);

'use strict';

angular.module('vmaxmanagerApp')
    .filter('characters', function () {
        return function (input, chars, breakOnWord) {
            if (isNaN(chars)) {
                return input;
            }
            if (chars <= 0) {
                return '';
            }
            if (input && input.length > chars) {
                input = input.substring(0, chars);

                if (!breakOnWord) {
                    var lastspace = input.lastIndexOf(' ');
                    // Get last space
                    if (lastspace !== -1) {
                        input = input.substr(0, lastspace);
                    }
                } else {
                    while (input.charAt(input.length-1) === ' ') {
                        input = input.substr(0, input.length - 1);
                    }
                }
                return input + '...';
            }
            return input;
        };
    })
    .filter('words', function () {
        return function (input, words) {
            if (isNaN(words)) {
                return input;
            }
            if (words <= 0) {
                return '';
            }
            if (input) {
                var inputWords = input.split(/\s+/);
                if (inputWords.length > words) {
                    input = inputWords.slice(0, words).join(' ') + '...';
                }
            }
            return input;
        };
    });

/*jshint bitwise: false*/
'use strict';

angular.module('vmaxmanagerApp')
    .service('Base64', function () {
        var keyStr = 'ABCDEFGHIJKLMNOP' +
            'QRSTUVWXYZabcdef' +
            'ghijklmnopqrstuv' +
            'wxyz0123456789+/' +
            '=';
        this.encode = function (input) {
            var output = '',
                chr1, chr2, chr3 = '',
                enc1, enc2, enc3, enc4 = '',
                i = 0;

            while (i < input.length) {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);

                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;

                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }

                output = output +
                    keyStr.charAt(enc1) +
                    keyStr.charAt(enc2) +
                    keyStr.charAt(enc3) +
                    keyStr.charAt(enc4);
                chr1 = chr2 = chr3 = '';
                enc1 = enc2 = enc3 = enc4 = '';
            }

            return output;
        };

        this.decode = function (input) {
            var output = '',
                chr1, chr2, chr3 = '',
                enc1, enc2, enc3, enc4 = '',
                i = 0;

            // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

            while (i < input.length) {
                enc1 = keyStr.indexOf(input.charAt(i++));
                enc2 = keyStr.indexOf(input.charAt(i++));
                enc3 = keyStr.indexOf(input.charAt(i++));
                enc4 = keyStr.indexOf(input.charAt(i++));

                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;

                output = output + String.fromCharCode(chr1);

                if (enc3 !== 64) {
                    output = output + String.fromCharCode(chr2);
                }
                if (enc4 !== 64) {
                    output = output + String.fromCharCode(chr3);
                }

                chr1 = chr2 = chr3 = '';
                enc1 = enc2 = enc3 = enc4 = '';
            }
        };
    })
    .factory('StorageService', ["$window", function ($window) {
        return {

            get: function (key) {
                return JSON.parse($window.localStorage.getItem(key));
            },

            save: function (key, data) {
                $window.localStorage.setItem(key, JSON.stringify(data));
            },

            remove: function (key) {
                $window.localStorage.removeItem(key);
            },

            clearAll : function () {
                $window.localStorage.clear();
            }
        };
    }]);


'use strict';

angular.module('vmaxmanagerApp')
    .filter('capitalize', function () {
        return function (input, scope) {
            if (input != null)
                input = input.toLowerCase();
            return input.substring(0, 1).toUpperCase() + input.substring(1);
        }
    });

'use strict';

angular.module('vmaxmanagerApp')
    .provider('AlertService', function () {
        this.toast = false;

        this.$get = ['$timeout', '$sce', '$translate', function($timeout, $sce, $translate) {

            var exports = {
                factory: factory,
                isToast: isToast,
                add: addAlert,
                closeAlert: closeAlert,
                closeAlertByIndex: closeAlertByIndex,
                clear: clear,
                get: get,
                success: success,
                error: error,
                info: info,
                warning : warning
            },

            toast = this.toast,
            alertId = 0, // unique id for each alert. Starts from 0.
            alerts = [],
            timeout = 5000; // default timeout

            function isToast() {
                return toast;
            }

            function clear() {
                alerts = [];
            }

            function get() {
                return alerts;
            }

            function success(msg, params, position) {
                return this.add({
                    type: "success",
                    msg: msg,
                    params: params,
                    timeout: timeout,
                    toast: toast,
                    position: position
                });
            }

            function error(msg, params, position) {
                return this.add({
                    type: "danger",
                    msg: msg,
                    params: params,
                    timeout: timeout,
                    toast: toast,
                    position: position
                });
            }

            function warning(msg, params, position) {
                return this.add({
                    type: "warning",
                    msg: msg,
                    params: params,
                    timeout: timeout,
                    toast: toast,
                    position: position
                });
            }

            function info(msg, params, position) {
                return this.add({
                    type: "info",
                    msg: msg,
                    params: params,
                    timeout: timeout,
                    toast: toast,
                    position: position
                });
            }

            function factory(alertOptions) {
                var alert = {
                    type: alertOptions.type,
                    msg: $sce.trustAsHtml(alertOptions.msg),
                    id: alertOptions.alertId,
                    timeout: alertOptions.timeout,
                    toast: alertOptions.toast,
                    position: alertOptions.position ? alertOptions.position : 'top right',
                    scoped: alertOptions.scoped,
                    close: function (alerts) {
                        return exports.closeAlert(this.id, alerts);
                    }
                }
                if(!alert.scoped) {
                    alerts.push(alert);
                }
                return alert;
            }

            function addAlert(alertOptions, extAlerts) {
                alertOptions.alertId = alertId++;
                alertOptions.msg = $translate.instant(alertOptions.msg, alertOptions.params);
                var that = this;
                var alert = this.factory(alertOptions);
                if (alertOptions.timeout && alertOptions.timeout > 0) {
                    $timeout(function () {
                        that.closeAlert(alertOptions.alertId, extAlerts);
                    }, alertOptions.timeout);
                }
                return alert;
            }

            function closeAlert(id, extAlerts) {
                var thisAlerts = extAlerts ? extAlerts : alerts;
                return this.closeAlertByIndex(thisAlerts.map(function(e) { return e.id; }).indexOf(id), thisAlerts);
            }

            function closeAlertByIndex(index, thisAlerts) {
                return thisAlerts.splice(index, 1);
            }

            return exports;
        }];

        this.showAsToast = function(isToast) {
            this.toast = isToast;
        };

    });

'use strict';

angular.module('vmaxmanagerApp')
    .provider('AlertModalsService', function () {
        this.toast = false;

        this.$get = ['$timeout', '$sce', '$translate', function($timeout, $sce,$translate) {

            var exports = {
                factory: factory,
                isToast: isToast,
                add: addAlert,
                closeAlert: closeAlert,
                closeAlertByIndex: closeAlertByIndex,
                clear: clear,
                get: get,
                success: success,
                error: error,
                info: info,
                warning : warning
            },

            toast = this.toast,
            alertId = 0, // unique id for each alert. Starts from 0.
            alerts = [],
            timeout = 5000000; // default timeout

            function isToast() {
                return toast;
            }

            function clear() {
                alerts = [];
            }

            function get() {
                return alerts;
            }

            function success(msg, params, position) {
                return this.add({
                    type: "success",
                    msg: msg,
                    params: params,
                    timeout: timeout,
                    toast: toast,
                    position: position
                });
            }

            function error(msg, params, position) {
                return this.add({
                    type: "danger",
                    msg: msg,
                    params: params,
                    timeout: timeout,
                    toast: toast,
                    position: position
                });
            }

            function warning(msg, params, position) {
                return this.add({
                    type: "warning",
                    msg: msg,
                    params: params,
                    timeout: timeout,
                    toast: toast,
                    position: position
                });
            }

            function info(msg, params, position) {
                return this.add({
                    type: "info",
                    msg: msg,
                    params: params,
                    timeout: timeout,
                    toast: toast,
                    position: position
                });
            }

            function factory(alertOptions) {
                var alert = {
                    type: alertOptions.type,
                    msg: $sce.trustAsHtml(alertOptions.msg),
                    id: alertOptions.alertId,
                    timeout: alertOptions.timeout,
                    toast: alertOptions.toast,
                    position: alertOptions.position ? alertOptions.position : 'top right',
                    scoped: alertOptions.scoped,
                    close: function (alerts) {
                        return exports.closeAlert(this.id, alerts);
                    }
                }
                if(!alert.scoped) {
                    alerts.push(alert);
                }
                return alert;
            }

            function addAlert(alertOptions, extAlerts) {
                alertOptions.alertId = alertId++;
                alertOptions.msg = $translate.instant(alertOptions.msg, alertOptions.params);
                var that = this;
                var alert = this.factory(alertOptions);
                if (alertOptions.timeout && alertOptions.timeout > 0) {
                    $timeout(function () {
                        that.closeAlert(alertOptions.alertId, extAlerts);
                    }, alertOptions.timeout);
                }
                return alert;
            }

            function closeAlert(id, extAlerts) {
                var thisAlerts = extAlerts ? extAlerts : alerts;
                return this.closeAlertByIndex(thisAlerts.map(function(e) { return e.id; }).indexOf(id), thisAlerts);
            }

            function closeAlertByIndex(index, thisAlerts) {
                return thisAlerts.splice(index, 1);
            }

            return exports;
        }];

        this.showAsToast = function(isToast) {
            this.toast = isToast;
        };

    });

'use strict';

angular.module('vmaxmanagerApp')
    .directive('jhAlert', ["AlertService", function(AlertService) {
        return {
            restrict: 'E',
            template: '<div class="alerts" ng-cloak="">' +
                            '<div ng-repeat="alert in alerts" ng-class="[alert.position, {\'toast\': alert.toast}]">' +
                                '<uib-alert ng-cloak="" type="{{alert.type}}" close="alert.close()"><pre>{{ alert.msg }}</pre></uib-alert>' +
                            '</div>' +
                      '</div>',
            controller: ['$scope',
                function($scope) {
                    $scope.alerts = AlertService.get();
                    $scope.$on('$destroy', function () {
                        $scope.alerts = [];
                    });
                }
            ]
        }
    }])
    .directive('jhAlertModals', ["AlertModalsService", function(AlertModalsService) {
        return {
          restrict: 'E',
          template: '<div class="alerts" ng-cloak="">' +
                          '<div ng-repeat="alert in alerts" ng-class="[alert.position, {\'toast\': alert.toast}]">' +
                              '<uib-alert ng-cloak="" type="{{alert.type}}" close="alert.close()"><pre>{{ alert.msg }}</pre></uib-alert>' +
                          '</div>' +
                    '</div>',
          controller: ['$scope',
              function($scope) {
                  $scope.alerts = AlertModalsService.get();
                  $scope.$on('$destroy', function () {
                      $scope.alerts = [];
                  });
              }
          ]
        }
    }])
    .directive('jhAlertError', ["AlertService", "$rootScope", "$translate", function(AlertService, $rootScope, $translate) {
        return {
            restrict: 'E',
            template: '<div class="alerts" ng-cloak="">' +
                            '<div ng-repeat="alert in alerts" ng-class="[alert.position, {\'toast\': alert.toast}]">' +
                                '<uib-alert ng-cloak="" type="{{alert.type}}" close="alert.close(alerts)"><pre>{{ alert.msg }}</pre></uib-alert>' +
                            '</div>' +
                      '</div>',
            controller: ['$scope',
                function($scope) {

                    $scope.alerts = [];

                    var cleanHttpErrorListener = $rootScope.$on('vmaxmanagerApp.httpError', function (event, httpResponse) {
                        var i;
                        event.stopPropagation();
                        switch (httpResponse.status) {
                            // connection refused, server not reachable
                            case 0:
                                addErrorAlert("Server not reachable",'error.server.not.reachable');
                                break;

                            case 400:
                                var errorHeader = httpResponse.headers('X-vmaxmanagerApp-error');
                                var entityKey = httpResponse.headers('X-vmaxmanagerApp-params');
                                if (errorHeader) {
                                    if (entityKey == "downloads") {
                                        var errorMessage = httpResponse.headers('X-vmaxmanagerApp-message');
                                        addErrorAlert(errorMessage);
                                    } else {
                                        var entityName = $translate.instant('global.menu.entities.' + entityKey);
                                        addErrorAlert(errorHeader, errorHeader, {entityName: entityName});
                                    }
                                } else if (httpResponse.data && httpResponse.data.fieldErrors) {
                                    for (i = 0; i < httpResponse.data.fieldErrors.length; i++) {
                                        var fieldError = httpResponse.data.fieldErrors[i];
                                        // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                                        var convertedField = fieldError.field.replace(/\[\d*\]/g, "[]");
                                        var fieldName = $translate.instant('vmaxmanagerApp.' + fieldError.objectName + '.' + convertedField);
                                        addErrorAlert('Field ' + fieldName + ' cannot be empty', 'error.' + fieldError.message, {fieldName: fieldName});
                                    }
                                } else if (httpResponse.data && httpResponse.data.message) {
                                    addErrorAlert(httpResponse.data.message, httpResponse.data.message, httpResponse.data);
                                } else {
                                    addErrorAlert(httpResponse.data);
                                }
                                break;

                             case 409:
                                var errorHeader = httpResponse.headers('X-vmaxmanagerApp-error');
                                var entityKey = httpResponse.headers('X-vmaxmanagerApp-params');
                                var entityName = $translate.instant('global.menu.entities.' + entityKey);
                                if (errorHeader) {
                                    if (errorHeader == "error.nameExists") {
                                        var name = httpResponse.headers('X-vmaxmanagerApp-message');
                                        var errorMessage = $translate.instant(errorHeader, {nameExist:name});
                                        addErrorAlert(errorMessage);
                                    } else {
                                        addErrorAlert(errorHeader, errorHeader, {entityName: entityName});
                                    }
                                } else {
                                     addErrorAlert(httpResponse.data);
                                 }
                                break;

                            default:
                                if (httpResponse.data && httpResponse.data.message) {
                                    addErrorAlert(httpResponse.data.message);
                                } else {
                                    addErrorAlert(JSON.stringify(httpResponse));
                                }
                        }
                    });

                    $scope.$on('$destroy', function () {
                        if(cleanHttpErrorListener !== undefined && cleanHttpErrorListener !== null){
                            cleanHttpErrorListener();
                            $scope.alerts = [];
                        }
                    });

                    var addErrorAlert = function (message, key, data) {
                        key = key && key != null ? key : message;
                        $scope.alerts.push(
                            AlertService.add(
                                {
                                    type: "danger",
                                    msg: key,
                                    params: data,
                                    timeout: 5000,
                                    toast: AlertService.isToast(),
                                    scoped: true
                                },
                                $scope.alerts
                            )
                        );
                    }
                }
            ]
        }
    }]);

(function(){
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .factory('ParseLinks', ParseLinks);

    function ParseLinks () {

        var service = {
            parse : parse
        };

        return service;

        function parse(header) {
            if (header.length === 0) {
                throw new Error('input must not be of zero length');
            }

            // Split parts by comma
            var parts = header.split(',');
            var links = {};
            // Parse each part into a named link
            angular.forEach(parts, function(p) {
                var section = p.split(';');
                if (section.length !== 2) {
                    throw new Error('section could not be split on ";"');
                }
                var url = section[0].replace(/<(.*)>/, '$1').trim();
                var queryString = {};
                url.replace(
                    new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
                    function($0, $1, $2, $3) { queryString[$1] = $3; }
                );
                var page = queryString.page;
                if (angular.isString(page)) {
                    page = parseInt(page);
                }
                var name = section[1].replace(/rel="(.*)"/, '$1').trim();
                links[name] = page;
            });
            return links;
        }
    }
})();
'use strict';

angular.module('vmaxmanagerApp')
    .service('DateUtils', ["$filter", function ($filter) {

    this.convertLocaleDateToServer = function(date, timezone) {
        if (date) {
            if(timezone){
                return $filter('date')(date, 'MM/dd/yyyy', timezone);
            }else{
                return $filter('date')(date, 'MM/dd/yyyy');
            }
        } else {
            return null;
        }
    };

    this.convertLocaleDateFromServer = function(date) {
        if (date) {
            var dateString = date.split("-");
            return new Date(dateString[0], dateString[1] - 1, dateString[2]);
        }
        return null;
    };

    this.convertDateTimeFromServer = function(date) {
        if (date) {
            return new Date(date);
        } else {
            return null;
        }
    }

    // common date format for all date input fields
    this.dateformat = function() {
        return 'MM/dd/yyyy';
    }
}])
.filter('parseDateFormat', ['DateUtils', function (DateUtils) {
    return function (input) {
        return DateUtils.convertLocaleDateToServer(input, "UTC");
    }
}])
.filter('parseLocalFormat', [function () {
    return function(d) {
        if (!d) return '';

        var dateMoment = moment(d).toLocaleString();

        var date = moment(dateMoment).format('YYYY-MM-DD HH:mm:ss');

        var d = date + " UTC";

        var dateFinal = new Date(d);

        return moment(dateFinal).format('YYYY-MM-DD HH:mm:ss');
    }
}]);

'use strict';

angular.module('vmaxmanagerApp')
    .service('DataUtils', ["$filter", function ($filter) {
    this.byteSize = function (base64String) {
        if (!angular.isString(base64String)) {
            return '';
        }
        function endsWith(suffix, str) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        }
        function paddingSize(base64String) {
            if (endsWith('==', base64String)) {
                return 2;
            }
            if (endsWith('=', base64String)) {
                return 1;
            }
            return 0;
        }
        function size(base64String) {
            return base64String.length / 4 * 3 - paddingSize(base64String);
        }
        function formatAsBytes(size) {
            return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " bytes";
        }

        return formatAsBytes(size(base64String));
    };
    this.abbreviate = function (text) {
        if (!angular.isString(text)) {
            return '';
        }
        if (text.length < 30) {
            return text;
        }
        return text ? (text.substring(0, 15) + '...' + text.slice(-10)) : '';
    };
}]);

'use strict';

angular.module('vmaxmanagerApp')
    .directive('jhSort', function () {
        return {
            restrict: 'A',
            scope: {
                predicate: '=jhSort',
                ascending: '=',
                callback: '&'
            },
            controller: ['$scope', function ($scope) {
                this.sort = function (field) {
                    if (field !== $scope.predicate) {
                        $scope.ascending = true;
                    } else {
                        $scope.ascending = !$scope.ascending;
                    }
                    $scope.predicate = field;
                    $scope.$apply();
                    $scope.callback();
                }
                this.applyClass = function (element) {
                    var allThIcons = element.parent().find('span.glyphicon'),
                    sortIcon = 'glyphicon-sort',
                    sortAsc = 'glyphicon-sort-by-attributes',
                    sortDesc = 'glyphicon-sort-by-attributes-alt',
                    remove = sortIcon + ' ' + sortDesc,
                    add = sortAsc,
                    thisIcon = element.find('span.glyphicon');
                    if (!$scope.ascending) {
                        remove = sortIcon + ' ' + sortAsc;
                        add = sortDesc;
                    }
                    allThIcons.removeClass(sortAsc + ' ' + sortDesc);
                    allThIcons.addClass(sortIcon);
                    thisIcon.removeClass(remove);
                    thisIcon.addClass(add);
                }
            }]
        }
    }).directive('jhSortBy', function () {
        return {
            restrict: 'A',
            scope: false,
            require: '^jhSort',
            link: function (scope, element, attrs, parentCtrl) {
                element.bind('click', function () {
                    parentCtrl.sort(attrs.jhSortBy);
                    parentCtrl.applyClass(element);
                });
            }
        };
    });

'use strict';

angular.module('vmaxmanagerApp')
    .directive('limitTo', ["$parse", function ($parse) {
        return {
            restrict: "A",
            require: 'ngModel',
            link: function(scope, elem, attrs, ngModel) {
                var limit = parseInt(attrs.limitTo);
                scope.$watch(attrs.ngModel, function (e) {
                    if (ngModel.$viewValue && ngModel.$viewValue.length > limit){
                        ngModel.$setViewValue(ngModel.$viewValue.substring(0, limit) );
                        ngModel.$render();
                    }
                });
            }
        }
    }]);

'use strict';

angular.module('vmaxmanagerApp')
.service('Timezone', function () {
    var service = {
        getTimezones : getTimezones
    };

    return service;

    var timezones = [];

    function loadTimezone () {
        var tz = [];
        _.forEach( moment.tz.names(), function(timezone) {
            var timezoneId =  timezone;
            var zone = moment.tz(timezone).format("Z");
            var timezoneDescription = "(GTM" + zone + ") - " + timezoneId;
            tz.push({name: timezoneId, description: timezoneDescription});
        });
        timezones = tz;
    };

    function getTimezones() {
        if (!timezones) {
            loadTimezone();
        }

        return  timezones;
    }
});

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('account', {
                abstract: true,
                parent: 'site'
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('activate', {
                parent: 'account',
                url: '/activate?key',
                data: {
                    authorities: [],
                    pageTitle: 'activate.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/account/activate/activate.html',
                        controller: 'ActivationController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('ActivationController', ["$scope", "$stateParams", "Auth", function ($scope, $stateParams, Auth) {
        Auth.activateAccount({key: $stateParams.key}).then(function () {
            $scope.error = null;
            $scope.success = 'OK';
        }).catch(function () {
            $scope.success = null;
            $scope.error = 'ERROR';
        });
    }]);


'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('login', {
                parent: 'account',
                url: '/',
                hideMenu: true,
                data: {
                    authorities: [],
                    pageTitle: 'login.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/account/login/login.html',
                        controller: 'LoginController'
                    },
                    'toolbar@' : {
                        controller: null,
                        templateUrl: null,
                    },
                    'menu@' : {
                        controller: null,
                        templateUrl: null,
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }]
                },
                onEnter: function(){
                	$('.modal-backdrop').remove();
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('LoginController', ["$rootScope", "$scope", "$state", "$timeout", "Auth", "Principal", function ($rootScope, $scope, $state, $timeout, Auth, Principal) {
        $scope.user = {};
        $scope.errors = {};

        $scope.rememberMe = true;
        $timeout(function (){angular.element('[ng-model="username"]').focus();});
        $scope.login = function (event) {
            event.preventDefault();
            Auth.login({
                username: $scope.username,
                password: $scope.password,
                rememberMe: $scope.rememberMe
            }).then(function () {
                $scope.authenticationError = false;
                $scope.authenticationActivatedError = false;
                Principal.identity().then(function(account) {
                	$scope.account = account;
                	if(account.passwordChange){
                		$scope.password = '';
                		$('#changePassword').modal('show');
                		$('#changePassword').on('hidden.bs.modal', function () {
	                		if($scope.success){
	                			$scope.redirect();
	                		}else{
	                			$scope.success = false;
	                			Auth.logout();
	                		}
                		});
                	}else{
                		$scope.redirect();
                	}
                });
            }).catch(function (response) {
            	if(response.data.error_description && response.data.error_description=='NoActivated'){
            		$scope.authenticationActivatedError = true;
            		$scope.authenticationError = false;
            	}else{
            		$scope.authenticationError = true;
            		$scope.authenticationActivatedError = false;
            	}
            });
        };
        
        $scope.redirect = function(){
        	$state.go('fleet');
       };
  }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('password', {
                parent: 'account',
                url: '/password',
                data: {
                	authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'global.menu.account.password'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/account/password/password.html',
                        controller: 'PasswordController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('password');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'global.menu.account.password'
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('PasswordController', ["$scope", "Auth", "Principal", "Parameters", function ($scope, Auth, Principal,Parameters) {
        Principal.identity().then(function(account) {
            $scope.account = account;
        });

        $scope.validationPassword = Parameters.getRegularExpressionValidationPassword();
        
        $scope.success = null;
        $scope.error = null;
        $scope.doNotMatch = null;
        $scope.changePassword = function () {
            if ($scope.password !== $scope.confirmPassword) {
                $scope.error = null;
                $scope.success = null;
                $scope.doNotMatch = 'ERROR';
            } else {
                $scope.doNotMatch = null;
                Auth.changePassword($scope.password).then(function () {
                    $scope.error = null;
                    $scope.success = 'OK';
                }).catch(function () {
                    $scope.success = null;
                    $scope.error = 'ERROR';
                });
            }
        };
    }]);

/* globals $ */
'use strict';

angular.module('vmaxmanagerApp')
    .directive('passwordStrengthBar', function () {
        return {
            replace: true,
            restrict: 'E',
            template: '<div id="strength">' +
                '<small translate="global.messages.validate.newpassword.strength">Password strength:</small>' +
                '<ul id="strengthBar">' +
                '<li class="point"></li><li class="point"></li><li class="point"></li><li class="point"></li><li class="point"></li>' +
                '</ul>' +
                '</div>',
            link: function (scope, iElement, attr) {
                var strength = {
                    colors: ['#F44336', '#F90', '#FFDD00', '#9F0', '#2CC55C'],
                    mesureStrength: function (p) {

                        var _force = 0;
                        var _regex = /[$-/:-?{-~!"^_`\[\]]/g; // "

                        var _lowerLetters = /[a-z]+/.test(p);
                        var _upperLetters = /[A-Z]+/.test(p);
                        var _numbers = /[0-9]+/.test(p);
                        var _symbols = _regex.test(p);

                        var _flags = [_lowerLetters, _upperLetters, _numbers, _symbols];
                        var _passedMatches = $.grep(_flags, function (el) {
                            return el === true;
                        }).length;

                        _force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
                        _force += _passedMatches * 10;

                        // penality (short password)
                        _force = (p.length <= 6) ? Math.min(_force, 10) : _force;

                        // penality (poor variety of characters)
                        _force = (_passedMatches === 1) ? Math.min(_force, 10) : _force;
                        _force = (_passedMatches === 2) ? Math.min(_force, 20) : _force;
                        _force = (_passedMatches === 3) ? Math.min(_force, 40) : _force;

                        return _force;

                    },
                    getColor: function (s) {

                        var idx = 0;
                        if (s <= 10) {
                            idx = 0;
                        }
                        else if (s <= 20) {
                            idx = 1;
                        }
                        else if (s <= 30) {
                            idx = 2;
                        }
                        else if (s <= 40) {
                            idx = 3;
                        }
                        else {
                            idx = 4;
                        }

                        return { idx: idx + 1, col: this.colors[idx] };
                    }
                };
                scope.$watch(attr.passwordToCheck, function (password) {
                    if (password) {
                        var c = strength.getColor(strength.mesureStrength(password));
                        iElement.removeClass('ng-hide');
                        iElement.find('ul').children('li')
                            .css({ 'background-color': '#DDD' })
                            .slice(0, c.idx)
                            .css({ 'background-color': c.col });
                    }
                });
            }
        };
    });

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('register', {
                parent: 'account',
                url: '/register',
                data: {
                    authorities: [],
                    pageTitle: 'register.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/account/register/register.html',
                        controller: 'RegisterController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('register');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('RegisterController', ["$scope", "$translate", "$timeout", "Auth", function ($scope, $translate, $timeout, Auth) {
        $scope.success = null;
        $scope.error = null;
        $scope.doNotMatch = null;
        $scope.errorUserExists = null;
        $scope.registerAccount = {};
        $timeout(function (){angular.element('[ng-model="registerAccount.login"]').focus();});

        $scope.register = function () {
            if ($scope.registerAccount.password !== $scope.confirmPassword) {
                $scope.doNotMatch = 'ERROR';
            } else {
                $scope.registerAccount.langKey = $translate.use();
                $scope.doNotMatch = null;
                $scope.error = null;
                $scope.errorUserExists = null;
                $scope.errorEmailExists = null;

                Auth.createAccount($scope.registerAccount).then(function () {
                    $scope.success = 'OK';
                }).catch(function (response) {
                    $scope.success = null;
                    if (response.status === 400 && response.data === 'login already in use') {
                        $scope.errorUserExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'e-mail address already in use') {
                        $scope.errorEmailExists = 'ERROR';
                    } else {
                        $scope.error = 'ERROR';
                    }
                });
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('settings', {
                parent: 'account',
                url: '/settings',
                data: {
                	authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'global.menu.account.settings'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/account/settings/settings.html',
                        controller: 'SettingsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settings');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'global.menu.account.settings'
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('SettingsController', ["$scope", "Principal", "Auth", "Language", "$translate", function ($scope, Principal, Auth, Language, $translate) {
        $scope.success = null;
        $scope.error = null;
        Principal.identity().then(function(account) {
            $scope.settingsAccount = copyAccount(account);
        });

        $scope.save = function () {
            Auth.updateAccount($scope.settingsAccount).then(function() {
                $scope.error = null;
                $scope.success = 'OK';
                Principal.identity(true).then(function(account) {
                    $scope.settingsAccount = copyAccount(account);
                });
                Language.getCurrent().then(function(current) {
                    if ($scope.settingsAccount.langKey !== current) {
                        $translate.use($scope.settingsAccount.langKey);
                    }
                });
            }).catch(function() {
                $scope.success = null;
                $scope.error = 'ERROR';
            });
        };

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login
            }
        }
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('ResetFinishController', ["$scope", "$stateParams", "$timeout", "Auth", "Parameters", function ($scope, $stateParams, $timeout, Auth, Parameters) {

        $scope.keyMissing = $stateParams.key === undefined;
        $scope.doNotMatch = null;
        $scope.validationPassword = Parameters.getRegularExpressionValidationPassword();
        $scope.resetAccount = {};
        $timeout(function (){angular.element('[ng-model="resetAccount.password"]').focus();});

        $scope.finishReset = function() {
            if ($scope.resetAccount.password !== $scope.confirmPassword) {
                $scope.doNotMatch = 'ERROR';
            } else {
                Auth.resetPasswordFinish({key: $stateParams.key, newPassword: $scope.resetAccount.password}).then(function () {
                    $scope.success = 'OK';
                    $scope.doNotMatch = null;
                }).catch(function (response) {
                    $scope.success = null;
                    $scope.doNotMatch = null;
                    $scope.error = 'ERROR';
                });
            }

        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('finishReset', {
                parent: 'account',
                url: '/reset/finish?key',
                hideMenu: true,
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/account/reset/finish/reset.finish.html',
                        controller: 'ResetFinishController'
                    },
                    'toolbar@' : {
                        controller: null,
                        templateUrl: null,
                    },
                    'menu@' : {
                        controller: null,
                        templateUrl: null,
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reset');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('RequestResetController', ["$rootScope", "$scope", "$state", "$timeout", "Auth", function ($rootScope, $scope, $state, $timeout, Auth) {

        $scope.success = null;
        $scope.error = null;
        $scope.errorEmailNotExists = null;
        $scope.resetAccount = {};
        $timeout(function (){angular.element('[ng-model="resetAccount.email"]').focus();});

        $scope.requestReset = function () {

            $scope.error = null;
            $scope.errorEmailNotExists = null;

            Auth.resetPasswordInit($scope.resetAccount.email).then(function () {
                $scope.success = 'OK';
            }).catch(function (response) {
                $scope.success = null;
                if (response.status === 400 && response.data === 'e-mail address not registered') {
                    $scope.errorEmailNotExists = 'ERROR';
                } else {
                    $scope.error = 'ERROR';
                }
            });
        }

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('requestReset', {
                parent: 'account',
                url: '/reset/request',
                hideMenu: true,
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/account/reset/request/reset.request.html',
                        controller: 'RequestResetController'
                    },
                    'toolbar@' : {
                        controller: null,
                        templateUrl: null,
                    },
                    'menu@' : {
                        controller: null,
                        templateUrl: null,
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reset');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('admin', {
                abstract: true,
                parent: 'site'
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('commander', {
                parent: 'admin',
                url: '/commander',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.commanderMonitor.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/commander/commander.html',
                        controller: 'CommanderController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commander');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'Commander Monitor'
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('CommanderController', ["$scope", "$rootScope", "Commander", "Server", function($scope, $rootScope, Commander, Server) {
    
   $scope.servers = Server.query();
   
   /**Data form UI**/
   $scope.data = {
        fleetId : '',
        yardId : '',
        serverId : ''
    };

    /** Event on server change*/
    $scope.onServerChange = function(serverId) {
        if (serverId) {
            Commander.getFleets(serverId, function(error, response) {
                if (error){
                	$scope.clearAll();
                	return bootbox.alert('Error getting fleets \n (Error ' + error.status + '):\n' + 'Please check url server.');	
                }
                $scope.clearSummary();
                $scope.fleets = response;
            });
        } else {
            $scope.fleets = '';
            $scope.data.fleetId = '';
            $scope.yards = '';
            $scope.data.yardId = '';
            $scope.clearSummary();
        }
    };

    
    /**Event on Fleet Change**/
    $scope.onFleetChange = function(serverId, fleetId) {
        if (fleetId) {
            Commander.getYards(serverId, fleetId, function(error, response) {
                if (error){
                	$scope.clearAll();
                	return bootbox.alert('Error getting yards (Error ' + error.status + '):\n' + 'Please check url server.');	
                }
                $scope.clearSummary();
                $scope.yards = response;
            });
        } else {
            $scope.yards = '';
            $scope.data.yardId = '';
        }
    };

    /**Get task from service**/
    $scope.monitorTasks = function(isRefresh) {
        if(!isRefresh){
            $scope.taskDetailList = null;
        }
        Commander.getTaskSummaryList($scope.data.serverId, $scope.data.fleetId, $scope.data.yardId, function(error, response) {
            if (error)
            {
            	$scope.clearAll();
            	return bootbox.alert('Error getting tasks summary (Error ' + error.status + '):\n' + 'Please check url server.');	
            	return;
            }
            $scope.taskSummaryList = response;
        });
    };

    /**Refresh query from service**/
    $scope.refresh = function() {
        $scope.monitorTasks(true);
        if($scope.taskDetailList && $scope.taskDetailList.length > 0) {
            $scope.taskDetails($scope.selectedYard, $scope.selectedVehicle);
        }
    };
    
    
    /**Clear All components **/
    $scope.clearAll = function() {
    	$scope.fleets = '';
        $scope.data.fleetId = '';
        $scope.yards = '';
        $scope.data.yardId = '';
        $scope.clearSummary();
        $scope.servers = Server.query();
    };
    
    /**Clear summary task and detail task **/
    $scope.clearSummary = function() {
        $scope.taskSummaryList = null;
        $scope.taskDetailList = null;
    };
    

    /**Get taskDetail of bus selected**/
    $scope.taskDetails = function(yardId, vehicleId) {
        $scope.selectedYard = yardId;
        $scope.selectedVehicle = vehicleId;
        Commander.getTaskList($scope.data.serverId, $scope.data.fleetId, yardId, vehicleId, function(error, response) {
            if (error){
            	$scope.clearAll();
            	return bootbox.alert('Error getting tasks detail (Error ' + error.status + '):\n' + 'Please check url server.');	
            }
            $scope.taskDetailList = response;
        });
    };
}]);

'use strict';

angular.module('vmaxmanagerApp')
       .factory('Commander', ["$http", function ($http) {
    return {
        getFleets: function(serverId, callback){
            $http.get('app/rest/commander/fleets/' + serverId).
            success(function(data, status, headers, config) {
                callback(null, data);
            }).
            error(function(data, status, headers, config) {
                callback({data: data, status: status});
            });
        },
        getYards: function(serverId, fleetId, callback){
            $http.get('app/rest/commander/yards/' + serverId + '/' + fleetId).
            success(function(data, status, headers, config) {
                callback(null, data);
            }).
            error(function(data, status, headers, config) {
                callback({data: data, status: status});
            });
        },
        getTaskSummaryList: function(serverId, fleetId, yardId, callback){
            var url = 'app/rest/commander/tasks/' + serverId +'/'+ fleetId;
            if(yardId) {
                url = url + '?yardId=' + yardId;
            }
            $http.get(url).
            success(function(data, status, headers, config) {
                callback(null, data);
            }).
            error(function(data, status, headers, config) {
                callback({data: data, status: status});
            });
        },
        getTaskList: function(serverId, fleetId, yardId, vehicleId, callback){
            $http.get('app/rest/commander/task/' + serverId + '/' + fleetId+ '/' + yardId + '/' + vehicleId).
            success(function(data, status, headers, config) {
                callback(null, data);
            }).
            error(function(data, status, headers, config) {
                callback({data: data, status: status});
            });
        }
    };
}]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('audits', {
                parent: 'admin',
                url: '/audits',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'audits.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/audits/audits.html',
                        controller: 'AuditsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('audits');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'audits.title'
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('AuditsController', ["$scope", "$filter", "AuditsService", function ($scope, $filter, AuditsService) {
        $scope.onChangeDate = function () {
            var dateFormat = 'yyyy-MM-dd';
            var fromDate = $filter('date')($scope.fromDate, dateFormat);
            var toDate = $filter('date')($scope.toDate, dateFormat);

            AuditsService.findByDates(fromDate, toDate).then(function (data) {
                $scope.audits = data;
            });
        };

        // Date picker configuration
        $scope.today = function () {
            // Today + 1 day - needed if the current day must be included
            var today = new Date();
            $scope.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
        };

        $scope.previousMonth = function () {
            var fromDate = new Date();
            if (fromDate.getMonth() === 0) {
                fromDate = new Date(fromDate.getFullYear() - 1, 0, fromDate.getDate());
            } else {
                fromDate = new Date(fromDate.getFullYear(), fromDate.getMonth() - 1, fromDate.getDate());
            }

            $scope.fromDate = fromDate;
        };

        $scope.today();
        $scope.previousMonth();
        $scope.onChangeDate();
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('configuration', {
                parent: 'admin',
                url: '/configuration',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'configuration.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/configuration/configuration.html',
                        controller: 'ConfigurationController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('configuration');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'configuration.title'
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('ConfigurationController', ["$scope", "ConfigurationService", function ($scope, ConfigurationService) {
        ConfigurationService.get().then(function(configuration) {
            $scope.configuration = configuration;
        });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('docs', {
                parent: 'admin',
                url: '/docs',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'global.menu.admin.apidocs'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/docs/docs.html'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', function ($translate) {
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'global.menu.admin.apidocs'
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('health', {
                parent: 'admin',
                url: '/health',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'health.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/health/health.html',
                        controller: 'HealthController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('health');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'health.title'
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('HealthController', ["$scope", "MonitoringService", "$uibModal", function ($scope, MonitoringService, $uibModal) {
        $scope.updatingHealth = true;
        $scope.separator = '.';

        $scope.refresh = function () {
            $scope.updatingHealth = true;
            MonitoringService.checkHealth().then(function (response) {
                $scope.healthData = $scope.transformHealthData(response);
                $scope.updatingHealth = false;
            }, function (response) {
                $scope.healthData =  $scope.transformHealthData(response.data);
                $scope.updatingHealth = false;
            });
        };

        $scope.refresh();

        $scope.getLabelClass = function (statusState) {
            if (statusState === 'UP') {
                return 'label-success';
            } else {
                return 'label-danger';
            }
        };

        $scope.transformHealthData = function (data) {
            var response = [];
            $scope.flattenHealthData(response, null, data);
            return response;
        };

        $scope.flattenHealthData = function (result, path, data) {
            angular.forEach(data, function (value, key) {
                if ($scope.isHealthObject(value)) {
                    if ($scope.hasSubSystem(value)) {
                        $scope.addHealthObject(result, false, value, $scope.getModuleName(path, key));
                        $scope.flattenHealthData(result, $scope.getModuleName(path, key), value);
                    } else {
                        $scope.addHealthObject(result, true, value, $scope.getModuleName(path, key));
                    }
                }
            });
            return result;
        };

        $scope.getModuleName = function (path, name) {
            var result;
            if (path && name) {
                result = path + $scope.separator + name;
            }  else if (path) {
                result = path;
            } else if (name) {
                result = name;
            } else {
                result = '';
            }
            return result;
        };


        $scope.showHealth = function(health) {
            var modalInstance = $uibModal.open({
                templateUrl: 'scripts/app/admin/health/health.modal.html',
                controller: 'HealthModalController',
                size: 'lg',
                resolve: {
                    currentHealth: function() {
                        return health;
                    },
                    baseName: function() {
                        return $scope.baseName;
                    },
                    subSystemName: function() {
                        return $scope.subSystemName;
                    }

                }
            });
        };

        $scope.addHealthObject = function (result, isLeaf, healthObject, name) {

            var healthData = {
                'name': name
            };
            var details = {};
            var hasDetails = false;

            angular.forEach(healthObject, function (value, key) {
                if (key === 'status' || key === 'error') {
                    healthData[key] = value;
                } else {
                    if (!$scope.isHealthObject(value)) {
                        details[key] = value;
                        hasDetails = true;
                    }
                }
            });

            // Add the of the details
            if (hasDetails) {
                angular.extend(healthData, { 'details': details});
            }

            // Only add nodes if they provide additional information
            if (isLeaf || hasDetails || healthData.error) {
                result.push(healthData);
            }
            return healthData;
        };

        $scope.hasSubSystem = function (healthObject) {
            var result = false;
            angular.forEach(healthObject, function (value) {
                if (value && value.status) {
                    result = true;
                }
            });
            return result;
        };

        $scope.isHealthObject = function (healthObject) {
            var result = false;
            angular.forEach(healthObject, function (value, key) {
                if (key === 'status') {
                    result = true;
                }
            });
            return result;
        };

        $scope.baseName = function (name) {
            if (name) {
              var split = name.split('.');
              return split[0];
            }
        };

        $scope.subSystemName = function (name) {
            if (name) {
              var split = name.split('.');
              split.splice(0, 1);
              var remainder = split.join('.');
              return remainder ? ' - ' + remainder : '';
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('HealthModalController', ["$scope", "$uibModalInstance", "currentHealth", "baseName", "subSystemName", function($scope, $uibModalInstance, currentHealth, baseName, subSystemName) {

        $scope.currentHealth = currentHealth;
        $scope.baseName = baseName, $scope.subSystemName = subSystemName;

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('logs', {
                parent: 'admin',
                url: '/logs',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'logs.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/logs/logs.html',
                        controller: 'LogsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('logs');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'audits.title'
                }

            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('LogsController', ["$scope", "LogsService", function ($scope, LogsService) {
        $scope.loggers = LogsService.findAll();

        $scope.changeLevel = function (name, level) {
            LogsService.changeLevel({name: name, level: level}, function () {
                $scope.loggers = LogsService.findAll();
            });
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('metrics', {
                parent: 'admin',
                url: '/metrics',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'metrics.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/metrics/metrics.html',
                        controller: 'MetricsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('metrics');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'metrics.title'
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('MetricsController', ["$scope", "MonitoringService", "$uibModal", function ($scope, MonitoringService, $uibModal) {
        $scope.metrics = {};
        $scope.updatingMetrics = true;

        $scope.refresh = function () {
            $scope.updatingMetrics = true;
            MonitoringService.getMetrics().then(function (promise) {
                $scope.metrics = promise;
                $scope.updatingMetrics = false;
            }, function (promise) {
                $scope.metrics = promise.data;
                $scope.updatingMetrics = false;
            });
        };

        $scope.$watch('metrics', function (newValue) {
            $scope.servicesStats = {};
            $scope.cachesStats = {};
            angular.forEach(newValue.timers, function (value, key) {
                if (key.indexOf('web.rest') !== -1 || key.indexOf('service') !== -1) {
                    $scope.servicesStats[key] = value;
                }
                if (key.indexOf('net.sf.ehcache.Cache') !== -1) {
                    // remove gets or puts
                    var index = key.lastIndexOf('.');
                    var newKey = key.substr(0, index);

                    // Keep the name of the domain
                    index = newKey.lastIndexOf('.');
                    $scope.cachesStats[newKey] = {
                        'name': newKey.substr(index + 1),
                        'value': value
                    };
                }
            });
        });

        $scope.refresh();

        $scope.refreshThreadDumpData = function() {
            MonitoringService.threadDump().then(function(data) {

                var modalInstance = $uibModal.open({
                    templateUrl: 'scripts/app/admin/metrics/metrics.modal.html',
                    controller: 'MetricsModalController',
                    size: 'lg',
                    resolve: {
                        threadDump: function() {
                            return data.content;
                        }

                    }
                });
            });
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('MetricsModalController', ["$scope", "$uibModalInstance", "threadDump", function($scope, $uibModalInstance, threadDump) {

        $scope.threadDump = threadDump;
        $scope.threadDumpRunnable = 0;
        $scope.threadDumpWaiting = 0;
        $scope.threadDumpTimedWaiting = 0;
        $scope.threadDumpBlocked = 0;

        angular.forEach(threadDump, function(value) {
            if (value.threadState === 'RUNNABLE') {
                $scope.threadDumpRunnable += 1;
            } else if (value.threadState === 'WAITING') {
                $scope.threadDumpWaiting += 1;
            } else if (value.threadState === 'TIMED_WAITING') {
                $scope.threadDumpTimedWaiting += 1;
            } else if (value.threadState === 'BLOCKED') {
                $scope.threadDumpBlocked += 1;
            }
        });

        $scope.threadDumpAll = $scope.threadDumpRunnable + $scope.threadDumpWaiting +
            $scope.threadDumpTimedWaiting + $scope.threadDumpBlocked;

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.getLabelClass = function (threadState) {
            if (threadState === 'RUNNABLE') {
                return 'label-success';
            } else if (threadState === 'WAITING') {
                return 'label-info';
            } else if (threadState === 'TIMED_WAITING') {
                return 'label-warning';
            } else if (threadState === 'BLOCKED') {
                return 'label-danger';
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('UserManagementDetailController', ["$scope", "$stateParams", "User", function ($scope, $stateParams, User) {
        $scope.user = {};
        $scope.load = function (login) {
            User.get({login: login}, function(result) {
                $scope.user = result;
            });
        };
        $scope.load($stateParams.login);
    }]);

'use strict';

angular.module('vmaxmanagerApp').controller('UserManagementDialogController', ['$scope', '$state', 'entity', 'User', 'Language',
    function($scope, $state, entity, User, Language) {

        $scope.user = entity;

        //Variables for controller checkbox Roles
        $scope.roleSystemAdminSelected = false;
        $scope.roleOperationsAdminSelected = false;
        $scope.roleOperationsSelected = false;
        $scope.isEdit = false;

        if (!_.isEmpty($scope.user) && !_.isEmpty($scope.user.authorities)) {
            _.each($scope.user.authorities, function(role) {
                switch (role) {
                    case 'ROLE_SYSTEM_ADMIN':
                        $scope.roleSystemAdminSelected = true;
                        break;

                    case 'ROLE_OPERATIONS_ADMIN':
                        $scope.roleOperationsAdminSelected = true;
                        break;

                    case 'ROLE_OPERATIONS':
                        $scope.roleOperationsSelected = true;
                        break;

                    default:
                        break;
                }
            });
        }

        Language.getAll().then(function(languages) {
            $scope.languages = languages;
        });

        var onSaveSuccess = function(result) {
            $scope.isSaving = false;
            $state.go('user-management');
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
        };

        $scope.save = function() {
            $scope.isSaving = true;
            $scope.setAuthorities();
            $scope.user.login = $scope.user.login.toLowerCase();
            if ($scope.user.id != null) {
                User.update($scope.user, onSaveSuccess, onSaveError);
            } else {
                User.save($scope.user, onSaveSuccess, onSaveError);
            }
        };

        /** Assign the roles checked*/
        $scope.setAuthorities = function() {
            $scope.user.authorities = [];
            if ($scope.roleOperationsSelected) {
                $scope.user.authorities.push("ROLE_OPERATIONS");
            }
            if ($scope.roleSystemAdminSelected) {
                $scope.user.authorities.push("ROLE_SYSTEM_ADMIN");
            }
            if ($scope.roleOperationsAdminSelected) {
                $scope.user.authorities.push("ROLE_OPERATIONS_ADMIN");
            }
            if (!$scope.roleOperationsAdminSelected && !$scope.roleSystemAdminSelected && !$scope.roleSystemAdminSelected) {
                $scope.user.authorities.push("ROLE_OPERATIONS");
            }
        };
    }
]);
'use strict';

angular.module('vmaxmanagerApp')
	.controller('user-managementDeleteController', ["$scope", "$uibModalInstance", "entity", "User", function($scope, $uibModalInstance, entity, User) {

        $scope.user = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (login) {
            User.delete({login: login},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('UserManagementController', ["$scope", "$rootScope", "Principal", "User", "ParseLinks", "Language", "USER_ROLES", function ($scope,$rootScope,Principal, User, ParseLinks, Language, USER_ROLES) {
        $scope.users = [];
        $scope.user;
        $scope.fields = User.filterVars();

        Language.getAll().then(function (languages) {
            $scope.languages = languages;
        });

		Principal.identity().then(function(account) {
            $scope.currentAccount = account;
        });
		$scope.predicate = 'id';
		$scope.reverse = true;
        $scope.page = 0;
        $scope.loadAll = function () {

            var pageable = {page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']};

            User.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.users.push(result[i]);
                }
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.users = [];
            $scope.loadAll();
        };
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.setActive = function (user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                $scope.loadAll();
                $scope.clear();
            });
        };

        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null
            };
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('user-management', {
                parent: 'admin',
                url: '/user-management',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'user-management.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/user-management/user-management.html',
                        controller: 'UserManagementController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user.management');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'user-management.home.title'
                }
            })
            .state('user-management-detail', {
                parent: 'user-management',
                url: '/:login/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'user-management.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/user-management/user-management-detail.html',
                        controller: 'UserManagementDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user.management');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'user-management'
                }
            })
            .state('user-management.new', {
                parent: 'user-management',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/user-management/user-management-dialog.html',
                        controller: 'UserManagementDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user.management');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
                        	 id: null, login: null, firstName: null, lastName: null, email: null,
                        	 activated: true, langKey: null, createdBy: null, createdDate: null,
                        	 lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                        	 resetKey: null, authorities: null
                             };;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'user-management'
                }
            })
            .state('user-management.edit', {
                parent: 'user-management',
                url: '/{login}/edit',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/user-management/user-management-dialog.html',
                        controller: 'UserManagementDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user.management');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'User', function($stateParams, User) {
                        return User.get({login : $stateParams.login}).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'user-management'
                }
            })
            .state('user-management.delete', {
                parent: 'user-management',
                url: '/{login}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/admin/user-management/user-management-delete-dialog.html',
                        controller: 'user-managementDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['User', function(User) {
                                return User.get({login : $stateParams.login});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('user-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('entity', {
                abstract: true,
                parent: 'site'
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('error', {
                parent: 'site',
                url: '/error',
                data: {
                    authorities: [],
                    pageTitle: 'error.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/error/error.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('error');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accessdenied', {
                parent: 'site',
                url: '/accessdenied',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/error/accessdenied.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('error');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('home', {
                parent: 'site',
                url: '/home',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/main/main.html',
                        controller: 'MainController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('main');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'main.home'
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('MainController', ["$scope", "Principal", function ($scope, Principal) {
        Principal.identity().then(function(account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
        });
    }]);

MessageFormat.locale.en=function(n){return n===1?"one":"other"}

MessageFormat.locale.fr=function(n){return n===0||n==1?"one":"other"}

'use strict';

angular.module('vmaxmanagerApp')
    .factory('Language', ["$q", "$http", "$translate", "LANGUAGES", function ($q, $http, $translate, LANGUAGES) {
        return {
            getCurrent: function () {
                var deferred = $q.defer();
                var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');

                if (angular.isUndefined(language)) {
                    language = 'en';
                }

                deferred.resolve(language);
                return deferred.promise;
            },
            getAll: function () {
                var deferred = $q.defer();
                deferred.resolve(LANGUAGES);
                return deferred.promise;
            }
        };
    }])

/*
 Languages codes are ISO_639-1 codes, see http://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 They are written in English to avoid character encoding issues (not a perfect solution)
 */
    .constant('LANGUAGES', [
        'en', 'fr'
        // jhipster-needle-006 - JHipster will add new languages here
    ]
);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('LanguageController', ["$scope", "$translate", "Language", "tmhDynamicLocale", function ($scope, $translate, Language, tmhDynamicLocale) {
        Language.getCurrent().then(function(l) {
            console.log('ctrl init promise')
            $scope.lang = l;
        });
        $scope.changeLanguage = function (languageKey) {
            $translate.use(languageKey);
            tmhDynamicLocale.set(languageKey);
            $scope.lang= languageKey;
        };

        Language.getAll().then(function (languages) {
            $scope.languages = languages;
        });
    }])
    .filter('findLanguageFromKey', function () {
        return function (lang) {
            return {
                "ca": "Català",
                "da": "Dansk",
                "de": "Deutsch",
                "en": "English",
                "es": "Español",
                "fr": "Français",
                "gl": "Galego",
                "hu": "Magyar",
                "it": "Italiano",
                "ja": "日本語",
                "ko": "한국어",
                "nl": "Nederlands",
                "pl": "Polski",
                "pt-br": "Português (Brasil)",
                "pt-pt": "Português",
                "ro": "Română",
                "ru": "Русский",
                "sv": "Svenska",
                "ta": "தமிழ்",
                "tr": "Türkçe",
                "zh-cn": "中文（简体）",
                "zh-tw": "繁體中文"
            }[lang];
        }
    });

'use strict';

angular.module('vmaxmanagerApp')
    .factory('AuthServerProvider', ["$http", "localStorageService", "Base64", function loginService($http, localStorageService, Base64) {
        return {
            login: function(credentials) {
                var data = "username=" +  encodeURIComponent(credentials.username) + "&password="
                    + encodeURIComponent(credentials.password) + "&grant_type=password&scope=read%20write&" +
                    "client_secret=mySecretOAuthSecret&client_id=vmaxmanagerapp";
                return $http.post('oauth/token', data, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Accept": "application/json",
                        "Authorization": "Basic " + Base64.encode("vmaxmanagerapp" + ':' + "mySecretOAuthSecret")
                    }
                }).success(function (response) {
                    var expiredAt = new Date();
                    expiredAt.setSeconds(expiredAt.getSeconds() + response.expires_in);
                    response.expires_at = expiredAt.getTime();
                    localStorageService.set('token', response);
                    return response;
                });
            },
            logout: function() {
                // logout from the server
                $http.post('api/logout').then(function() {
                    localStorageService.clearAll();
                });
            },
            getToken: function () {
                return localStorageService.get('token');
            },
            hasValidToken: function () {
                var token = this.getToken();
                return token && token.expires_at && token.expires_at > new Date().getTime();
            }
        };
    }]);

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('tracker', {
                parent: 'admin',
                url: '/tracker',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN'],
                    pageTitle: 'tracker.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/tracker/tracker.html',
                        controller: 'TrackerController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('tracker');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'tracker.title'
                },
                onEnter: ["Tracker", function(Tracker) {
                    Tracker.subscribe();
                }],
                onExit: ["Tracker", function(Tracker) {
                    Tracker.unsubscribe();
                }],
            });
    }]);

angular.module('vmaxmanagerApp')
    .controller('TrackerController', ["$scope", "AuthServerProvider", "$cookies", "$http", "Tracker", function ($scope, AuthServerProvider, $cookies, $http, Tracker) {
        // This controller uses a Websocket connection to receive user activities in real-time.

        $scope.activities = [];
        Tracker.receive().then(null, null, function(activity) {
            showActivity(activity);
        });

        function showActivity(activity) {
            var existingActivity = false;
            for (var index = 0; index < $scope.activities.length; index++) {
                if($scope.activities[index].sessionId == activity.sessionId) {
                    existingActivity = true;
                    if (activity.page == 'logout') {
                        $scope.activities.splice(index, 1);
                    } else {
                        $scope.activities[index] = activity;
                    }
                }
            }
            if (!existingActivity && (activity.page != 'logout')) {
                $scope.activities.push(activity);
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('Tracker', ["$rootScope", "$cookies", "$http", "$q", function ($rootScope, $cookies, $http, $q) {
        var stompClient = null;
        var subscriber = null;
        var listener = $q.defer();
        var connected = $q.defer();
        var alreadyConnectedOnce = false;
        function sendActivity() {
            if (stompClient != null && stompClient.connected) {
                stompClient
                    .send('/topic/activity',
                    {},
                    JSON.stringify({'page': $rootScope.toState.name}));
            }
        }
        return {
            connect: function () {
                //building absolute path so that websocket doesnt fail when deploying with a context path
                var loc = window.location;
                var url = '//' + loc.host + loc.pathname + 'websocket/tracker';
                var authToken = JSON.parse(localStorage.getItem("ls.token")).access_token;
                url += '?access_token=' + authToken;
                var socket = new SockJS(url);
                stompClient = Stomp.over(socket);
                var headers = {};
                stompClient.connect(headers, function(frame) {
                    connected.resolve("success");
                    sendActivity();
                    if (!alreadyConnectedOnce) {
                        $rootScope.$on('$stateChangeStart', function (event) {
                            sendActivity();
                        });
                        alreadyConnectedOnce = true;
                    }
                });
            },
            subscribe: function() {
                connected.promise.then(function() {
                    subscriber = stompClient.subscribe("/topic/tracker", function(data) {
                        listener.notify(JSON.parse(data.body));
                    });
                }, null, null);
            },
            unsubscribe: function() {
                if (subscriber != null) {
                    subscriber.unsubscribe();
                }
                listener = $q.defer();
            },
            receive: function() {
                return listener.promise;
            },
            sendActivity: function () {
                if (stompClient != null) {
                    sendActivity();
                }
            },
            disconnect: function() {
                if (stompClient != null) {
                    stompClient.disconnect();
                    stompClient = null;
                }
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('fleet', {
                parent: 'entity',
                url: '/fleets',
                data: {
                	authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.fleet.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/fleet/fleets.html',
                        controller: 'FleetController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fleet');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.fleet.home.title'
                }
            })
            .state('fleet.detail', {
                parent: 'fleet',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.fleet.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/fleet/fleet-detail.html',
                        controller: 'FleetDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fleet');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Fleet', function($stateParams, Fleet) {
                        return Fleet.get({id : $stateParams.id}).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'fleet'
                }
            })
            .state('fleet.new', {
                parent: 'fleet',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.fleet.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/fleet/fleet-dialog.html',
                        controller: 'FleetDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fleet');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
                                    fleetId: null,
                                    description: null,
                                    adminEmail: null,
                                    notes: null,
                                    isActive: true,
                                    deploymentType: null,
                                    contactName: null,
                                    contactPhone: null,
                                    contactEmail: null,
                                    soldName: null,
                                    soldEmail: null,
                                    id: null
                                };;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'fleet'
                }
            })
            .state('fleet.edit', {
                parent: 'fleet',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.fleet.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/fleet/fleet-dialog.html',
                        controller: 'FleetDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fleet');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Fleet', function($stateParams, Fleet) {
                        return Fleet.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'fleet'
                }

            })
            .state('fleet.delete', {
                parent: 'fleet',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/fleet/fleet-delete-dialog.html',
                        controller: 'FleetDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['Fleet', function(Fleet) {
                                return Fleet.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('fleet', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('FleetController', ["$scope", "$state", "Fleet", "ParseLinks", function ($scope, $state, Fleet, ParseLinks) {

        $scope.fleets = [];
        $scope.predicate = 'fleetId';
        $scope.reverse = true;
        $scope.page = 0;

        //filter configs
        $scope.filterConfig = {
            inputGroupClass: '',
            btnClass: 'btn-raised'
        };

        $scope.loadAll = function() {
        	var pageable = {page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']};
        	Fleet.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.fleets.push(result[i]);
                }
            });
        };

        $scope.reset = function() {
            $scope.page = 0;
            $scope.fleets = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();
        $scope.fields = Fleet.filterVars();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.fleet = {
                fleetId: null,
                description: null,
                adminEmail: null,
                notes: null,
                isActive: null,
                deploymentType: null,
                contactName: null,
                contactPhone: null,
                contactEmail: null,
                soldName: null,
                soldEmail: null,
                id: null
            };
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp').controller('FleetDialogController',
    ['$scope', '$state', '$stateParams', '$translate', 'entity', 'Fleet', 'Server', 'Customer', 'SalesRegion', 'DeploymentType',
        function($scope, $state, $stateParams, $translate, entity, Fleet, Server, Customer, SalesRegion, DeploymentType) {

        $scope.fleet = entity;
        $scope.servers = Server.query();
        $scope.customers = Customer.query();
        $scope.salesregions = SalesRegion.query();
        $scope.deploymentTypes = DeploymentType.query();

        $scope.load = function(id) {
            Fleet.get({id : id}, function(result) {
                $scope.fleet = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:fleetUpdate', result);
            $scope.isSaving = false;
            $state.go('fleet');
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;

            var error = result.headers ? result.headers('X-vmaxmanagerApp-error') : null;
            if (!_.isNull(error)) {
                $translate('vmaxmanagerApp.fleet.' + error, { param: $scope.fleet.fleetId } ).then(function(fleetIdError) {
                    bootbox.alert(fleetIdError);
                    $scope.fleet.fleetId = '';
                });
            }
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.fleet.id != null) {
                Fleet.update($scope.fleet, onSaveSuccess, onSaveError);
            } else {
                Fleet.save($scope.fleet, onSaveSuccess, onSaveError);
            }
        };

        $scope.deploymentTypeChange = function() {
            $scope.fleet.server = null;
        };
}]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('FleetDeleteController', ["$scope", "$uibModalInstance", "entity", "Fleet", function($scope, $uibModalInstance, entity, Fleet) {

        $scope.fleet = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            Fleet.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('FleetDetailController', ["$scope", "$rootScope", "$stateParams", "entity", "Fleet", "Server", "Customer", "SalesRegion", function ($scope, $rootScope, $stateParams, entity, Fleet, Server, Customer, SalesRegion) {
        $scope.fleet = entity;
        $scope.load = function (id) {
            Fleet.get({id: id}, function(result) {
                $scope.fleet = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:fleetUpdate', function(event, result) {
            $scope.fleet = result;
        });
        $scope.$on('$destroy', unsubscribe);
 }]);

'use strict';

angular.module('vmaxmanagerApp').factory('DeploymentType', ["$resource", function($resource) {
    return $resource('app/rest/deploymentTypes/:id', {}, {
        'query' : {
            method : 'GET',
            isArray : true
        },
        'get' : {
            method : 'GET'
        }
    });
}]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('Fleet', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/fleets/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'filterQuery': { method: 'POST', url: 'api/fleets/query', isArray:true },
            'filterVars': { method: 'GET', url: 'api/fleets/info', isArray:true },
            'update': { method:'PUT' }
        });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('server', {
                parent: 'entity',
                url: '/servers',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.server.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/server/servers.html',
                        controller: 'ServerController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('server');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.server.home.title'
                }
            })
            .state('server.detail', {
                parent: 'server',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.server.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/server/server-detail.html',
                        controller: 'ServerDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('server');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Server', function($stateParams, Server) {
                        return Server.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'server'
                }
            })
            .state('server.new', {
                parent: 'server',
                    url: '/new',
                    data: {
                        authorities: ['ROLE_SYSTEM_ADMIN']
                    },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/server/server-dialog.html',
                        controller: 'ServerDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('server');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            name: null,
                            url: null,
                            ipAddress: null,
                            id: null
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'server'
                }
            })
            .state('server.edit', {
                parent: 'server',
                    url: '/{id}/edit',
                    data: {
                        authorities: ['ROLE_SYSTEM_ADMIN']
                    },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/server/server-dialog.html',
                        controller: 'ServerDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('server');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Server', function($stateParams, Server) {
                        return Server.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'server'
                }
            })
            .state('server.delete', {
                parent: 'server',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/server/server-delete-dialog.html',
                        controller: 'ServerDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['Server', function(Server) {
                                return Server.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('server', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }]);

'use strict';
angular.module('vmaxmanagerApp')
    .controller('ServerController', ["$scope", "$state", "Server", "ParseLinks", function ($scope, $state, Server, ParseLinks) {

        $scope.servers = [];
        $scope.predicate = 'name';
        $scope.reverse = true;
        $scope.page = 0;

        //filter configs
        $scope.filterConfig = {
            inputGroupClass: '',
            btnClass: 'btn-raised'
        };

        $scope.loadAll = function() {
        	var pageable = {page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'name']};
            Server.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.servers.push(result[i]);
                }
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.servers = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();
        $scope.fields = Server.filterVars();

        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.server = {
                name: null,
                url: null,
                ipAddress: null,
                id: null
            };
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp').controller('ServerDialogController',
    ['$scope', '$stateParams', 'entity', 'Server', '$state',
        function($scope, $stateParams, entity, Server, $state) {

        $scope.server = entity;
        $scope.load = function(id) {
            Server.get({id : id}, function(result) {
                $scope.server = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:serverUpdate', result);
            $state.go('server');
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.server.id != null) {
                Server.update($scope.server, onSaveSuccess, onSaveError);
            } else {
                Server.save($scope.server, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $state.go('server');;
        };
}]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('ServerDeleteController', ["$scope", "$uibModalInstance", "entity", "Server", function($scope, $uibModalInstance, entity, Server) {

        $scope.server = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            Server.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('ServerDetailController', ["$scope", "$rootScope", "$stateParams", "entity", "Server", function ($scope, $rootScope, $stateParams, entity, Server) {
        $scope.server = entity;
        $scope.load = function (id) {
            Server.get({id: id}, function(result) {
                $scope.server = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:serverUpdate', function(event, result) {
            $scope.server = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('Server', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/servers/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'filterQuery': { method: 'POST', url: 'api/servers/query', isArray:true },
            'filterVars': { method: 'GET', url: 'api/servers/info', isArray:true },
            'update': { method:'PUT' }
        });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('customer', {
                parent: 'entity',
                url: '/customers',
                data: {
                	authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.customer.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/customer/customers.html',
                        controller: 'CustomerController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('customer');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.customer.home.title'
                }
            })
            .state('customer.detail', {
                parent: 'customer',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.customer.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/customer/customer-detail.html',
                        controller: 'CustomerDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('customer');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Customer', function($stateParams, Customer) {
                        return Customer.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'customer'
                }
            })
            .state('customer.new', {
                parent: 'customer',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/customer/customer-dialog.html',
                                                controller: 'CustomerDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('server');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            name: null,
                            description: null,
                            id: null
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'customer'
                }
            })
            .state('customer.edit', {
                parent: 'customer',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/customer/customer-dialog.html',
                                                controller: 'CustomerDialogController',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('server');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Customer', function($stateParams, Customer) {
                        return Customer.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'customer'
                }
            })
            .state('customer.delete', {
                parent: 'customer',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/customer/customer-delete-dialog.html',
                        controller: 'CustomerDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['Customer', function(Customer) {
                                return Customer.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('customer', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('CustomerController', ["$scope", "$state", "Customer", "ParseLinks", function ($scope, $state, Customer, ParseLinks) {

        $scope.customers = [];
        $scope.predicate = 'ownerId';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.loadAll = function() {
            Customer.query({page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.customers.push(result[i]);
                }
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.customers = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.customer = {
                name: null,
                description: null,
                id: null
            };
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp').controller('CustomerDialogController',
    ['$scope', '$stateParams', 'entity', 'Customer', '$state',
        function($scope, $stateParams, entity, Customer, $state) {

        $scope.customer = entity;
        $scope.load = function(id) {
            Customer.get({id : id}, function(result) {
                $scope.customer = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:customerUpdate', result);
            $state.go('customer');
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.customer.id != null) {
                Customer.update($scope.customer, onSaveSuccess, onSaveError);
            } else {
                Customer.save($scope.customer, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $state.go('customer');
        };
}]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('CustomerDeleteController', ["$scope", "$uibModalInstance", "entity", "Customer", function($scope, $uibModalInstance, entity, Customer) {

        $scope.customer = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            Customer.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('CustomerDetailController', ["$scope", "$rootScope", "$stateParams", "entity", "Customer", function ($scope, $rootScope, $stateParams, entity, Customer) {
        $scope.customer = entity;
        $scope.load = function (id) {
            Customer.get({id: id}, function(result) {
                $scope.customer = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:customerUpdate', function(event, result) {
            $scope.customer = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('Customer', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/customers/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function($stateProvider) {
        $stateProvider
            .state('device', {
                parent: 'entity',
                url: '/devices',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.device.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/device/devices.html',
                        controller: 'DeviceController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('device');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.device.home.title'
                }
            })
            .state('device.detail', {
                parent: 'device',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.device.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/device/device-detail.html',
                        controller: 'DeviceDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('device');
                        $translatePartialLoader.addPart('license');
                        $translatePartialLoader.addPart('settingsInstance');
                        $translatePartialLoader.addPart('fleet');
                        $translatePartialLoader.addPart('server');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'DeviceStatusMonitoring', function($stateParams, DeviceStatusMonitoring) {
                        return DeviceStatusMonitoring.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    localDateDeviceSettingRequestAudit: ['$stateParams', 'DeviceSettingsRequestAudit', function($stateParams, DeviceSettingsRequestAudit) {
                       return DeviceSettingsRequestAudit.get({
                           id: $stateParams.id
                       }).$promise;
                   }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'device'
                }
            })
            .state('device.new', {
                parent: 'device',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/device/device-dialog.html',
                        controller: 'DeviceDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('device');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
                            providerId: null,
                            deviceId: null,
                            simId: null,
                            salesOrder: null,
                            expirationDate: null,
                            provisionDate: null,
                            status: null,
                            ipAddress: null,
                            notes: null,
                            id: null
                        };
                    }],
                    statusWorkflow: [function() {
                        return null;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'device'
                }
            })
            .state('device.edit', {
                parent: 'device',
                url: '/{id}/edit',
                params: {
                    isEdit: true
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/device/device-dialog.html',
                        controller: 'DeviceDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('device');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Device', function($stateParams, Device) {
                        return Device.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    statusWorkflow: ['DeviceStatusWorkflow', 'entity', function(DeviceStatusWorkflow, entity) {
                        return DeviceStatusWorkflow.query({
                            status: entity.status
                        }).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'device'
                }
            })
            .state('device.delete', {
                parent: 'device',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/device/device-delete-dialog.html',
                        controller: 'DeviceDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['Device', function(Device) {
                                return Device.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('device', null, {
                            reload: true
                        });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('device.settings-view', {
                parent: 'device',
                url: '/{deviceId}/view-device-setting/{settingsId}',
                params: {
                    parentState: null,
                    id: null,
                    deviceId: null,
                    deviceTypeId: null,
                    settingsId: null,
                    showChangeSettingsButton: true
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    headerLabel: 'vmaxmanagerApp.settingsInstance.process.viewSettings',
                    hideButtons: false,
                    isView: true
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        $translatePartialLoader.addPart('device');
                        $translatePartialLoader.addPart('license');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({
                            id: $stateParams.settingsId
                        }).$promise;
                    }],
                    licenseEntity: function() {
                        return null;
                    },
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                        return $stateParams.deviceId ? Device.get({
                            id: $stateParams.deviceId
                        }).$promise : null;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.settingsInstance.process.viewSettings',
                    parent: 'device'
                }
            })
            .state('device.settings-new', {
                parent: 'device',
                url: '/{deviceId}/new-device-setting',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.settingsInstance.new.title',
                    headerLabel: 'vmaxmanagerApp.settingsInstance.process.newSettings',
                    hideButtons: true,
                    showChangeSettingsButton: true,
                    isNew: true
                },
                params: {
                    parentState: null,
                    id: null,
                    deviceId: null,
                    deviceTypeId: null,
                    settingsId: null,
                    settingsTemplateId: null
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', function($stateParams) {
                        return {
                            id: null,
                            name: null,
                            notes: null,
                            template: null,
                            settingsTemplate: {
                                id: $stateParams.settingsTemplateId,
                                deviceType: {
                                    id: $stateParams.deviceTypeId
                                },
                                typeEntitySettingsEnum: 'DEVICE'
                            }
                        };
                    }],
                    licenseEntity: function() {
                        return null;
                    },
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                        return $stateParams.deviceId ? Device.get({
                            id: $stateParams.deviceId
                        }).$promise : null;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'device.settings-view'
                }
            })
            .state('device.settings-edit', {
                parent: 'device',
                url: '/{deviceId}/edit-device-settings/{settingsId}',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.settingsInstance.edit.title',
                    headerLabel: 'vmaxmanagerApp.settingsInstance.process.editSettings',
                    hideButtons: true,
                    showChangeSettingsButton: true,
                    isEdit: true
                },
                params: {
                    parentState: null,
                    id: null,
                    deviceId: null,
                    deviceTypeId: null,
                    settingsId: null
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({
                            id: $stateParams.settingsId
                        }).$promise;
                    }],
                    licenseEntity: function() {
                        return null;
                    },
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                        return $stateParams.deviceId ? Device.get({
                            id: $stateParams.deviceId
                        }).$promise : null;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'device.settings-view'
                }
            })
            .state('device.settings-clone', {
                parent: 'device',
                url: '/{deviceId}/clone-device-setting',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.settingsInstance.clone.title',
                    headerLabel: 'vmaxmanagerApp.settingsInstance.process.cloneSettings',
                    hideButtons: true,
                    showChangeSettingsButton: true,
                    isClone: true
                },
                params: {
                    parentState: null,
                    id: null,
                    deviceId: null,
                    deviceTypeId: null,
                    settingsId: null
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({
                            id: $stateParams.settingsId
                        }).$promise;
                    }],
                    licenseEntity: function() {
                        return null;
                    },
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                        return $stateParams.deviceId ? Device.get({
                            id: $stateParams.deviceId
                        }).$promise : null;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.clone',
                    parent: 'device.settings-view'
                }
            })
            .state('device.settings-change', {
                parent: 'device',
                url: '/{deviceId}/change-device-settings',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.settingsInstance.edit.title',
                    headerLabel: 'vmaxmanagerApp.settingsInstance.process.changeSettings',
                    hideButtons: true,
                    showChangeSettingsButton: true,
                    isEdit: true,
                    isChangeSettings: true
                },
                params: {
                    parentState: null,
                    id: null,
                    deviceId: null,
                    deviceTypeId: null,
                    settingsId: null
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({
                            id: $stateParams.settingsId
                        }).$promise;
                    }],
                    licenseEntity: function() {
                        return null;
                    },
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                        return $stateParams.deviceId ? Device.get({
                            id: $stateParams.deviceId
                        }).$promise : null;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.change',
                    parent: 'device.settings-view'
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('DeviceController', ["$scope", "$state", "Device", "DeviceWithoutQuarantine", "ParseLinks", "UtcDateString", function ($scope, $state, Device,DeviceWithoutQuarantine, ParseLinks, UtcDateString) {
        $scope.devices = [];
        $scope.predicate = 'providerId';
        $scope.reverse = true;
        $scope.sorts = ["deviceId,asc"];
        $scope.page = 0;
        $scope.per_page = 20;
        $scope.showQuarantine = false;
        $scope.isAllDevicesSelected = false;
        $scope.bulkSelectedDevicesList = [];
        $scope.inBulkAllSelectionProcess = false;
        $scope.showCheck = false;
        $scope.showAllFields = false;

        //filter configs
        $scope.filterConfig = {
            inputGroupClass: '',
            btnClass: 'btn-raised'
        };

        $scope.filterInitialRules = {
            'operator' : 'and',
            'rules' : [{condition: '!=', field:{name: 'status', type:"text"}, data:'QUARANTINE'}]
        };

        $scope.loadAll = function(isScroll) {
            if (!_.isBoolean(isScroll) && !isScroll) {
                $scope.devices = [];
            }

            var pageable = {page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']};
            Device.filterQuery({pageable:pageable, filters:$scope.queryObject, hideQuarantined: !$scope.showQuarantine}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    var device = result[i];
                    device.checked = $scope.isAllDevicesSelected;

                    if (device.provisionDate) {
                        var provisionDate = new Date(UtcDateString.createString(device.provisionDate));
                        device.provisionDate =  provisionDate;
                    }
                    $scope.devices.push(device);
                    if ($scope.isAllDevicesSelected) {
                        $scope.bulkSelectedByDevice(device)
                    }
                }
            });
        };

        Device.filterVars({}, function(data) {
            var f = [];
            angular.forEach(data, function(d) {
                f.push(d);
            });
            $scope.fields = f;
            $scope.loadAll();
        });

    	var setLicensesToScope = function(result, headers){
    		_.each(result,function(item){
    			$scope.devices.push(item);
    		});
    		$scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
        };

        $scope.reset = function() {
            $scope.page = 0;
            $scope.devices = [];
            $scope.loadAll();
        };

        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll(true);
        };


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.getDevices = function(){
        	if($scope.showQuarantine){
        		$scope.classAction = 'btn-danger';
        		Device.query({}, setLicensesToScope);
            }else{
            	$scope.classAction = null;
            	DeviceWithoutQuarantine.query({}, setLicensesToScope);
            }
        };


        $scope.clear = function () {
            $scope.device = {
                providerId: null,
                deviceId: null,
                simId: null,
                salesOrder: null,
                expirationDate: null,
                provisionDate: null,
                status: null,
                ipAddress: null,
                notes: null,
                id: null
            };
        };

        $scope.disabledSettings = function(status){
            return status !== 'ASSIGNED' && status !== 'NON_RENEWAL';
        };

        /**
        * This method handle the selection of the one device.
        */
        $scope.bulkSelectedByDevice = function(device){
            var deviceIndexInBulkList = _.indexOf($scope.bulkSelectedDevicesList, device.serialNumber);
            if (device.checked) {
                if (deviceIndexInBulkList === -1) {
                    // Add to Bulk List.
                    $scope.bulkSelectedDevicesList.push(device.serialNumber);
                }
            } else {
                $scope.bulkSelectedDevicesList.splice( deviceIndexInBulkList, 1 );
            }

            if (!$scope.inBulkAllSelectionProcess) {
                if($scope.bulkSelectedDevicesList.length === $scope.devices.length && $scope.bulkSelectedDevicesList.length > 0){
                    $scope.isAllDevicesSelected = true;
                } else if($scope.bulkSelectedDevicesList.length > 0){
                    $scope.isAllDevicesSelected = false;
                } else {
                    $scope.isAllDevicesSelected = false;
                }
            }
        };

        /**
        * This method check or uncheck all devices.
        */
        $scope.bulkSelectAllDevices = function(){
            if (!$scope.inBulkAllSelectionProcess) {
                $scope.inBulkAllSelectionProcess = true;
                _.each($scope.devices,function(device){
                    device.checked = $scope.isAllDevicesSelected;
                    $scope.bulkSelectedByDevice(device);
                });
            }
            $scope.inBulkAllSelectionProcess = false;
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp').controller('DeviceDialogController',
    ['$scope', '$state', '$stateParams','entity', 'Device', 'Fleet', 'DeviceType', 'Providers', 'ParseLinks', 'DeviceStatus', 'License', '$translate', 'statusWorkflow', 'DateUtils',
        function($scope, $state, $stateParams, entity, Device, Fleet, DeviceType, Providers, ParseLinks, DeviceStatus, License, $translate, statusWorkflow, DateUtils) {

    	/**set max Input Date.**/
        $scope.loadDateInput = function(){
            $("#dateInput").attr({"max" : 9999+'-'+12+'-'+31});
        };

        $scope.message={errors:{
            delete:'The Device could not be deleted. Please verify it is not assigned to any License and try again',
            unknown:'Unexpected error, please contact to support'
        }};

    	$scope.devices = [];

        $scope.load = function(id) {
            if (id != null) {
                Device.get({id : id}, function(result) {
                    $scope.device = result;
                    if ($scope.device && $scope.device.provisionDate) {
                        $scope.pickerDate = new Date($scope.device.provisionDate.getFullYear(), $scope.device.provisionDate.getMonth(), $scope.device.provisionDate.getDate(),
                        $scope.device.provisionDate.getHours(), $scope.device.provisionDate.getMinutes(), $scope.device.provisionDate.getSeconds());
                    }
                });
            }
        };

        if ($stateParams.id) {
            $scope.load($stateParams.id);
        }

        $scope.statusWorkflowList = statusWorkflow;
        $scope.isDisabledStatusWorkflowList = statusWorkflow ? statusWorkflow.length <= 1 : true;
        $scope.fleets = Fleet.query();
        $scope.devicetypes = DeviceType.query();
        $scope.providerss = Providers.query();
        $scope.licenses = License.query();
        $scope.deviceStatus = DeviceStatus.query();

        $scope.sorts = ["deviceId,asc"];
        $scope.page = 0;
        $scope.per_page = 20;
        $scope.showSettings = false;
        $scope.isAnylicensePublish = false;
        $scope.isEdit = $stateParams.isEdit ? $stateParams.isEdit : false;
        $scope.loadDateInput();



        $scope.removeFieldValueByError = function(errorCode) {
            switch(errorCode) {
                case 'serialNumberError':
                    $scope.device.serialNumber = "";
                    break;
                case 'deviceIdError':
                    $scope.device.deviceId = "";
                    break;
                default:
                    break;
            }
        }

        var onSaveSuccess = function (result) {
            $scope.isSaving = false;
        	if(result.error != null){
        	    $scope.removeFieldValueByError(result.error);
        		$translate('vmaxmanagerApp.device.' + result.error).then(function(deviceIdError) {
        			bootbox.alert(deviceIdError);
        		});
        	}else{
        		if($scope.addOther){
        			$scope.reset();
        			$scope.clear();
        		}else{
        			$scope.$emit('vmaxmanagerApp:deviceUpdate', result);
        			$state.go('device');
        		}
        	}
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
            if(result.error != null){
                $scope.removeFieldValueByError(result.error);
        		$translate('vmaxmanagerApp.device.' + result.error).then(function(deviceIdError) {
        			bootbox.alert(deviceIdError);
        		});
        	}
        };

        $scope.create = function () {
            $scope.isSaving = true;
            if ($scope.pickerDate) {
                $scope.device.provisionDate = new Date(moment.utc($scope.pickerDate).format("YYYY-MM-DDTHH:mm:ss"));
            }

            $scope.device.isProvidedByCustomer = $scope.device.isProvidedByCustomer ? $scope.device.isProvidedByCustomer : false;
            if ($scope.device.id != null) {
                $scope.validateLicenseStatus();
                $scope.isEdit = true;
                Device.update($scope.device, onSaveSuccess, onSaveError);
            } else {
                Device.save($scope.device, onSaveSuccess, onSaveError);
            }
        };

        $scope.update = function (id) {
            Device.get({id: id}, function(result) {
                $scope.device = result;
                $scope.validateLicenseStatus();
                $scope.selectedProvider();
                $scope.isEdit = true;
                $('#saveDeviceModal').modal('show');
            });
        };

        $scope.reset = function() {
        };

        $scope.clear = function() {
        	if($scope.addOther){
        		$scope.device = { providers: { id: $scope.device.providers.id
        			},deviceType: { id: $scope.device.deviceType.id
        			},providerId: null
        			, deviceId: null
        			, simId: null
        			, provisionDate: $scope.device.provisionDate != null ? new Date($scope.device.provisionDate) : null
        			, status: $scope.device.status
        			, id: null
        			, ipAddress: null
        			, notes: null
        		};
        	}else{
        		 $scope.device = { providerId: null, deviceId: null, simId: null, provisionDate: null, status: null, id: null, ipAddress: null, notes: null };
        		 $scope.deviceIdPlaceholder = '';
                 $scope.providerIdPlaceholder = '';
        	}

            $scope.showSettings = false;
            $scope.isAnylicensePublish = false;
            $scope.isEdit = false;
            $scope.addOther = false;
            $scope.loadDateInput();
        };

        $scope.cancel = function(result) {
        	$state.go('deviceType');
        };

        /*
         * selected provider.
         * @author jmontoya
         */
        $scope.selectedProvider = function() {
        	$scope.deviceIdPlaceholder = '';
        	$scope.providerIdPlaceholder = '';

        	if($scope.device.deviceType){
        	    $scope.selectedDeviceType();
        	}
        };

        /**
         * selected DeviceType.
         * @author jmontoya
         */
        $scope.selectedDeviceType = function(){

            var deviceType = [];

            if($scope.device && $scope.device.deviceType){
                deviceType = _.where($scope.devicetypes, {
                    'id' : $scope.device.deviceType.id
                });

                $scope.deviceIdPlaceholder = deviceType && deviceType[0] && deviceType[0].deviceIdLabel ? deviceType[0].deviceIdLabel : "";
            }


        	//Primero se selecciona el proveedor, se filtra la lista de deviceType y
        	//con esta lista filtrada se obtiene la marca de agua para que sea guía para el usuario.
        	if($scope.device.providers && deviceType.length > 0){
        		$scope.providerIdPlaceholder = $scope.getProviderIdByDeviceType(deviceType[0].id,$scope.device.providers.id);
        	}
        };


        /**Get provderId label**/
        $scope.getProviderIdByDeviceType = function(deviceId,providerId){

        	var providerIdLbl = '';
        	switch (deviceId) {
        	  case 1:
        		  if(providerId==1){
        			 providerIdLbl = "MEID";
        		  }
        	    break;
        	  case 2:
        		  if(providerId==1){
        			  providerIdLbl = "MEID(MSN)";
        		  }else if(providerId==3){
        			  providerIdLbl = "MEID";
        		  }
        	    break;
        	  case 3:
        		  if(providerId==1){
        			  providerIdLbl = "ESN";
        		  }else if(providerId==2){
        			  providerIdLbl = "ESN";
        		  }else if(providerId==3){
        			  providerIdLbl = "IMEI";
        		  }else if(providerId==4){
        			  providerIdLbl = "IMEI";
        		  }
        	    break;
        	  case 4:
        		  if(providerId==1){
        			  providerIdLbl = "ESN";
        		  }
          	    break;
        	  case 5:
        		  if(providerId==1){
        			  providerIdLbl = "IMEI";
        		  }
          	    break;
        	  default:
        	     return providerIdLbl;
        	    break;
        	}
        	return providerIdLbl;
        }

        $scope.updateProvisionDate = function () {
         $scope.device.provisionDate = moment.utc($scope.pickerDate).format("YYYY-MM-DDTHH:mm:ss[Z]");
        }

        /**
         * validate lincense
         * @author jmontoya
         */
        $scope.validateLicenseStatus = function(){
        	var arrayLicense = $scope.device.licenses;
        	if(arrayLicense != null && arrayLicense.length > 0){
        		$scope.showSettings = $scope.device.deviceType.requiresSettings == true;
        		var licensePublished = _.where(arrayLicense, {
        			'licenseStatus': 'PUBLISHED'
        		});
        		$scope.isAnylicensePublish = licensePublished.length > 0;
        		if($scope.showSettings)$scope.loadSettings();
        	}else{
        		$scope.showSettings = false;
        		$scope.isAnylicensePublish = false;
        	}
        };
}]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('DeviceDeleteController', ["$scope", "$uibModalInstance", "$translate", "entity", "Device", function($scope, $uibModalInstance, $translate, entity, Device) {

        if (!_.isEmpty(entity)) {
            $scope.device = entity;
            if (!_.isEmpty($scope.device.status)) {
                if ($scope.device.status == 'ASSIGNED' || $scope.device.status == 'NON_RENEWAL') {
                    $scope.isDeviceAssigned = true;
                    $scope.modalMessage = $translate.instant('vmaxmanagerApp.device.delete.warning');
                } else {
                    $scope.isDeviceAssigned = false;
                    $scope.modalMessage = $translate.instant('vmaxmanagerApp.device.delete.question', { id: $scope.device.deviceId } );
                }
            }
        }

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            Device.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('DeviceDetailController', ["$scope", "$rootScope", "$stateParams", "entity", "DeviceStatusMonitoring", "Fleet", "DeviceType", "Providers", "License", "$translate", "localDateDeviceSettingRequestAudit", "UtcDateString", function ($scope, $rootScope, $stateParams, entity, DeviceStatusMonitoring, Fleet, DeviceType, Providers, License, $translate, localDateDeviceSettingRequestAudit, UtcDateString) {

        var parseJsonTemplates = function(){
            if(entity.settingsInstance){
                if(entity.settingsInstance.template){
                    entity.settingsInstance.template = JSON.parse(entity.settingsInstance.template);
                }
                if(entity.settingsInstance.settingsTemplate && entity.settingsInstance.settingsTemplate.template){
                    entity.settingsInstance.settingsTemplate.template = JSON.parse(entity.settingsInstance.settingsTemplate.template);
                }
            }
        }

        var buildLocalDate = function(info){
            if(info && info.localDate){
                var newLocalDateString = UtcDateString.createString(info.localDate);
                $scope.localDateRequestAudit = moment(new Date(newLocalDateString)).format('MM/DD/YYYY HH:mm:ss');
            }else{
                $scope.localDateRequestAudit = null;
            }
        }

        parseJsonTemplates();
        buildLocalDate(localDateDeviceSettingRequestAudit);

        $scope.device = entity;

        $scope.load = function (id) {
            DeviceStatusMonitoring.get({id: id}, function(result) {
                $scope.device = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:deviceUpdate', function(event, result) {
            $scope.device = result;
        });
        $scope.$on('$destroy', unsubscribe);

        var translations = {
            'PATTERN_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.pattern'),
            'REQUIRED_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.required'),
            'MAX_LENGTH_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.maxLength'),
            'URL_EXISTS_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.urlExits')
        };

        // The layout configuration could be: "narrow" or "wide".
        $scope.settingsManagerConfig = {
            layout: 'narrow',
            labelClass: 'control-label',
            inputGroupClass: 'form-group label-floating',
            inputClass: 'form-control',
            selectClass: 'form-control',
            errorTextClass: '',
            translations: translations
        };

        $scope.deploymentTypeIcon = function(){
            if($scope.device && $scope.device.fleet && $scope.device.fleet.deploymentType){
                return $scope.device.fleet.deploymentType === "ONSITE"? 'business' : 'cloud';
            }
        }

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('Device', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/devices/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    if (data.expirationDate) {
                        data.expirationDate = new Date(data.expirationDate);
                    }
                    if (data.provisionDate) {
                        data.provisionDate = new Date(data.provisionDate);
                    }
                    return data;
                }
            },
            'filterQuery': { method: 'POST', url: 'api/devices/query', isArray:true },
            'filterVars': { method: 'GET', url: 'api/devices/info', isArray:true },
            'update': {method:'PUT'}
        });
    }])
    .factory('AllDeviceStatus', ["$resource", function ($resource) {
        return $resource('api/devices/findAllDeviceStatuses', {}, {
            'query' : { method: 'GET', isArray: true}
        });
    }])
    .factory('DeviceStatusWorkflow', ["$resource", function ($resource) {
        return $resource('api/devices/getDeviceStatusWorkflowList/:status', {}, {
            'query' : { method: 'GET', isArray: true}
        });
    }])
    .factory('DeviceWithoutQuarantine', ["$resource", function ($resource) {
        return $resource('api/devices/devicesWithoutQuarantine/:id', {}, {
            'query': { method: 'POST', isArray: true}
        });
    }])
    .factory('DeviceStatusMonitoring', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/devices/statusMonitoring/:id', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.expirationDate = !_.isEmpty(data.expirationDate) ? new Date(data.expirationDate) : null;
                    data.provisionDate =  !_.isEmpty(data.provisionDate) ?  moment(new Date(data.provisionDate)).format('MM/DD/YYYY HH:mm:ss') : null;
                    if (!_.isEmpty(data.licenses) && _.isArray(data.licenses)) {
                        _.each(data.licenses, function(license) {
                            license.startDate = !_.isEmpty(license.startDate) ? moment (license.startDate).format('MM/DD/YYYY') : null;
                            license.expiryDate = !_.isEmpty(license.expiryDate) ? moment (license.expiryDate).format('MM/DD/YYYY') : null;
                        });
                    }
                    return data;
                }
            }
        });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('deviceType', {
                parent: 'entity',
                url: '/deviceTypes',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.deviceType.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/deviceType/deviceTypes.html',
                        controller: 'DeviceTypeController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('deviceType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.deviceType.home.title'
                }
            })
            .state('deviceType.detail', {
                parent: 'deviceType',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.deviceType.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/deviceType/deviceType-detail.html',
                        controller: 'DeviceTypeDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('deviceType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'DeviceType', function($stateParams, DeviceType) {
                        return DeviceType.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'deviceType'
                }
            })
            .state('deviceType.new', {
                parent: 'deviceType',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/deviceType/deviceType-dialog.html',
                        controller: 'DeviceTypeDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('deviceType');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
                        	 name: null,
                        	 partId: null,
                        	 deviceIdLabel: null,
                             id: null
                             };;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'deviceType'
                }
            })
            .state('deviceType.edit', {
                parent: 'deviceType',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/deviceType/deviceType-dialog.html',
                        controller: 'DeviceTypeDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('deviceType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'DeviceType', function($stateParams, DeviceType) {
                        return DeviceType.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'deviceType'
                }
            })
            .state('deviceType.delete', {
                parent: 'deviceType',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/deviceType/deviceType-delete-dialog.html',
                        controller: 'DeviceTypeDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['DeviceType', function(DeviceType) {
                                return DeviceType.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('deviceType', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('DeviceTypeController', ["$scope", "$state", "DeviceType", "ParseLinks", function ($scope, $state, DeviceType, ParseLinks) {

        $scope.deviceTypes = [];
        $scope.predicate = 'name';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.loadAll = function() {
            DeviceType.query({page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.deviceTypes.push(result[i]);
                }
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.deviceTypes = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.deviceType = {
                name: null,
                partId: null,
                id: null
            };
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp').controller('DeviceTypeDialogController',
    ['$scope', '$state', 'entity', 'DeviceType',
        function($scope, $state, entity, DeviceType) {

        $scope.deviceType = entity;
        $scope.load = function(id) {
            DeviceType.get({id : id}, function(result) {
                $scope.deviceType = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:deviceTypeUpdate', result);
            $scope.isSaving = false;
            $state.go('deviceType');
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.deviceType.id != null) {
                DeviceType.update($scope.deviceType, onSaveSuccess, onSaveError);
            } else {
                DeviceType.save($scope.deviceType, onSaveSuccess, onSaveError);
            }
        };
}]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('DeviceTypeDeleteController', ["$scope", "$uibModalInstance", "entity", "DeviceType", function($scope, $uibModalInstance, entity, DeviceType) {

        $scope.deviceType = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            DeviceType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('DeviceTypeDetailController', ["$scope", "$rootScope", "$stateParams", "entity", "DeviceType", function ($scope, $rootScope, $stateParams, entity, DeviceType) {
        $scope.deviceType = entity;
        $scope.load = function (id) {
            DeviceType.get({id: id}, function(result) {
                $scope.deviceType = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:deviceTypeUpdate', function(event, result) {
            $scope.deviceType = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('DeviceType', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/deviceTypes/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findDeviceTypeAvailableToLicense':
            {
                method:'GET',
                isArray: true,
                url : 'api/deviceTypes/findDeviceTypeAvailableToLicense?licenseId=:licenseId'
            },
            'findDeviceTypeAssignedToLicense':
            {
                method:'GET',
                isArray: true,
                url : 'api/deviceTypes/findDeviceTypeAssignedToLicense?licenseId=:licenseId'
            },
            'findDeviceTypeWithSettingsTemplate':
            {
                method:'GET',
                isArray: true,
                url : 'api/deviceTypes/findDeviceTypeWithSettingsTemplate'
            }
        });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('license', {
                parent: 'entity',
                url: '/license',
                data: {
                	authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.license.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/license/licenses.html',
                        controller: 'LicenseController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('license');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.license.home.title'
                }
            })
            .state('license.detail', {
                parent: 'license',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.license.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/license/license-detail.html',
                        controller: 'LicenseDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('license');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'License', function($stateParams, License) {
                        return License.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'license'
                }
            })
            .state('license.new', {
                parent: 'license',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/license/license-dialog.html',
                        controller: 'LicenseDialogController'
                    }
                },
                 resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('license');
                            return $translate.refresh();
                        }],
                    entity: function() {
                        return {
                           slots: null,
                           slotUsed: null,
                           salesOrder: null,
                           previousSalesOrder: null,
                           startDate: null,
                           duration: null,
                           expiryDate: null,
                           dataAmount: null,
                           chargeType: null,
                           licenseStatus: null,
                           notes: null,
                           id: null
                       };
                    }
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'license'
                }
            })
            .state('license.edit', {
                parent: 'license',
                url: '/{id}/edit',
                params: {
                    settingsId: null,
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/license/license-dialog.html',
                        controller: 'LicenseDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('license');
                            return $translate.refresh();
                        }],
                        entity: ['$stateParams', 'License', function($stateParams, License) {
                            return License.get({id : $stateParams.id}).$promise;
                        }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'license'
                }
            })
            .state('license.delete', {
                parent: 'license',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/license/license-delete-dialog.html',
                        controller: 'LicenseDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['License', function(License) {
                                return License.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                            $state.go('license', null, { reload: true });
                        }, function() {
                            $state.go('^');
                        })
                }]
            })
            .state('license.device', {
                abstract: false,
                parent: 'license'
            })
            .state('license.device.assign', {
                parent: 'license',
                url: '/assign/device/{id}',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/license/license-assign-device.html',
                        controller: 'LicenseAssignDeviceController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('license');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'License', function($stateParams, License) {
                        return License.get({id : $stateParams.id}).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.license.devices.title',
                    parent: 'license'
                }
            })
            // Dynamic settings instance calls.
            .state('license.edit.settings-view', {
                parent: 'entity',
                url: '/{id}/view-settings/{settingsId}',
                params: {
                    parentState: null,
                    settingsId: null,
                    deviceId: null,
                    deviceTypeId: null,
                    license: null,
                    device: null,
                    settingsTemplateId: null,
                    softwareOrDeviceId: null,
                    typeEntitySettingsEnum: null,
                    showChangeSettingsButton: null
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    headerLabel:'vmaxmanagerApp.settingsInstance.process.viewSettings',
                    hideButtons: false,
                    isView: true
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({id : $stateParams.settingsId}).$promise;
                    }],
                    licenseEntity: ['$stateParams', 'License', function($stateParams, License) {
                      return $stateParams.id? License.get({id : $stateParams.id}).$promise: null;
                    }],
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                      return $stateParams.deviceId? Device.get({id : $stateParams.deviceId}).$promise: null;
                    }]
                },
                ncyBreadcrumb: {
                   label: 'vmaxmanagerApp.settingsInstance.process.viewSettings',
                   parent: function ($scope) {
                        return $scope.dynamicParentBreadcrumb();
                   }
               }
            })
            .state('license.edit.settings-edit', {
                parent: 'entity',
                url: '/{id}/edit-settings/{settingsId}',
                params: {
                    parentState: null,
                    settingsId: null,
                    deviceId: null,
                    deviceTypeId: null,
                    showChangeSettingsButton: null
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    headerLabel:'vmaxmanagerApp.settingsInstance.process.editSettings',
                    hideButtons: true,
                    isEdit: true
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({id : $stateParams.settingsId}).$promise;
                    }],
                    licenseEntity: ['$stateParams', 'License', function($stateParams, License) {
                      return $stateParams.id? License.get({id : $stateParams.id}).$promise: null;
                    }],
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                      return $stateParams.deviceId? Device.get({id : $stateParams.deviceId}).$promise: null;
                    }]
                },
                ncyBreadcrumb: {
                   label: 'vmaxmanagerApp.settingsInstance.process.editSettings',
                   parent: function ($scope) {
                        return $scope.dynamicParentBreadcrumb();
                   }
               }
            })
            .state('license.edit.settings-new', {
                parent: 'entity',
                url: '/{id}/new-settings',
                params: {
                    parentState: null,
                    settingsId: null,
                    deviceId: null,
                    deviceTypeId: null,
                    settingsTemplateId: null,
                    softwareOrDeviceId: null,
                    typeEntitySettingsEnum: null,
                    showChangeSettingsButton: null
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    headerLabel:'vmaxmanagerApp.settingsInstance.process.newSettings',
                    hideButtons: true,
                    isNew: true,
                    isNewFromLicense: true
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', function($stateParams) {
                            var newSettingsTemplate = {};
                            switch($stateParams.typeEntitySettingsEnum) {
                                case "DEVICE":
                                    newSettingsTemplate = {
                                        id: $stateParams.settingsTemplateId,
                                        deviceType: {
                                            id:$stateParams.softwareOrDeviceId
                                        },
                                        typeEntitySettingsEnum: $stateParams.typeEntitySettingsEnum
                                    };
                                    break;
                                case "SOFTWARE":
                                    newSettingsTemplate = {
                                        id: $stateParams.settingsTemplateId,
                                        softwareGroup: {
                                            id:$stateParams.softwareOrDeviceId
                                        },
                                        typeEntitySettingsEnum: $stateParams.typeEntitySettingsEnum
                                    };
                                    break;
                            }

                            return {
                                    id: null,
                                    name: null,
                                    notes: null,
                                    template: null,
                                    settingsTemplate: newSettingsTemplate
                             };
                    }],
                    licenseEntity: ['$stateParams', 'License', function($stateParams, License) {
                      return $stateParams.id? License.get({id : $stateParams.id}).$promise: null;
                    }],
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                      return $stateParams.deviceId? Device.get({id : $stateParams.deviceId}).$promise: null;
                    }]
                },
                ncyBreadcrumb: {
                   label: 'vmaxmanagerApp.settingsInstance.process.newSettings',
                   parent: function ($scope) {
                        return $scope.dynamicParentBreadcrumb();
                   }
               }
            })
            .state('license.edit.settings-clone', {
                parent: 'entity',
                url: '/{id}/clone-settings/{settingsId}',
                params: {
                    parentState: null,
                    settingsId: null,
                    deviceId: null,
                    deviceTypeId: null,
                    showChangeSettingsButton: null
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    headerLabel: 'vmaxmanagerApp.settingsInstance.process.cloneSettings',
                    hideButtons: true,
                    isClone: true
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({id : $stateParams.settingsId}).$promise;
                    }],
                    licenseEntity: ['$stateParams', 'License', function($stateParams, License) {
                      return $stateParams.id? License.get({id : $stateParams.id}).$promise: null;
                    }],
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                      return $stateParams.deviceId? Device.get({id : $stateParams.deviceId}).$promise: null;
                    }]
                },
                ncyBreadcrumb: {
                   label: 'vmaxmanagerApp.settingsInstance.process.cloneSettings',
                   parent: function ($scope) {
                        return $scope.dynamicParentBreadcrumb();
                   }
               }
            })
            .state('license.edit.settings-change', {
                parent: 'entity',
                url: '/{id}/change-settings',
                params: {
                    parentState: null,
                    settingsId: null,
                    deviceId: null,
                    deviceTypeId: null,
                    showChangeSettingsButton: null
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    headerLabel:'vmaxmanagerApp.settingsInstance.process.changeSettings',
                    hideButtons: true,
                    isChangeSettings: true
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                        controller: 'SettingsInstanceDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settingsInstance');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                        return SettingsInstance.get({id : $stateParams.settingsId}).$promise;
                    }],
                    licenseEntity: ['$stateParams', 'License', function($stateParams, License) {
                      return $stateParams.id? License.get({id : $stateParams.id}).$promise: null;
                    }],
                    deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                      return $stateParams.deviceId? Device.get({id : $stateParams.deviceId}).$promise: null;
                    }]
                },
                ncyBreadcrumb: {
                   label: 'vmaxmanagerApp.settingsInstance.process.changeSettings',
                   parent: function ($scope) {
                        return $scope.dynamicParentBreadcrumb();
                   }
               }
            })
            .state('license.device.assign.setting', {
                parent: 'license.device.assign',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                params: {
                    deviceType: null,
                    assignDevice: null
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/license/license-assign-device-setting.html',
                        controller: 'LicenseAssignDeviceSettingController',
                        size: 'md',
                        resolve : {
                            settings : ['SettingsInstance', function(SettingsInstance){
                                var setting = SettingsInstance.getSettingsInstanceByDeviceType({deviceType:$stateParams.deviceType}).$promise;
                                return setting;
                            }],
                            settingsTemplate : ['SettingsTemplate', function(SettingsTemplate){
                                var settingsTemplate = SettingsTemplate.getSettingsTemplatesByDeviceType({deviceType:$stateParams.deviceType}).$promise;
                                return settingsTemplate;
                            }],
                            license : ['License', function(License){
                                var license = License.get({id : $stateParams.id});
                                return license;
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('license.device.assign', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('license.device.assign.unAssignSetting', {
                parent: 'license.device.assign',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                params: {
                    deviceStatus: null,
                    assignDevice: null
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/license/license-unassign-device-setting.html',
                        controller: 'LicenseUnassignDeviceSettingController',
                        size: 'md',
                        resolve : {
                            settings : ['SettingsInstance', function(SettingsInstance){
                                var setting = SettingsInstance.getSettingsInstanceByDeviceType({deviceType:$stateParams.deviceType}).$promise;
                                return setting;
                            }],
                            license : ['License', function(License){
                                var license = License.get({id : $stateParams.id});
                                return license;
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('license.device.assign', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('LicenseController', ["$scope", "$state", "License", "ParseLinks", "LicenseService", "DownloadService", "AlertService", "$filter", function ($scope, $state, License, ParseLinks, LicenseService, DownloadService, AlertService, $filter) {

        $scope.licenses = [];
        $scope.sorts = ["fleet.fleetId,asc","expiryDate,desc","id,asc"];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.showExpired = false;

        //filter configs
        $scope.filterConfig = {
            inputGroupClass: '',
            btnClass: 'btn-raised'
        };

        $scope.filterInitialRules = {
            'operator' : 'and',
            'rules' : [{condition: '!=', field:{name: 'licenseStatus', type:"text"}, data:'EXPIRED'}]
        };

        $scope.loadAll = function() {
        	var pageable = {page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']};
            License.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.licenses.push(result[i]);
                }
            });
        };

        $scope.reset = function() {
            $scope.page = 0;
            $scope.licenses = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };

        License.filterVars({}, function(data) {
            var f = [];
            angular.forEach(data, function(d) {
                f.push(d);
            });
            $scope.fields = f;
            $scope.loadAll();
        });

        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.license = {
                slots: null,
                slotUsed: null,
                salesOrder: null,
                previousSalesOrder: null,
                startDate: null,
                duration: null,
                expiryDate: null,
                dataAmount: null,
                chargeType: null,
                licenseStatus: null,
                notes: null,
                id: null
            };
        };

        /**
         * method is invoked when going to export one or many licenses.
         *
         * @author jmontoya
         */
        $scope.export = function(license) {
            $scope.license = License.get({id: license.id},function(){
                if(license.licenseStatus === 'DRAFT'){
                    // modal confirmation download licenses
                    LicenseService.downloadLicenseModal($scope, LicenseService.message.export, $scope.license, 'export', LicenseService.modalTitle.other);
                }else{
                    DownloadService.downloadFile('api/exportLicense/' + license.fleet.id + '/' + license.fleet.fleetId + '/' + license.id);
                }
            });
        };

        /**
         * Method call when action export is confirm.
         *
         * @author jmontoya
         */
        $scope.exportModalMethod = function(license) {
            $scope.license.licenseStatus = 'PUBLISHED';
            License.save($scope.license, function () {
                $scope.refresh();
                var downloadFile = $("input[name='downloadFile']:checked").val();
                if(downloadFile){
                    DownloadService.downloadFile('api/exportLicense/' + license.fleet.id + '/' + license.fleet.fleetId + '/' + license.id);
                }
            });
        }

        /***********************************************************************
         * @author wmontalvo [gmendoza] has to put his implementation here.
         **********************************************************************/
        $scope.upload=function(license){
            LicenseService.downloadLicenseModal($scope, LicenseService.message.upload, license, 'upload', LicenseService.modalTitle.upload);
        };

        /***********************************************************************
         * @author wmontalvo if a license is in PUBLISHED status then it has to
         *         change the status to CANCELLED.
         **********************************************************************/
        $scope.cancelLicense = function(license){
            // Check if a license is in PUBLISHED status then it has to change
            // the status to CANCELLED.
            if(license.licenseStatus==='PUBLISHED'){
                $scope.license = License.get({id: license.id},function(license){
                    if(license.devices.length > 0){
                        AlertService.error($filter('translate')('vmaxmanagerApp.license.cannotCancelBecauseDevicesAssigned'));
                    }else{
                        // modal confirmation download licenses
                        var messages = LicenseService.message.cancel;
                        LicenseService.downloadLicenseModal($scope, messages, license, 'cancel', LicenseService.modalTitle.cancel);
                    }
                });
            }
        };

        /**
         * Method call when action cancel is confirm.
         *
         * @author jmontoya
         */
        $scope.cancelandExport = function(license){
            $scope.license = License.get({id: license.id},function(){
                $scope.license.licenseStatus = 'CANCELLED';
                License.save($scope.license, function () {
                    $scope.refresh();
                    var downloadFile = $("input[name='downloadFile']:checked").val();
                    if(downloadFile){
                        DownloadService.downloadFile('api/exportLicense/' + license.fleet.id + '/' + license.fleet.fleetId + '/' + license.id);
                    }
                });
            });
        };

        /**
         * Method call when upload action is confirmed for a  cloud license.
         */
        $scope.uploadLicense = function(license){
              license.licenseStatus = "PUBLISHED";
              License.save(license);
        };

        /**
        * Edit settings instance from license summary.
        */
        $scope.editSettings = function(evt, licenseId, settingsInstanceId) {
            if (licenseId && settingsInstanceId) {
                $state.go('license.edit.settings-view', {id: licenseId, settingsId: settingsInstanceId, parentState: 'license'});
            }
            evt.stopPropagation();
        }

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('License', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/licenses/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                    data.expiryDate = DateUtils.convertDateTimeFromServer(data.expiryDate);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'filterQuery': { method: 'POST', url: 'api/licenses/query', isArray:true },
            'filterVars': { method: 'GET', url: 'api/licenses/info', isArray:true },
            'activeLicenses': { method: 'POST', url: 'api/activeLicenses', isArray:true}
        });
    }]).factory('DownloadService', ["$http", function($http) {
        return {
          downloadFile : function(httpPath) {
              // Use an arraybuffer
              $http.get(httpPath, {
                  responseType : 'arraybuffer'
              }).success(function(data, status, headers) {

                  var octetStreamMime = 'application/octet-stream';
                  var success = false;

                  // Get the headers
                  headers = headers();

                  // Get the filename from the x-filename header or default to
                  // "download.bin"
                  var filename = headers['x-filename'] || 'download.bin';

                  // Determine the content type from the header or default to
                  // "application/octet-stream"
                  var contentType = headers['content-type'] || octetStreamMime;

                  try {
                      // Try using msSaveBlob if supported
                      console.log("Trying saveBlob method ...");
                      var blob = new Blob([ data ], {
                          type : contentType
                      });
                      if (navigator.msSaveBlob)
                          navigator.msSaveBlob(blob, filename);
                      else {
                          // Try using other saveBlob implementations, if
                          // available
                          var saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob;
                          if (saveBlob === undefined)
                              throw "Not supported";
                          saveBlob(blob, filename);
                      }
                      console.log("saveBlob succeeded");
                      success = true;
                  } catch (ex) {
                      console.log("saveBlob method failed with the following exception:");
                      console.log(ex);
                  }

                  if (!success) {
                      // Get the blob url creator
                      var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
                      if (urlCreator) {
                          // Try to use a download link
                          var link = document.createElement('a');
                          if ('download' in link) {
                              // Try to simulate a click
                              try {
                                  // Prepare a blob URL
                                  console.log("Trying download link method with simulated click ...");
                                  var blob = new Blob([ data ], {
                                      type : contentType
                                  });
                                  var url = urlCreator.createObjectURL(blob);
                                  link.setAttribute('href', url);

                                  // Set the download attribute (Supported in
                                  // Chrome 14+ / Firefox 20+)
                                  link.setAttribute("download", filename);

                                  // Simulate clicking the download link
                                  var event = document.createEvent('MouseEvents');
                                  event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                                  link.dispatchEvent(event);
                                  console.log("Download link method with simulated click succeeded");
                                  success = true;

                              } catch (ex) {
                                  console.log("Download link method with simulated click failed with the following exception:");
                                  console.log(ex);
                              }
                          }

                          if (!success) {
                              // Fallback to window.location method
                              try {
                                  // Prepare a blob URL
                                  // Use application/octet-stream when using
                                  // window.location to force download
                                  console.log("Trying download link method with window.location ...");
                                  var blob = new Blob([ data ], {
                                      type : octetStreamMime
                                  });
                                  var url = urlCreator.createObjectURL(blob);
                                  window.location = url;
                                  console.log("Download link method with window.location succeeded");
                                  success = true;
                              } catch (ex) {
                                  console.log("Download link method with window.location failed with the following exception:");
                                  console.log(ex);
                              }
                          }

                      }
                  }

                  if (!success) {
                      // Fallback to window.open method
                      console.log("No methods worked for saving the arraybuffer, using last resort window.open");
                      window.open(httpPath, '_blank', '');
                  }
              }).error(function(data, status) {
                  console.log("Request failed with status: " + status);
              });
          }
        };
    }]).factory("LicenseService" , function(){

        var message={
          delete:'Are you sure that you want to delete this license?',
          cancel:'Are you sure that you want to cancel this license?',
          upload:'This action will publish on cloud this license.',
          export:'This action will publish this license.',
          update:'This action will re-publish this license.',
          cannotDeleteBecauseDevicesAssigned : 'Please remove devices from this license before Deleting it',
          cannotCancelBecauseDevicesAssigned : 'Please remove devices from this license before Canceling it',
          slotsLessThanDevices : 'Slots quantity must be higher or equal than devices assigned.',
          licenseExpiresEarlierThanToday : 'Cannot modify a license whose expiration date is Today or before Today.'
        };

        var modalTitle = {
          cancel: 'Cancel License',
          other: 'Download Licenses',
          upload:'Upload license CLOUD'
        };

        /**
        * Modal confirmation download licenses.     *
        * @author jmontoya
        *
        * Refactoring code
        * @author amejiav
        */
        var downloadLicenseModal = function(scope, messages, license, event, title){
          var checkBoxHTML='';
          var showCheckBox = license.fleet.deploymentType == 'ONSITE' && license.slotType.slotType == 'APP' ?true:false;
          if(showCheckBox){
              checkBoxHTML='<div class="form-group"><div class="col-md-4"> <label for="downloadFile-0"><input type="checkbox" name="downloadFile" id="downloadFile-0" value="Download Licenses"> Download Licenses </label> </div> </div>';
          }
          var message='<div class="row">  ' +'<div class="col-md-12"> ' +'<form class="form-horizontal"> ' +'<div class="form-group"> ' +'<div class="col-md-10"> ' +'<span  class="control-label">' + messages + '</span> </div> ' +'</div> '+checkBoxHTML+'</form> </div>  </div>';
          bootbox.dialog({
              title: title,
              message: message,
              buttons: {
                  cancel: {
                      label: "Cancel",
                      className: "btn btn-default btn-raised",
                      callback: function () {
                          if(event === 'edit'){
                              $('#saveLicenseModal').modal('show');
                          }
                      }
                  },
                  success: {
                      label: "Ok",
                      className: "btn btn-primary btn-raised",
                      callback: function () {
                          switch(event){
                              case 'edit':
                                  scope.updateAndExport(license);
                                  break;
                              case 'export':
                              {
                                  scope.page=1;
                                  scope.exportModalMethod(license);
                                  break;
                              }
                              case  'cancel':
                                  scope.cancelandExport(license);
                                  break;
                              case 'upload':
                                 scope.uploadLicense(license);
                                 break;
                              default:
                                  bootbox.alert('This feature is not implemented yet');
                                  break;
                          }
                      }
                  }
              }});
        };

        return {
          message : message,
          modalTitle : modalTitle,
          downloadLicenseModal : function(scope, messages, license, event, title){
              downloadLicenseModal(scope, messages, license, event, title);
          }
        }
    });

'use strict';

angular.module('vmaxmanagerApp')
    .factory('AssignDeviceService', ["$http", function ($http) {

    	/**
    	 * Method that retrieves the Devices available to be assigned to the License.
    	 * @author william.cornejo
    	 */
        var findAvailableToLicense = function(licenseId, deviceTypeId, callback) {
        	var params;
        	if (deviceTypeId) {
        		params = '?licenseId=' + licenseId +'&deviceTypeId=' + deviceTypeId;
        	} else {
        		params = '?licenseId=' + licenseId;
        	}
            var req = {
                method : 'GET',
                url : 'api/devices/findAvailableToLicense' + params,
                headers : {
                    'Content-Type' : 'application/json'
                }
            };
            $http(req).success(function(response, status, headers, config) {
                var result = response;
                if (callback) {
                    callback(result, headers);
                }
                return result;
            }).error(function(data, status, headers, config) {
                console.log(data);
                // called asynchronously if an error occurs or server returns response with an error status.
            });
        };

        /**
         * Finds the Devices that have already been assigned to a License
         * @author william.cornejo
         */
        var findAssignedByLicense = function(licenseId,deviceTypeId,callback){

        	var params;
        	if (deviceTypeId) {
        		params = '?licenseId=' + licenseId +'&deviceTypeId=' + deviceTypeId;
        	} else {
        		params = '?licenseId=' + licenseId;
        	}
        	var req = {
                method : 'GET',
                url : 'api/devices/findAssignedByLicense' + params,
                headers : {
                    'Content-Type' : 'application/json'
                }
            };
            $http(req).success(function(response, status, headers, config) {
                var result = response;
                if (callback) {
                    callback(result, headers);
                }
                return result;
            }).error(function(data, status, headers, config) {
                console.log(data);
                // called asynchronously if an error occurs or server returns response with an error status.
            });
        };

        /**
         * Assign devices to the License
         * @author william.cornejo
         */
        var assignDevicesToLicense = function(assignDeviceDto, callback){
        	var req = {
                method : 'PUT',
                url : 'api/devices/assignDevicesToLicense/',
                headers : {
                    'Content-Type' : 'application/json'
                },
                data : assignDeviceDto
            };
            $http(req).success(function(response, status, headers, config) {
                var result = response;
                if (callback) {
                    callback(result, headers);
                }
                return result;
            }).error(function(data, status, headers, config) {
                console.log(data);
                // called asynchronously if an error occurs or server returns response with an error status.
            });
        };

        /**
         * Unassigns a Device from a License
         * @author william.cornejo
         */
        var unAssignDeviceToLicense = function(unAssignDeviceDto, callback){
        	var req = {
                    method : 'PUT',
                    url : 'api/devices/unAssignDeviceToLicense/',
                    headers : {
                        'Content-Type' : 'application/json'
                    },
                    data : unAssignDeviceDto
                };
                $http(req).success(function(response, status, headers, config) {
                    var result = response;
                    if (callback) {
                        callback(result, headers);
                    }
                    return result;
                }).error(function(data, status, headers, config) {
                    console.log(data);
                    // called asynchronously if an error occurs or server returns response with an error status.
                });
        }

        /**
         * Retrieves the possible Device Statuses
         * @author william.cornejo
         */
        var findDeviceStatuses = function(callback){
        	var req = {
                    method : 'GET',
                    url : 'api/devices/findDeviceStatuses/',
                    headers : {
                        'Content-Type' : 'application/json'
                    }
                };
                $http(req).success(function(response, status, headers, config) {
                    var result = response;
                    if (callback) {
                        callback(result, headers);
                    }
                    return result;
                }).error(function(data, status, headers, config) {
                    console.log(data);
                    callback(undefined, headers, data)
                });
        }

        return {
        	findAvailableToLicense : function(licenseId, deviceTypeId, callback) {
        		findAvailableToLicense(licenseId, deviceTypeId, callback);
            },
            findAssignedByLicense : function(licenseId,deviceTypeId,callback) {
            	findAssignedByLicense(licenseId, deviceTypeId, callback);
            },
            assignDevicesToLicense : function(assignDeviceDto, callback){
            	assignDevicesToLicense(assignDeviceDto, callback)
            },
            unAssignDeviceToLicense : function(unAssignDeviceDto, callback){
            	unAssignDeviceToLicense(unAssignDeviceDto, callback);
            },
            findDeviceStatuses : function(callback){
            	findDeviceStatuses(callback);
            }
        };

    }]);

'use strict';

angular.module('vmaxmanagerApp').factory('ChargeType', ["$resource", function($resource) {
    return $resource('app/rest/chargeTypes/:id', {}, {
        'query' : {
            method : 'GET',
            isArray : true
        },
        'get' : {
            method : 'GET'
        }
    });
}]);

'use strict';

angular.module('vmaxmanagerApp').factory('LicenseStatus', ["$resource", function($resource) {
    return $resource('app/rest/licenseStatuses/:id', {}, {
        'query' : {
            method : 'GET',
            isArray : true
        },
        'get' : {
            method : 'GET'
        }
    });
}]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('emailBounce', {
                parent: 'entity',
                url: '/emailBounces',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.emailBounce.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/emailBounce/emailBounces.html',
                        controller: 'EmailBounceController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('emailBounce');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.emailBounce.home.title'
                }
            })
            .state('emailBounce.detail', {
                parent: 'entity',
                url: '/emailBounce/{id}',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.emailBounce.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/emailBounce/emailBounce-detail.html',
                        controller: 'EmailBounceDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('emailBounce');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'EmailBounce', function($stateParams, EmailBounce) {
                        return EmailBounce.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'emailBounce'
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('EmailBounceController', ["$scope", "$state", "EmailBounce", "ParseLinks", function ($scope, $state, EmailBounce, ParseLinks) {

        $scope.emailBounces = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.loadAll = function() {
            EmailBounce.query({page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.emailBounces.push(result[i]);
                }
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.emailBounces = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.emailBounce = {
                messageId: null,
                timestamp: null,
                bounceType: null,
                bounceSubType: null,
                recipients: null,
                isComplaint: null,
                id: null
            };
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('EmailBounceDetailController', ["$scope", "$rootScope", "$stateParams", "entity", "EmailBounce", function ($scope, $rootScope, $stateParams, entity, EmailBounce) {
        $scope.emailBounce = entity;
        $scope.load = function (id) {
            EmailBounce.get({id: id}, function(result) {
                $scope.emailBounce = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:emailBounceUpdate', function(event, result) {
            $scope.emailBounce = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('EmailBounce', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/emailBounces/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.timestamp = DateUtils.convertDateTimeFromServer(data.timestamp);
                    return data;
                }
            }
        });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('slotType', {
                parent: 'entity',
                url: '/slotTypes',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.slotType.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/slotType/slotTypes.html',
                        controller: 'SlotTypeController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('slotType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.slotType.home.title'
                }
            })
            .state('slotType.detail', {
                parent: 'slotType',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.slotType.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/slotType/slotType-detail.html',
                        controller: 'SlotTypeDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('slotType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SlotType', function($stateParams, SlotType) {
                        return SlotType.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'slotType'
                }
            })
            .state('slotType.new', {
                parent: 'slotType',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/slotType/slotType-dialog.html',
                        controller: 'SlotTypeDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('slotType');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
                            feature: null,
                            requiresData: null,
                            requiresSlot: null,
                            slotIcon: null,
                            slotType: null,
                            requiresDevice: null,
                            requiresSoftwareVersion: null,
                            id: null
                             };;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'slotType'
                }
            })
            .state('slotType.edit', {
                parent: 'slotType',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/slotType/slotType-dialog.html',
                        controller: 'SlotTypeDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('slotType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SlotType', function($stateParams, SlotType) {
                        return SlotType.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'slotType'
                }
            })
            .state('slotType.delete', {
                parent: 'slotType',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/slotType/slotType-delete-dialog.html',
                        controller: 'SlotTypeDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['SlotType', function(SlotType) {
                                return SlotType.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('slotType', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('SlotTypeController', ["$scope", "$state", "SlotType", "ParseLinks", function ($scope, $state, SlotType, ParseLinks) {

        $scope.slotTypes = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.loadAll = function() {
            SlotType.query({page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.slotTypes.push(result[i]);
                }
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.slotTypes = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.slotType = {
                feature: null,
                requiresData: null,
                requiresSlot: null,
                slotIcon: null,
                slotType: null,
                requiresDevice: null,
                requiresSoftwareVersion: null,
                id: null
            };
        };

        $scope.getSoftwareLicense = function (slotType) {
            var softwareLicense;
            if (!_.isEmpty(slotType)) {
                if (!_.isEmpty(slotType.softwareLicense)) {
                       softwareLicense = slotType.softwareLicense.softwareVersion;
                }
            }
            return softwareLicense;
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp').controller('SlotTypeDialogController',
    ['$scope', '$state', 'entity', 'SlotType', 'Software', 'SlotTypeEnum', 'Feature', 'SoftwareLicense',
        function($scope, $state, entity, SlotType, Software, SlotTypeEnum, Feature, SoftwareLicense) {

        $scope.slotType = entity;
        $scope.softwares = Software.query();
        $scope.software_licenses = SoftwareLicense.query();
        $scope.slotTypeEnum =  SlotTypeEnum.query();
        $scope.features = Feature.query();
        $scope.isFeature = false;
        $scope.load = function(id) {
            SlotType.get({id : id}, function(result) {
                $scope.slotType = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:slotTypeUpdate', result);
            $scope.isSaving = false;
            $state.go('slotType');
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.slotType.id != null) {
                SlotType.update($scope.slotType, onSaveSuccess, onSaveError);
            } else {
                SlotType.save($scope.slotType, onSaveSuccess, onSaveError);
            }
        };

        $scope.slotTypeChange = function() {
        	$scope.isFeature = $scope.slotType.slotType == 'FEATURE';
        	$('#featureList').prop('required', $scope.isFeature);
        	if(!$scope.isFeature){
        		$scope.slotType.feature = null;
        	}else{
        		$scope.editForm.$invalid = true;
        	}
        };

        $scope.requiresDeviceChange = function() {

        	if($scope.slotType && $scope.slotType.requiresDevice){
        		$scope.slotType.requiresSlot = true;
        		}
        };
}]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('SlotTypeDeleteController', ["$scope", "$uibModalInstance", "entity", "SlotType", function($scope, $uibModalInstance, entity, SlotType) {

        $scope.slotType = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            SlotType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('SlotTypeDetailController', ["$scope", "$rootScope", "$stateParams", "entity", "SlotType", "Software", "SoftwareLicense", function ($scope, $rootScope, $stateParams, entity, SlotType, Software, SoftwareLicense) {
        $scope.slotType = entity;
        $scope.load = function (id) {
            SlotType.get({id: id}, function(result) {
                $scope.slotType = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:slotTypeUpdate', function(event, result) {
            $scope.slotType = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('SlotType', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/slotTypes/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }]);

'use strict';
angular.module('vmaxmanagerApp').factory('SlotTypeEnum', ["$resource", function($resource) {
    return $resource('app/rest/slotTypeEnum/:id', {}, {
        'query' : {
            method : 'GET',
            isArray : true
        },
        'get' : {
            method : 'GET'
        }
    });
}]);

'use strict';
angular.module('vmaxmanagerApp').factory('Feature', ["$resource", function($resource) {
    return $resource('app/rest/feature/:id', {}, {
        'query' : {
            method : 'GET',
            isArray : true
        },
        'get' : {
            method : 'GET'
        }
    });
}]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('salesRegion', {
                parent: 'entity',
                url: '/salesRegions',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.salesRegion.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/salesRegion/salesRegions.html',
                        controller: 'SalesRegionController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesRegion');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.salesRegion.home.title'
                }
            })
            .state('salesRegion.detail', {
                parent: 'salesRegion',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.salesRegion.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/salesRegion/salesRegion-detail.html',
                        controller: 'SalesRegionDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesRegion');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SalesRegion', function($stateParams, SalesRegion) {
                        return SalesRegion.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'salesRegion'
                }
            })
            .state('salesRegion.new', {
                parent: 'salesRegion',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/salesRegion/salesRegion-dialog.html',
                        controller: 'SalesRegionDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesRegion');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
                        	 name: null,
                             salesRepresentative: null,
                             emails: null,
                             id: null
                             };;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'salesRegion'
                }
            })
            .state('salesRegion.edit', {
                parent: 'salesRegion',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/salesRegion/salesRegion-dialog.html',
                        controller: 'SalesRegionDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesRegion');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SalesRegion', function($stateParams, SalesRegion) {
                        return SalesRegion.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'salesRegion'
                }
            })
            .state('salesRegion.delete', {
                parent: 'salesRegion',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/salesRegion/salesRegion-delete-dialog.html',
                        controller: 'SalesRegionDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['SalesRegion', function(SalesRegion) {
                                return SalesRegion.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('salesRegion', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('SalesRegionController', ["$scope", "$state", "SalesRegion", "ParseLinks", function ($scope, $state, SalesRegion, ParseLinks) {

        $scope.salesRegions = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.loadAll = function() {
            SalesRegion.query({page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.salesRegions.push(result[i]);
                }
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.salesRegions = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.salesRegion = {
                name: null,
                salesRepresentative: null,
                emails: null,
                id: null
            };
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp').controller('SalesRegionDialogController',
    ['$scope','$state','entity', 'SalesRegion',
        function($scope, $state, entity, SalesRegion) {

        $scope.salesRegion = entity;
        $scope.load = function(id) {
            SalesRegion.get({id : id}, function(result) {
                $scope.salesRegion = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:salesRegionUpdate', result);
            $scope.isSaving = false;
            $state.go('salesRegion');
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.salesRegion.id != null) {
                SalesRegion.update($scope.salesRegion, onSaveSuccess, onSaveError);
            } else {
                SalesRegion.save($scope.salesRegion, onSaveSuccess, onSaveError);
            }
        };
}]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('SalesRegionDeleteController', ["$scope", "$uibModalInstance", "entity", "SalesRegion", function($scope, $uibModalInstance, entity, SalesRegion) {

        $scope.salesRegion = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            SalesRegion.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('SalesRegionDetailController', ["$scope", "$rootScope", "$stateParams", "entity", "SalesRegion", function ($scope, $rootScope, $stateParams, entity, SalesRegion) {
        $scope.salesRegion = entity;
        $scope.load = function (id) {
            SalesRegion.get({id: id}, function(result) {
                $scope.salesRegion = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:salesRegionUpdate', function(event, result) {
            $scope.salesRegion = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('SalesRegion', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/salesRegions/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('providers', {
                parent: 'entity',
                url: '/providers',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.providers.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/providers/providers.html',
                        controller: 'ProvidersController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('providers');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.providers.home.title'
                }
            })
            .state('providers.detail', {
                parent: 'providers',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.providers.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/providers/providers-detail.html',
                        controller: 'ProvidersDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('providers');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Providers', function($stateParams, Providers) {
                        return Providers.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'providers'
                }
            })
            .state('providers.new', {
                parent: 'providers',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/providers/providers-dialog.html',
                        controller: 'ProvidersDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('providers');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
	                            name: null,
	                            icon: null,
	                            id: null
                             };;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'providers'
                }
            })
            .state('providers.edit', {
                parent: 'providers',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/providers/providers-dialog.html',
                        controller: 'ProvidersDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('providers');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Providers', function($stateParams, Providers) {
                        return Providers.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'providers'
                }
            })
            .state('providers.delete', {
                parent: 'providers',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/providers/providers-delete-dialog.html',
                        controller: 'ProvidersDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['Providers', function(Providers) {
                                return Providers.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('providers', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('ProvidersController', ["$scope", "$state", "Providers", "ParseLinks", function ($scope, $state, Providers, ParseLinks) {

        $scope.providerss = [];
        $scope.predicate = 'name';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.loadAll = function() {
            Providers.query({page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.providerss.push(result[i]);
                }
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.providerss = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.providers = {
                name: null,
                icon: null,
                id: null
            };
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp').controller('ProvidersDialogController',
    ['$scope', '$state', 'Upload', 'entity', 'Providers', '$timeout',
        function($scope, $state, Upload, entity, Providers, $timeout) {

        $scope.providers = entity;
        $scope.fileReaderSupported = window.FileReader != null && (window.FileAPI == null || FileAPI.html5 != false);
        $scope.load = function(id) {
            Providers.get({id : id}, function(result) {
                $scope.providers = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:providersUpdate', result);
            $scope.isSaving = false;
            $state.go('providers');
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.onFileSelect = function(file){
              if (file != null) {
                  if ($scope.fileReaderSupported && file.type.indexOf('image') > -1) {
                      $timeout(function() {
                          var fileReader = new FileReader();
                          fileReader.readAsDataURL(file);
                          fileReader.onload = function(e) {
                              $timeout(function() {
                                  file.dataUrl = e.target.result;
                              });
                          };
                      });
                  }
              }
          };

          $scope.create = function () {

        	  $scope.isSaving = true;
              var fileObject = { file: null };

              if($scope.providers.picFile && $scope.providers.picFile.size > 0){
                  fileObject.file = $scope.providers.picFile;
              }

              if(!$scope.providers.id){
                  $scope.providers.id = 0;
              }

              var provider = {
            		  'id': $scope.providers.id,
            		  'icon': $scope.providers.icon,
            		  'name': $scope.providers.name
              };

              fileObject.upload = Upload.upload({
                  url: 'api/providerss',
                  method: 'POST',
                  data: {'provider': angular.toJson(provider)},
                  sendObjectsAsJsonBlob: false,
                  file: fileObject.file
              });

              fileObject.upload.then(function(response) {
                  fileObject.result = response.data;
                  $state.go('providers');
              }, function(response,message) {
                  if (response.status > 0){
                      bootbox.dialog({
                          message: "Sorry something has gone bad, please contact to SEON",
                          title: "Server Error",
                          buttons: {
                            success: {
                              label: "Ok!",
                              className: "btn-danger",
                              callback: function() {
                                  console.log(response.status + ': ' + response.data);
                              }
                            }
                          }
                      });
                  }
              });
          };
}]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('ProvidersDeleteController', ["$scope", "$uibModalInstance", "entity", "Providers", function($scope, $uibModalInstance, entity, Providers) {

        $scope.providers = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            Providers.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('ProvidersDetailController', ["$scope", "$rootScope", "$stateParams", "entity", "Providers", function ($scope, $rootScope, $stateParams, entity, Providers) {
        $scope.providers = entity;
        $scope.load = function (id) {
            Providers.get({id: id}, function(result) {
                $scope.providers = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:providersUpdate', function(event, result) {
            $scope.providers = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('Providers', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/providerss/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'POST' }
        });
    }]);
'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('software', {
                parent: 'entity',
                url: '/softwares',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.software.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/software/softwares.html',
                        controller: 'SoftwareController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('software');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.software.home.title'
                }
            })
            .state('software.detail', {
                parent: 'software',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.software.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/software/software-detail.html',
                        controller: 'SoftwareDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('software');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Software', function($stateParams, Software) {
                        return Software.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'software'
                }
            })
            .state('software.new', {
                parent: 'software',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/software/software-dialog.html',
                        controller: 'SoftwareDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('software');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
	                            name: null,
	                            icon: null,
	                            cloud: null,
	                            local: null,
	                            softwareGroup: null,
	                            id: null
                             };;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'software'
                }
            })
            .state('software.edit', {
                parent: 'software',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/software/software-dialog.html',
                        controller: 'SoftwareDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('deviceType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Software', function($stateParams, Software) {
                        return Software.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'software'
                }
            })
            .state('software.delete', {
                parent: 'software',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/software/software-delete-dialog.html',
                        controller: 'SoftwareDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['Software', function(Software) {
                                return Software.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('software', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('SoftwareController', ["$scope", "$state", "Software", "ParseLinks", function ($scope, $state, Software, ParseLinks) {

        $scope.softwares = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.loadAll = function() {
            Software.query({page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.softwares.push(result[i]);
                }
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.softwares = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.software = {
                name: null,
                icon: null,
                cloud: null,
                local: null,
                softwareGroup: null,
                id: null
            };
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp').controller('SoftwareDialogController',
    ['$scope', '$state', '$timeout','Upload', 'entity', 'Software', 'SoftwareGroup', 'ParseLinks',
        function($scope, $state, $timeout, Upload, entity, Software, SoftwareGroup, ParseLinks) {

        $scope.software = entity;
        $scope.softwaregroups = SoftwareGroup.query();
        $scope.fileReaderSupported = window.FileReader != null && (window.FileAPI == null || FileAPI.html5 != false);
        $scope.load = function(id) {
            Software.get({id : id}, function(result) {
                $scope.software = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:softwareUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.create = function () {

        	$scope.isSaving = true;
            var fileObject = { file: null };

            if($scope.software.picFile && $scope.software.picFile.size > 0){
                fileObject.file = $scope.software.picFile;
            }
            if(!$scope.software.id){
                $scope.software.id = 0;
            }

            var software = {
            		'id': $scope.software.id,
            		'name': $scope.software.name,
            		'icon': $scope.software.icon,
            		'cloud': $scope.software.cloud,
            		'local': $scope.software.local,
            		'softwareGroup': $scope.software.softwareGroup
            };

            fileObject.upload = Upload.upload({
                url: 'api/softwares',
                method: 'POST',
                data: {'software' : angular.toJson(software) },
                sendObjectsAsJsonBlob: false,
                file: fileObject.file
            });

            fileObject.upload.then(function(response) {
                fileObject.result = response.data;
                $scope.softwares = [];
                $scope.page=1;
                $scope.perPage=20;
                $state.go('software');
            }, function(response,message) {
                if (response.status > 0){
                    bootbox.dialog({
                        message: "Sorry something has gone bad, please contact to SEON",
                        title: "Server Error",
                        buttons: {
                          success: {
                            label: "Ok!",
                            className: "btn-danger",
                            callback: function() {
                                console.log(response.status + ': ' + response.data);
                            }
                          }
                        }});
                }
            });
        };

        $scope.onFileSelect=function(file){
              if (file != null) {
                  if ($scope.fileReaderSupported && file.type.indexOf('image') > -1) {
                      $timeout(function() {
                          var fileReader = new FileReader();
                          fileReader.readAsDataURL(file);
                          fileReader.onload = function(e) {
                              $timeout(function() {
                                  file.dataUrl = e.target.result;
                              });
                          };
                      });
                  }
              }
          };
}]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('SoftwareDeleteController', ["$scope", "$uibModalInstance", "entity", "Software", function($scope, $uibModalInstance, entity, Software) {

        $scope.software = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            Software.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('SoftwareDetailController', ["$scope", "$rootScope", "$stateParams", "entity", "Software", function ($scope, $rootScope, $stateParams, entity, Software) {
        $scope.software = entity;
        $scope.load = function (id) {
            Software.get({id: id}, function(result) {
                $scope.software = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:softwareUpdate', function(event, result) {
            $scope.software = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }]);

'use strict';

angular.module('vmaxmanagerApp').factory('DeviceStatus', ["$resource", function($resource) {
    return $resource('app/rest/deviceStatus', {}, {
        'get' : {
        	method : 'GET'
        }
    });
}]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('Software', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/softwares/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { 
            	method:'POST'           	
        	}
        });
    }]);
'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('softwareLicense', {
                parent: 'entity',
                url: '/softwareLicenses',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.softwareLicense.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/softwareLicense/softwareLicenses.html',
                        controller: 'SoftwareLicenseController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('softwareLicense');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.softwareLicense.home.title'
                }
            })
            .state('softwareLicense.detail', {
                parent: 'softwareLicense',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.softwareLicense.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/softwareLicense/softwareLicense-detail.html',
                        controller: 'SoftwareLicenseDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('softwareLicense');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SoftwareLicense', function($stateParams, SoftwareLicense) {
                        return SoftwareLicense.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'softwareLicense'
                }
            })
            .state('softwareLicense.new', {
                parent: 'softwareLicense',
                                url: '/new',
                                data: {
                                    authorities: ['ROLE_SYSTEM_ADMIN']
                                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/softwareLicense/softwareLicense-dialog.html',
                        controller: 'SoftwareLicenseDialogController',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('softwareLicense');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            softwareVersion: null,
                            licenseVersion: null,
                            id: null
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'softwareLicense'
                }
            })
            .state('softwareLicense.edit', {
                parent: 'softwareLicense',
                                url: '/{id}/edit',
                                data: {
                                    authorities: ['ROLE_SYSTEM_ADMIN']
                                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/softwareLicense/softwareLicense-dialog.html',
                                                controller: 'SoftwareLicenseDialogController',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('softwareLicense');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SoftwareLicense', function($stateParams, SoftwareLicense) {
                        return SoftwareLicense.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'softwareLicense'
                }
            })
            .state('softwareLicense.delete', {
                parent: 'softwareLicense',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/softwareLicense/softwareLicense-delete-dialog.html',
                        controller: 'SoftwareLicenseDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['SoftwareLicense', function(SoftwareLicense) {
                                return SoftwareLicense.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('softwareLicense', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('SoftwareLicenseController', ["$scope", "$state", "SoftwareLicense", "ParseLinks", function ($scope, $state, SoftwareLicense, ParseLinks) {

        $scope.softwareLicenses = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.loadAll = function() {
            SoftwareLicense.query({page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.softwareLicenses.push(result[i]);
                }
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.softwareLicenses = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.softwareLicense = {
                softwareVersion: null,
                licenseVersion: null,
                id: null
            };
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp').controller('SoftwareLicenseDialogController',
    ['$scope', '$stateParams', 'entity', 'SoftwareLicense', 'SoftwareGroup', 'LicenseVersionEnum', '$state',
        function($scope, $stateParams, entity, SoftwareLicense, SoftwareGroup, LicenseVersionEnum, $state) {

        $scope.softwareLicense = entity;
        $scope.softwaregroups = SoftwareGroup.query();
        $scope.licenseVersionEnum = LicenseVersionEnum.query();
        $scope.load = function(id) {
            SoftwareLicense.get({id : id}, function(result) {
                $scope.softwareLicense = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:softwareLicenseUpdate', result);
            $state.go('softwareLicense');
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.softwareLicense.id != null) {
                SoftwareLicense.update($scope.softwareLicense, onSaveSuccess, onSaveError);
            } else {
                SoftwareLicense.save($scope.softwareLicense, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $state.go('softwareLicense');
        };
}]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('SoftwareLicenseDeleteController', ["$scope", "$uibModalInstance", "entity", "SoftwareLicense", function($scope, $uibModalInstance, entity, SoftwareLicense) {

        $scope.softwareLicense = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            SoftwareLicense.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('SoftwareLicenseDetailController', ["$scope", "$rootScope", "$stateParams", "entity", "SoftwareLicense", "SoftwareGroup", function ($scope, $rootScope, $stateParams, entity, SoftwareLicense, SoftwareGroup) {
        $scope.softwareLicense = entity;
        $scope.load = function (id) {
            SoftwareLicense.get({id: id}, function(result) {
                $scope.softwareLicense = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:softwareLicenseUpdate', function(event, result) {
            $scope.softwareLicense = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }]);

'use strict';
angular.module('vmaxmanagerApp').factory('LicenseVersionEnum', ["$resource", function($resource) {
    return $resource('app/rest/licenseVersionEnum/:id', {}, {
        'query' : {
            method : 'GET',
            isArray : true
        },
        'get' : {
            method : 'GET'
        }
    });
}]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('SoftwareLicense', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/softwareLicenses/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getSoftwareLicensesByGroupId': {method:'GET', url:'api/softwareLicenses/softwaregroupid/:softwaregroupid' ,
                isArray: true,
                transformResponse: function(data){
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    }]);

'use strict';
angular.module('vmaxmanagerApp').factory('SoftwareGroupEnum', ["$resource", function($resource) {
    return $resource('app/rest/softwareGroupEnum/:id', {}, {
        'query' : {
            method : 'GET',
            isArray : true
        },
        'get' : {
            method : 'GET'
        }
    });
}]);

'use strict';

angular.module('vmaxmanagerApp')
.factory('entityAuditInterceptor', ['$q', '$injector', function($q, $injector) {
    var requestInterceptor = {
        request: function(config) {
            var deferred = $q.defer();
            // set createdBy | lastModifiedBy for all POST | PUT request
            if (config.method === 'POST' || config.method === 'PUT') {
                var obj = config.data;
                var Principal = $injector.get('Principal');
                Principal.identity().then(function(identity) {
                    if (obj !== undefined && obj !== null && !(typeof obj === 'string' || obj instanceof String)) {
                        if (config.method === 'POST') {
                             //When is create a new user, the identity = null in this case is the 'system' who register audit entity
                             if(identity==null){
                                 obj['createBy'] = 'system';
                             }else{
                                 obj['createdBy'] = identity.login;
                             }
                             obj['createdDate'] = new Date();
                        } else {
		                      if(identity==null){
		                              obj['lastModifiedBy'] = 'system';
		                          }else{
		                              obj['lastModifiedBy'] = identity.login;
		                          }
                            obj['lastModifiedDate'] = new Date();
                        }
                    }
                    deferred.resolve(config);
                }, function() {
                    deferred.resolve(config);
                });
            } else {
                deferred.resolve(config);
            }
            return deferred.promise;
        }
    };
    return requestInterceptor;
}]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('EntityAuditService', ["$http", function ($http) {
        return {
            findAllAudited: function () {
                return $http.get('api/audits/entity/all').then(function (response) {
                    return response.data;
                });
            },
            findByEntity: function (entityType, limit, order) {
                return $http.get('api/audits/entity/changes', {
                    params: {
                        entityType: entityType,
                        limit: limit,
                        order: order
                    }
                }).then(function (response) {
                    return response.data;
                });
            },
            getPrevVersion: function (qualifiedName, entityId, commitVersion) {
                return $http.get('api/audits/entity/changes/version/previous', {
                    params: {
                        qualifiedName: qualifiedName,
                        entityId: entityId,
                        commitVersion: commitVersion
                    }
                }).then(function (response) {
                    return response.data;
                });
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('entityAudit', {
                parent: 'admin',
                url: '/entityAudits',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN'],
                    pageTitle: 'Audits'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/entityAudit/entityAudits.html',
                        controller: 'EntityAuditController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('audits');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'Audits'
                }
            })
            .state('entityAudit.auditByEntityId', {
                parent: 'entityAudit',
                url: '/{entityName}/{entityId}',
                params: {
                    entityName : null,
                    entityId : null
                },
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN'],
                    pageTitle: 'global.menu.admin.audits'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/entityAudit/entityAudits.html',
                        controller: 'EntityAuditController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('audits');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'global.menu.admin.audits',
                    parent: function ($scope) {
                        return $scope.dynamicParentBreadcrumb();
                    }
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .constant("ENTITY_AUDIT_TYPE", {
    	"device":"com.seon.vmaxmanager.domain.Device",
    	"license":"com.seon.vmaxmanager.domain.License",
    	"fleet":"com.seon.vmaxmanager.domain.Fleet",
    	"settings-instance":"com.seon.vmaxmanager.domain.SettingsInstance",
    	"salesRegion":"com.seon.vmaxmanager.domain.SalesRegion",
    	"customer":"com.seon.vmaxmanager.domain.Customer",
    	"user-management":"com.seon.vmaxmanager.domain.User",
    	"app-resource":"com.seon.vmaxmanager.domain.AppResource",
    	"app-resource-user-profile":"com.seon.vmaxmanager.domain.AppResourceUserProfile"
    })
    .controller('EntityAuditController', ["$scope", "$filter", "$uibModal", "EntityAuditService", "AlertService", "ObjectDiff", "$state", "$stateParams", "ENTITY_AUDIT_TYPE", function ($scope, $filter, $uibModal, EntityAuditService, AlertService, ObjectDiff, $state, $stateParams, ENTITY_AUDIT_TYPE) {

        $scope.entities = [];
        $scope.audits = [];
        $scope.limits = [25, 50, 100, 200];
        $scope.limit = 25;
        $scope.orderType = "desc";
        $scope.filter = {};

        var findByEntity = function(entityType){
            EntityAuditService.findByEntity(entityType, $scope.limit, $scope.orderType).then(function (data) {
                $scope.audits = data.map(function(it){
                    it.entityValue = JSON.parse(it.entityValue);
                    it.entityId = it.entityId.toString();
                    return it;
                });
                $scope.loading = false;
            }, function(){
                $scope.loading = false;
            });
        }

        EntityAuditService.findAllAudited().then(function (data) {
            $scope.entities = data;
            if($stateParams.entityName && $stateParams.entityId){
                var entity = ENTITY_AUDIT_TYPE[$stateParams.entityName];
                if (entity) {
                    $scope.qualifiedName = entity;
                    findByEntity(entity);
                    $scope.filter.entityId = $stateParams.entityId;
                }
            }
        });

        $scope.loading = false;
        $scope.loadChanges = function () {
            $scope.loading = true;
            $scope.filter = {};
            findByEntity($scope.qualifiedName);
        };

        $scope.getEntityName = function (qualifiedName) {
            if (qualifiedName) {
                var splits = qualifiedName.split(".");
                return splits[splits.length - 1];
            }
            else return null;
        };

        $scope.format = function(val){
            if(val)
                return ObjectDiff.objToJsonView(val);
            else return '';
        };

        $scope.isObject = function(val){
            return (val && (typeof val) == 'object');
        };
        $scope.isDate = function(key){
            return (key && key.indexOf("Date") != -1);
        };

        $scope.openChange = function(audit){

            if(audit.commitVersion < 2){
                AlertService.warning("There is no previous version available for this entry.\nThis is the first" +
                    " audit entry captured for this object");
            } else {
                EntityAuditService.getPrevVersion(audit.entityType, audit.entityId, audit.commitVersion).then(function (data) {
                    var previousVersion = JSON.parse(data.entityValue),
                        currentVersion = audit.entityValue;
                    var diff = ObjectDiff.diffOwnProperties(previousVersion, currentVersion);

                    $uibModal.open({
                        templateUrl: 'scripts/app/admin/entityAudit/entityAudit.detail.html',
                        controller: 'AuditDetailModalCtrl',
                        size: 'lg',
                        resolve: {
                            diff: function () {
                                return diff;
                            },
                            audit: function () {
                                return audit;
                            }
                        }
                    });
                });
            }
        };

        $scope.onEntityChange = function () {
            $scope.audits = [];
            $scope.limit = 25;
            $scope.orderType = "desc";
            $scope.filter = {};
        };

        function convertDates(obj) {
            for(var key in obj) {
                if (obj.hasOwnProperty(key) && obj[key]) {
                    if (key.indexOf("Date") != -1 && (obj[key] instanceof Date || Object.prototype.toString.call(obj[key]) === '[object Date]' || (new Date(obj[key]) !== "Invalid Date" && !isNaN(new Date(obj[key]))))) {
                        obj[key] = $filter('date')(obj[key]);
                    }
                }
            }
            return obj;
        }

        /**
         * This method handles the parent breadcrumb for external calls.
         */
        $scope.dynamicParentBreadcrumb = function() {
            if ($stateParams.entityName) {
                return $stateParams.entityName
            } else {
                return '';
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('AuditDetailModalCtrl', ["$scope", "$uibModalInstance", "ObjectDiff", "diff", "audit", function ($scope, $uibModalInstance, ObjectDiff, diff, audit) {

        $scope.diffValue = ObjectDiff.toJsonView(diff);
        $scope.diffValueChanges = ObjectDiff.toJsonDiffView(diff);
        $scope.audit = audit;
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('softwareGroup', {
                parent: 'entity',
                url: '/softwareGroups',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.softwareGroup.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/softwareGroup/softwareGroups.html',
                        controller: 'SoftwareGroupController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('softwareGroup');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('softwareGroup.detail', {
                parent: 'softwareGroup',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.softwareGroup.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/softwareGroup/softwareGroup-detail.html',
                        controller: 'SoftwareGroupDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('softwareGroup');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SoftwareGroup', function($stateParams, SoftwareGroup) {
                        return SoftwareGroup.get({id : $stateParams.id});
                    }]
                }
            })
            .state('softwareGroup.new', {
                parent: 'softwareGroup',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/softwareGroup/softwareGroup-dialog.html',
                        controller: 'SoftwareGroupDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('softwareGroup', null, { reload: true });
                    }, function() {
                        $state.go('softwareGroup');
                    })
                }]
            })
            .state('softwareGroup.edit', {
                parent: 'softwareGroup',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/softwareGroup/softwareGroup-dialog.html',
                        controller: 'SoftwareGroupDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['SoftwareGroup', function(SoftwareGroup) {
                                return SoftwareGroup.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('softwareGroup', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('softwareGroup.delete', {
                parent: 'softwareGroup',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/softwareGroup/softwareGroup-delete-dialog.html',
                        controller: 'SoftwareGroupDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['SoftwareGroup', function(SoftwareGroup) {
                                return SoftwareGroup.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('softwareGroup', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('SoftwareGroupController', ["$scope", "$state", "SoftwareGroup", "ParseLinks", function ($scope, $state, SoftwareGroup, ParseLinks) {

        $scope.softwareGroups = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.loadAll = function() {
            SoftwareGroup.query({page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.softwareGroups.push(result[i]);
                }
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.softwareGroups = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.softwareGroup = {
                name: null,
                id: null
            };
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp').controller('SoftwareGroupDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'SoftwareGroup',
        function($scope, $stateParams, $uibModalInstance, entity, SoftwareGroup) {

        $scope.softwareGroup = entity;
        $scope.load = function(id) {
            SoftwareGroup.get({id : id}, function(result) {
                $scope.softwareGroup = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:softwareGroupUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.softwareGroup.id != null) {
                SoftwareGroup.update($scope.softwareGroup, onSaveSuccess, onSaveError);
            } else {
                SoftwareGroup.save($scope.softwareGroup, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
}]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('SoftwareGroupDeleteController', ["$scope", "$uibModalInstance", "entity", "SoftwareGroup", function($scope, $uibModalInstance, entity, SoftwareGroup) {

        $scope.softwareGroup = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            SoftwareGroup.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('SoftwareGroupDetailController', ["$scope", "$rootScope", "$stateParams", "entity", "SoftwareGroup", function ($scope, $rootScope, $stateParams, entity, SoftwareGroup) {
        $scope.softwareGroup = entity;
        $scope.load = function (id) {
            SoftwareGroup.get({id: id}, function(result) {
                $scope.softwareGroup = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:softwareGroupUpdate', function(event, result) {
            $scope.softwareGroup = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('SoftwareGroup', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/softwareGroups/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findSoftwareGroupWithSettingsTemplate':
            {
                method:'GET',
                isArray: true,
                url : 'api/softwareGroups/findSoftwareGroupWithSettingsTemplate'
            }
        });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('LicenseAssignDeviceController', ["$scope", "$translate", "$rootScope", "$q", "$stateParams", "$state", "$filter", "ParseLinks", "entity", "DeviceType", "AssignDeviceService", "SettingsInstance", "License", "AlertService", "SettingsTemplate", function($scope, $translate, $rootScope, $q, $stateParams, $state,$filter, ParseLinks, entity,
			DeviceType, AssignDeviceService, SettingsInstance, License, AlertService, SettingsTemplate) {

        //Device Type Available
        $scope.deviceType;
        $scope.deviceTypeAssign;
        $scope.settingsInstance;
        $scope.deviceStatus;
        $scope.settings;
        $scope.license = entity;
        $scope.nonRenewalTooltip = $translate.instant('vmaxmanagerApp.license.nonRenewalStatus');


        /**
         * Refreshes the Devices loading since the first page
         */
        $scope.refreshDevicesForLicense = function(){
            if(typeof($scope.license.id) == 'undefined' || $scope.license == null){
                return;
            }

            var promiseFindAvailableToLicense = AssignDeviceService.findAvailableToLicense($scope.license.id, null, $scope.setAvailableDevicesToScope);
            var promiseFindAssignedByLicense = AssignDeviceService.findAssignedByLicense($scope.license.id, null, $scope.setAssignedDevicesToScope);
            var promiseFindDeviceTypeAssignedToLicense = DeviceType.findDeviceTypeAssignedToLicense({licenseId:$scope.license.id}).$promise;
            var promiseFindDeviceTypeAvailableToLicense = DeviceType.findDeviceTypeAvailableToLicense({licenseId:$scope.license.id}).$promise;

            $q.all([promiseFindAvailableToLicense,
                    promiseFindAssignedByLicense,
                    promiseFindDeviceTypeAssignedToLicense,
                    promiseFindDeviceTypeAvailableToLicense]).then(function(promise) {

                $scope.deviceTypesAssign = promise[2];
                $scope.devicetypes = promise[3];
                $scope.assignDevice.checkAllAsig = false;
                $scope.assignDevice.checkAll = false;
                $scope.settings = null;
            });

        };

        /** Set the available devices retrieved from the Server into the Scope */
        $scope.setAvailableDevicesToScope=function(result, headers){
            $scope.assignDevice.availableDevices = result;
            _.each($scope.assignDevice.availableDevices,function(device){
                if(typeof(device.checked) != 'undefined'){
                    device.checked = true;
                }else{
                    device.checked = false;
                }

            });
            $scope.verifyListAvailableChecked();
        };

        /** Set the Assigned devices retrieved from the server into the scope */
        $scope.setAssignedDevicesToScope=function(result, headers){
            $scope.assignDevice.assignedDevices = result;
        };

        /** Set the Device settings retrieved from the server into the scope */
        $scope.setDeviceSettingsToScope=function(result){
            $scope.assignDevice.settingsOptions = result;
        };

        /**
                 * Load Assign Devices screen for a License
                 */
        if($stateParams.id){

            /** Initialize the Assign Device Object. Useful template to recognize the used parameters while assigning a Device to a License */
            $scope.assignDevice = {
                    "license" : $scope.license,
                    "settings" : 0,
                    "checkAll" : false,
                    "availableDevices" : [],
                    "assignedDevices" : [],
                    unAssignDto : {  "deviceId" : null, "licenseId" : $scope.license.id, "newStatus" : "" }
            };
            $scope.refreshDevicesForLicense();
        };


        /**
         * Performs the preload of a Device and prepare it for removal
         * @author william.cornejo
         */
        $scope.unAssignDeviceToLicenseLoad = function(device){
            $scope.assignDevice.unAssignDto.deviceId = device.id;
        }


        /**/////////////////////////////////// Ckeck rows ////////////////////////*/

        /**
         * Device Type Device Available Change.
         * @since 22/06/2016
         */
        $scope.deviceTypeChange = function(){
        	$scope.settings = SettingsInstance.getSettingsInstanceByDeviceType({deviceType:$scope.deviceType});
        	$scope.settingsTemplate = SettingsTemplate.getSettingsTemplatesByDeviceType({deviceType:$scope.deviceType});

        	AssignDeviceService.findAvailableToLicense($stateParams.id, $scope.deviceType, $scope.setAvailableDevicesToScope);
        	$scope.assignDevice.checkAll = false;
        };

        /**
         * Device Type Device Assigned Change.
         * @author jcardona
         * @since 28/06/2016
         */
        $scope.deviceTypeAssignChange = function(){
        	AssignDeviceService.findAssignedByLicense($scope.assignDevice.license.id, $scope.deviceTypeAssign, $scope.setAssignedDevicesToScope);
        	  $scope.assignDevice.checkAllAsig = false;
        };

        /** Verifies if the Assign button should be disabled */
        $scope.assignButtonDisabled = function(){
            var disabled = true;
            if(typeof($scope.assignDevice) == 'undefined'){
                disabled = true;
            }else{
                _.each($scope.assignDevice.availableDevices, function(device){
                    if(device.checked){
                        disabled = false;
                        return false;
                    }
                });
            }
            return disabled;
        }


        /** Verifies if the Assign button should be disabled */
        $scope.unAssignButtonDisabled = function(){
            var disabled = true;
            if(typeof($scope.assignDevice) == 'undefined'){
                disabled = true;
            }else{
                _.each($scope.assignDevice.assignedDevices, function(device){
                    if(device.checked){
                        disabled = false;
                        return false;
                    }
                });
            }
            return disabled;
        }

        /**
         * Check/Unchecks all devices when the common Check changes status
         */
        $scope.checkAllAvailableDevices  = function(){
            _.each($scope.assignDevice.availableDevices,function(device){
                device.checked = $scope.assignDevice.checkAll;
            });
        }

        /**
         * Check/Unchecks all devices when the common Check changes status
         */
        $scope.checkAllAssignedDevices  = function(){
            _.each($scope.assignDevice.assignedDevices,function(device){
                if(device.status == "ASSIGNED"){
                    device.checked = $scope.assignDevice.checkAllAsig;
                }
            });
        }


        /**
         * Verifies if after checking and individual device the common checkbox must be checked.
         */
        $scope.verifyCheckAllDevices = function(device){
            if(!device.checked){
                $scope.assignDevice.checkAll = false;
            }else{
                $scope.verifyListAvailableChecked();
            }
        }

        /** Logic to verify if the check all checkbox must be modified */
        $scope.verifyListAvailableChecked = function(){
            var finalValue = $scope.assignDevice.availableDevices.length > 0;
            _.each($scope.assignDevice.availableDevices,function(device){
                if(!device.checked){
                    finalValue = false;
                    return false;
                }
            });
            $scope.assignDevice.checkAll = finalValue;
        }

        /**
         * Verifies if after checking and individual device the common checkbox must be checked.
         */
        $scope.verifyCheckAllAssignedDevices = function(device){
            if(!device.checked){
                $scope.assignDevice.checkAllAsig = false;
            }else{
                $scope.verifyListAssignedChecked();
            }
        }

        /** Logic to verify if the check all checkbox must be modified */
        $scope.verifyListAssignedChecked = function(){
            var finalValue = $scope.assignDevice.assignedDevices.length > 0;
            _.each($scope.assignDevice.assignedDevices,function(device){
                if(!device.checked){
                    finalValue = false;
                    return false;
                }
            });
            $scope.assignDevice.checkAllAsig = finalValue;
        }

        /**
        * Edit settings instance from assign device.
        */
        $scope.editSettings = function(evt, settingsInstanceId, deviceId, deviceTypeId) {
            if ($scope.license.id) {
                if (deviceId) {
                    $state.go('license.edit.settings-view', {id: $scope.assignDevice.license.id, settingsId: settingsInstanceId, deviceId: deviceId, deviceTypeId: deviceTypeId, showChangeSettingsButton: true, parentState: 'license.device.assign'});
                } else {
                    $state.go('license.edit.settings-view', {id: $scope.assignDevice.license.id, settingsId: settingsInstanceId, deviceId: deviceId, parentState: 'license.device.assign'});
                }
            }
            evt.stopPropagation();
        }

        /**
        * Assign devices whether they have or not settings templates.
        */
        $scope.assignDevices = function() {

            if (! _.isEmpty($scope.settings) || ! _.isEmpty($scope.settingsTemplate)) {
                $state.go('license.device.assign.setting', {id: $scope.assignDevice.license.id, deviceType: $scope.deviceType, assignDevice: $scope.assignDevice});

            } else {
                var assignDeviceDto = { licenseId : $scope.assignDevice.license.id, devices : new Array()};
                _.each($scope.assignDevice.availableDevices, function(device){
                    if(device.checked){
                        assignDeviceDto.devices.push(device.id);
                    }
                });

                // Validate slots and devices
                if($scope.assignDevice.license.slots < $scope.assignDevice.assignedDevices.length + assignDeviceDto.devices.length){
                    AlertService.error($rootScope.message.error.deviceAssignNotEnoughSlots);
                    return;
                }
                AssignDeviceService.assignDevicesToLicense(assignDeviceDto, $scope.refreshDevicesForLicense);
            }
        }

        $scope.validSettings = function(device){
            return _.isEmpty(device.settingsInstance);
        };

   }]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('LicenseDeleteController', ["$scope", "$uibModalInstance", "entity", "License", function($scope, $uibModalInstance, entity, License) {

        $scope.license = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            License.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('vmaxmanagerApp').controller('LicenseDialogController',
    ['$scope', '$stateParams', 'entity', 'License', 'LicenseStatus', 'ChargeType', '$q', 'Software', 'SlotType', 'Fleet', 'SoftwareLicense', 'SettingsInstance', '$state', 'LicenseService', 'DownloadService',
        function($scope, $stateParams, entity, License,  LicenseStatus, ChargeType, $q, Software, SlotType, Fleet, SoftwareLicense, SettingsInstance, $state, LicenseService, DownloadService) {

        var checkStartDate = function(){
            var now = entity.startDate;

            if(entity.startDate && moment(entity.startDate).isValid()){
                entity.startDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),  now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
            }
        }

        checkStartDate();

        $scope.license = entity;
        $scope.isEdit = false;
        $scope.addOther = false;

        $scope.licenseStatus = LicenseStatus.query();
        $scope.chargeTypes = ChargeType.query();
        $scope.fleets = Fleet.query();
        $scope.licenseDurations = [
            {id: 1, name: "1 Month"},
            {id: 3, name: "3 Months"},
            {id: 6, name: "6 Months"},
            {id: 12, name: "12 Months"},
            {id: 0, name: "Custom (Months)"},
        ];

        $q.all([$scope.license.$promise]).then(function() {
            if($scope.license.id){
                $q.all([$scope.licenseStatus.$promise, $scope.chargeTypes.$promise, $scope.fleets.$promise]).then(function() {
                    $scope.isEdit = true;
                    $scope.isNotDraft = $scope.license.licenseStatus !== 'DRAFT';
                    $scope.setDuration();
                    $scope.license.startDate = new Date($scope.license.startDate);
                    var chargeType = _.filter($scope.chargeTypes, function(chargeType){ return chargeType.name == $scope.license.chargeType; });
                    $scope.license.chargeType = chargeType[0].id;
                    $scope.requiredData = $scope.license.slotType.requiresData;
                    $scope.software = $scope.license.slotType.software;
                    $scope.selectedFleet();
                });
            }
        });

        $scope.setDuration = function(){
            if($scope.license.duration != 1 && $scope.license.duration != 3 && $scope.license.duration != 6 && $scope.license.duration != 12){
                $scope.license.customMonth = $scope.license.duration;
                $scope.license.duration = 0;
            }
            $scope.durationChange();
        };

        /**
         * duration custom change.
         * @author jmontoya
         */
        $scope.durationChange = function(){
            $scope.requiredCustom = $scope.license.duration == 0;
            if(!$scope.requiredCustom){
                $scope.license.customMonth = null;
            }else{
                $scope.editForm.$invalid = $scope.license.customMonth == null;
            }
        };

        /**
         * selected Fleet.
         * @author jmontoya
         */
        $scope.selectedFleet = function() {
            var fleet = _.where($scope.fleets, { 'id' : $scope.license.fleet.id } );
            var isCloud =  fleet[0] && fleet[0].deploymentType == 'CLOUD';

            $scope.showAndRequiredSoftwareVersion($scope.license.slotType);

            Software.query(function(result){
                if(isCloud){
                    $scope.softwares = _.filter(result, function(item){
                        return item.cloud == isCloud;
                    });
                }else{
                    $scope.softwares = _.filter(result, function(item){
                        return item.local == !isCloud;
                    });
                }
                if($scope.isEdit){
                    $scope.selectedSoftware();
                }
            });
        };

        /**
         * selected software.
         * @author jmontoya
         */
        $scope.selectedSoftware = function(){
            $scope.slottypes = {};
            SlotType.query(function(result){
                $scope.slottypes = _.filter(result, function(item){
                    return item.software.id == $scope.software.id;
                });

                _.each($scope.slottypes, function(slottype){
                    if(slottype.feature != null && slottype.feature != ''){
                        slottype.slotTypeName = slottype.slotType + ' - ' +  slottype.feature;
                    }else{
                        slottype.slotTypeName = slottype.slotType ;
                    }
                });
                if($scope.isEdit){
                    $scope.selectedSlotType();
                }
            });
        };

        /**
         * selected slotType.
         * @author jmontoya
         */
        $scope.selectedSlotType = function(){
            if($scope.license.slotType){
                var slot = _.where($scope.slottypes, { "id": $scope.license.slotType.id });
                if(slot[0]){
                    $scope.requiredData =  slot[0].requiresData;
                    if(!$scope.requiredData){
                        $scope.license.dataAmount = null;
                    }
                    $scope.showAndRequiredSlot(slot[0]);
                    $scope.showAndRequiredSoftwareVersion(slot[0]);
                }else{
                    $scope.editForm.$invalid = true;
                }
                //Settings options
                if($scope.license.slotType.slotType === 'APP') {
                    $scope.settings = SettingsInstance.getSettingsInstanceByTypeSettingsAndAndSoftwareGroup({typeSettings:'SOFTWARE', softwareGroup:$scope.license.slotType.software.softwareGroup.id});
                    if ($stateParams.settingsId && $scope.license.id) {
                        if (parseInt($scope.license.settingsInstance.id) != parseInt($stateParams.settingsId)) {
                            $q.all([$scope.settings.$promise]).then(function() {
                                _.each($scope.settings, function(settingsInstance){
                                    if(parseInt(settingsInstance.id) == parseInt($stateParams.settingsId)){
                                        $scope.license.settingsInstance = settingsInstance;
                                    }
                                });
                            });
                        }
                    }
                } else {
                    $scope.license.settingsInstance = null;
                }

            }
        };

        /**
         * show and required slot
         * @author jmontoya
         */
        $scope.showAndRequiredSlot = function(slot){
            if(slot && slot.requiresSlot){
                $scope.showSlot = true;
            }else{
                $scope.showSlot = false;
                $scope.license.slots = null;
            }
        };

        /**
         * show and required slot
         * @author jcardona
         */
        $scope.showAndRequiredSoftwareVersion = function(slot){

            $scope.softwarelicenses = {};
            $scope.showRequiresSoftwareVersion = false;
            $scope.requiresSoftwareVersion = false;

            if(slot){
                var software = _.where($scope.softwares,{"id" : $scope.software.id})
                if(slot.slotType == 'APP' && software.length> 0){
                    $scope.softwarelicenses = SoftwareLicense.getSoftwareLicensesByGroupId({softwaregroupid : software[0].softwareGroup.id});
                    if($scope.license && $scope.license.fleet){
                        var fleetSelected = $scope.fleets.filter(function(fleet){
                            return fleet.id == $scope.license.fleet.id;
                        });
                        if(fleetSelected && fleetSelected[0] && fleetSelected[0].deploymentType === "ONSITE"){
                            $scope.showRequiresSoftwareVersion = true;
                            $scope.requiresSoftwareVersion = true;
                        }
                    }
                }
            }
        };

        $scope.create = function () {
             // Prevent save license with expiration date minor to today date.
             if($scope.license.startDate!==''){
                 // Get start date of the license
                 var startingDate = new Date($scope.license.startDate);
                 // Get the license month duration
                 var monthsExpiration = $scope.license.duration != 0 ? $scope.license.duration : $scope.license.customMonth;
                 // Build the expiration date
                 var expirationDate = new Date(new Date(startingDate).setMonth(startingDate.getMonth() + monthsExpiration));
                 if(expirationDate < $scope.today){
                     AlertModalsService.error($filter('translate')('vmaxmanagerApp.license.licenseExpiresEarlierThanToday'));
                     return;
                 }
             }

             //Selected custom duration.
             if($scope.license.duration == 0){
                 $scope.license.duration = $scope.license.customMonth;
             }

             if(!$scope.license.id){
                 // To set the status in draft.
                 $scope.license.licenseStatus = 'DRAFT';
             }

             // Verify if the Slots are at least the minimum by checking the number of devices assigned.
             if($scope.isEdit === true && $scope.license.slots && $scope.license.devices.length > $scope.license.slots){
                 AlertModalsService.error($filter('translate')('vmaxmanagerApp.license.slotsLessThanDevices'));
                 return;
             }

             if($scope.isEdit === true && $scope.license.licenseStatus === 'PUBLISHED') {
                 LicenseService.downloadLicenseModal($scope, LicenseService.message.update, $scope.license, 'edit', LicenseService.modalTitle.other);
             }else{
                 License.save($scope.license, function () {
                     if(!$scope.addOther){
                         $state.go('license');
                     } else {
                         $scope.clear();
                     }
                 });
             }
         };

        $scope.clear = function () {
            $scope.license = {
                fleet:{
                   id: $scope.license.fleet ? $scope.license.fleet.id : null
                },
                slots: null,
                salesOrder: $scope.license.salesOrder,
                previousSalesOrder: $scope.license.previousSalesOrder,
                startDate: new Date($scope.license.startDate),
                requiresData: null,
                dataAmount: null,
                chargeType: $scope.license.chargeType,
                slotType: null,
                notes: null,
                licenseStatus: $scope.license.licenseStatus,
                duration: $scope.license.duration,
                customMonth:$scope.license.customMonth,
                id: null
            };
            $scope.setDuration($scope.license.duration);
            $scope.isEdit=false;
            $scope.addOther=false;
            $scope.isNotDraft=false;
            $scope.softwarelicenses = {};
            $scope.requiredData = false;
            $scope.selectedFleet();
        };

        /**
         * Method call when action update is confirm.
         *
         * @author jmontoya
         */
        $scope.updateAndExport = function (license){
            $scope.license = license;
            License.save($scope.license, function () {
                $('#saveLicenseModal').modal('hide');
                var downloadFile = $("input[name='downloadFile']:checked").val();
                if(downloadFile){
                    DownloadService.downloadFile('api/exportLicense/' + license.fleet.id + '/' + license.fleet.fleetId + '/' + license.id);
                }
                $state.go('license');
            });
        };

        /**
        * Edit settings instance from license edit.
        */
        $scope.editSettings = function(evt) {
            if ($scope.license !== null && $scope.license.id !== null && $scope.license.id !== undefined && $scope.license.settingsInstance) {
                $state.go('license.edit.settings-view', {id: $scope.license.id, settingsId: $scope.license.settingsInstance.id, parentState: 'license.edit'});
            }
            evt.stopPropagation();
        }
}]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('LicenseDetailController', ["$scope", "$rootScope", "$stateParams", "entity", "License", "SlotType", "Fleet", "SoftwareLicense", "SettingsInstance", function ($scope, $rootScope, $stateParams, entity, License, SlotType, Fleet, SoftwareLicense, SettingsInstance) {
        $scope.license = entity;
        $scope.load = function (id) {
            License.get({id: id}, function(result) {
                $scope.license = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:licenseUpdate', function(event, result) {
            $scope.license = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('SettingsTemplate', ["$resource", function ($resource) {
        return $resource('api/settingsTemplates/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getSettingsTemplatesByTypeSettingsAndSoftwareGroup': {
                 method:'GET',
                 isArray: true,
                 url:'api/getSettingsTemplatesByTypeSettingsAndSoftwareGroup?typeSettings=:typeSettings&softwareGroup=:softwareGroup'
            },
            'getSettingsTemplatesByTypeSettingsAndDeviceType': {
                 method:'GET',
                 isArray: true,
                 url:'api/getSettingsTemplatesByTypeSettingsAndDeviceType?typeSettings=:typeSettings&deviceType=:deviceType'
            },
            'getSettingsTemplatesByDeviceType': {
                 method:'GET',
                 isArray: true,
                 url:'api/getSettingsTemplatesByDeviceType/:deviceType'
            }
        });
    }]);

(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('settings-instance', {
            parent: 'entity',
            url: '/settings-instance',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.settingsInstance.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/settingsInstance/settings-instances.html',
                    controller: 'SettingsInstanceController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settingsInstance');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            },
            ncyBreadcrumb: {
                label: 'vmaxmanagerApp.settingsInstance.home.title'
            }
        })
        .state('settings-instance-detail', {
            parent: 'settings-instance',
            url: '/{id}/view',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.settingsInstance.detail.title',
                headerLabel:'vmaxmanagerApp.settingsInstance.process.viewSettings',
                hideButtons: true,
                showChangeSettingsButton: true,
                isView: true
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                    controller: 'SettingsInstanceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settingsInstance');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                    return SettingsInstance.get({id : $stateParams.id}).$promise;
                }],
                licenseEntity: function () { return null; },
                deviceEntity: function () { return null; }
            },
             ncyBreadcrumb: {
               label: 'entity.action.view',
               parent: 'settings-instance'
           }
        })
        .state('settings-instance.new', {
            parent: 'settings-instance',
            url: '/new',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.settingsInstance.new.title',
                headerLabel: 'vmaxmanagerApp.settingsInstance.process.newSettings',
                hideButtons: true,
                showChangeSettingsButton: true,
                isNew: true
            },
            params: {
                parentState: null,
                id: null,
                deviceId: null,
                deviceTypeId: null
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                    controller: 'SettingsInstanceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settingsInstance');
                    return $translate.refresh();
                }],
                entity: function () {
                            return {
                                name: null,
                                notes: null,
                                template: null,
                                id: null
                            };
                },
                licenseEntity: function () { return null; },
                deviceEntity: function () { return null; }
            },
            ncyBreadcrumb: {
              label: 'entity.action.new',
              parent: 'settings-instance'
          }
        })
        .state('settings-instance.edit', {
            parent: 'settings-instance',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.settingsInstance.edit.title',
                headerLabel: 'vmaxmanagerApp.settingsInstance.process.editSettings',
                hideButtons: true,
                showChangeSettingsButton: true,
                isEdit: true
            },
            params: {
                parentState: null,
                id: null,
                deviceId: null,
                deviceTypeId: null
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                    controller: 'SettingsInstanceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settingsInstance');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                    return SettingsInstance.get({id : $stateParams.id}).$promise;
                }],
                licenseEntity: function () { return null; },
                deviceEntity: function () { return null; }
            },
            ncyBreadcrumb: {
               label: 'entity.action.edit',
               parent: 'settings-instance'
           }
        })
        .state('settings-instance.clone', {
            parent: 'settings-instance',
            url: '/{deviceId}/clone',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.settingsInstance.clone.title',
                headerLabel: 'vmaxmanagerApp.settingsInstance.process.cloneSettings',
                hideButtons: true,
                showChangeSettingsButton: true,
                isClone: true
            },
            params: {
                parentState: null,
                id: null,
                deviceId: null,
                deviceTypeId: null
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                    controller: 'SettingsInstanceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settingsInstance');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                    return SettingsInstance.get({id : $stateParams.id}).$promise;
                }],
                licenseEntity: function () { return null; },
                deviceEntity: function () { return null; }
            },
             ncyBreadcrumb: {
               label: 'entity.action.clone',
               parent: 'settings-instance'
           }
        })
        .state('settings-instance.delete', {
            parent: 'settings-instance',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
            },
            ncyBreadcrumb: {
                label: 'vmaxmanagerApp.settingsInstance.home.title',
                parent: 'entity'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-delete-dialog.html',
                    controller: 'SettingsInstanceDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['SettingsInstance', function(SettingsInstance) {
                            return SettingsInstance.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('settings-instance', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('settings-instance.settings-change', {
            parent: 'settings-instance',
            url: '/{deviceId}/change-settings',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.settingsInstance.edit.title',
                headerLabel:'vmaxmanagerApp.settingsInstance.process.changeSettings',
                hideButtons: true,
                showChangeSettingsButton: true,
                isEdit: true,
                isChangeSettings: true
            },
            params: {
                parentState: null,
                id: null,
                deviceId: null,
                deviceTypeId: null,
                settingsId: null
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/settingsInstance/settings-instance-dialog.html',
                    controller: 'SettingsInstanceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settingsInstance');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SettingsInstance', function($stateParams, SettingsInstance) {
                    return SettingsInstance.get({id : $stateParams.id}).$promise;
                }],
                licenseEntity: function () { return null; },
                deviceEntity: ['$stateParams', 'Device', function($stateParams, Device) {
                    return $stateParams.deviceId? Device.get({id : $stateParams.deviceId}).$promise: null;
                }]
            },
            ncyBreadcrumb: {
               label: 'entity.action.change',
               parent: 'settings-instance'
           }
        });
    }
})();

'use strict';
angular.module('vmaxmanagerApp')
    .controller('SettingsInstanceController', ["$scope", "$state", "$translate", "$filter", "AlertService", "SettingsInstance", "ParseLinks", function ($scope, $state, $translate, $filter, AlertService, SettingsInstance, ParseLinks) {

    	$scope.settingsInstances = [];
        $scope.reverse = true;
        $scope.page = 0;
        $scope.predicate = 'name';
        $scope.loadAll = function() {
        	SettingsInstance.query({page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.settingsInstances.push(result[i]);
                }
            });
        }

    $scope.reset = function() {
        $scope.page = 0;
        $scope.settingsInstances = [];
        $scope.loadAll();
    };
    $scope.loadPage = function(page) {
        $scope.page = page;
        $scope.loadAll();
    };

    $scope.loadAll();

    $scope.refresh = function () {
        $scope.reset();
        $scope.clear();
    };

    $scope.clear = function () {
        $scope.settingsInstances = {
            name: null,
            notes: null,
            template: null,
            id: null
        };
    };

    /**
     * Validate if can be delete
     * @since 2016-07-07
     * @autor jcardona
     */
    $scope.validateDelete = function (params) {
    	 $scope.settingsId = params.id;
    	 $scope.result = SettingsInstance.existSettingsInstanceAppliedToTypeSettingsId({typeSettings:params.typeSettings,id: params.id},function() {
             if($scope.result.quantity>0){
            	 AlertService.error($filter('translate')('error.dataIntegrity'));
        	 }else{
        		 $state.go('settings-instance.delete', { id: $scope.settingsId }, { reload: true });
        	 }
    	 });
    };


}]);

(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('SettingsInstanceDialogController', SettingsInstanceDialogController);

    SettingsInstanceDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$window', 'entity', 'SettingsInstance', 'SettingsTemplate', 'SoftwareGroup', 'DeviceType', 'SettingsType', 'Timezone', 'licenseEntity', 'License', 'deviceEntity', 'Device', '$translate'];

    function SettingsInstanceDialogController($timeout, $scope, $state, $stateParams, $window, entity, SettingsInstance, SettingsTemplate, SoftwareGroup, DeviceType, SettingsType, Timezone, licenseEntity, License, deviceEntity, Device, $translate) {
        // Loading the variables
        var vm = this;
        vm.isView = $state.current.data.isView;
        vm.isEdit = $state.current.data.isEdit;
        vm.isClone = $state.current.data.isClone;
        vm.isNew = $state.current.data.isNew;
        vm.isChangeSettings = $state.current.data.isChangeSettings;
        vm.settingsName = entity ? entity.name : null;
        vm.isEditionWithRestrictions = null;
        vm.headerLabel = $state.current.data.headerLabel;
        vm.hideButtons = $state.current.data.hideButtons;
        vm.showChangeSettingsButton = $stateParams.showChangeSettingsButton;
        vm.timezones = Timezone.getTimezones();
        vm.licenseEntity = licenseEntity;
        vm.deviceEntity = deviceEntity;
        vm.showTemplate = false;
        vm.parentState = $stateParams.parentState;
        vm.invalidParams = false;
        vm.notAllowedCharacters = ['$']
        if (vm.isView == true || vm.isChangeSettings == true) {
            vm.allFieldsReadOnly = true;
        } else {
            vm.allFieldsReadOnly = false;
        }

        vm.saveBtnText = 'entity.action.save';
        if (vm.isClone) vm.saveBtnText = 'entity.action.clone';
        if (vm.isChangeSettings) vm.saveBtnText = 'entity.action.change';
        vm.backBtnText = vm.isView ? "entity.action.back" : "entity.action.cancel"

        var existLicense = vm.licenseEntity && vm.licenseEntity.id;

        var translations = {
            'PATTERN_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.pattern'),
            'REQUIRED_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.required'),
            'MAX_LENGTH_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.maxLength'),
            'URL_EXISTS_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.urlExits')
        };

        // The layout configuration could be: "narrow" or "wide".
        vm.settingsManagerConfig = {
            layout: 'narrow',
            labelClass: 'control-label',
            inputGroupClass: 'form-group label-floating',
            inputClass: 'form-control',
            selectClass: 'form-control',
            errorTextClass: '',
            translations: translations
        };

        // Process to change settings instance.
        if (vm.isChangeSettings && $stateParams.deviceTypeId) {
            vm.deviceSettingsInstanceId = $stateParams.settingsId;
            vm.deviceSettingsInstance = null;
            vm.deviceSettingsInstanceList = SettingsInstance.getSettingsInstanceByDeviceType({
                deviceType: $stateParams.deviceTypeId
            }, function(result) {
                if (result !== null && result !== undefined) {
                    result.forEach(function(settings) {
                        if (settings.id == vm.deviceSettingsInstanceId) {
                            vm.deviceSettingsInstance = settings;
                        }
                    });
                } else {
                    vm.jsonData = null;
                }
            });
        } else {
            if ($stateParams.deviceTypeId) {
                vm.deviceSettingsInstanceList = SettingsInstance.getSettingsInstanceByDeviceType({
                    deviceType: $stateParams.deviceTypeId
                }, function(result) {
                    if (result !== null && result !== undefined) {
                        result.forEach(function(settings) {
                            if (settings.id == vm.deviceSettingsInstanceId) {
                                vm.deviceSettingsInstance = settings;
                            }
                        });
                    } else {
                        vm.jsonData = null;
                    }
                });
            }
        }

        /**
         * This method handle the tab section change.
         */
        vm.changeTab = function(section) {
            vm.section = section;
        }

        /**
         * This method handle the tab section change.
         */
        vm.checkEditionWithRestrictions = function(isApplied) {
            switch (vm.parentState) {
                case 'license.edit':
                case 'license':
                case 'license.device.assign':
                    if (vm.isEdit || vm.isNew || vm.isClone) {
                        vm.isEditionWithRestrictions = true;
                    } else {
                        vm.isEditionWithRestrictions = false;
                    }
                    break;
                default:
                    vm.isEditionWithRestrictions = isApplied;
                    break;
            }
        }

        /**
         * Checks is the current settings was applied to license or device.
         */
        vm.checkAppliedTo = function() {
            if (entity.id && vm.isEdit && !vm.isChangeSettings) {
                vm.appliedTo = SettingsInstance.existSettingsInstanceAppliedToTypeSettingsId({
                    id: entity.id,
                    typeSettings: entity.settingsTemplate.typeEntitySettingsEnum
                }, function(result) {
                    vm.checkEditionWithRestrictions(result.quantity > 0);
                });
            } else {
                vm.checkEditionWithRestrictions(false);
            }
        }

        /**
         * This method parses a string to json.
         */
        function parseJson() {
            vm.jsonData = JSON.parse(vm.settingsInstance.template);
            if (!isNullOrEmpty(vm.jsonData[0])) {
                vm.changeTab(vm.jsonData[0]);
            }
        }

        // Initial controller process
        if ($state.current.data.isClone) {
            // Is a clone process.
            vm.isEditionWithRestrictions = true;
            vm.settingsInstance = {
                name: null,
                id: null,
                settingsTemplate: entity.settingsTemplate,
                template: entity.template,
                notes: entity.notes
            };
        } else {
            // Checking if the current setting instance was applied to licence or device.
            vm.checkAppliedTo();
            vm.settingsInstance = entity;
        }

        if (vm.settingsInstance != null) {
            if (vm.settingsInstance.settingsTemplate != null) {
                if (vm.settingsInstance.settingsTemplate.softwareGroup != null) {
                    vm.appliedToAlertLabel = 'vmaxmanagerApp.settingsInstance.settingsBeingUsedByLicenses';
                } else {
                    vm.appliedToAlertLabel = 'vmaxmanagerApp.settingsInstance.settingsBeingUsedByDevices';
                }
            }
        }

        vm.save = save;

        if (!isNullOrEmpty(vm.settingsInstance.template)) {
            parseJson();
        }

        loadSettingsTypeStore(vm);

        /**
         * Timeout method.
         */
        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        /**
         * Updates settings instance for devices.
         */
        function updateDeviceSettingsInstance(newSettingsInstance) {
            var device = vm.deviceEntity;
            if (device) {
                device.settingsInstance = newSettingsInstance;
                Device.update(device, function() {
                    saveStateHandler()
                });
            }
        }

        /**
         * Save action.
         */
        function save() {
            vm.isSaving = true;
           if(validateAdditionalParams()) {
                if (vm.isChangeSettings) {
                    updateDeviceSettingsInstance(vm.settingsInstance);
                } else {
                    if (!isNullOrEmpty(vm.jsonData)) {
                        vm.settingsInstance.template = JSON.stringify(vm.jsonData);
                    }
                    if (vm.settingsInstance.id !== null) {
                        SettingsInstance.update(vm.settingsInstance, onSaveSuccess, onSaveError);
                    } else {
                        SettingsInstance.save(vm.settingsInstance, onSaveSuccess, onSaveError);
                    }
                }
           } else {
               vm.isSaving = false;
               vm.invalidParams = true;
           }
        }

        function validateAdditionalParams() {
        var validParameters = true;
            _.each(vm.jsonData, function(section){
                if (section.id == "additionalParams") {
                    if (!_.isEmpty(section.fields[0].data)) {
                        var advancedParams = section.fields[0].data.split("\n");
                        _.each(advancedParams, function(advancedParam){
                            if (_.isEmpty(advancedParam) || advancedParam.indexOf(" ") != -1 || advancedParam.indexOf("=") == -1 ||  _.isEmpty(advancedParam.substring(advancedParam.indexOf("=") + 1 ))) {
                                validParameters = false;
                            }
                        });
                    }
                }
            });
        return validParameters;
        }

        /**
         * State handler method for save event.
         */
        function saveStateHandler() {
            if (vm.parentState == null || vm.parentState == undefined) {
                $state.go($state.current.parent);
            } else {
                switch (vm.parentState) {
                    case 'device':
                        $state.go(vm.parentState, null, { reload: true });
                    case 'license.edit':
                    case 'license':
                        $state.go(vm.parentState, $stateParams);
                        break;
                    case 'license.device.assign':
                        $stateParams["device"] = vm.deviceEntity;
                        $stateParams["license"] = vm.licenseEntity;
                        $state.go(vm.parentState, $stateParams);
                        break;
                    default:
                        $state.go(vm.parentState);
                        break;
                }
            }
        }

        /**
         * Save success handler.
         */
        function onSaveSuccess(result) {
            $scope.$emit('vmaxmanagerApp:settingsInstanceUpdate', result);
            vm.isSaving = false;
            var isNormalSaveProcess = true;

            if (vm.licenseEntity != null && vm.licenseEntity != undefined) {
                if (result != null) {
                    if ($state.current.data.isClone || $state.current.data.isNew || $state.current.data.isChangeSettings) {
                        switch (vm.parentState) {
                            case 'license.edit':
                            case 'license':
                                isNormalSaveProcess = false;
                                vm.licenseEntity.settingsInstance = result;
                                License.save(vm.licenseEntity, function() {
                                    saveStateHandler()
                                });
                                break;
                            case 'license.device.assign':
                                isNormalSaveProcess = false;
                                updateDeviceSettingsInstance(result);
                                break;
                        }
                    }
                }
            } else {
                if (vm.parentState === 'device') {
                    updateDeviceSettingsInstance(result);
                }
            }
            if (isNormalSaveProcess) {
                saveStateHandler()
            }
        }

        /**
         * Save error handler.
         */
        function onSaveError(httpResponse) {
            vm.isSaving = false;
            var error = httpResponse.headers ? httpResponse.headers('X-vmaxmanagerApp-error') : null;
            if (error == "error.nameExists") {
                vm.settingsInstance.name = '';
            }
        }

        /**
         * Method to load the settings types store.
         */
        function loadSettingsTypeStore(vm) {
            vm.showTemplate = false;
            vm.softwaregroups = SoftwareGroup.findSoftwareGroupWithSettingsTemplate();
            vm.devicetypes = DeviceType.findDeviceTypeWithSettingsTemplate();

            vm.settingstypes = [];
            SettingsType.query(function(result) {
                _.each(result, function(item) {
                    vm.settingstypes.push(item["name"]);
                });
            });

            var isNew = validateIsNewSetting(vm);
            if (isNew) {
                if ($state.current.data.isNewFromLicense) {
                    var settingsTemplate = vm.settingsInstance.settingsTemplate;
                    var typeEntitySettings = settingsTemplate.typeEntitySettingsEnum;
                    var settingsTemplateId = settingsTemplate.id;
                    switch (typeEntitySettings) {
                        case "DEVICE":
                            loadDeviceTypes(true, settingsTemplateId);
                            break;
                        case "SOFTWARE":
                            loadSoftwareGroup();
                            break;
                    }
                } else if (vm.parentState === 'device') {
                    vm.isEditionWithRestrictions = true;
                    loadDeviceTypes(true, settingsTemplateId);
                }
            } else {
                loadDeviceTypes(isNew, null);
            }
        }

        /**
         * Method to validate if the current setting is new or not.
         */
        function validateIsNewSetting(vm) {
            var isNew = false;
            if (!isNullOrEmpty(vm) && !vm.isClone) {
                if (!isNullOrEmpty(vm.settingsInstance)) {
                    isNew = isNullOrEmpty(vm.settingsInstance.id);
                }
            }
            return isNew;
        }

        /**
         * Method to validate if a value is null or empty.
         */
        function isNullOrEmpty(value) {
            return (value === undefined || value === null || value === '') ? true : false;
        }

        /**
         * This method updates the settings template and parse a string to json.
         */
        function updateSettingsTemplate(settingsTemplate) {
            vm.settingsInstance.settingsTemplate = settingsTemplate;
            vm.settingsInstance.template = settingsTemplate.template;
            parseJson();
        }

        /**
         * Loads the software group store.
         */
        function loadSoftwareGroup() {
            vm.showTemplate = false;
            vm.settingsInstance.template = '';
            vm.settingstemplates = null;

            if (!isNullOrEmpty(vm.settingsInstance.settingsTemplate.softwareGroup)) {
                if (!isNullOrEmpty(vm.settingsInstance.settingsTemplate.softwareGroup.id)) {
                    var softwareGroupId = vm.settingsInstance.settingsTemplate.softwareGroup.id;
                    SettingsTemplate.getSettingsTemplatesByTypeSettingsAndSoftwareGroup({
                        typeSettings: 'SOFTWARE',
                        softwareGroup: softwareGroupId
                    }, function(result) {
                        if (result !== null && result !== undefined) {
                            if (result[0] !== null && result[0] !== undefined) {
                                updateSettingsTemplate(result[0]);
                            }
                        } else {
                            vm.jsonData = null;
                        }
                    });
                }
            }
        }

        /**
         * Loads the device type store.
         */
        function loadDeviceTypes(isNew, settingsTemplateId) {
            vm.showTemplate = false;
            var currentTemplate = vm.settingsInstance.settingsTemplate;
            if (!isNullOrEmpty(vm.settingsInstance.settingsTemplate.deviceType)) {
                var deviceType = vm.settingsInstance.settingsTemplate.deviceType;
                var deviceTypeId = deviceType.id ? deviceType.id : deviceType;
                SettingsTemplate.getSettingsTemplatesByTypeSettingsAndDeviceType({
                    typeSettings: 'DEVICE',
                    deviceType: deviceTypeId
                }, function(result) {
                    if (result != null && vm != null) {
                        if (result.length > 1) {
                            vm.showTemplate = true;
                            vm.settingstemplates = result;
                            if (settingsTemplateId != null && settingsTemplateId != undefined) {
                                result.forEach(function(settingsTemplateResult) {
                                    if (settingsTemplateResult.id == settingsTemplateId) {
                                        updateSettingsTemplate(settingsTemplateResult);
                                    }
                                });
                            } else {
                                if (isNew) {
                                    vm.settingsInstance.settingsTemplate = {
                                        id: null,
                                        deviceType: {
                                            id: deviceTypeId
                                        },
                                        typeEntitySettingsEnum: "DEVICE"
                                    };
                                    vm.settingsInstance.template = null;
                                    vm.jsonData = null;
                                }
                            }
                        } else {
                            if (isNew) {
                                updateSettingsTemplate(result[0]);
                            }
                        }
                    }
                });
            }
        }

        /**
         * This method handle the parent breadcrumb for external calls, for this reason is part of the scope.l
         */
        $scope.dynamicParentBreadcrumb = function() {
            switch (vm.parentState) {
                case 'license.device.assign':
                    return 'license.device.assign';
                    break;
                case 'device':
                    return 'device';
                    break;
                default:
                    return 'license.edit';
                    break;
            }
        };

        /**
         * Handler for Settings Type control.
         */
        vm.selectedSettingsType = function() {
            vm.showTemplate = false;
            vm.settingsInstance.settingsTemplate.softwareGroup = null;
            vm.settingsInstance.settingsTemplate.deviceType = null;
            vm.settingsInstance.template = '';
            vm.jsonData = null;
            vm.settingstemplates = null;

            switch (vm.settingsInstance.settingsTemplate.typeEntitySettingsEnum) {
                case "DEVICE":
                    vm.settingsInstance.settingsTemplate.deviceType = {};
                    break;
                case "SOFTWARE":
                    vm.settingsInstance.settingsTemplate.softwareGroup = {};
                    break;
            }
        };

        /**
         * Handler for Software Group control.
         */
        vm.selectedSoftwareGroup = function() {
            loadSoftwareGroup();
        };

        /**
         * Handler for Device Type control.
         */
        vm.selectedDeviceType = function() {
            loadDeviceTypes(true, null);
        };

        /**
         * Handler for the template control.
         */
        vm.selectedTemplate = function() {
            if (vm.settingsInstance.settingsTemplate != null) {
                vm.settingsInstance.template = vm.settingsInstance.settingsTemplate.template;
                parseJson();
            }
        };

        /**
         * This method triggered the back history event.
         */
        vm.back = function() {
            if ($state.current.data.isView) {
                if (vm.parentState == null || vm.parentState == undefined) {
                    $state.go($state.current.parent);
                } else {
                    switch (vm.parentState) {
                        case 'license.edit':
                        case 'license':
                            $state.go(vm.parentState, $stateParams);
                            break;
                        case 'license.device.assign':
                            $stateParams["device"] = vm.deviceEntity;
                            $stateParams["license"] = vm.licenseEntity;
                            $state.go(vm.parentState, $stateParams);
                            break;
                        case 'device':
                            $state.go(vm.parentState, $stateParams);
                            break;
                        default:
                            $state.go(vm.parentState);
                            break;
                    }
                }
            } else {
                switch (vm.parentState) {
                    case 'license.edit':
                    case 'license':
                        $state.go('license.edit.settings-view', $stateParams);
                        break;
                    case 'license.device.assign':
                        $stateParams["device"] = vm.deviceEntity;
                        $stateParams["license"] = vm.licenseEntity;
                        $state.go('license.edit.settings-view', $stateParams);
                        break;
                    case 'device':
                        $state.go('device.settings-view', {
                            parentState: "device",
                            id: vm.deviceEntity.id,
                            deviceId: vm.deviceEntity.id,
                            deviceTypeId: vm.deviceEntity.deviceType.id,
                            settingsId: vm.deviceEntity.settingsInstance.id
                        });
                        break;
                    default:
                        $state.go('settings-instance');
                        break;
                }
            }
        }

        /**
         * This method creates a new settings instance.
         */
        vm.newSettingsInstance = function() {
            if (existLicense) {
                var softwareGroupOrDeviceTypeId = null;
                switch (vm.settingsInstance.settingsTemplate.typeEntitySettingsEnum) {
                    case "DEVICE":
                        softwareGroupOrDeviceTypeId = vm.settingsInstance.settingsTemplate.deviceType.id;
                        break;
                    case "SOFTWARE":
                        softwareGroupOrDeviceTypeId = vm.settingsInstance.settingsTemplate.softwareGroup.id;
                        break;
                }

                $state.go('license.edit.settings-new', {
                    id: vm.licenseEntity.id,
                    settingsId: vm.settingsInstance.id,
                    deviceId: vm.deviceEntity ? vm.deviceEntity.id : null,
                    settingsTemplateId: vm.settingsInstance.settingsTemplate.id,
                    typeEntitySettingsEnum: vm.settingsInstance.settingsTemplate.typeEntitySettingsEnum,
                    softwareOrDeviceId: softwareGroupOrDeviceTypeId,
                    parentState: vm.parentState,
                    showChangeSettingsButton: vm.showChangeSettingsButton,
                    deviceTypeId: $stateParams.deviceTypeId
                });
            } else {
                if (vm.parentState = 'device') {
                    $state.go('device.settings-new', {
                        parentState: "device",
                        id: vm.deviceEntity.id,
                        deviceId: vm.deviceEntity.id,
                        deviceTypeId: vm.deviceEntity.deviceType.id,
                        settingsId: vm.deviceEntity.settingsInstance.id,
                        settingsTemplateId: vm.deviceEntity.settingsInstance.settingsTemplate.id
                    });
                }
            }
        }

        /**
         * This method edits a new settings instance.
         */
        vm.editSettingsInstance = function() {
            if (existLicense) {
                $state.go('license.edit.settings-edit', {
                    id: vm.licenseEntity.id,
                    settingsId: vm.settingsInstance.id,
                    deviceId: $stateParams.deviceId,
                    deviceTypeId: $stateParams.deviceTypeId,
                    parentState: vm.parentState,
                    showChangeSettingsButton: vm.showChangeSettingsButton
                });
            } else {
                if (vm.parentState = 'device') {
                    $state.go('device.settings-edit', {
                        parentState: "device",
                        id: vm.deviceEntity.id,
                        deviceId: vm.deviceEntity.id,
                        deviceTypeId: vm.deviceEntity.deviceType.id,
                        settingsId: vm.deviceEntity.settingsInstance.id
                    });
                }
            }
        }

        /**
         * This method clones a new settings instance.
         */
        vm.cloneSettingsInstance = function() {
            if (existLicense) {
                $state.go('license.edit.settings-clone', {
                    id: vm.licenseEntity.id,
                    settingsId: vm.settingsInstance.id,
                    deviceId: $stateParams.deviceId,
                    deviceTypeId: $stateParams.deviceTypeId,
                    parentState: vm.parentState,
                    showChangeSettingsButton: vm.showChangeSettingsButton
                });
            } else {
                if (vm.parentState = 'device') {
                    $state.go('device.settings-clone', {
                        parentState: "device",
                        id: vm.deviceEntity.id,
                        deviceId: vm.deviceEntity.id,
                        deviceTypeId: vm.deviceEntity.deviceType.id,
                        settingsId: vm.deviceEntity.settingsInstance.id
                    });
                }
            }
        }

        /**
         * This method change a current settings instance template.
         */
        vm.changeSettingsInstance = function() {
            if (existLicense) {
                $state.go('license.edit.settings-change', {
                    id: vm.licenseEntity.id,
                    settingsId: vm.settingsInstance.id,
                    deviceId: $stateParams.deviceId,
                    deviceTypeId: $stateParams.deviceTypeId,
                    parentState: vm.parentState,
                    showChangeSettingsButton: vm.showChangeSettingsButton
                });
            } else {
                if (vm.parentState = 'device') {
                    $state.go('device.settings-change', {
                        parentState: "device",
                        id: vm.deviceEntity.id,
                        deviceId: vm.deviceEntity.id,
                        deviceTypeId: vm.deviceEntity.deviceType.id,
                        settingsId: vm.deviceEntity.settingsInstance.id
                    });
                }
            }
        }

        /**
         * Event handler for change settings instance for a device.
         */
        vm.selectedDeviceSettingsInstance = function() {
            vm.settingsInstance = vm.deviceSettingsInstance;
            parseJson();
        }

        /**
         * This method replace all $ from a string.
         * (This validation was added for wildcards process).
         */
        vm.replaceWildcardSymbol = function(originalData) {
            var wildcardSymbol = '$';
            var newData = originalData.replace(wildcardSymbol, '')

            if (newData.indexOf(wildcardSymbol) !== -1) {
                newData = vm.replaceWildcardSymbol(newData);
            }
            return newData;
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('vmaxmanagerApp')
        .controller('SettingsInstanceDeleteController',SettingsInstanceDeleteController);

    SettingsInstanceDeleteController.$inject = ['$uibModalInstance', 'entity', 'SettingsInstance'];

    function SettingsInstanceDeleteController($uibModalInstance, entity, SettingsInstance) {
        var vm = this;

        vm.settingsInstance = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
        	vm.deleteError = false;
        	SettingsInstance.delete({id: id}, onDeleteSuccess, onDeleteError);
        }
        
        function onDeleteSuccess (result) {
        	$uibModalInstance.close(true);
        }

        function onDeleteError (response) {
        	vm.deleteError = true;
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('vmaxmanagerApp')
        .factory('SettingsInstance', SettingsInstance);

    SettingsInstance.$inject = ['$resource'];

    function SettingsInstance ($resource) {
        var resourceUrl =  'api/settings-instances/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getSettingsInstanceByTypeSettingsAndAndSoftwareGroup':
                {
                    method:'GET',
                    isArray: true,
                    url:'api/getSettingsInstanceByTypeSettingsAndAndSoftwareGroup?typeSettings=:typeSettings&softwareGroup=:softwareGroup'
                },
              'getSettingsInstanceByDeviceType': 
              {
                	method:'GET',
                	isArray: true,
                	url:'api/getSettingsInstanceByDeviceType?deviceType=:deviceType'
             }, 
            'existSettingsInstanceAppliedToTypeSettingsId':
            {
                method:'GET',
                isArray: false,
                url:'api/existSettingsInstanceAppliedToTypeSettingsId?typeSettings=:typeSettings&id=:id'
            }
        });
    }
})();

'use strict';

angular.module('vmaxmanagerApp').factory('SettingsType', ["$resource", function($resource) {
    return $resource('app/rest/typeEntitySettingsEnum/:id', {}, {
        'query' : {
            method : 'GET',
            isArray : true
        },
        'get' : {
            method : 'GET'
        }
    });
}]);
'use strict';

angular.module('vmaxmanagerApp')
    .service('Parameters', function () {

        var REGULAR_EXPRESSION_VALIDATION_PASSWORDS = '((?=.*\\d)(?=.*[A-Za-z])(?=.*\\W).{8,32})';

        var REGULAR_EXPRESSION_VALIDATION_ALPHANUMERIC = '[A-Za-z0-9]*';

        var REGULAR_EXPRESSION_MOBILE_NUMBER = '(\\(([0-9]{3})\\) [0-9]{3} - [0-9]{4}){1,16}';

        var getRegularExpressionValidationPassword = function(){
            return REGULAR_EXPRESSION_VALIDATION_PASSWORDS;
        }

        var getRegularExpressionValidationAlphanumeric = function(){
            return REGULAR_EXPRESSION_VALIDATION_ALPHANUMERIC;
        }

        var getRegularExpressionMobileNumber = function(){
            return REGULAR_EXPRESSION_MOBILE_NUMBER;
        }

        return{
            getRegularExpressionValidationPassword:getRegularExpressionValidationPassword,
            getRegularExpressionValidationAlphanumeric:getRegularExpressionValidationAlphanumeric,
            getRegularExpressionMobileNumber:getRegularExpressionMobileNumber
        }

    });

'use strict';

angular.module('vmaxmanagerApp')
    .service('Parameters', function () {

        var REGULAR_EXPRESSION_VALIDATION_PASSWORDS = '((?=.*\\d)(?=.*[A-Za-z])(?=.*\\W).{8,32})';

        var REGULAR_EXPRESSION_VALIDATION_ALPHANUMERIC = '[A-Za-z0-9]*';

        var REGULAR_EXPRESSION_MOBILE_NUMBER = '(\\(([0-9]{3})\\) [0-9]{3} - [0-9]{4}){1,16}';

        var getRegularExpressionValidationPassword = function(){
            return REGULAR_EXPRESSION_VALIDATION_PASSWORDS;
        }

        var getRegularExpressionValidationAlphanumeric = function(){
            return REGULAR_EXPRESSION_VALIDATION_ALPHANUMERIC;
        }

        var getRegularExpressionMobileNumber = function(){
            return REGULAR_EXPRESSION_MOBILE_NUMBER;
        }

        return{
            getRegularExpressionValidationPassword:getRegularExpressionValidationPassword,
            getRegularExpressionValidationAlphanumeric:getRegularExpressionValidationAlphanumeric,
            getRegularExpressionMobileNumber:getRegularExpressionMobileNumber
        }

    });

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('expandable-devices', {
                parent: 'admin',
                url: '/expandable-devices',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.expandableDevices.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/interfaces/expandableDevices/expandable-devices.html',
                        controller: 'ExpandableDeviceController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expandableDevices');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.expandableDevices.title'
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('ExpandableDeviceController', ["$scope", "$q", "DeviceType", "ExpandableService", function ($scope,$q,DeviceType,ExpandableService) {

    	$scope.devicetypes = DeviceType.query();
    	$scope.deviceTypeObj;
    	$scope.messageError;
    	$scope.inProgress;
    	$scope.status;
    	$scope.resultSyncronice; 
    	
        /**
         * Selected DeviceType.
         * @author jcardona
         */
        $scope.selectedDeviceType = function(){
        	$scope.status = null;
        	$scope.messageError = null;  
        	$scope.inProgress = false;
        	$scope.resultSyncronice = null;
        };
    	
        
        /**
         * Synchonize
         * @author jcardona
         */
        $scope.synchronizeDeviceType = function(){
        	$scope.status = null;
        	$scope.messageError = null;
        	$scope.inProgress = true;
        	$scope.resultSyncronice = ExpandableService.getSincronizeExpandableDeviceType({deviceTypeId:$scope.deviceTypeObj.id});
           	$q.all([$scope.resultSyncronice.$promise]).then(function() {
           		$scope.status= $scope.resultSyncronice.status;
           		if($scope.resultSyncronice.status=='ERROR'){
           			$scope.messageError = $scope.resultSyncronice.errorMessage;
           		}
            	$scope.inProgress = false;
            	$scope.deviceTypeObj = null;
            	$scope.devicetypes = DeviceType.query();
        	});
        };
    	
    }]);

(function() {
    'use strict';
    angular
        .module('vmaxmanagerApp')
        .factory('ExpandableService', ExpandableService);

    ExpandableService.$inject = ['$resource'];

    function ExpandableService ($resource) {
        var resourceUrl =  'api/interface/expandable';

        return $resource(resourceUrl, {}, {
            'getSincronizeExpandableDeviceType':
                {
                    method:'GET',
                    isArray: false,
                    url:'api/interface/expandable/getSincronizeExpandableDeviceType?deviceTypeId=:deviceTypeId'
                }
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('app-resource', {
            parent: 'entity',
            url: '/app-resource',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.appResource.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/appResource/app-resources.html',
                    controller: 'AppResourceController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appResource');
                    $translatePartialLoader.addPart('resourceTypeEnum');
                    $translatePartialLoader.addPart('restrictionDownloadTypeEnum');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            },
            ncyBreadcrumb: {
              label: 'vmaxmanagerApp.appResource.home.title'
            }
        })
        .state('app-resource.view', {
            parent: 'app-resource',
            url: '/{id}/view',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.appResource.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/appResource/app-resource-detail.html',
                    controller: 'AppResourceDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appResource');
                    $translatePartialLoader.addPart('resourceTypeEnum');
                    $translatePartialLoader.addPart('restrictionDownloadTypeEnum');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AppResource', function($stateParams, AppResource) {
                    return AppResource.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'app-resource',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            },
            ncyBreadcrumb: {
                label: 'entity.action.view',
                parent: 'app-resource'
            }
        })
        .state('app-resource.new', {
            parent: 'app-resource',
            url: '/new',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/appResource/app-resource-dialog.html',
                    controller: 'AppResourceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        name: null,
                        url: null,
                        maxDownloads: null,
                        resourceTypeEnum: null,
                        restrictionDownloadTypeEnum: null,
                        id: null
                    };
                },
                allDeviceStatus: ['AllDeviceStatus', function(AllDeviceStatus) {
                    return AllDeviceStatus.query({}).$promise;
                }],
                profiles: [function() {
                  return [];
                }]
            },
            ncyBreadcrumb: {
                label: 'entity.action.new',
                parent: 'app-resource'
            }
        })
        .state('app-resource.edit', {
            parent: 'app-resource',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/appResource/app-resource-dialog.html',
                    controller: 'AppResourceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['AppResource', '$stateParams', function(AppResource, $stateParams) {
                    return AppResource.get({id : $stateParams.id}).$promise;
                }],
                allDeviceStatus: ['AllDeviceStatus', function(AllDeviceStatus) {
                    return AllDeviceStatus.query({}).$promise;
                }],
                profiles: ['AppResourceUserProfileByResource', '$stateParams', function(AppResourceUserProfileByResource, $stateParams) {
                    return AppResourceUserProfileByResource.query({id : $stateParams.id}).$promise;
                }]
            },
            ncyBreadcrumb: {
                label: 'entity.action.edit',
                parent: 'app-resource'
            }
        })
        .state('app-resource.delete', {
            parent: 'app-resource',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'scripts/app/entities/appResource/app-resource-delete-dialog.html',
                    controller: 'AppResourceDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AppResource', function(AppResource) {
                            return AppResource.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('app-resource', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();

(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('AppResourceController', AppResourceController);

    AppResourceController.$inject = ['$scope', '$state', 'AppResource', 'ParseLinks', 'AlertService'];

    function AppResourceController ($scope, $state, AppResource, ParseLinks, AlertService) {
        var vm = this;

        vm.appResources = [];
        vm.loadPage = loadPage;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reverse = true;

        //filter configs
        vm.filterConfig = {
            inputGroupClass: '',
            btnClass: 'btn-raised'
        };

        vm.fields = AppResource.filterVars();

        loadAll();

        function loadAll () {

            var pageable = {page: vm.page, size: 40, sort: sort()};

            AppResource.filterQuery({pageable:pageable, filters:vm.queryObject}, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.appResources.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.error);
            }
        }

        vm.refresh = function () {
            vm.page = 0;
            vm.appResources = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('AppResourceDialogController', AppResourceDialogController);

    AppResourceDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'AppResource', 'DeviceType', 'allDeviceStatus',
                                            'profiles', 'AppResourceProfilerList', '$state', 'AppResourceUserProfileByResource'];

    function AppResourceDialogController ($timeout, $scope, $stateParams, entity, AppResource, DeviceType, allDeviceStatus,
                                            profiles, AppResourceProfilerList, $state, AppResourceUserProfileByResource) {
        var vm = this;

        vm.appResource = entity;
        vm.save = save;
        vm.devicetypes = DeviceType.query();
        vm.profiles = profiles;

        vm.deviceStatusModel = [];

        var status = [];
        var statusModel = [];

        vm.profilesModel = [];

        var profilesToImportData = [];
        var profilesToImportModel = [];

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function save () {
            vm.isSaving = true;
            var allStatus = vm.joinDeviceStatusModel(vm.deviceStatusModel);
            vm.appResource.statusToDownload = allStatus;
            if (vm.appResource.id !== null) {
                AppResource.update(vm.appResource, onSaveSuccess, onSaveError);
            } else {
                AppResource.save(vm.appResource, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            var listResources = [];

            _.each(vm.profileModel, function(data){
                listResources.push({id:data.id, name: data.label});
            });

            vm.appResource.id = result.id;

            var resource = {
                appResource : vm.appResource,
                listResourceUserProfile : listResources,
                id : null
            };

            AppResourceProfilerList.saveList(resource, onSaveSuccessResourceUser, onSaveErrorResourceUser);

            $scope.$emit('vmaxmanagerApp:appResourceUpdate', result);

            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function onSaveSuccessResourceUser (result) {
            vm.isSaving = false;
            $state.go("app-resource");
        }

        function onSaveErrorResourceUser () {
            vm.isSaving = false;
            $state.go("app-resource");
        }

        var transformModelFromDeviceStatus = function(allStatus, deviceStatus){
            for(var i=0; i < allStatus.length; i++){
                if(deviceStatus){
                    for(var j=0; j < deviceStatus.length; j++){
                        if(allStatus[i] == deviceStatus[j]){
                            statusModel.push({id: i, label:allStatus[i]});
                        }
                    }
                }
                status.push({id: i, label:allStatus[i]});
            }
            vm.deviceStatusData = status;
            vm.deviceStatusModel = statusModel;
        }

        var bindResourceStatus = function(){
            if(allDeviceStatus){
                if (vm.appResource.id !== null) {
                    if(vm.appResource.statusToDownload){
                        var statusProviderFromEntity = vm.appResource.statusToDownload.split(",");
                        transformModelFromDeviceStatus(allDeviceStatus, statusProviderFromEntity);
                    }else{
                        transformModelFromDeviceStatus(allDeviceStatus, null);
                    }
                }else{
                    transformModelFromDeviceStatus(allDeviceStatus);
                }
            }
        }

        vm.joinDeviceStatusModel = function(deviceStatusModel){
            var joinStatus = "";
            for(var i=0; i<deviceStatusModel.length; i++){
                if(i == deviceStatusModel.length - 1){
                    joinStatus += deviceStatusModel[i].label;
                } else {
                    joinStatus += deviceStatusModel[i].label + ",";
                }
            }
            return joinStatus;
        }

        var transformModelFromProfiles = function(allProfiles){
            _.each(allProfiles, function(profile){
                if(profile.assigned){
                    profilesToImportModel.push({id: profile.userProfile.id, label:profile.userProfile.name});
                }
                profilesToImportData.push({id: profile.userProfile.id, label:profile.userProfile.name});
            });
            vm.profilesData = profilesToImportData;
            vm.profileModel = profilesToImportModel;
        }

        vm.bindProfiles = function(profiles){
            if(profiles){
                transformModelFromProfiles(profiles);
            }
        }


        bindResourceStatus();
        vm.bindProfiles(profiles);

        vm.onSuccessChangeDeviceType = function (result) {
            if(result != null && result.length > 0) {
                vm.profiles = result;
                vm.bindProfiles(result);
            } else {
                vm.profiles = null;
            }
        }

        vm.loadProfilesByDeviceTypeId = function(){
            vm.profilesData = [];
            vm.profileModel = [];
            profilesToImportData = [];
            profilesToImportModel = [];
            if (vm.appResource.deviceType !== null) {
                AppResourceUserProfileByResource.getAppResourceUserProfilesByDeviceTypeId(
                    {id : vm.appResource.deviceType.id}, vm.onSuccessChangeDeviceType);
            }
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('AppResourceDetailController', AppResourceDetailController);

    AppResourceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AppResource', 'DeviceType'];

    function AppResourceDetailController($scope, $rootScope, $stateParams, previousState, entity, AppResource, DeviceType) {
        var vm = this;

        vm.appResource = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vmaxmanagerApp:appResourceUpdate', function(event, result) {
            vm.appResource = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('AppResourceDeleteController',AppResourceDeleteController);

    AppResourceDeleteController.$inject = ['$uibModalInstance', 'entity', 'AppResource'];

    function AppResourceDeleteController($uibModalInstance, entity, AppResource) {
        var vm = this;

        vm.appResource = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AppResource.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('vmaxmanagerApp')
        .factory('AppResource', AppResource);

    AppResource.$inject = ['$resource'];

    function AppResource ($resource) {
        var resourceUrl =  'api/app-resources/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'filterQuery': { method: 'POST', url: 'api/app-resources/query', isArray:true },
            'filterVars': { method: 'GET', url: 'api/app-resources/info', isArray:true }
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('download-resources-audit', {
            parent: 'entity',
            url: '/download-resources-audit',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.downloadResourcesAudit.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/downloadResourcesAudit/download-resources-audits.html',
                    controller: 'DownloadResourcesAuditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('downloadResourcesAudit');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            },
            ncyBreadcrumb: {
                label: 'vmaxmanagerApp.downloadResourcesAudit.home.title'
            }
        })
    }

})();

(function() {
    'use strict';
    angular
        .module('vmaxmanagerApp')
        .factory('DownloadResourcesAudit', DownloadResourcesAudit);

    DownloadResourcesAudit.$inject = ['$resource', 'DateUtils'];

    function DownloadResourcesAudit ($resource, DateUtils) {
        var resourceUrl =  'api/download-resources-audits/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.downloadDate = DateUtils.convertDateTimeFromServer(data.downloadDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('DownloadResourcesAuditController', DownloadResourcesAuditController);

    DownloadResourcesAuditController.$inject = ['$scope', '$state', 'DownloadResourcesAudit', 'ParseLinks', 'AlertService', '$filter',  '$translate'];

    function DownloadResourcesAuditController ($scope, $state, DownloadResourcesAudit, ParseLinks, AlertService, $filter, $translate) {
        var vm = this;

        vm.downloadResourcesAudits = [];
        vm.page = 0;
        vm.reverse = true;
        vm.appResource = {};
        vm.searchTooltip = $translate.instant('vmaxmanagerApp.downloadResourcesAudit.tooltip.search');;

        vm.filter =  {};

        function loadAll(filter) {

            if(!_.isEmpty(filter)){
                DownloadResourcesAudit.query({
                    page: vm.page,
                    size: 50,
                    sort: sort(),
                    dateFrom : filter.dateFrom,
                    dateTo : filter.dateTo,
                    deviceType : filter.deviceType,
                    serialNumber : filter.serialNumber
                }, onSuccess, onError);
            }else{
                 DownloadResourcesAudit.query({
                     page: vm.page,
                     size: 50,
                     sort: sort()
                 }, onSuccess, onError);
             }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.downloadResourcesAudits.push(data[i]);
                }
                vm.change();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.reset = function() {
            vm.page = 0;
            vm.downloadResourcesAudits = [];
            loadAll(vm.filter);
        }

        vm.loadPage = function(page) {
            vm.page = page;
            loadAll(vm.filter);
        }

        vm.change = function() {
            if(vm.appResource.dateTo < vm.appResource.dateFrom){
                vm.appResource.dateTo = null;
            }
            if(vm.appResource.dateFrom){
                vm.minDate = vm.appResource.dateFrom;
            }
         }

         vm.search = function(){

            var dateFormat = 'yyyy-MM-dd';
            var fromDate = null;
            var toDate = null;
            vm.page = 0;
            vm.downloadResourcesAudits = [];

            if(vm.appResource.dateFrom){
                fromDate = $filter('date')(vm.appResource.dateFrom, dateFormat);
            }

            if(vm.appResource.dateTo){
                vm.appResource.dateTo.setDate(vm.appResource.dateTo.getDate() + 1);
                toDate = $filter('date')(vm.appResource.dateTo, dateFormat);
            }

            vm.filter =  {
                dateFrom : fromDate,
                dateTo : toDate,
                deviceType : vm.appResource.deviceType,
                serialNumber : vm.appResource.serialNumber
            };

            if(vm.appResource.dateTo){
                vm.appResource.dateTo.setDate(vm.appResource.dateTo.getDate() - 1);
            }

            loadAll(vm.filter);

         }

         vm.today = function () {
             var today = new Date();
             vm.appResource.dateTo = new Date(today.getFullYear(), today.getMonth(), today.getDate());
         };

         vm.previousMonth = function () {
             var fromDate = new Date();
             if (fromDate.getMonth() === 0) {
                 fromDate = new Date(fromDate.getFullYear() - 1, 0, fromDate.getDate());
             } else {
                 fromDate = new Date(fromDate.getFullYear(), fromDate.getMonth() - 1, fromDate.getDate());
             }

             vm.appResource.dateFrom = fromDate;
         };

         vm.today();
         vm.previousMonth();
         vm.search();
    }
})();

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('downloads', {
                abstract: true,
                parent: 'site',
                url: '/downloads',
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('ds2', {
                parent: 'downloads',
                url: '/ds',
                hideMenu: true,
                downloadsView: true,
                data: {
                    authorities: [],
                    pageTitle: 'title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/downloads/ds2/ds2.html',
                        controller: 'Ds2Controller'
                    },
                     'toolbar@' : {
                         controller: null,
                         templateUrl: null,
                     },
                     'menu@' : {
                         controller: null,
                         templateUrl: null,
                     }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    deviceType: ['AppDownloadsService', function(AppDownloadsService) {
                        return AppDownloadsService.getDeviceTypeByName({name: 'DS2'}).$promise;
                    }]
                },
                onEnter: function(){
                	$('.modal-backdrop').remove();
                }
            });
    }]);

'use strict';

angular.module('vmaxmanagerApp').controller('Ds2Controller',
    ['$scope', '$state', '$q', 'AppDownloadsService', 'AppResourceDownloadService', 'deviceType', '$window', 'UrlService',
        function($scope, $state, $q, AppDownloadsService, AppResourceDownloadService, deviceType, $window, UrlService) {
            $scope.captchaResponse = null;
            $scope.showResponseContainer = false;
            $scope.appResourcesList = null;
            $scope.deviceTypeId = null;
            $scope.hasProfiles = null;
            $scope.selectedProfile = null;
            $scope.profiles = null;
            $scope.appResourceUserProfile = null;
            $scope.deviceType = deviceType;

            $q.all([$scope.deviceType.$promise]).then(function() {
                if($scope.deviceType.id){
                    $scope.deviceTypeId = $scope.deviceType.id;
                    $scope.profiles = AppDownloadsService.getProfilesByDeviceTypeId({deviceTypeId: $scope.deviceTypeId});
                    $q.all([$scope.profiles.$promise]).then(function() {
                        if ($scope.profiles.length > 0) {
                            $scope.hasProfiles = true;
                        }
                    });
                }
            });

            $scope.changeProfile = function () {
                if ($scope.selectedProfile != null) {
                    $scope.showResponseContainer = false;
                    $scope.appResourcesList = null;
                }
            };

            $scope.serialNumberChange = function () {
                $scope.showResponseContainer = false;
                $scope.appResourcesList = null;
            };

            $scope.onSuccess = function (result){
                $scope.appResourcesList = result
                $scope.showResponseContainer = true;
                $scope.captchaResponse = null;
                grecaptcha.reset();
                $scope.stopDownloads = false;
            };

            $scope.onError = function (result){
                $scope.showResponseContainer = false;
                $scope.captchaResponse = null;
                grecaptcha.reset();
                $scope.stopDownloads = false;
            };

            $scope.buildAuditEntity = function (status) {
                var auditEntity = {
                    serialNumber: $scope.serialNumber,
                    appResourceId: $scope.currentAppResource.resourceId,
                    profileId: $scope.selectedProfile ? $scope.selectedProfile.id : null,
                    status: status
                }

                return auditEntity;
            };

            $scope.onDownloadSuccess = function (headers){
                var willBeDownloadable = headers['x-willbedownloadable'];
                if (willBeDownloadable != null && willBeDownloadable != undefined) {
                    if (willBeDownloadable == false || willBeDownloadable == "false") {
                        if ($scope.appResourcesList != null) {
                            $scope.appResourcesList.forEach(function(resource){
                                if (resource.resourceId == $scope.currentAppResource.resourceId) {
                                    resource.downloadable = false;
                                }
                            });
                        }
                    }
                }

                var auditEntity = $scope.buildAuditEntity('COMPLETED');
                AppDownloadsService.updateDownloadsAudit(auditEntity);
                $scope.stopDownloads = false;
            };

            $scope.onDownloadError = function () {
                var auditEntity = $scope.buildAuditEntity('ERROR');
                AppDownloadsService.updateDownloadsAudit(auditEntity);
                $scope.stopDownloads = false;
            };

            $scope.search = function() {
                var downloadRequest = {
                        serialNumber: $scope.serialNumber,
                        typeId: $scope.deviceTypeId,
                        captchaResponse: $scope.captchaResponse,
                        hasProfile: $scope.hasProfiles ? $scope.hasProfiles : false,
                        profileId: $scope.hasProfiles? $scope.selectedProfile.id : 0
                    };
                AppDownloadsService.getResourcesBySerialNumber(downloadRequest, $scope.onSuccess, $scope.onError);
            };

            var onSuccessDownloadValidation = function(resource){
                return function(resultPromise) {
                    resource.downloadable = resultPromise.downloadable;
                };
            }

            $scope.downloadResource = function(appResource) {
                // Remove the alerts.
                $scope.alerts = [];

                // Identify the profile id.
                var profileId = $scope.selectedProfile ? $scope.selectedProfile.id : null;

                // Do the download petition.
                $scope.currentAppResource = appResource;
                if (appResource.downloadable) {
                    if(appResource.downloadType === "S3"){
                        $scope.stopDownloads = true;
                        AppResourceDownloadService.downloadFile('api/downloads/' + appResource.id + '/' + appResource.resourceId
                        + '/' + appResource.resourceId, $scope.onDownloadSuccess, $scope.onDownloadError);
                    }else{
                        if(appResource.url && UrlService.validURL(appResource.url)){
                            var auditEntity = $scope.buildAuditEntity('COMPLETED');
                            AppDownloadsService.updateDownloadsAudit(auditEntity);
                            $window.location.href = appResource.url;
                            AppDownloadsService.getDownloadableValidation({serialNumber : $scope.serialNumber, resourceId : appResource.resourceId}, onSuccessDownloadValidation(appResource));
                        }else{
                            var auditEntity = $scope.buildAuditEntity('ERROR');
                            AppDownloadsService.updateDownloadsAudit(auditEntity);
                        }
                    }
                }
            };
}]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('AppDownloadsService', ["$resource", function($resource) {
        return $resource('api/downloads', {}, {
            'getResourcesBySerialNumber': {
                method: 'POST',
                isArray: true
            },
            'updateDownloadsAudit': {
                method: 'POST',
                isArray: false,
                url: 'api/downloads/audit/update'
            },
            'getDeviceTypeByName': {
                method: 'GET',
                isArray: false   ,
                url: 'api/downloads/getDeviceTypeByName?name=:name'
            },
             'getProfilesByDeviceTypeId': {
                 method: 'GET',
                 isArray: true   ,
                 url: 'api/downloads/getProfilesByDeviceTypeId?deviceTypeId=:deviceTypeId'
             },
             'getAllProfiles': {
                method: 'GET',
                isArray: true   ,
                url: 'api/downloads/getAllProfiles'
             },
             'getDownloadableValidation': {
                 method: 'GET',
                 isArray: false,
                 url: 'api/downloads/getDownloadableValidation'
              }
        });
    }]).factory('AppResourceDownloadService', ["$http", function($http) {
        return {
            downloadFile: function(httpPath, onSuccessCallBack, onErrorCallback) {
                // Use an arraybuffer
                $http.get(httpPath, {
                    responseType: 'arraybuffer'
                }).success(function(data, status, headers) {

                    var octetStreamMime = 'application/octet-stream';
                    var success = false;

                    // Get the headers
                    headers = headers();

                    // Get the filename from the x-filename header or default to
                    // "download.bin"
                    var filename = headers['x-filename'] || 'download.bin';

                    // Determine the content type from the header or default to
                    // "application/octet-stream"
                    var contentType = headers['content-type'] || octetStreamMime;

                    try {
                        // Try using msSaveBlob if supported
                        console.log("Trying saveBlob method ...");
                        var blob = new Blob([data], {
                            type: contentType
                        });
                        if (navigator.msSaveBlob)
                            navigator.msSaveBlob(blob, filename);
                        else {
                            // Try using other saveBlob implementations, if
                            // available
                            var saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob;
                            if (saveBlob === undefined)
                                throw "Not supported";
                            saveBlob(blob, filename);
                        }
                        console.log("saveBlob succeeded");
                        success = true;
                    } catch (ex) {
                        console.log("saveBlob method failed with the following exception:");
                        console.log(ex);
                    }

                    if (success) {
                        if (onSuccessCallBack != null && onSuccessCallBack != undefined) {
                            onSuccessCallBack(headers);
                        }
                        return;
                    }
                    else {
                        // Get the blob url creator
                        var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
                        if (urlCreator) {
                            // Try to use a download link
                            var link = document.createElement('a');
                            if ('download' in link) {
                                // Try to simulate a click
                                try {
                                    // Prepare a blob URL
                                    console.log("Trying download link method with simulated click ...");
                                    var blob = new Blob([data], {
                                        type: contentType
                                    });
                                    var url = urlCreator.createObjectURL(blob);
                                    link.setAttribute('href', url);

                                    // Set the download attribute (Supported in
                                    // Chrome 14+ / Firefox 20+)
                                    link.setAttribute("download", filename);

                                    // Simulate clicking the download link
                                    var event = document.createEvent('MouseEvents');
                                    event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                                    link.dispatchEvent(event);
                                    console.log("Download link method with simulated click succeeded");
                                    success = true;

                                } catch (ex) {
                                    console.log("Download link method with simulated click failed with the following exception:");
                                    console.log(ex);
                                }
                            }

                            if (success) {
                                if (onSuccessCallBack != null && onSuccessCallBack != undefined) {
                                    onSuccessCallBack(headers);
                                }
                                return;
                            }
                            else {
                                // Fallback to window.location method
                                try {
                                    // Prepare a blob URL
                                    // Use application/octet-stream when using
                                    // window.location to force download
                                    console.log("Trying download link method with window.location ...");
                                    var blob = new Blob([data], {
                                        type: octetStreamMime
                                    });
                                    var url = urlCreator.createObjectURL(blob);
                                    window.location = url;
                                    console.log("Download link method with window.location succeeded");
                                    success = true;
                                } catch (ex) {
                                    console.log("Download link method with window.location failed with the following exception:");
                                    console.log(ex);
                                }
                            }

                        }
                    }

                    if (success) {
                        if (onSuccessCallBack != null && onSuccessCallBack != undefined) {
                            onSuccessCallBack(headers);
                        }
                        return;
                    }
                    else {
                        // Fallback to window.open method
                        console.log("No methods worked for saving the arraybuffer, using last resort window.open");
                        window.open(httpPath, '_blank', '');
                    }
                }).error(function(data, status) {
                    console.log("Request failed with status: " + status);
                    if (onErrorCallback != null && onErrorCallback != undefined) {
                        onErrorCallback();
                    }
                });
            }
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
.factory('ExportService', function () {

    var myStyle = {};

    var setStyle = function(sheetName, bookName){
        myStyle = {
            sheetid: sheetName,
            headers: true,
            caption: {
                title: bookName
            }
        };
    };

    var getStyle = function(){
        return myStyle;
    };

    var exportToExcel = function (filename, style, data) {
        return alasql('SELECT * INTO XLS("'+filename+'.xls",?) FROM ?',[style, data]);
    };

    return {
        setStyle : setStyle,
        getStyle : getStyle,
        exportToExcel : exportToExcel
    }
})
.service('MapServerToExport', function() {

    this.convert = function(serves){

        var ser = [];

        _.forEach(serves, function(serve) {
            var newServe = {
                "Name": serve.name ? serve.name : "",
                "Url": serve.url ? serve.url : "",
                "IP Address": serve.ipAddress ? serve.ipAddress : ""
            };

            ser.push(newServe);
        });

        return ser;
    }
})
.service('MapCustomerToExport', function() {

    this.convert = function(customers){

        var cus = [];

        _.forEach(customers, function(customer) {
            var newCustomer = {
                "Name": customer.name ? customer.name : "",
                "Description": customer.description ? customer.description : ""
            };

            cus.push(newCustomer);
        });

        return cus;
    }
})
.service('MapFleetToExport', function() {

    this.convert = function(fleets){

        var fle = [];


        _.forEach(fleets, function(fleet) {
            var newFleet = {
                "Fleet Id": fleet.fleetId ? fleet.fleetId : "",
                "Deployment Type": fleet.deploymentType ? fleet.deploymentType : "",
                "Customer": fleet.customer.name ? fleet.customer.name : "",
                "Server": fleet.server? fleet.server.name : "",
                "Sales Region": fleet.salesRegion ? fleet.salesRegion.name : ""
            };

            fle.push(newFleet);
        });

        return fle;
    }
})
.service('MapLicenseToExport', ["$filter", function($filter) {

    this.convert = function(licenses){

        var lic = [];

        _.forEach(licenses, function(license) {
            var newLicense = {
                "License No": license.id ? license.id : "",
                "Software": license.slotType && license.slotType.software && license.slotType.software.name ? license.slotType.software.name : "",
                "Deploy": license.fleet && license.fleet.deploymentType ? license.fleet.deploymentType : "",
                "Fleet": license.fleet && license.fleet.fleetId ? license.fleet.fleetId : "",
                "Slot Type": license.slotType && license.slotType.slotType ? license.slotType.slotType : "",
                "Slots": license.slots ? license.slots : 0,
                "Slot Used": license.slotUsed ? license.slotUsed : 0,
                "Expires": license.expiryDate ? $filter('date')(license.expiryDate, 'MM-dd-yyyy') : "",
                "Sales Order": license.salesOrder ? license.salesOrder : "",
                "Prev Sales Order": license.previousSalesOrder ? license.previousSalesOrder : "",
                "Status": license.licenseStatus ? license.licenseStatus : "",
                "Charge Type": license.chargeType ? license.chargeType : "",
                "Software Version": license.softwareLicenses && license.softwareLicenses.softwareVersion ? license.softwareLicenses.softwareVersion : ""
            };

            lic.push(newLicense);
        });

        return lic;
    }
}])
.service('MapSalesRegionToExport', function() {

    this.convert = function(salesRegions){

        var saleRegion = [];

        _.forEach(salesRegions, function(salesRegion) {
            var newSalesRegion = {
                "Name": salesRegion.name ? salesRegion.name : "",
                "Sales Representative": salesRegion.salesRepresentative ? salesRegion.salesRepresentative : "",
                "Email": salesRegion.emails ? salesRegion.emails : ""
            };

            saleRegion.push(newSalesRegion);
        });

        return saleRegion;
    }
})
.service('MapDeviceToExport', ["$filter", function($filter) {

    this.convert = function(devices){

        var dev = [];

        _.forEach(devices, function(device) {
            var newDevice = {
                "Provider ID": device.providers && device.providers.id ? device.providers.id : "",
                "Serial Number": device.serialNumber ? device.serialNumber : "",
                "Device ID": device.deviceId ? device.deviceId : "",
                "Sim ID": device.simId ? device.simId : "",
                "Fleet": device.fleet ? device.fleet.fleetId : "",
                "Device Type": device.deviceType && device.deviceType.name ? device.deviceType.name : "",
                "Provider": device.providers && device.providers.name ? device.providers.name : "",
                "Sales Order": device.salesOrder ? device.salesOrder : "",
                "Expiration Date": device.expirationDate ? $filter('date')(device.expirationDate, 'MM-dd-yyyy') : "",
                "Provision Date": device.provisionDate ? $filter('date')(device.provisionDate, 'MM-dd-yyyy') : "",
                "Is Import": device.isImport ? device.isImport : "",
                "Status": device.status ? device.status : ""
            };

            dev.push(newDevice);
        });

        return dev;
    }
}])
.service('MapDeviceTypeToExport', ["$filter", function($filter) {

    this.convert = function(deviceTypes){

        var devType = [];

        _.forEach(deviceTypes, function(deviceType) {
            var newDeviceType = {
                "Name": deviceType.name ? deviceType.name : "",
                "Part Id": deviceType.partId ? deviceType.partId : "",
                "Last Synchronization Date": deviceType.lastSynchronizationDate ? $filter('date')(deviceType.lastSynchronizationDate, 'MM-dd-yyyy-HH-mm') : ""
            };

            devType.push(newDeviceType);
        });

        return devType;
    }
}])
.service('MapSlotTypeToExport', ["$filter", function($filter) {

    this.convert = function(slotTypes){

        var slotTyp = [];

        _.forEach(slotTypes, function(slotType) {
            var newSlotType = {
                "Slot Type": slotType.slotType ? slotType.slotType : "",
                "Software": slotType.software && slotType.software.name ? slotType.software.name : "",
                "Feature": slotType.feature ? slotType.feature : "",
                "Requires Data": slotType.requiresData ? slotType.requiresData : false,
                "Requires Slot": slotType.requiresSlot ? slotType.requiresSlot : false,
                "Requires Device": slotType.requiresDevice ? slotType.requiresDevice : false,
                "Requires Software Version": slotType.requiresSoftwareVersion ? slotType.requiresSoftwareVersion : false,
                "Software License": slotType.softwareLicense && slotType.softwareLicense.softwareVersion ? slotType.softwareLicense.softwareVersion : ""
            };

            slotTyp.push(newSlotType);
        });

        return slotTyp;
    }
}])
.service('MapProvidersToExport', function() {

    this.convert = function(providers){

        var prov = [];

        _.forEach(providers, function(provider) {
            var newProvider = {
                "Name": provider.name ? provider.name : ""
            };

            prov.push(newProvider);
        });

        return prov;
    }
})
.service('MapSoftwareToExport', function() {

    this.convert = function(softwares){

        var sw = [];

        _.forEach(softwares, function(software) {
            var newSoftware = {
                "Name": software.name ? software.name : "",
                "Cloud": software.cloud ? software.cloud : false,
                "Local": software.local ? software.local : false,
                "Software Group": software.softwareGroup && software.softwareGroup.name ? software.softwareGroup.name : ""
            };

            sw.push(newSoftware);
        });

        return sw;
    }
})
.service('MapSoftwareLicenseToExport', function() {

    this.convert = function(softwareLicenses){

        var swLicense = [];

        _.forEach(softwareLicenses, function(softwareLicense) {
            var newSoftwareLicense = {
                "Software Version": softwareLicense.softwareVersion ? softwareLicense.softwareVersion : "",
                "License Version": softwareLicense.licenseVersion ? softwareLicense.licenseVersion : "",
                "Software Group": softwareLicense.softwareGroup && softwareLicense.softwareGroup.name ? softwareLicense.softwareGroup.name : ""
            };

            swLicense.push(newSoftwareLicense);
        });

        return swLicense;
    }
})
.service('MapSettingToExport', function() {

    this.convert = function(settings){

        var set = [];

        _.forEach(settings, function(setting) {
            var newSetting = {
                "Name": setting.name ? setting.name : "",
                "Settings Type": setting.settingsTemplate && setting.settingsTemplate.typeEntitySettingsEnum ?
                                    setting.settingsTemplate.typeEntitySettingsEnum : "",
                "Software Group": setting.settingsTemplate && setting.settingsTemplate.softwareGroup && setting.settingsTemplate.softwareGroup.name ?
                                    setting.settingsTemplate.softwareGroup.name : "",
                "Device Type": setting.settingsTemplate && setting.settingsTemplate.deviceType && setting.settingsTemplate.deviceType.name ?
                                    setting.settingsTemplate.deviceType.name : ""
            };

            set.push(newSetting);
        });

        return set;
    }
})
.service('MapUserToExport', function() {

    this.convert = function(users){

        var u = [];

        _.forEach(users, function(user) {
            var newUser = {
                "Login": user.login ? user.login : "",
                "First name": user.firstName ? user.firstName : "",
                "Last name": user.lastName ? user.lastName : "",
                "Email": user.email ? user.email : "",
                "Roles": user.authorities ? user.authorities.toString() : "",
                "Status": user.activated ? user.activated : false
            };

            u.push(newUser);
        });

        return u;
    }
})
.service('MapAppResourceToExport', function() {

    this.convert = function(appResources){

        var appRes = [];

        _.forEach(appResources, function(appResource) {
            var newAppResources = {
                "Name": appResource.name ? appResource.name : "",
                "Url": appResource.url ? appResource.url : "",
                "Max Downloads": appResource.maxDownloads ? appResource.maxDownloads : "",
                "Resource Type": appResource.resourceTypeEnum ? appResource.resourceTypeEnum : "",
                "Restriction Download Type": appResource.restrictionDownloadTypeEnum ? appResource.restrictionDownloadTypeEnum : "",
                "Device Type": appResource.deviceType && appResource.deviceType.name ? appResource.deviceType.name : ""
            };

            appRes.push(newAppResources);
        });

        return appRes;
    }
})
.service('MapAppResourceUserProfileToExport', function() {

    this.convert = function(appResourceUserProfiles){

        var newAppResourceUserProfiles = [];

        _.forEach(appResourceUserProfiles, function(appResourceUserProfile) {
            var newAppResourceUserProfile = {
                "Name": appResourceUserProfile.name ? appResourceUserProfile.name : "",
                "Device Type": appResourceUserProfile.deviceType ? appResourceUserProfile.deviceType.name : ""
            };

            newAppResourceUserProfiles.push(newAppResourceUserProfile);
        });

        return newAppResourceUserProfiles;
    }
})
.service('MapDownloadResourceAuditToExport', ["$filter", function($filter) {

    this.convert = function(downloadResourceAudits){

        var newDownloadResourceAudits = [];

        _.forEach(downloadResourceAudits, function(downloadResourceAudit) {
            var newDownloadResourceAudit = {
                "Download Date": downloadResourceAudit.downloadDate ? $filter('date')(downloadResourceAudit.downloadDate, 'MM-dd-yyyy hh:mm:ss') : "",
                "Serial Number": downloadResourceAudit.serialNumber ? downloadResourceAudit.serialNumber : "",
                "Type Name": downloadResourceAudit.typeName ? downloadResourceAudit.typeName : "",
                "Resource Name": downloadResourceAudit.appResourceName ? downloadResourceAudit.appResourceName : "",
                "Resource Type": downloadResourceAudit.appResourceType ? downloadResourceAudit.appResourceType : "",
                "Status": downloadResourceAudit.status ? downloadResourceAudit.status : "",
                "Comments": downloadResourceAudit.comments ? downloadResourceAudit.comments : ""
            };

            newDownloadResourceAudits.push(newDownloadResourceAudit);
        });

        return newDownloadResourceAudits;
    }
}]);

'use strict';

angular.module('vmaxmanagerApp')
    .directive('export', ["ExportService", "$filter", "Server", "Customer", "Fleet", "License", "SalesRegion", "Device", "DeviceType", "SlotType", "Providers", "Software", "SoftwareLicense", "SettingsInstance", "User", "DownloadResourcesAudit", "MapServerToExport", "MapCustomerToExport", "MapFleetToExport", "MapLicenseToExport", "MapSalesRegionToExport", "MapDeviceToExport", "MapDeviceTypeToExport", "MapSlotTypeToExport", "MapProvidersToExport", "MapSoftwareToExport", "MapSoftwareLicenseToExport", "MapSettingToExport", "MapUserToExport", "AppResource", "AppResourceUserProfile", "MapAppResourceToExport", "MapAppResourceUserProfileToExport", "MapDownloadResourceAuditToExport", function (ExportService, $filter,
                                   Server, Customer, Fleet, License, SalesRegion, Device, DeviceType, SlotType, Providers, Software,
                                   SoftwareLicense, SettingsInstance, User, DownloadResourcesAudit, MapServerToExport, MapCustomerToExport, MapFleetToExport,
                                   MapLicenseToExport, MapSalesRegionToExport, MapDeviceToExport, MapDeviceTypeToExport, MapSlotTypeToExport,
                                   MapProvidersToExport, MapSoftwareToExport, MapSoftwareLicenseToExport, MapSettingToExport, MapUserToExport,
                                   AppResource, AppResourceUserProfile, MapAppResourceToExport, MapAppResourceUserProfileToExport,
                                   MapDownloadResourceAuditToExport) {
        return {
            restrict: 'E',
            scope: {
                table: '@table',
                filter: '=filter'
            },
            template: '<i class="glyphicon glyphicon-export export-element" ng-click="exportData()" rel="tooltip" title="Export to Excel"></i>',
            link: function postLink(scope, element, attrs, form) {

                scope.exportData = function(){

                    var date = $filter('date')(new Date(), 'MMddyyyyhhmmss');

                    switch(scope.table){
                        case "server":
                            Server.query({}, function(result, headers) {
                                var exportData = MapServerToExport.convert(result);
                                ExportService.setStyle("Server sheet", "Servers");
                                ExportService.exportToExcel("server-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "customer":
                            Customer.query({}, function(result, headers) {
                                var exportData = MapCustomerToExport.convert(result);
                                ExportService.setStyle("Customer sheet", "Customers");
                                ExportService.exportToExcel("customer-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "fleet":
                            Fleet.query({}, function(result, headers) {
                                var exportData = MapFleetToExport.convert(result);
                                ExportService.setStyle("Fleet sheet", "Fleets");
                                ExportService.exportToExcel("fleet-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "license":
                            License.query({}, function(result, headers) {
                                var exportData = MapLicenseToExport.convert(result);
                                ExportService.setStyle("License sheet", "Licenses");
                                ExportService.exportToExcel("license-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "salesRegion":
                            SalesRegion.query({}, function(result, headers) {
                                var exportData = MapSalesRegionToExport.convert(result);
                                ExportService.setStyle("Sales Region sheet", "Sales Regions");
                                ExportService.exportToExcel("salesRegion-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "device":
                            Device.query({}, function(result, headers) {
                                var exportData = MapDeviceToExport.convert(result);
                                ExportService.setStyle("Device sheet", "Devices");
                                ExportService.exportToExcel("device-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "deviceType":
                            DeviceType.query({}, function(result, headers) {
                                var exportData = MapDeviceTypeToExport.convert(result);
                                ExportService.setStyle("Device Type sheet", "Device Types");
                                ExportService.exportToExcel("deviceType-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "slotType":
                            SlotType.query({}, function(result, headers) {
                                var exportData = MapSlotTypeToExport.convert(result);
                                ExportService.setStyle("Slot Type sheet", "Slot Types");
                                ExportService.exportToExcel("slotType-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "providers":
                            Providers.query({}, function(result, headers) {
                                var exportData = MapProvidersToExport.convert(result);
                                ExportService.setStyle("Providers sheet", "Providers");
                                ExportService.exportToExcel("provider-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "software":
                            Software.query({}, function(result, headers) {
                                var exportData = MapSoftwareToExport.convert(result);
                                ExportService.setStyle("Software sheet", "Software");
                                ExportService.exportToExcel("software-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "softwareLicense":
                            SoftwareLicense.query({}, function(result, headers) {
                                var exportData = MapSoftwareLicenseToExport.convert(result);
                                ExportService.setStyle("Software License sheet", "Software License");
                                ExportService.exportToExcel("softwareLicense-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "setting":
                            SettingsInstance.query({}, function(result, headers) {
                                var exportData = MapSettingToExport.convert(result);
                                ExportService.setStyle("Settings sheet", "Settings");
                                ExportService.exportToExcel("settings-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "user":
                            User.query({}, function(result, headers) {
                                var exportData = MapUserToExport.convert(result);
                                ExportService.setStyle("User sheet", "Users");
                                ExportService.exportToExcel("user-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "appResource":
                            AppResource.query({}, function(result, headers) {
                                var exportData = MapAppResourceToExport.convert(result);
                                ExportService.setStyle("App Resource sheet", "App Resource");
                                ExportService.exportToExcel("appResource-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "appResourceUserProfile":
                            AppResourceUserProfile.query({}, function(result, headers) {
                                var exportData = MapAppResourceUserProfileToExport.convert(result);
                                ExportService.setStyle("App Resource User Profile sheet", "App Resource User Profile");
                                ExportService.exportToExcel("appResourceUserProfile-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "downloadResourceAudit":
                            DownloadResourcesAudit.query({}, function(result, headers) {
                                var exportData = MapDownloadResourceAuditToExport.convert(result);
                                ExportService.setStyle("Download Resource Audit sheet", "Download Resource Audit");
                                ExportService.exportToExcel("downloadResourceAudit-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                    }
                };
            }
        }
    }]);


angular.module('seon.queryBuilder', ['queryBuilder/tpls/filtersContainer.html', 'queryBuilder/tpls/filters.html']);

angular.module('seon.queryBuilder').config(["$translateProvider", function ($translateProvider) {
    $translateProvider.useMissingTranslationHandler('undefHandler');
}]);

angular.module('seon.queryBuilder').factory('undefHandler', function() {
    return function (translationID, uses) {
        return translationID.replace(/^(filters\.columns\.)/,"") .replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); }).replace('.', ' ');
    }
});

angular.module('seon.queryBuilder').directive('queryBuilderContainer', QueryBuilderContainer);
QueryBuilderContainer.$inject = ['$translate', '$sce', '$uibModal', '$window', '$timeout'];

function QueryBuilderContainer($translate, $sce, $uibModal, $window, $timeout) {
    return {
        restrict: 'E',
        scope: {
            fields: '=',
            advancedQuery: '=',
            queryObject: '=',
            filterCallback: '=',
            config: '=?',
            initialRules: '=?'
        },
        templateUrl: function(ele, attrs) {
            return attrs.templateUrl || 'queryBuilder/tpls/filtersContainer.html';
        },
        link: function(scope, elem, attrs) {
            if (!scope.config) {scope.config = {};}


            if (scope.initialRules) {
                scope.group = angular.copy(scope.initialRules);
            }
            else {
                scope.group = {
                    'operator' : 'and',
                    'rules' : [{condition: '', field:'', data:''}]
                };
            }

            scope.$watch('fields', function(newVal) {
                if (newVal) {
                    angular.forEach(scope.group.rules, function(rule) {
                        if (!rule.hasOwnProperty('group')) {
                            var field;
                            for (var i=0; i<scope.fields.length; i++) {
                                if (scope.fields[i].name === rule.field.name) {
                                    field = scope.fields[i];
                                    break;
                                }
                            }
                            rule.field = field;
                        }
                    });
                }
            });

            scope.queryStr = '';
            scope.showBuilder = false;
            scope.showQueryStr = true;
            scope.advMode = scope.advancedQuery;


            scope.toggleGroups = function(show) {
                var hasGroup = false;
                if (scope.group && scope.group.rules) {
                    for (var i = 0; i<scope.group.rules.length; i++) {
                        if (scope.group.rules[i].hasOwnProperty('group')) {
                            hasGroup = true;
                            break;
                        }
                    }
                }
                if (!show && hasGroup) {
                    scope.modalInstance = $uibModal.open({
                        animation: true,
                        keyboard: true,
                        templateUrl: 'templates/ConfirmBasic.html',
                        controller: ["$scope", "$uibModalInstance", "config", function ConfirmBasicCtrl($scope, $uibModalInstance, config) {
                            var vm = this;
                            vm.config = config;
                            vm.ok = function() {$uibModalInstance.close('ok'); }
                            vm.cancel = function() {$uibModalInstance.dismiss('cancel'); };
                        }],
                        bindToController: true,
                        controllerAs: 'vm',
                        resolve: {
                            config: function() {return scope.config;}
                        },
                        size: 'sm'
                    });

                    scope.modalInstance.result.then(function(status) {
                        removeAllGroups();
                    }, function(reason) {
                        scope.advMode = true;
                    });
                }
            };

            scope.modalOk = function() {scope.modalInstance.close('ok'); }
            scope.modalCancel = function() {scope.modalInstance.dismiss('cancel'); };

            function removeAllGroups() {
                var newGroup = {'operator' : scope.group.operator, 'rules' : []};
                angular.forEach(scope.group.rules, function(val, key) {
                    if (!val.hasOwnProperty('group')) {
                        newGroup.rules.push(val);
                    }
                });
                scope.group = newGroup;
            }

            scope.$watch('group', function(newVal, oldVal) {
                scope.queryObject = scope.cleanGroup(newVal);
                scope.queryStr = $sce.trustAsHtml(setQueryString(scope.queryObject).slice(1, -1));
            }, true);

            scope.cleanGroup = function(group) {
                var resp = {operator: group.operator, rules: []};
                angular.forEach(group.rules, function(e) {
                    // This change was applied on vMaxManager to resolve the bug VMM-1054.
                    if (e.hasOwnProperty('field')) {
                        if (_.isEmpty(e.data)) {
                            if (!_.isEmpty(e.field)) {
                                if (e.field.type == 'date' ||  e.field.type == 'dateTime') {
                                    e.pickerDate = undefined;
                                }
                            }
                        }
                    }

                    if (e.hasOwnProperty('group')) {
                        var newGrp = {group: scope.cleanGroup(e.group)};
                        if (newGrp.group.rules.length > 0) {
                            resp.rules.push(newGrp);
                        }
                    }
                    else {
                        if (e.field && e.condition && (e.data || e.data === 0)) {
                            resp.rules.push({field: e.field.name, condition:e.condition, data:e.data});
                        }
                    }
                });
                return resp;
            }

            function setQueryString(group) {
                if (!group) return "";
                for (var str = "(", i = 0; i < group.rules.length; i++) {
                    i > 0 && (str += " " + ('<b>' + group.operator.toUpperCase() + '</b>') + " ");
                    str += group.rules[i].group ?
                    setQueryString(group.rules[i].group) :
                    group.rules[i].field + " " + ('<b>' + htmlEntities(group.rules[i].condition) + '</b>') + " " + group.rules[i].data;
                }
                return str + ")";
            }

            function htmlEntities(str) {
                return String(str).replace(/</g, '&lt;').replace(/>/g, '&gt;');
            }

            function cleanGroupAndQueryStr(group) {
                var grp = {operator: group.operator, rules: []};
                var str = "(";

                angular.forEach(group.rules, function(e, i) {
                    i > 0 && (str += " " + group.operator + " ");

                    if (e.hasOwnProperty('group')) {
                        var inner = cleanGroupAndQueryStr(e.group);
                        var newGrp = {group: inner.group};
                        if (newGrp.group.rules.length > 0) {
                            str += inner.string;
                            grp.rules.push(newGrp);
                        }
                    }
                    else {
                        if (e.field && e.condition && e.data) {
                            grp.rules.push({field: e.field.name, condition:e.condition, data:e.data});
                            str += e.field.name + " " + htmlEntities(e.condition) + " " + e.data;
                        }
                    }
                })

                return {group: grp, string: (str + ")")};
            }


            scope.clearFilters = function() {
                scope.group = {
                    'operator' : 'and',
                    'rules' : [{condition: '', field:'', data:''}]
                }
                $timeout(function() {
                    scope.filterCallback();
                });
            }
        }
    };
}

angular.module('seon.queryBuilder').directive('queryBuilder', QueryBuilder);
QueryBuilder.$inject = ['$compile', '$translate', '$timeout'];

function QueryBuilder($compile, $translate, $timeout) {
    return {
        restrict: 'E',
        scope: {
            fields: '=fields',
            group: '=',
            advancedQuery: '=',
            config: '=?'
        },
        templateUrl: function(ele, attrs) {
            return attrs.templateUrl || 'queryBuilder/tpls/filters.html';
        },
        compile: function (element, attrs) {
            var content, directive;
            content = element.contents().remove();
            return function (scope, element, attrs) {

                scope.highlightCondition = function(ev) {
                    angular.element(ev.currentTarget.closest('div')).toggleClass('highlighted', true);
                }

                scope.unhighlightCondition = function(ev) {
                    angular.element(ev.currentTarget.closest('div')).toggleClass('highlighted', false);
                }

                scope.collapseGroup = false;
                scope.toggleGroup = function() {
                    scope.collapseGroup = !scope.collapseGroup;
                    scope.collapseGroup && (scope.innerExpression = computed(scope.group));
                }

                function computed(group) {
                    if (!group) return "";
                    for (var str = "(", i = 0; i < group.rules.length; i++) {
                        var rule = group.rules[i];
                        if (!rule.group && (!rule.field.name || !rule.condition || !rule.data)) { continue;}
                        i > 0 && (str += " " + group.operator + " ");
                        str += group.rules[i].group ?
                        computed(group.rules[i].group) :
                        rule.field.name + " " + (rule.condition) + " " + rule.data;
                    }
                    return str + ")";
                }


                //calendar stuff
                scope.dateFormat = "MM/dd/yyyy";
                scope.datetimeFormat = "MM/dd/yyyy HH:mm:ss";
                // scope.datepickerOpts = { maxDate: moment() }
                // scope.altInputFormats = ["yyyy-MM-dd HH:mm:ss", "MM/dd/yyyy HH:mm:ss", 'yyyy-MM-dd', 'yyyy/MM/dd'];
                // scope.calendarOpened = false;
                // scope.format = scope.altInputFormats[1];


                scope.$watch('advancedQuery', function(newVal) {
                    scope.showRemGrp = scope.advancedQuery && scope.nestedLevel > 0;
                    scope.showAddGrp = scope.advancedQuery && scope.nestedLevel < 5;
                }, true);


                scope.validateString = function (rule) {
                    if (rule.data && rule.data.length > 100) {
                        rule.data = rule.data.substring(0, 100);
                    }
                }

                scope.checkNumbers = function(ev) {
                    if(isNaN(String.fromCharCode(ev.keyCode))){
                        ev.preventDefault();
                    }
                }

                scope.validateNumber = function (rule) {
                    if (rule.data && rule.data.toString().length > 11) {
                        rule.data = Number(rule.data.toString().substring(0, 11));
                    }
                }

                scope.updateData = function(rule) {
                    var d = rule.pickerDate;
                    if (d && rule.field.type == 'date') {
                        rule.data = moment(d).format("YYYY-MM-DD");
                    }
                    else if (d && rule.field.type == 'dateTime') {
                        rule.data = moment.utc(d).format("YYYY-MM-DDTHH:mm:ss[Z]");
                    }
                    else {
                        rule.data = '';
                        rule.pickerDate = undefined;
                    }
                }


                //vars to show/hide elements
                scope.isChildFiltersDirective = ("isChildFiltersDirective" in scope.$parent);
                scope.nestedLevel = scope.isChildFiltersDirective?  (scope.$parent.nestedLevel + 1) : 0;
                scope.showRemGrp = scope.nestedLevel > 0;
                scope.showAddGrp = scope.advancedQuery && scope.nestedLevel < 5;
                scope.minRules = scope.nestedLevel == 0 ? 1 : 2;
                scope.maxRules = 15;

                scope.showRemRule = function() {
                    var r = scope.group.rules.filter(function(v) {
                            return !v.hasOwnProperty('group');
                    });
                    return (r.length > scope.minRules);
                }


                scope.operators = [
                    { name: 'All', value: 'and', locale: 'filters.all' },
                    { name: 'Any', value: 'or' , locale: 'filters.any' }
                ];

                //conditions vars
                var eq = {name: 'equals',       value: '=',    locale: 'filters.equal'      };
                var ne = {name: 'not equal',    value: '!=',   locale: 'filters.notEqual'   };
                var gt = {name: 'greater than', value: '>',    locale: 'filters.greaterThan'};
                var lt = {name: 'less than',    value: '<',    locale: 'filters.lessThan'   };
                var lk = {name: 'contains',     value: 'like', locale: 'filters.contains'   };
                scope.conditions = {
                    "text": [eq, ne, lk],
                    "boolean": [eq, ne],
                    "number": [eq, ne, gt, lt],
                    "date" : [eq, ne, gt, lt],
                    "dateTime" : [eq, ne, gt, lt]
                }

                scope.setConditions = function (r, old) {
                    (old.field && r.field.type != old.field.type) && (r.data = '');
                    (r.field.type == 'boolean') && (r.condition = '=');
                    (r.field.type == 'date') && (r.calendarOpened = false);
                }

                scope.addCondition = function () {
                    if (!scope.group.rules.length >= scope.maxRules) {
                        return;
                    }
                    scope.group.rules.push({
                        condition: '',
                        field: '',
                        data: ''
                    });
                };

                scope.removeCondition = function (index) {
                    if (!scope.showRemRule) {
                        return;
                    }
                    scope.group.rules.splice(index, 1);
                };

                scope.addGroup = function () {
                    if (!scope.group.rules.length >= scope.maxRules) {
                        return;
                    }
                    scope.group.rules.push({
                        group: {
                            operator: 'and',
                            rules: [{condition: '', field:'', data:''}, {condition: '', field:'', data:''}]
                        }
                    });
                };

                scope.removeGroup = function () {
                    "group" in scope.$parent && scope.$parent.group.rules.splice(scope.$parent.$index, 1);
                };

                directive || (directive = $compile(content));

                element.append(directive(scope, function ($compile) {
                    return $compile;
                }));
            }
        }
    }
};

angular.module("queryBuilder/tpls/filtersContainer.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("queryBuilder/tpls/filtersContainer.html",
    "<div ng-show=\"queryStr || showBuilder\" class=\"filterString\">\n" +
    "	<strong>\n" +
    "		<i class=\"glyphicon glyphicon-tasks\"></i>\n" +
    "		<span translate=\"filters.filters\">Filters: </span>\n" +
    "	</strong>\n" +
    "	<span ng-bind-html=\"queryStr\"></span>\n" +
    "</div>\n" +
    "\n" +
    "<button class=\"btn btnShowFilterGroups btn-info\" ng-class=\"config.btnClass\" ng-click=\"showBuilder = !showBuilder;\">\n" +
    "	<span class=\"showHideFilters\">\n" +
    "        {{(showBuilder? 'filters.hideFilters' : ( queryObject.rules.length ? 'filters.filterBy' : 'filters.showFilters' )) | translate}}\n" +
    "        <i class=\"glyphicon\" ng-class=\"showBuilder? 'glyphicon-triangle-top' : 'glyphicon-triangle-bottom'\"></i>\n" +
    "    </span>\n" +
    "</button>\n" +
    "\n" +
    "<label class=\"advanceModeLabel\" ng-show=\"advancedQuery && showBuilder\">\n" +
    "	<span translate=\"filters.advMode\">Advanced Mode: </span>\n" +
    "	<input type=\"checkbox\" ng-model=\"advMode\" ng-change=\"toggleGroups(advMode)\">\n" +
    "</label>\n" +
    "\n" +
    "<div class=\"filterButtons\" ng-show=\"showBuilder && group.rules.length > 6\">\n" +
    "	<button class=\"btn btn-danger\" ng-class=\"config.btnClass\" ng-click=\"clearFilters()\" ng-disabled=\"queryObject.rules.length < 1\">\n" +
    "		<span translate=\"filters.clear\">Clear</span>\n" +
    "	</button>\n" +
    "	<button class=\"btn btn-primary\" ng-class=\"config.btnClass\" ng-click=\"filterCallback()\" ng-disabled=\"queryObject.rules.length < 1\" >\n" +
    "		<span translate=\"filters.filter\">Filter</span>\n" +
    "	</button>\n" +
    "</div>\n" +
    "\n" +
    "<div>\n" +
    "	<query-builder class=\"col-xs-12 filterGroupContainer\" group=\"group\" fields=\"fields\" advanced-query=\"advMode\" config=\"config\" ng-show=\"showBuilder\"></query-builder>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"filterButtons\" ng-show=\"showBuilder\">\n" +
    "	<button class=\"btn btn-danger\" ng-class=\"config.btnClass\" ng-click=\"clearFilters()\" ng-disabled=\"queryObject.rules.length < 1\">\n" +
    "		<span translate=\"filters.clear\">Clear</span>\n" +
    "	</button>\n" +
    "	<button class=\"btn btn-primary\" ng-class=\"config.btnClass\" ng-click=\"filterCallback()\" ng-disabled=\"queryObject.rules.length < 1\" >\n" +
    "		<span translate=\"filters.filter\">Filter</span>\n" +
    "	</button>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"templates/ConfirmBasic.html\">\n" +
    "	<div class=\"modal-body\">\n" +
    "		<p translate=\"filters.deleteGroupsMsg\">Switching to Basic Mode with delete all existing groups</p>\n" +
    "	</div>\n" +
    "	<div class=\"modal-footer\">\n" +
    "		<button type=\"button\" class=\"btn btn-danger\" ng-class=\"vm.config.btnClass\" data-dismiss=\"modal\" ng-click=\"vm.cancel()\">\n" +
    "			<span class=\"glyphicon glyphicon-ban-circle\"></span>&nbsp;<span translate=\"filters.cancel\">Cancel</span>\n" +
    "		</button>\n" +
    "		<button type=\"submit\" class=\"btn btn-primary\" ng-class=\"vm.config.btnClass\" ng-click=\"vm.ok()\">\n" +
    "			<span class=\"glyphicon glyphicon-remove-circle\"></span>&nbsp;<span translate=\"filters.ok\">OK</span>\n" +
    "		</button>\n" +
    "	</div>\n" +
    "\n" +
    "</script>\n" +
    "");
}]);

angular.module("queryBuilder/tpls/filters.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("queryBuilder/tpls/filters.html",
    "<div class=\"alert-group\">\n" +
    "	<div class=\"form-inline\">\n" +
    "		<div ng-hide=\"collapseGroup\">\n" +
    "<!-- 		<button class=\"btn btn-sm btn-info\" style=\"\" ng-click=\"switch(true)\" ng-show=\"showRemGrp\" ng-disabled=\"$first\">\n" +
    "			<span class=\"glyphicon glyphicon-chevron-up\"></span>\n" +
    "		</button>\n" +
    "		<button class=\"btn btn-sm btn-info\" style=\"\" ng-click=\"switch(false)\" ng-show=\"showRemGrp\" ng-disabled=\"$last\">\n" +
    "			<span class=\"glyphicon glyphicon-chevron-down\"></span>\n" +
    "		</button> -->\n" +
    "			<div class=\"displayResultsContent\" ng-show=\"group.rules.length>1\" style=\"margin-bottom:4px;\">\n" +
    "				<span translate=\"filters.rulesLabel1\" ng-if=\"!isChildFiltersDirective\">Display results matching </span>\n" +
    "				<select ng-options=\"o.value as o.locale | translate for o in operators\" ng-model=\"group.operator\" class=\"form-control input-sm\"></select>\n" +
    "				<span translate=\"filters.rulesLabel2\"> of the following expressions</span>\n" +
    "			</div>\n" +
    "			<div class=\"displayInlineButtons\">\n" +
    "				<button ng-click=\"addCondition()\" class=\"btn btn-sm btn-info\" ng-class=\"config.btnClass\" ng-show=\"group.rules.length < maxRules\">\n" +
    "					<span class=\"glyphicon glyphicon-plus-sign\"></span> {{'filters.addCondition' | translate}}\n" +
    "				</button>\n" +
    "				<button ng-click=\"addGroup()\" class=\"btn btn-sm btn-primary\" ng-class=\"config.btnClass\"  ng-show=\"showAddGrp && group.rules.length < maxRules\">\n" +
    "					<span class=\"glyphicon glyphicon-plus-sign\"></span> {{'filters.addGroup' | translate}}\n" +
    "				</button>\n" +
    "				<button ng-click=\"removeGroup()\" class=\"btn btn-sm btn-danger\" ng-class=\"config.btnClass\" ng-show=\"showRemGrp\">\n" +
    "					<span class=\"glyphicon glyphicon-remove\"></span> {{'filters.removeGroup' | translate}} \n" +
    "				</button>\n" +
    "				<button ng-click=\"toggleGroup()\" class=\"btn btn-sm\" ng-class=\"config.btnClass\" ng-show=\"isChildFiltersDirective\">\n" +
    "					<span class=\"glyphicon glyphicon-triangle-top\"></span>\n" +
    "				</button>\n" +
    "	        </div>\n" +
    "	    </div>\n" +
    "	    <div class=\"filterCollapseGroup\" ng-show=\"collapseGroup\">\n" +
    "	    	<span><i class=\"glyphicon glyphicon-tasks\"></i> {{innerExpression}} </span>\n" +
    "	    	<button ng-click=\"toggleGroup()\" class=\"btn btn-sm expand-group-btn\" ng-class=\"config.btnClass\">\n" +
    "	    		<span class=\"glyphicon glyphicon-triangle-bottom\"></span>\n" +
    "	    	</button>\n" +
    "	    </div>\n" +
    "	</div>\n" +
    "	<div class=\"group-conditions\" ng-class=\"collapseGroup? 'collapsed' : ''\">\n" +
    "		<div ng-repeat=\"rule in group.rules | orderBy:'index'\" class=\"condition\">\n" +
    "\n" +
    "\n" +
    "			<div ng-switch=\"rule.hasOwnProperty('group')\">\n" +
    "				<div ng-switch-when=\"true\">\n" +
    "					<query-builder group=\"rule.group\" fields=\"fields\" advanced-query=\"true\" config=\"config\"></query-builder>\n" +
    "				</div>\n" +
    "				<div ng-switch-default=\"ng-switch-default\">\n" +
    "					<div class=\"form-inline hasCondition\">\n" +
    "<!-- 						<button class=\"btn btn-sm btn-info\" style=\"\" ng-click=\"switch(true)\" ng-disabled=\"$first\">\n" +
    "							<span class=\"glyphicon glyphicon-chevron-up\"></span>\n" +
    "						</button>\n" +
    "						<button class=\"btn btn-sm btn-info\" style=\"\" ng-click=\"switch(false)\" ng-disabled=\"$last\">\n" +
    "							<span class=\"glyphicon glyphicon-chevron-down\"></span>\n" +
    "						</button> -->\n" +
    "\n" +
    "						<p  class=\"input-group\">\n" +
    "							<select ng-options=\"t as (('filters.columns.' + t.name) | translate) for t in fields\" class=\"form-control fields-select\"\n" +
    "							        ng-model=\"rule.field\" ng-change=\"setConditions(rule, {{rule}})\"  ng-focus=\"highlightCondition($event)\" ng-blur=\"unhighlightCondition($event)\"></select>\n" +
    "						</p>\n" +
    "						<p  class=\"input-group\">\n" +
    "							<select ng-options=\"c.value as c.locale | translate for c in conditions[rule.field.type]\" class=\"form-control conditions-select\" ng-disabled=\"!rule.field || (rule.field.type == 'boolean')\"\n" +
    "							        ng-model=\"rule.condition\" ng-focus=\"highlightCondition($event)\" ng-blur=\"unhighlightCondition($event)\"></select>\n" +
    "						</p>\n" +
    "\n" +
    "						<p ng-if=\"rule.field.type == 'boolean'\" class=\"input-group\">\n" +
    "							<select class=\"form-control conditions-select\" ng-model=\"rule.data\"ng-focus=\"highlightCondition($event)\" ng-blur=\"unhighlightCondition($event)\">\n" +
    "								<option value=\"true\">{{'filters.true' | translate}}</option>\n" +
    "								<option value=\"false\">{{'filters.false' | translate}}</option>\n" +
    "							</select>\n" +
    "						</p>\n" +
    "\n" +
    "						<p ng-if=\"rule.field.type == 'text'\" class=\"input-group\">\n" +
    "							<input  type=\"text\" ng-model=\"rule.data\" class=\"form-control data-input\" ng-focus=\"highlightCondition($event)\" ng-blur=\"unhighlightCondition($event)\" ng-change=\"validateString(rule)\"/>\n" +
    "						</p>\n" +
    "						<p ng-if=\"rule.field.type == 'number'\" class=\"input-group\">\n" +
    "							<input  type=\"number\" ng-model=\"rule.data\" class=\"form-control data-input\" ng-focus=\"highlightCondition($event)\" ng-blur=\"unhighlightCondition($event)\" ng-keypress=\"checkNumbers($event)\" ng-change=\"validateNumber(rule)\"/>\n" +
    "						</p>\n" +
    "						<p ng-if=\"rule.field.type == 'date'\" class=\"input-group\">\n" +
    "						    <input  type=\"text\" class=\"form-control data-input\" uib-datepicker-popup=\"{{dateFormat}}\" ng-model=\"rule.pickerDate\"\n" +
    "						            is-open=\"rule.calendarOpened\" datepicker-options=\"datepickerOpts\" \n" +
    "						            close-text=\"{{'reports.notificationAudit.close' | translate}}\"  clear-text=\"{{'reports.notificationAudit.clean' | translate}}\" \n" +
    "						            current-text=\"{{'reports.notificationAudit.today' | translate}}\" show-button-bar=\"false\"\n" +
    "						            ng-focus=\"highlightCondition($event)\" ng-blur=\"unhighlightCondition($event)\" ng-change=\"updateData(rule)\"/>\n" +
    "						    <span class=\"input-group-btn\">\n" +
    "						        <button type=\"button\" class=\"btn btn-default\" ng-class=\"config.btnClass\" ng-click=\"rule.calendarOpened = !rule.calendarOpened\"><i class=\"glyphicon glyphicon-calendar\"></i></button>\n" +
    "						    </span>\n" +
    "						</p>\n" +
    "						<p ng-if=\"rule.field.type == 'dateTime'\" class=\"input-group\">\n" +
    "						    <input  type=\"text\" class=\"form-control data-input\" uib-datepicker-popup=\"{{datetimeFormat}}\" ng-model=\"rule.pickerDate\"\n" +
    "						            is-open=\"rule.calendarOpened\" datepicker-options=\"datepickerOpts\" \n" +
    "						            close-text=\"{{'reports.notificationAudit.close' | translate}}\"  clear-text=\"{{'reports.notificationAudit.clean' | translate}}\" \n" +
    "						            current-text=\"{{'reports.notificationAudit.today' | translate}}\" show-button-bar=\"false\"\n" +
    "						            ng-focus=\"highlightCondition($event)\" ng-blur=\"unhighlightCondition($event)\" ng-change=\"updateData(rule)\"/>\n" +
    "						    <span class=\"input-group-btn\">\n" +
    "						        <button type=\"button\" class=\"btn btn-default\" ng-class=\"config.btnClass\" ng-click=\"rule.calendarOpened = !rule.calendarOpened\"><i class=\"glyphicon glyphicon-calendar\"></i></button>\n" +
    "						    </span>\n" +
    "						</p>\n" +
    "\n" +
    "\n" +
    "						<button style=\"margin-left: 5px\" ng-click=\"removeCondition($index)\" class=\"btn btn-sm btn-danger\"  ng-class=\"config.btnClass\"ng-show=\"showRemRule()\">\n" +
    "							<span class=\"glyphicon glyphicon-remove\"></span>\n" +
    "						</button>\n" +
    "\n" +
    "\n" +
    "\n" +
    "					</div>\n" +
    "				</div>\n" +
    "			</div>\n" +
    "		</div>\n" +
    "	</div>\n" +
    "</div>");
}]);

'use strict';

angular.module('seon.settingsConfigurator', ['seonsm/settingsConfigurator.html']);

angular.module('seon.settingsConfigurator').filter('highlight', ["$sce", function($sce) {
    return function(text, phrase) {
        if (phrase && text) text = text.replace(new RegExp('('+phrase+')', 'gi'), '<span class="setting-highlight">$1</span>')
            return $sce.trustAsHtml(text)
    }
}]);

angular.module('seon.settingsConfigurator').directive('urlExists', ["$http", function($http) {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function(scope, ele, attr, ctrl) {

			function checkUrl(ngModelValue) {
				console.log(ngModelValue)
				ctrl.$setValidity('urlExistsValidator', true);

				$http.get(ngModelValue).then(function successCallback(response) {
					console.log('SUCCESS', response)
				}, function errorCallback(response) {
					console.log('ERROR', response)
				    ctrl.$setValidity('urlExists', false);
				});

				return ngModelValue;
			}

			ctrl.$parsers.unshift(checkUrl);
		}
	}
}]);

angular.module('seon.settingsConfigurator').directive('settingsConfigurator', settingsConfigurator);
settingsConfigurator.$inject = ['$http'];


function settingsConfigurator($http) {
	return {
		restrict: 'E',
		scope: {
			json: '=',
			config: '=',
			endpointErrorCallback: '&',
            readonly: '=?',
            notallowedcharacters: '=?'
		},
		templateUrl: function(element, attrs) {
		  return attrs.templateUrl || 'seonsm/settingsConfigurator.html';
		},
		link: function postLink(scope, element, attrs) {
			var layouts = ['wide', 'narrow']

			if (!scope.config) {scope.config = {};}

			if (!scope.config.layout || layouts.indexOf(scope.config.layout) === -1) {
				scope.config.layout = 'wide';
			}

			scope.urlPattern = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
			scope.ipPattern = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;


			scope.loadEndpoint = function(field) {
				console.log('loading endpoint', field);
				$http.get(field.url).then(function successCallback(response) {
					scope.endpoints[(field.id)] = response.data;
				}, function errorCallback(response) {
					console.log('ERROR', response);
					scope.endpoints[(field.id)] = [];
					if (scope.config.errorMessageCallback) {
						scope.config.errorMessageCallback("Unable to contact endpoint: '" + field.url + "'");
					}
				});
			}

			scope.changeSection = function(section) {
				scope.selectedSection = section;
			};

			scope.collapseSection = function(section) {
				section.collapsed = !(section.collapsed === true);
			};


			scope.validateString = function (field) {
				var maxLen = field['max-length']? field['max-length'] : 254;
				if (field.data && field.data.length > maxLen) {
					field.data = field.data.substring(0, maxLen);
				}
			}

			scope.checkNumbers = function(ev) {
				var key = ev.keyCode || ev.charCode;
				if(isNaN(String.fromCharCode(key))
					      || (String.fromCharCode(key) == "-" && field.data.toString().indexOf("-") >= -1) ) {
					ev.preventDefault();
				}
			}

			scope.validateNumber = function (field) {
				var maxValue = field.max? parseInt(field.max): 999999;
				var minValue = field.min? parseInt(field.min): -999999;
				var maxLength = maxValue.toString().length;
				if (field.data && field.data.toString().length > maxLength) {
					field.data = Number(field.data.toString().substring(0, maxLength));
				}
				if (field.max && field.data && parseInt(field.data) > maxValue) {
					field.data = maxValue;
				}
				if (field.max && field.data && parseInt(field.data) < minValue) {
					field.data = minValue;
				}
			}

			scope.checkDecimalNumbers = function(ev, field) {
				var key = ev.keyCode || ev.charCode;
				if(isNaN(String.fromCharCode(key))
					      || (String.fromCharCode(key) == "." && field.data.toString().indexOf(".") >= -1)
					      || (String.fromCharCode(key) == "-" && field.data.toString().indexOf("-") >= -1) ) {
					ev.preventDefault();
				}
			}

			scope.validateDecimalNumber = function (field) {
				var maxValue = field.max? parseFloat(field.max): 999999;
				var minValue = field.min? parseFloat(field.min): -999999;
				var maxLen = 25;
				if (field.data && field.data.toString().length > maxLen) {
					field.data = Number(field.data.toString().substring(0, maxLen));
				}
				if (field.max && field.data && parseFloat(field.data) > maxValue) {
					field.data = maxValue;
				}
				if (field.max && field.data && parseFloat(field.data) < minValue) {
					field.data = minValue;
				}
			}

			scope.preventEnter = function(event) {
				var key = event.keyCode || event.charCode;
				if(key == 13) {
					event.preventDefault();
					return false;
				}
			};

			scope.$watch('json', function(newVal, oldVal) {
				if (newVal) {
					scope.json = newVal;
					newVal && (scope.selectedSection = scope.json[0]);
					scope.search = '';
					angular.forEach(scope.json, function(section) {
						section.resultFound = false;
						angular.forEach(section.fields, function(field) {
							field.matchesSearch = false;
						});
					});
				}
			});

			scope.$watch('config.layout', function(newVal) {
				console.log(newVal);
				scope.config.layout = (layouts.indexOf(newVal) === -1? 'wide' : newVal);
			});


			scope.$watch('search', function(s) {
				scope.endpoints = {};
				if (s !== '' && s !== undefined) {
					var str = s.toLowerCase();
					angular.forEach(scope.json, function(section, idx) {
						section.resultFound = false;
						angular.forEach(section.fields, function(field, i) {
							field.matchesSearch = (field.label.toLowerCase().indexOf(str) > -1 || field.tooltip.toLowerCase().indexOf(str) > -1);
							section.resultFound = (section.resultFound || field.matchesSearch);
						});
					});
				}
				else {
					angular.forEach(scope.json, function(section, idx) {
						section.resultFound = false;
					});
				}
			});

			scope.translatedString = function(str) {
				if (!str || str.length > 100 || !scope.config || !scope.config.translations || !scope.config.translations[str] ) { return str; }
				return scope.config.translations[str];
			}


			scope.timezones = [];
			_.forEach( moment.tz.names(), function(timezone) {
			    var timezoneId =  timezone;
			    var zone = moment.tz(timezone).format("Z");
			    var timezoneDescription = "(GTM" + zone + ") - " + timezoneId;
			    scope.timezones.push({name: timezoneId, description: timezoneDescription});
			});
            
            scope.checkReadOnly = function(field) {
                var isReadOnlyField = false;
				if (scope.readonly !== '' && scope.readonly !== undefined && scope.readonly !== null) {
                    if (scope.readonly == true) {
                        isReadOnlyField = true;
                    }   
                }
                if (field.isreadonly !== '' && field.isreadonly !== undefined && field.isreadonly !== null && isReadOnlyField === false) {
                    if (field.isreadonly == true) {
                        isReadOnlyField = true;
                    }   
                }
                return isReadOnlyField;
			};
            
            scope.checkValidCharacters = function(ev) {
                var isNotAllowedCharacter = false;
                if (scope.notallowedcharacters !== '' && scope.notallowedcharacters !== undefined && scope.notallowedcharacters !== null) {
                    if (_.isArray(scope.notallowedcharacters)) {
                        scope.notallowedcharacters.forEach(function(itemChar){
                            if (ev.charCode === itemChar.charCodeAt(0)) {
                                isNotAllowedCharacter = true;
                            }
                        });
                    }
                }
                
                if (isNotAllowedCharacter === true) {
                    ev.preventDefault();
                }
            };
            
            scope.setInternalValue = function(field) {
                var key = field.key;
                var seletedItem = field.options.filter(function(option){
                    return option.key === key;
                });
                if (seletedItem) {                
                    field.data = seletedItem[0].value; 
                }
            };
            
		}
	};
}


angular.module("seonsm/settingsConfigurator.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("seonsm/settingsConfigurator.html",
    "<div class=\"settings-container\" ng-class=\"config.layout\">\n" +
    "\n" +
    "    <div class=\"form-inline col-xs-12\">\n" +
    "        <div class=\"form-group pull-right\">\n" +
    "            <label for=\"settingsSearch\"> {{translatedString('Search')}}: </label>\n" +
    "            <input id=\"settingsSearch\" type=\"text\" class=\"search form-control\" ng-model=\"search\" ng-keydown=\"preventEnter($event)\">\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"sections-panel\" ng-show=\"config.layout == 'wide'\">\n" +
    "        <ul class=\"\">\n" +
    "            <li ng-repeat=\"section in json\" ng-class=\"{'active': selectedSection == section, 'result-found' : section.resultFound}\" ng-click=\"changeSection(section)\">\n" +
    "                <div>  <span ng-bind-html=\"translatedString(section.name) | highlight:search\"></span></div>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "    <div class=\"fields-panel\" ng-class=\"{'collapsed' : section.collapsed, 'result-found' : (config.layout === 'narrow' && section.resultFound)}\" ng-repeat=\"section in json\" ng-show=\"config.layout == 'narrow' || section === selectedSection\">\n" +
    "        <div class=\"section-title\" ng-show=\"config.layout === 'narrow'\"  ng-click=\"collapseSection(section)\">\n" +
    "            <div><span ng-bind-html=\"translatedString(section.name) | highlight:search\"></span></div>\n" +
    "        </div>\n" +
    "        <div class=\"settings-input-group\" ng-repeat=\"field in section.fields\" ng-hide=\"search && !field.matchesSearch\" ng-form name=\"jsonInputForm\">\n" +
    "            <div class=\"form-group\" ng-class=\"config.inputGroupClass\" ng-switch=\"field.type\">\n" +
    "                <label ng-class=\"config.labelClass\">\n" +
    "                    <span class=\"text-danger\" ng-show=\"field.required\">* </span>\n" +
    "                    <span ng-bind-html=\"translatedString(field.label) | highlight:search\"></span>\n" +
    "                </label>\n" +
    "\n" +
    "                <input ng-switch-when=\"text\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" type=\"text\" class=\"form-control\" ng-class=\"config.inputClass\"\n" +
    "                       ng-model=\"field.data\" ng-required=\"field.required\" placeholder=\"{{field['default-value']}}\" ng-change=\"validateString(field)\" ng-readonly=\"checkReadOnly(field)\" ng-keypress=\"checkValidCharacters($event)\"/>\n" +
    "                \n" +
    "                <textarea  ng-switch-when=\"notMappedParameters\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" ng-model=\"field.data\" ng-required=\"field.required\" cols=\"{{field.cols}}\" \n" +
    "                       rows=\"{{field.rows}}\" placeholder=\"{{field['default-value']}}\" ng-readonly=\"checkReadOnly(field)\"/>                   \n" +
    "\n" +
    "                <input ng-switch-when=\"ip-address\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\"  type=\"text\" class=\"form-control\" ng-class=\"config.inputClass\"\n" +
    "                       ng-model=\"field.data\" ng-required=\"field.required\" ng-pattern=\"ipPattern\" placeholder=\"{{field['default-value']}}\" ng-readonly=\"readonly===true\"/>\n" +
    "\n" +
    "                <input ng-switch-when=\"number\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" type=\"text\" class=\"form-control\" ng-class=\"config.inputClass\"\n" +
    "                       ng-model=\"field.data\" ng-required=\"field.required\" min=\"{{field.min && field.min || '0'}}\" limit-to=\"{{field.max && field.max.toString().length || '4'}}\"\n" +
    "                       placeholder=\"{{field['default-value']}}\" ng-keypress=\"checkNumbers($event)\" ng-change=\"validateNumber(field)\" ng-readonly=\"readonly===true\"/>\n" +
    "\n" +
    "                <input ng-switch-when=\"number-decimal\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" type=\"number\" class=\"form-control\" ng-class=\"config.inputClass\"\n" +
    "                       ng-model=\"field.data\" ng-required=\"field.required\" step=\"0.01\" min=\"{{field.min && field.min || '0'}}\" limit-to=\"{{field.max && field.max.toString().length + 3 || '7'}}\"\n" +
    "                       placeholder=\"{{field['default-value']}}\" ng-keypress=\"checkDecimalNumbers($event, field)\" ng-change=\"validateDecimalNumber(field)\" ng-readonly=\"readonly===true\"/>\n" +
    "\n" +
    "                <input ng-switch-when=\"email\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" type=\"email\" class=\"form-control\" ng-class=\"config.inputClass\"\n" +
    "                       ng-model=\"field.data\" ng-required=\"field.required\" ng-maxlength=\"254\" placeholder=\"{{field['default-value']}}\" ng-readonly=\"readonly===true\"/>        \n" +
    "                \n" +
    "                <input ng-switch-when=\"url\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" type=\"text\" name=\"url-{{field.id}}\" class=\"form-control\" ng-class=\"config.inputClass\"\n" +
    "                       ng-model=\"field.data\" ng-required=\"field.required\" placeholder=\"{{field['default-value']}}\" ng-pattern=\"urlPattern\" ng-model-options=\"{ debounce: 500 }\" url-exists ng-readonly=\"readonly===true\"/>\n" +
    "\n" +
    "                <select ng-switch-when=\"select\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" class=\"form-control\" ng-model=\"field.data\" ng-class=\"config.selectClass\"\n" +
    "                        ng-options=\"option as option for option in field.options\" ng-required=\"field.required\" ng-disabled=\"readonly===true\"></select>\n" +
    "\n" +
    "                <select ng-switch-when=\"key-value-select\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" class=\"form-control\" ng-class=\"config.selectClass\" ng-model=\"field.data\"\n" +
    "                        ng-options=\"option.key as option.value for option in field.options\" ng-required=\"field.required\" ng-disabled=\"readonly===true\"></select>\n" +
    "                \n" +
    "                <select ng-switch-when=\"internal-key-value-select\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" class=\"form-control\" ng-class=\"config.selectClass\" ng-model=\"field.key\"\n" +
    "                        ng-options=\"option.key as option.key for option in field.options\" ng-required=\"field.required\" ng-disabled=\"readonly===true\" ng-change=\"setInternalValue(field)\"></select>\n" +
    "\n" +
    "                <select ng-switch-when=\"timezone\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" class=\"form-control\" ng-class=\"config.selectClass\" ng-model=\"field.data\"\n" +
    "                        ng-options=\"timezone.name as timezone.description for timezone in timezones\" ng-required=\"field.required\" placeholder=\"{{field['default-value']}}\" ng-disabled=\"readonly===true\"></select>\n" +
    "\n" +
    "                <select ng-switch-when=\"keyvalue-endpoint\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" class=\"form-control\" ng-class=\"config.selectClass\" ng-model=\"field.data\"\n" +
    "                        ng-options=\"endpointOption[(field.options.key)] as endpointOption[(field.options.value)] for endpointOption in endpoints[field.id]\" ng-required=\"field.required\" placeholder=\"{{field['default-value']}}\" ng-init=\"loadEndpoint(field)\" ng-disabled=\"readonly===true\"></select>\n" +
    "\n" +
    "                <div ng-switch-when=\"checkbox\" id=\"jsonId{{field.id}}\" name=\"jsonName{{field.id}}\" class=\"checkbox\">\n" +
    "                    <label> <input type=\"checkbox\" ng-model=\"field.data\" ng-required=\"field.required\"  ng-class=\"config.checkboxClass\" ng-readonly=\"readonly===true\"> </label>\n" +
    "                </div>\n" +
    "\n" +
    "                <div ng-switch-default class=\"alert alert-danger\">Unsupported field type: \"{{field.type}}\"</div>\n" +
    "\n" +
    "                <!-- errors  -->\n" +
    "                <ul class=\"json-input-errors\">\n" +
    "                    <li ng-class=\"config.errorTextClass\" ng-show=\"jsonInputForm[('jsonName' + field.id)].$error.pattern\"> {{translatedString('PATTERN_ERROR')}}</li>\n" +
    "                    <li ng-class=\"config.errorTextClass\" ng-show=\"jsonInputForm[('jsonName' + field.id)].$error.required\"> {{translatedString('REQUIRED_ERROR')}}</li>\n" +
    "                    <li ng-class=\"config.errorTextClass\" ng-show=\"jsonInputForm[('jsonName' + field.id)].$error.maxLength\"> {{translatedString('MAX_LENGTH_ERROR')}} ({{field.max}})</li>\n" +
    "                    <li ng-class=\"config.errorTextClass\" ng-show=\"jsonInputForm[('jsonName' + field.id)].$error.minLength\"> {{translatedString('MIN_LENGTH_ERROR')}} ({{field.min}})</li>\n" +
    "                    <li ng-class=\"config.errorTextClass\" ng-show=\"jsonInputForm[('jsonName' + field.id)].$error.urlExists\"> {{translatedString('URL_EXISTS_ERROR')}}</li>\n" +
    "                </ul>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"field-description\" ng-switch=\"!!field['default-value']\">\n" +
    "                <div ng-switch-when=\"true\" class=\"text-container\"><span ng-bind-html=\"(field.tooltip + '<br><b>Default:</b> ' + field['default-value']) | highlight:search\"></span> </div>\n" +
    "                <div ng-switch-default class=\"text-container\"><span ng-bind-html=\"(field.tooltip) | highlight:search\"></span> </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>");
}]);

(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('app-resource-user-profile', {
            parent: 'entity',
            url: '/app-resource-user-profile',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.appResourceUserProfile.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/appResourceUserProfile/app-resource-user-profiles.html',
                    controller: 'AppResourceUserProfileController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appResourceUserProfile');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            },
             ncyBreadcrumb: {
                 label: 'vmaxmanagerApp.appResourceUserProfile.home.title'
             }
        })
        .state('app-resource-user-profile.view', {
            parent: 'entity',
            url: '/{id}/view',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.appResourceUserProfile.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/appResourceUserProfile/app-resource-user-profile-detail.html',
                    controller: 'AppResourceUserProfileDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appResourceUserProfile');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AppResourceUserProfile', function($stateParams, AppResourceUserProfile) {
                    return AppResourceUserProfile.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'app-resource-user-profile',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            },
             ncyBreadcrumb: {
               label: 'entity.action.view',
               parent: 'app-resource-user-profile'
           }
        })
        .state('app-resource-user-profile.edit', {
            parent: 'app-resource-user-profile',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
            },
            views: {
                'content@': {
                     templateUrl: 'scripts/app/entities/appResourceUserProfile/app-resource-user-profile-dialog.html',
                     controller: 'AppResourceUserProfileDialogController',
                     controllerAs: 'vm'
                }
            },
            resolve: {
                 translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                     $translatePartialLoader.addPart('appResourceUserProfile');
                     return $translate.refresh();
                 }],
                 entity: ['$stateParams', 'AppResourceUserProfile', function($stateParams, AppResourceUserProfile) {
                     return AppResourceUserProfile.get({id : $stateParams.id}).$promise;
                 }]
             },
              ncyBreadcrumb: {
                label: 'entity.action.edit',
                parent: 'app-resource-user-profile'
            }
        })
        .state('app-resource-user-profile.new', {
            parent: 'app-resource-user-profile',
            url: '/new',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/appResourceUserProfile/app-resource-user-profile-dialog.html',
                    controller: 'AppResourceUserProfileDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                     $translatePartialLoader.addPart('appResourceUserProfile');
                     return $translate.refresh();
                 }],
                 entity: [function() {
                     return {
                         id: null,
                         name: null,
                         deviceType: null
                     };
                 }]
            },
            ncyBreadcrumb: {
                label: 'entity.action.new',
                parent: 'app-resource-user-profile'
            }
        })
         .state('app-resource-user-profile.delete', {
            parent: 'app-resource-user-profile',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'scripts/app/entities/appResourceUserProfile/app-resource-user-profile-delete-dialog.html',
                    controller: 'AppResourceUserProfileDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AppResourceUserProfile', function(AppResourceUserProfile) {
                            return AppResourceUserProfile.get({id : $stateParams.id}).$promise;
                        }],
                        associatedResources: ['AppResourceProfiler', function(AppResourceProfiler) {
                            return AppResourceProfiler.getAppResourceProfilerByProfileId({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('app-resource-user-profile', null, { reload: 'app-resource-user-profile' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();

(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('AppResourceUserProfileController', AppResourceUserProfileController);

    AppResourceUserProfileController.$inject = ['$scope', '$state', 'AppResourceUserProfile', 'ParseLinks', 'AlertService'];

    function AppResourceUserProfileController ($scope, $state, AppResourceUserProfile, ParseLinks, AlertService) {
        var vm = this;
        
        vm.appResourceUserProfiles = [];
        vm.loadPage = loadPage;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;

        loadAll();

        function loadAll () {
            AppResourceUserProfile.query({
                page: vm.page,
                size: 20,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.appResourceUserProfiles.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.appResourceUserProfiles = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('AppResourceUserProfileDeleteController',AppResourceUserProfileDeleteController);

    AppResourceUserProfileDeleteController.$inject = ['$uibModalInstance', 'entity', 'AppResourceUserProfile', 'associatedResources'];

    function AppResourceUserProfileDeleteController($uibModalInstance, entity, AppResourceUserProfile, associatedResources) {
        var vm = this;

        vm.appResourceUserProfile = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.associatedResourcesToProfile = associatedResources;
        vm.hasAssociatedResources = false;
        if (associatedResources !== null && associatedResources !== undefined && associatedResources.length > 0) {
            vm.hasAssociatedResources = true;
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AppResourceUserProfile.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('AppResourceUserProfileDetailController', AppResourceUserProfileDetailController);

    AppResourceUserProfileDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AppResourceUserProfile'];

    function AppResourceUserProfileDetailController($scope, $rootScope, $stateParams, previousState, entity, AppResourceUserProfile) {
        var vm = this;

        vm.appResourceUserProfile = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('vmaxmanagerApp:appResourceUserProfileUpdate', function(event, result) {
            vm.appResourceUserProfile = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('AppResourceUserProfileDialogController', AppResourceUserProfileDialogController);

    AppResourceUserProfileDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', 'entity', 'AppResourceUserProfile', 'DeviceType'];

    function AppResourceUserProfileDialogController ($timeout, $scope, $stateParams, $state, entity, AppResourceUserProfile, DeviceType) {
        var vm = this;

        vm.appResourceUserProfile = entity;
        vm.save = save;
        vm.deviceTypes = DeviceType.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function save () {
            vm.isSaving = true;
            if (vm.appResourceUserProfile.id !== null) {
                AppResourceUserProfile.update(vm.appResourceUserProfile, onSaveSuccess, onSaveError);
            } else {
                AppResourceUserProfile.save(vm.appResourceUserProfile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('vmaxmanagerApp:appResourceUserProfileUpdate', result);
            vm.isSaving = false;
            $state.go('app-resource-user-profile');
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();

(function() {
    'use strict';
    angular
            .module('vmaxmanagerApp')
            .factory('AppResourceUserProfile', AppResourceUserProfile)
            .factory('AppResourceUserProfileByResource', AppResourceUserProfileByResource);

        AppResourceUserProfile.$inject = ['$resource'];
        AppResourceUserProfileByResource.$inject = ['$resource'];

        function AppResourceUserProfile ($resource) {
            var resourceUrl =  'api/app-resource-user-profiles/:id';

            return $resource(resourceUrl, {}, {
                'query': { method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                },
                'update': { method:'PUT' }
            });
        }

        function AppResourceUserProfileByResource ($resource) {
            var resourceUrl =  'api/appResourceUserProfilesByResource/:id';

            return $resource(resourceUrl, {}, {
                'query': { method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                },
                'update': { method:'PUT' },
                'getAppResourceUserProfilesByDeviceTypeId': {
                    method:'GET',
                    isArray: true,
                    url:'api/getAppResourceUserProfilesByDeviceTypeId/:id'
                }
            });
        }
})();

(function() {
    'use strict';
    AppResourceProfilerList.$inject = ["$resource"];
    angular
        .module('vmaxmanagerApp')
        .factory('AppResourceProfiler', AppResourceProfiler)
        .factory('AppResourceProfilerList', AppResourceProfilerList);

    AppResourceProfiler.$inject = ['$resource'];

    function AppResourceProfiler ($resource) {
        var resourceUrl =  'api/app-resource-profilers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method:'PUT'
            },
            'getAppResourceProfilerByProfileId': {
                method:'GET',
                isArray: true,
                url:'api/getAppResourceProfilerByProfileId/:id'
            }
        });
    }

    function AppResourceProfilerList ($resource) {
        var resourceUrl =  'api/appResourceProfilersList/:id';

        return $resource(resourceUrl, {}, {
            'saveList': { method: 'POST', isArray: true}
        });
    }
})();

'use strict';

angular.module('vmaxmanagerApp')
    .service('UrlService', ["$filter", function ($filter) {

    this.validURL = function(str) {

        var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return regexp.test(str);
    }
}]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('LicenseAssignDeviceSettingController', ["$scope", "$translate", "$rootScope", "$q", "$stateParams", "$state", "$uibModalInstance", "settings", "settingsTemplate", "AlertService", "AssignDeviceService", "license", "DeviceType", function($scope, $translate, $rootScope, $q, $stateParams, $state, $uibModalInstance,
	                                                settings, settingsTemplate, AlertService, AssignDeviceService, license, DeviceType) {

        var assignDevice = $stateParams.assignDevice;

        $scope.settings = settings;
        $scope.settingsTemplate = settingsTemplate;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.assignDevicesToLicense = function(){
            var paramSettingsInstance = ($scope.settingsInstance!= null) ? $scope.settingsInstance.id  : "";
            var assignDeviceDto = { licenseId : license.id, settingsInstance : paramSettingsInstance , devices : new Array()};
            _.each(assignDevice.availableDevices, function(device){
                if(device.checked){
                    assignDeviceDto.devices.push(device.id);
                }
            });

            // Validate slots and devices
            if(assignDevice.license.slots < assignDevice.assignedDevices.length + assignDeviceDto.devices.length){
                AlertService.error($rootScope.message.error.deviceAssignNotEnoughSlots);
                return;
            }
            AssignDeviceService.assignDevicesToLicense(assignDeviceDto, $scope.refreshDevicesForLicense);
        };

        $scope.refreshDevicesForLicense = function(){
            if(typeof(license.id) == 'undefined' || license == null){
                return;
            }
            $scope.settings = null;
            $uibModalInstance.close(true);
        };

        $scope.invalidSettings = function(){
            return !(!_.isEmpty($scope.settingsTemplate) && !_.isEmpty($scope.settings));
        };
   }]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('LicenseUnassignDeviceSettingController', ["$scope", "$translate", "$rootScope", "$q", "$stateParams", "$state", "$uibModalInstance", "settings", "AlertService", "AssignDeviceService", "license", function($scope, $translate, $rootScope, $q, $stateParams, $state, $uibModalInstance,
	                                                settings, AlertService, AssignDeviceService, license) {

        var findStatus = function(){
            AssignDeviceService.findDeviceStatuses(function(result){
                $scope.deviceStatusList = [];
                _.each(result,function(item){
                    $scope.deviceStatusList.push({ "id" : item, "name" : item });
                });
            });
        }

        findStatus();

        var assignDevice = $stateParams.assignDevice;

        $scope.settings = settings;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        /**
         * Un Assign Devices to License and retrieve the new lists for the Assigned and Available devices
         */
        $scope.unAssignDevicesToLicenseBatch = function(){
            var unAssignDeviceDto = { licenseId : assignDevice.license.id, newStatus : $scope.deviceStatus , devices : new Array()};
            _.each(assignDevice.assignedDevices, function(device){
                if(device.checked){
                    unAssignDeviceDto.devices.push(device.id);
                }
            });

            AssignDeviceService.unAssignDeviceToLicense(unAssignDeviceDto, $scope.refreshDevicesForLicense);
        }

        $scope.refreshDevicesForLicense = function(){
            if(typeof(license.id) == 'undefined' || license == null){
                return;
            }
            $scope.settings = null;
            $uibModalInstance.close(true);
        };
   }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('BulkController', ["$scope", "Principal", "$rootScope", "$state", "$translate", function ($scope, Principal, $rootScope, $state, $translate) {
        $scope.isAuthenticated = Principal.isAuthenticated;

        switch($state.current.data.process){
            case "device":

                $scope.title = $translate.instant('vmaxmanagerApp.bulk.device.title');

                $scope.tabs = [
                    { title:$translate.instant('vmaxmanagerApp.bulk.device.selectDevice'), content:'contentBulk', disabled: true, stepNumber : 1 },
                    { title:$translate.instant('vmaxmanagerApp.bulk.device.operationDetails'), content:'contentBulk', disabled: true, stepNumber : 2 },
                    { title:$translate.instant('vmaxmanagerApp.bulk.device.confirmation'), content:'contentBulk', disabled: true, stepNumber : 3 },
                    { title:$translate.instant('vmaxmanagerApp.bulk.device.results'), content:'contentBulk', disabled: true, stepNumber : 4 }
                ];
            break;
        }

        $scope.drawStepClasses = function(currentStepNumber){
            if($state.current.data.stepNumber > currentStepNumber || $state.current.data.stepNumber == $scope.tabs.length){
                return 'mdl-stepper-step active-step step-done';
            }
            if($state.current.data.stepNumber < currentStepNumber){
                return 'mdl-stepper-step';
            }
            if($state.current.data.stepNumber == currentStepNumber){
                return 'mdl-stepper-step active-step';
            }
        }

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .config(["$stateProvider", function($stateProvider) {
        $stateProvider
            .state('bulkDeviceContainer', {
                abstract: true,
                parent: 'device'
            })
            .state('bulkDevice', {
                parent: 'bulkDeviceContainer',
                url: '/bulk',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.bulk.device.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/bulk/bulk.html',
                        controller: 'BulkController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bulk');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.bulk.device.title',
                    parent: 'device'
                }
            })
            .state('bulkDevice.selectDevices', {
                parent: 'bulkDevice',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.bulk.device.title',
                    stepNumber: 1,
                    process: "device"
                },
                params: {
                    selectedDevices: [],
                    processType: null
                },
                views: {
                    'contentBulk@bulkDevice': {
                        templateUrl: 'scripts/app/bulk/device/selectDevices/bulkDevice-selectDevices.html',
                        controller: 'BulkDeviceSelectDevicesController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bulk');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.bulk.device.selectDevice',
                    parent: 'device'
                }
            })
            .state('bulkDevice.operationDetails', {
                parent: 'bulkDevice.selectDevices',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.bulk.device.title',
                    stepNumber: 2,
                    process: "device"
                },
                views: {
                    'contentBulk@bulkDevice': {
                        templateUrl: 'scripts/app/bulk/device/operationDetails/bulkDevice-operationDetails.html',
                        controller: 'BulkDeviceOperationDetailsController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bulk');
                        return $translate.refresh();
                    }],
                    providersList: ['Providers', function(Providers) {
                        return Providers.query().$promise;
                    }],
                    statusList: ['BulkDeviceStatus', function(BulkDeviceStatus) {
                        return BulkDeviceStatus.get().$promise;
                    }]
                }
            })
            .state('bulkDevice.operationDetails-manual', {
                parent: 'bulkDevice.selectDevices',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.bulk.device.title',
                    stepNumber: 2,
                    process: "device"
                },
                views: {
                    'contentBulk@bulkDevice': {
                        templateUrl: 'scripts/app/bulk/device/operationDetails/bulkDevice-operationDetails-manual.html',
                        controller: 'BulkDeviceOperationDetailsManualController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bulk');
                        return $translate.refresh();
                    }],
                    providersList: ['Providers', function(Providers) {
                        return Providers.query().$promise;
                    }],
                    statusList: ['BulkDeviceStatus', function(BulkDeviceStatus) {
                        return BulkDeviceStatus.get().$promise;
                    }],
                    entity:  function() {
                        return {
                            provider: null,
                            provisionDate: null,
                            status: null
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.bulk.device.operationDetails',
                    parent: 'device'
                }
            })
            .state('bulkDevice.confirmation', {
                parent: 'bulkDevice.operationDetails',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.bulk.device.title',
                    stepNumber: 3,
                    process: "device"
                },
                views: {
                    'contentBulk@bulkDevice': {
                        templateUrl: 'scripts/app/bulk/device/confirmation/bulkDevice-confirmation.html',
                        controller: 'BulkDeviceConfirmationController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bulk');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.bulk.device.confirmation',
                    parent: 'device'
                }
            })
            .state('bulkDevice.confirmation-manual', {
                parent: 'bulkDevice.operationDetails-manual',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.bulk.device.title',
                    stepNumber: 3,
                    process: "device"
                },
                views: {
                    'contentBulk@bulkDevice': {
                        templateUrl: 'scripts/app/bulk/device/confirmation/bulkDevice-confirmation.html',
                        controller: 'BulkDeviceConfirmationController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bulk');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.bulk.device.confirmation',
                    parent: 'device'
                }
            })
            .state('bulkDevice.results', {
                parent: 'bulkDevice',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.bulk.device.title',
                    stepNumber: 4,
                    process: "device"
                },
                views: {
                    'contentBulk@bulkDevice': {
                        templateUrl: 'scripts/app/bulk/device/results/bulkDevice-results.html',
                        controller: 'BulkDeviceResultsController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bulk');
                        return $translate.refresh();
                    }],
                    updateResponse: ['BulkUpdateDevices', 'BulkService', function(BulkUpdateDevices, BulkService) {
                        var devicesToUpdate =  BulkService.getDevicesToUpdate();
                        return BulkUpdateDevices.update(devicesToUpdate).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.bulk.device.confirmation',
                    parent: 'device'
                }
            })
            .state('bulkDevice.operationDetails.backFromFile', {
                parent: 'bulkDevice.operationDetails',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$state', '$uibModal', function($state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/bulk/device/operationDetails/bulkDevice-operationDetails-back.html',
                        controller: 'BulkDeviceOperationDetailsBackController',
                        size: 'md',
                        backdrop: 'static',
                        keyboard: false
                    }).result.then(function() {
                        $state.go('bulkDevice.selectDevices', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('bulkDevice.operationDetails-manual.backManual', {
                parent: 'bulkDevice.operationDetails-manual',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$state', '$uibModal', function($state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/bulk/device/operationDetails/bulkDevice-operationDetails-back.html',
                        controller: 'BulkDeviceOperationDetailsBackController',
                        size: 'md',
                        backdrop: 'static',
                        keyboard: false
                    }).result.then(function() {
                        $state.go('bulkDevice.selectDevices', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('bulkDevice.confirmation.next', {
                parent: 'bulkDevice.confirmation',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$state', '$uibModal', function($state, $uibModal, $stateParams) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/bulk/device/confirmation/bulkDevice-confirmation-next.html',
                        controller: 'BulkDeviceConfirmationNextController',
                        size: 'md',
                        backdrop: 'static',
                        keyboard: false
                    }).result.then(function() {
                        $state.go('bulkDevice.results');
                    }, function() {
                        $state.go('bulkDevice.confirmation');
                    });
                }]
            })
            .state('bulkDevice.confirmation-manual.next', {
                parent: 'bulkDevice.confirmation-manual',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$state', '$uibModal', function($state, $uibModal, $stateParams) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/bulk/device/confirmation/bulkDevice-confirmation-next.html',
                        controller: 'BulkDeviceConfirmationNextController',
                        size: 'md',
                        backdrop: 'static',
                        keyboard: false
                    }).result.then(function() {
                        $state.go('bulkDevice.results');
                    }, function() {
                        $state.go('bulkDevice.confirmation-manual');
                    });
                }]
            })
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('BulkDeviceConfirmationController', ["$scope", "$stateParams", "BulkService", "$state", "BulkConstants", function ($scope, $stateParams, BulkService, $state, BulkConstants) {

        var deviceSelectedList = BulkService.getDevicesSelected();
        var resultConfirmation = BulkService.getResponseConfirmation();
        var content = 0;

        var mapDateFields = function(){
            if(resultConfirmation && _.isArray(resultConfirmation)){
                _.each(resultConfirmation, function(result){
                    if(result.columnId == "provisionDate" && moment(result.value).isValid()){
                        var dateMoment = moment(result.value).toLocaleString();
                        var date = moment(dateMoment).format('YYYY-MM-DD HH:mm:ss');
                        result.value = date.toString();
                    }
                });
            }
        }

        if ( $state.current.parent == 'bulkDevice.operationDetails-manual') {
            content = BulkService.getDevicesSelected();
            $scope.deviceSelectedLength = !_.isNaN(content.length) ? content.length : 0;
        } else {
            content = BulkService.getFileContent();
            $scope.deviceSelectedLength = !_.isNaN(content.length) ? (content.length - 1) : 0;
        }

        mapDateFields();
        $scope.responses = resultConfirmation;

        $scope.goToModalConfirmation = function(){
            if ( $state.current.parent == 'bulkDevice.operationDetails-manual') {
                $state.go("bulkDevice.confirmation-manual.next");
            } else {
                $state.go("bulkDevice.confirmation.next");
            }
        }

        /*
        * Go to back page.
        */
        $scope.goToPreviouslyStep = function() {
            $state.go($state.current.parent);
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('BulkDeviceOperationDetailsController', ["$scope", "BulkService", "providersList", "statusList", "$state", "DateUtils", "$translate", "BulkConstants", function ($scope, BulkService, providersList, statusList, $state, DateUtils, $translate, BulkConstants) {

        $scope.headers = BulkService.getFileHeader();

        $scope.providers = providersList;
        $scope.deviceStatutes = statusList;

        $scope.operationDetail = BulkService.getOperationDetailFromFile();

        $scope.isFromFile = function(radioType){
            return radioType && radioType == 'file';
        }

        $scope.isManual = function(radioType){
            return radioType && radioType == 'manual';
        }

        $scope.disableRadioFile = function(radioType){
            return !radioType || radioType != 'file';
        }

        $scope.disableRadioManual = function(radioType){
            return !radioType || radioType != 'manual';
        }

        $scope.goToNextStep = function(){
            var response = buildResponse();
            BulkService.setResponseConfirmation(response);
            BulkService.setOperationDetailFromFile($scope.operationDetail);
            $state.go("bulkDevice.confirmation", {parentState: $state.current.name});
        }

        $scope.checkingValidationForm = function(){

            if(!$scope.operationDetail){
                return true;
            }

            if($scope.operationDetail.provider && $scope.operationDetail.provider.checkbox && ($scope.operationDetail.provider.file || $scope.operationDetail.provider.manual)
                || $scope.operationDetail.deviceId && $scope.operationDetail.deviceId.checkbox && $scope.operationDetail.deviceId.file
                || $scope.operationDetail.providerId && $scope.operationDetail.providerId.checkbox && $scope.operationDetail.providerId.file
                || $scope.operationDetail.simId && $scope.operationDetail.simId.checkbox && $scope.operationDetail.simId.file
                || $scope.operationDetail.ipAddress && $scope.operationDetail.ipAddress.checkbox && $scope.operationDetail.ipAddress.file
                || $scope.operationDetail.provisionDate && $scope.operationDetail.provisionDate.checkbox && ($scope.operationDetail.provisionDate.file || $scope.operationDetail.provisionDate.manual)
                || $scope.operationDetail.deviceStatus && $scope.operationDetail.deviceStatus.checkbox && ($scope.operationDetail.deviceStatus.file || $scope.operationDetail.deviceStatus.manual)
                || $scope.operationDetail.notes && $scope.operationDetail.notes.checkbox && $scope.operationDetail.notes.file){
                return false;
            }else{
                return true;
            }
        }

        var buildResponse = function(){
            var response = [];

            if(!$scope.operationDetail){
                return response;
            }

            if($scope.operationDetail.provider && $scope.operationDetail.provider.checkbox){
                if($scope.operationDetail.provider.radio == "manual" && $scope.operationDetail.provider.manual){
                    response.push( {"type" : $scope.operationDetail.provider.radio,
                                    "value" : $scope.operationDetail.provider.manual.name,
                                    "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelManual'),
                                    "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.provider'),
                                    "columnId" : BulkConstants.device.provider});
                }
                if($scope.operationDetail.provider.radio == "file" && $scope.operationDetail.provider.file){
                    response.push( {"type" : $scope.operationDetail.provider.radio,
                                    "value" : $scope.operationDetail.provider.file.name,
                                    "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                    "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.provider'),
                                    "columnId" : BulkConstants.device.provider});
                }
            }
            if($scope.operationDetail.deviceId && $scope.operationDetail.deviceId.checkbox && $scope.operationDetail.deviceId.file){
                response.push( {"type" : "file",
                                "value" : $scope.operationDetail.deviceId.file.name,
                                "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.deviceId'),
                                "columnId" : BulkConstants.device.deviceId});
            }
            if($scope.operationDetail.providerId && $scope.operationDetail.providerId.checkbox && $scope.operationDetail.providerId.file){
                response.push( {"type" : "file",
                                "value" : $scope.operationDetail.providerId.file.name,
                                "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.providerId'),
                                "columnId" : BulkConstants.device.providerId});
            }
            if($scope.operationDetail.simId && $scope.operationDetail.simId.checkbox && $scope.operationDetail.simId.file){
                response.push( {"type" : "file",
                                "value" : $scope.operationDetail.simId.file.name,
                                "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.simId'),
                                "columnId" : BulkConstants.device.simId});
            }
            if($scope.operationDetail.ipAddress && $scope.operationDetail.ipAddress.checkbox && $scope.operationDetail.ipAddress.file){
                response.push( {"type" : "file",
                                "value" : $scope.operationDetail.ipAddress.file.name,
                                "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.ipAddress'),
                                "columnId" : BulkConstants.device.ipAddress});
            }
            if($scope.operationDetail.provisionDate && $scope.operationDetail.provisionDate.checkbox){
                if($scope.operationDetail.provisionDate.radio == "manual" && $scope.operationDetail.provisionDate.manual){
                    response.push( {"type" : $scope.operationDetail.provisionDate.radio,
                                    "value" : $scope.operationDetail.provisionDate.manual,
                                    "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelManual'),
                                    "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.provisionDate'),
                                    "columnId" : BulkConstants.device.provisionDate});
                }
                if($scope.operationDetail.provisionDate.radio == "file" && $scope.operationDetail.provisionDate.file){
                    response.push( {"type" : $scope.operationDetail.provisionDate.radio,
                                    "value" : $scope.operationDetail.provisionDate.file.name,
                                    "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                    "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.provisionDate'),
                                    "columnId" : BulkConstants.device.provisionDate});
                }
            }
            if($scope.operationDetail.deviceStatus && $scope.operationDetail.deviceStatus.checkbox){
                if($scope.operationDetail.deviceStatus.radio == "manual" && $scope.operationDetail.deviceStatus.manual){
                    response.push( {"type" : $scope.operationDetail.deviceStatus.radio,
                                    "value" : $scope.operationDetail.deviceStatus.manual,
                                    "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelManual'),
                                    "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.deviceStatus'),
                                    "columnId" : BulkConstants.device.status});
                }
                if($scope.operationDetail.deviceStatus.radio == "file" && $scope.operationDetail.deviceStatus.file){
                    response.push( {"type" : $scope.operationDetail.deviceStatus.radio,
                                    "value" : $scope.operationDetail.deviceStatus.file.name,
                                    "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                    "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.deviceStatus'),
                                    "columnId" : BulkConstants.device.status});
                }
            }
            if($scope.operationDetail.notes && $scope.operationDetail.notes.checkbox && $scope.operationDetail.notes.file){
                response.push( {"type" : "file",
                                "value" : $scope.operationDetail.notes.file.name,
                                "label" : $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelFromFile'),
                                "columnName" : $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.notes'),
                                "columnId" : BulkConstants.device.notes});
            }

            return response;
        }

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('BulkDeviceResultsController', ["$scope", "updateResponse", function ($scope, updateResponse) {
        $scope.updateResponse = updateResponse;
        $scope.showUpdatedDevices = false;
        $scope.showErrorsDevices = false;
        $scope.updatedDevicesLength = 0;
        $scope.errorDevicesLength = 0;
        $scope.isCollapseUpdatedDevicesContainer = false;
        $scope.isCollapseErrorDevicesContainer = false;
        $scope.currentUpdatedDevicesPage = 1;
        $scope.currentErrorDevicesPage = $scope.currentUpdatedDevicesPage;
        $scope.numPerPage = 10;

        if (!_.isEmpty($scope.updateResponse)) {
            $scope.updatedDevices = $scope.updateResponse.updatedDevices;
            $scope.DevicesWithError = $scope.updateResponse.errors;

            if (!_.isEmpty($scope.updatedDevices)) {
                $scope.updatedDevicesLength = $scope.updatedDevices.length;
                if ($scope.updatedDevicesLength > 0) {
                    $scope.showUpdatedDevices = true;
                    $scope.showProviderCol = !_.isEmpty($scope.updatedDevices[0].provider);
                    $scope.showDeviceIdCol = !_.isEmpty($scope.updatedDevices[0].deviceId);
                    $scope.showProviderIdCol = !_.isEmpty($scope.updatedDevices[0].providerId);
                    $scope.showSimIdCol = !_.isEmpty($scope.updatedDevices[0].simId);
                    $scope.showIpAddressCol = !_.isEmpty($scope.updatedDevices[0].ipAddress);
                    $scope.showProvisionDateCol = !_.isEmpty($scope.updatedDevices[0].provisionDate);
                    $scope.showStatusCol = !_.isEmpty($scope.updatedDevices[0].status);
                    $scope.showNotesCol = !_.isEmpty($scope.updatedDevices[0].notes);
                }
            }

            if (!_.isEmpty($scope.DevicesWithError)) {
                $scope.errorDevicesLength = $scope.DevicesWithError.length;
                if ($scope.errorDevicesLength > 0) {
                    $scope.showErrorsDevices = true;
                }
            }
        }

        $scope.updatedDevicesPaginate = function(value) {
            var begin, end, index;
            begin = ($scope.currentUpdatedDevicesPage - 1) * $scope.numPerPage;
            end = begin + $scope.numPerPage;
            index = $scope.updatedDevices.indexOf(value);
            return (begin <= index && index < end);
        };

        $scope.errorDevicesPaginate = function(value) {
            var begin, end, index;
            begin = ($scope.currentErrorDevicesPage - 1) * $scope.numPerPage;
            end = begin + $scope.numPerPage;
            index = $scope.DevicesWithError.indexOf(value);
            return (begin <= index && index < end);
        };

        $scope.updatedDevicesButtonClick = function () {
            $scope.isCollapseUpdatedDevicesContainer = !angular.element('#collapseUpdatedDevices').hasClass('in');
        };

        $scope.removeDevicesButtonClick = function () {
            $scope.isCollapseErrorDevicesContainer = !angular.element('#collapseRemoveDevices').hasClass('in');
        };
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .controller('BulkDeviceSelectDevicesController', ["$scope", "$stateParams", "$state", "BulkService", "BulkConstants", "BulkDeviceStatus", function ($scope, $stateParams, $state, BulkService, BulkConstants, BulkDeviceStatus) {

        BulkService.resetAll();

        if (!_.isNull($stateParams.selectedDevices)) {
            BulkService.setDevicesSelected($stateParams.selectedDevices);
        }

        /*
        * Load the input file.
        */
        $scope.loadFileInput = function(){
            return $scope.radioBulk == BulkConstants.device.radioTypes.file;
        }

        /*
        * Checks the form.
        */
        $scope.checkingValidationForm = function(){
            var wereDevicesManuallySelected = BulkService.getDevicesSelected().length > 0 ? true : false;
            if(($scope.radioBulk == BulkConstants.device.radioTypes.manual && wereDevicesManuallySelected) ||
                ($scope.radioBulk == BulkConstants.device.radioTypes.file && $scope.checkFileContent())){
                return true;
            }else{
                return false;
            }
        }

        $scope.checkFileContent = function(){
            return $scope.errors && $scope.errors.length == 0 && $scope.fileContent && $scope.fileContent.length > 0;
        }

        $scope.$watch("radioBulk", function(newRadioValue, oldRadioValue){
            if(newRadioValue === oldRadioValue){
                return;
            }

            var wereDevicesManuallySelected = BulkService.getDevicesSelected().length > 0 ? true : false;

            if(newRadioValue == BulkConstants.device.radioTypes.manual && !wereDevicesManuallySelected){
                $scope.errorsManual = [];
                $scope.errorsManual.push(BulkConstants.errors.noDevicesSelected);
            }
        });

        /*
        * Go to the next step.
        */
        $scope.goToNextStep = function() {
            if ($scope.radioBulk == BulkConstants.device.radioTypes.manual) {
                $state.go('bulkDevice.operationDetails-manual');
            } else {
                if($scope.fileContent){
                    BulkService.setFileHeader($scope.headers);
                    BulkService.setFileContent($scope.fileContent);
                    BulkService.setSerialNumber($scope.headers.serialNumber);
                    BulkService.setFileHeader($scope.headers);
                    $state.go("bulkDevice.operationDetails");
               }
            }
        }
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .directive('fileReaderComponent', ["$translate", "$translatePartialLoader", "$filter", "BulkConstants", function($translate, $translatePartialLoader, $filter, BulkConstants) {

        var validCsvFile = function(scope, contents) {

            var contentRows = contents.split('\n');
            var rows = contentRows.length;
            var header = contentRows[0].trim();
            var contentHeader = header.split(',');
            var countHeader = contentHeader.length;
            var errors = [];

//          Validate the header
            if(rows < 2 || countHeader < 2 || header.substr(header.length - 1) === ',' || contentHeader.indexOf("") !== -1){
                if(rows < 2){
                    errors.push(BulkConstants.errors.minimumTwoRows);
                }
                if(countHeader < 2){
                    errors.push(BulkConstants.errors.minimumTwoColumns);
                }
                if(header.substr(header.length - 1) === ','){
                    errors.push(BulkConstants.errors.noEndCommaSeparator);
                }
                if(contentHeader.indexOf("") !== -1){
                    errors.push(BulkConstants.errors.noEmptyValueBetweenCommas);
                }
            }else {
//              Validate the content
                for(var i = 1; i < contentRows.length; i++){
                    var countColumn = contentRows[i].split(",").length;
                    if(countHeader < countColumn || countHeader > countColumn){
                        errors.push("The row #" + (i + 1) + " has " + countColumn + " columns but the header has " + countHeader + " columns");
                    }
                }
            }

            buildResponse(scope, errors, contentHeader, contentRows);

        };

        var buildHeader = function(header){

            var availableOptions = [];

            for(var i = 0; i < header.length; i++){
                availableOptions.push({id: i, name: header[i]});
            }

            return availableOptions;
        }

        var buildResponse = function(scope, errors, contentHeader, contentRows){
            if(errors.length == 0){

                var headers = buildHeader(contentHeader);

                scope.$apply(function () {
                    scope.fileReader = contentRows;
                    scope.fileHeaders = headers;
                });
            }else{
                scope.$apply(function () {
                    scope.fileErrors = errors;
                });
            }
        }

        return {
            scope: {
                fileReader:"=",
                fileErrors:"=",
                fileHeaders:"="
            },
            link: function(scope, element) {
                $(element).on('click', function(changeEvent) {
                    this.value = null;
                });

                $(element).on('change', function(changeEvent) {

                    var errors = [];

                    scope.fileErrors = [];
                    scope.fileHeaders = [];
                    var files = changeEvent.target.files;

                    if(files && files.length > 0){
                        var fileTypeIndex = files[0].name.lastIndexOf(".");
                        var fileType = files[0].name.substr(fileTypeIndex, files[0].name.length);

                        if(files[0].type !== "application/vnd.ms-excel" && fileType !== ".csv"){
                            errors.push(BulkConstants.errors.fileTypeError);
                            buildResponse(scope, errors);
                        }
                    }

                    if (files.length && errors.length == 0) {
                        var r = new FileReader();
                        r.onload = function(e) {
                            var contents = e.target.result;
                            validCsvFile(scope, contents);
                        };
                        r.readAsText(files[0]);
                    }else{
                        scope.$apply(function () {
                            scope.fileReader = [];
                        });
                    }
                });
            }
        };
    }]);

    "use strict";
    angular.module("vmaxmanagerApp")
        .constant("BulkConstants", {
            "device" : {
                "provider" : "provider",
                "deviceId" : "deviceId",
                "providerId" : "providerId",
                "simId" : "simId",
                "ipAddress" : "ipAddress",
                "provisionDate" : "provisionDate",
                "status" : "status",
                "notes" : "notes",
                "radioTypes" : {
                    "manual" : "manual",
                    "file" : "fromFile"
                }
            },
            "errors" : {
                "minimumTwoRows" : "The file minimum must have two rows",
                "minimumTwoColumns" : "The header minimum must have two columns",
                "noEndCommaSeparator" : "The header must not end with a comma separator",
                "noEmptyValueBetweenCommas" : "The header must not get an empty value between commas separator",
                "fileTypeError" : "The file must be CSV (Comma-Separated Values) type",
                "noSerialNumberSelected" : "There is not Serial Number selected",
                "noDevicesSelected" : "You must selected at least one device"
            }
        });

'use strict';

angular.module('vmaxmanagerApp')
    .controller('BulkDeviceOperationDetailsManualController', ["$scope", "$state", "$translate", "entity", "BulkConstants", "BulkService", "DateUtils", "providersList", "statusList", function($scope, $state, $translate, entity, BulkConstants, BulkService, DateUtils, providersList, statusList) {
        BulkService.resetResponseConfirmation();
        $scope.providersList = providersList;
        $scope.statusList = statusList;
        $scope.fieldsValuesSet = entity;
        $scope.checkedFieldsSet = {
                        provider: !_.isEmpty($scope.fieldsValuesSet.provider),
                        provisionDate: !_.isEmpty($scope.fieldsValuesSet.provisionDate),
                        status: !_.isEmpty($scope.fieldsValuesSet.status)
                    };
        /*
        * Check which fields were selected.
        */
        $scope.checkFields = function() {
            var op = $scope.checkedFieldsSet;
            if (!op.provider) {
                $scope.fieldsValuesSet.provider = null;
            }
            if (!op.provisionDate) {
                $scope.fieldsValuesSet.provisionDate = null;
            }
            if (!op.status) {
                $scope.fieldsValuesSet.status = null;
            }
            if (!op.provider && !op.provisionDate && !op.status) {
                $scope.isFormDisabled = true;
            } else {
                $scope.isFormDisabled = null;
            }
        };

        $scope.checkFields();

        /*
        * Go to the next step.
        */
        $scope.goToNextStep = function() {
            var updateFields = buildUpdateFields();
            BulkService.setResponseConfirmation(updateFields);
            $state.go("bulkDevice.confirmation-manual");
        }

        /*
        * Builds the data set with the fields to update.
        */
        var buildUpdateFields = function() {
            var response = [];

            if (!$scope.checkedFieldsSet) {
                return response;
            }

            if ($scope.fieldsValuesSet.provider && $scope.checkedFieldsSet.provider) {
                var item = buildUpdateItem(null,
                                           $scope.fieldsValuesSet.provider.name,
                                           $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.provider'),
                                           BulkConstants.device.provider);
                response.push(item);
            }
            if ($scope.fieldsValuesSet.provisionDate && $scope.checkedFieldsSet.provisionDate) {
                var item = buildUpdateItem($scope.fieldsValuesSet.provisionDate,
                                           $scope.fieldsValuesSet.provisionDate,
                                           $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.provisionDate'),
                                           BulkConstants.device.provisionDate);
                response.push(item);
            }
            if ($scope.fieldsValuesSet.status && $scope.checkedFieldsSet.status) {
                var item = buildUpdateItem(null,
                                           $scope.fieldsValuesSet.status,
                                           $translate.instant('vmaxmanagerApp.bulk.device.operationDetailsProcess.deviceStatus'),
                                           BulkConstants.device.status);
                response.push(item);
            }

            return response;
        };

        /*
        * Builds the field to update.
        */
        var buildUpdateItem = function(internalValue, value, columnName, columnId) {
            return {
                "type": 'manual',
                "internalValue": internalValue,
                "value": value,
                "label": $translate.instant('vmaxmanagerApp.bulk.device.confirmationProcess.labelManual'),
                "columnName": columnName,
                "columnId": columnId
            };
        };

    }]);

'use strict';

angular.module('vmaxmanagerApp')
.factory('BulkService', ["BulkConstants", function (BulkConstants) {

    var fileContent = {};
    var fileHeaders = [];
    var devicesSelected = [];
    var responseConfirmation = [];
    var devicesToUpdate = [];
    var serialNumber = null;
    var operationDetailFromFile = {};

    var resetAll = function () {
        fileContent = {};
        fileHeaders = [];
        responseConfirmation = [];
        devicesToUpdate = [];
        serialNumber = null;
        operationDetailFromFile = {};
    }

    var setFileContent = function(content){
        fileContent = content;
    }

    var getFileContent = function(){
        return fileContent;
    }

    var resetFileContent = function(){
        fileContent = {};
    }

    var setFileHeader = function(header){
        fileHeaders = header;
    }

    var getFileHeader = function(){
        return fileHeaders;
    }

    var resetFileHeader = function(){
        fileHeaders = [];
    }

    var setDevicesSelected = function(deviceSelectedList){
        devicesSelected = deviceSelectedList;
    }

    var getDevicesSelected = function(){
        return devicesSelected;
    }

    var resetDevicesSelected = function(){
        devicesSelected = [];
    }
    var setResponseConfirmation = function(response){
        responseConfirmation = response;
    }

    var getResponseConfirmation = function(){
        return responseConfirmation;
    }

    var resetResponseConfirmation = function(){
        responseConfirmation = [];
    }

    var setSerialNumber = function(serial){
        serialNumber = serial;
    }

    var getSerialNumber = function(){
        return serialNumber;
    }

    var resetSerialNumber = function(){
        serialNumber = null;
    }

    var setDevicesToUpdate = function(devices){
        devicesToUpdate = devices;
    }

    var getDevicesToUpdate = function(){
        return devicesToUpdate;
    }

    var resetDevicesToUpdate = function(){
        devicesToUpdate = [];
    }

    var setOperationDetailFromFile = function(operationDetail){
        operationDetailFromFile = operationDetail;
    }

    var getOperationDetailFromFile = function(){
        return operationDetailFromFile;
    }

    var resetOperationDetailFromFile = function(){
        operationDetailFromFile = [];
    }

    /*
    * Build response for bulk from file process.
    */
    var buildResponse = function(){

        var deviceSelectedList = getDevicesSelected();
        var resultConfirmation = getResponseConfirmation();

        var serialNumber = getSerialNumber();

        if(serialNumber){

            var headers = getFileHeader();

            var indexColumn = findValueOnTheColumn(headers, serialNumber.name);

            if(indexColumn !== -1){

                var response = [];
                var sizeFileContent = getFileContent().length;
                var content = getFileContent().splice(1, sizeFileContent);

                var serialNumberList = getSerialNumbersList(content, indexColumn);

               _.each(serialNumberList, function(serialNumberRow, indexRow){

                    var objectResponse = {};

                    objectResponse["serialNumber"] = serialNumberRow;
                    objectResponse["error"] = "";

                    _.each(resultConfirmation, function(result){

                        var value = result.value;

                        if(result.type === "file"){
                            var indexColumnBody = findValueOnTheColumn(headers, result.value);

                            if(indexColumnBody !== -1){
                                var columnValue = content[indexRow].split(",")[indexColumnBody];
                                value = columnValue;
                            }else{
                                response.push({"serialNumber" : serialNumber,
                                               "error" : "The column "+result.columnId+" was not found in the CSV file"});
                                return;
                            }
                        }

                        switch(result.columnId){
                            case BulkConstants.device.provider:
                                objectResponse["provider"] = value;
                                break;
                            case BulkConstants.device.deviceId:
                                objectResponse["deviceId"] = value;
                                break;
                            case BulkConstants.device.providerId:
                                objectResponse["providerId"] = value;
                                break;
                            case BulkConstants.device.simId:
                                objectResponse["simId"] = value;
                                break;
                            case BulkConstants.device.ipAddress:
                                objectResponse["ipAddress"] = value;
                                break;
                            case BulkConstants.device.provisionDate:
                                var provisionDate = moment(value, ["YYYY-MM-DD HH:mm:ss", "YYYY/MM/DD HH:mm:ss" ]);
                                if (provisionDate.isValid()) {
                                    objectResponse["provisionDate"] = provisionDate;
                                } else {
                                    objectResponse["error"] = "The value " + value + " is not a valid date. The valid format must be YYYY-MM-DD HH:mm:ss";
                                }
                                break;
                            case BulkConstants.device.status:
                                objectResponse["status"] = value;
                                break;
                            case BulkConstants.device.notes:
                                objectResponse["notes"] = value;
                                break;
                        }
                    });

                    response.push(objectResponse);
                });
            }else{
                response.push({"serialNumber" : serialNumber,
                               "error" : "The Serial Number "+serialNumber+" was not found in the CSV file"});
            }
        }else{
            response.push({"serialNumber" : serialNumber,
                           "error" : "There is not Serial Number selected"});
        }

        return response;
    }

    var buildManualDataSet = function () {

        var deviceSelectedList = getDevicesSelected();
        var resultConfirmation = getResponseConfirmation();

        var resultDataSet = [];
        _.each(deviceSelectedList, function(deviceSelected){
            var updateObject = {};
            updateObject['serialNumber'] = deviceSelected;
            _.each(resultConfirmation, function(updateField){
                var fieldName = updateField['columnId'];

                var fieldValue = null;
                var valueTemp = !_.isNull(updateField['internalValue']) ? updateField['internalValue'] : updateField['value'];

                if(updateField.columnId === "provisionDate"){
                    fieldValue = moment(valueTemp, "MM/DD/YYYY");

                }else{
                    fieldValue = valueTemp;
                }

                updateObject[fieldName] = fieldValue;
            });
            resultDataSet.push(updateObject);
        });
        return resultDataSet;
    }

    var findValueOnTheColumn = function(headers, value){

        var indexColumn = -1;

        _.each(headers, function(header, indexHeader){
            if(header.name == value){
                indexColumn = indexHeader;
                return false;
            }
        });

        return indexColumn;
    }

    var getSerialNumbersList = function(content, indexColumn){

        var serialNumberRows = [];

        _.each(content, function(row){
            var columnValue = row.split(",")[indexColumn];

            if(columnValue){
                serialNumberRows.push(columnValue);
            }
        });

        return serialNumberRows;
    }

    return {
        resetAll : resetAll,
        setFileContent : setFileContent,
        getFileContent : getFileContent,
        resetFileContent : resetFileContent,
        setFileHeader : setFileHeader,
        getFileHeader : getFileHeader,
        resetFileHeader : resetFileHeader,
        setDevicesSelected : setDevicesSelected,
        getDevicesSelected : getDevicesSelected,
        resetDevicesSelected : resetDevicesSelected,
        setResponseConfirmation : setResponseConfirmation,
        getResponseConfirmation : getResponseConfirmation,
        resetResponseConfirmation : resetResponseConfirmation,
        setSerialNumber : setSerialNumber,
        getSerialNumber : getSerialNumber,
        resetSerialNumber : resetSerialNumber,
        setDevicesToUpdate : setDevicesToUpdate,
        getDevicesToUpdate : getDevicesToUpdate,
        resetDevicesToUpdate : resetDevicesToUpdate,
        setOperationDetailFromFile : setOperationDetailFromFile,
        getOperationDetailFromFile : getOperationDetailFromFile,
        resetOperationDetailFromFile : resetOperationDetailFromFile,
        buildResponse : buildResponse,
        buildManualDataSet : buildManualDataSet
    }
}])
.factory('BulkDeviceStatus', ["$resource", function($resource) {
    return $resource('api/bulk/deviceStatus/', {}, {
        'get': {
            method: 'GET',
            isArray: true
        }
    });
}])
.factory('BulkUpdateDevices', ["$resource", function($resource) {
    return $resource('api/bulk/updateDevices', {}, {
        'update': { method: 'PUT' }
    });
}])
.factory('BulkAllSerialNumbers', ["$resource", function($resource) {
    return $resource('api/bulk/getAllDevicesSerialNumbers', {}, {
        'get': {
            method: 'GET',
            isArray: true
        }
    });
}]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('BulkDeviceOperationDetailsBackController', ["$scope", "$uibModalInstance", function($scope, $uibModalInstance) {

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.continueWorkflow = function() {
            $uibModalInstance.close();
        }

    }]);

'use strict';

angular.module('vmaxmanagerApp')
	.controller('BulkDeviceConfirmationNextController', ["$scope", "$state", "$uibModalInstance", "$stateParams", "BulkService", function($scope, $state, $uibModalInstance, $stateParams, BulkService) {

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.continueWorkflow = function() {
            var result;
            if ( $state.current.parent == 'bulkDevice.confirmation-manual') {
                result = BulkService.buildManualDataSet();
            } else {
                result = BulkService.buildResponse();
            }

            if ( !_.isNull(result) ) {
                BulkService.setDevicesToUpdate(result);
                $state.go("bulkDevice.results", $uibModalInstance.close());
            }
        }

    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .factory('DeviceSettingsRequestAudit', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/deviceSettingsAudit/findLocalDateTime/:id', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    var info = {}
                    info.localDate = data;
                    return info;
                }
            }
        });
    }]);

'use strict';

angular.module('vmaxmanagerApp')
    .service('UtcDateString', ["$filter", function ($filter) {

    this.createString = function(dateString) {
        if (dateString) {
            var utcString = dateString.substring(5,7) + '/' + dateString.substring(8,10) + '/' + dateString.substring(0,4)
            + ' ' +  dateString.substring(11,13) + ':' + dateString.substring(14,16) + ':' + dateString.substring(17,19) + ' UTC';
            return utcString;
        } else {
            return null;
        }
    }
}]);
