(function() {
    'use strict';
    angular
            .module('vmaxmanagerApp')
            .factory('AppResourceUserProfile', AppResourceUserProfile)
            .factory('AppResourceUserProfileByResource', AppResourceUserProfileByResource);

        AppResourceUserProfile.$inject = ['$resource'];
        AppResourceUserProfileByResource.$inject = ['$resource'];

        function AppResourceUserProfile ($resource) {
            var resourceUrl =  'api/app-resource-user-profiles/:id';

            return $resource(resourceUrl, {}, {
                'query': { method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                },
                'update': { method:'PUT' }
            });
        }

        function AppResourceUserProfileByResource ($resource) {
            var resourceUrl =  'api/appResourceUserProfilesByResource/:id';

            return $resource(resourceUrl, {}, {
                'query': { method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                },
                'update': { method:'PUT' },
                'getAppResourceUserProfilesByDeviceTypeId': {
                    method:'GET',
                    isArray: true,
                    url:'api/getAppResourceUserProfilesByDeviceTypeId/:id'
                }
            });
        }
})();
