'use strict';

angular.module('vmaxmanagerApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('salesRegion', {
                parent: 'entity',
                url: '/salesRegions',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.salesRegion.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/salesRegion/salesRegions.html',
                        controller: 'SalesRegionController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesRegion');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.salesRegion.home.title'
                }
            })
            .state('salesRegion.detail', {
                parent: 'salesRegion',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.salesRegion.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/salesRegion/salesRegion-detail.html',
                        controller: 'SalesRegionDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesRegion');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SalesRegion', function($stateParams, SalesRegion) {
                        return SalesRegion.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'salesRegion'
                }
            })
            .state('salesRegion.new', {
                parent: 'salesRegion',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/salesRegion/salesRegion-dialog.html',
                        controller: 'SalesRegionDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesRegion');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
                        	 name: null,
                             salesRepresentative: null,
                             emails: null,
                             id: null
                             };;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'salesRegion'
                }
            })
            .state('salesRegion.edit', {
                parent: 'salesRegion',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/salesRegion/salesRegion-dialog.html',
                        controller: 'SalesRegionDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesRegion');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SalesRegion', function($stateParams, SalesRegion) {
                        return SalesRegion.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'salesRegion'
                }
            })
            .state('salesRegion.delete', {
                parent: 'salesRegion',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/salesRegion/salesRegion-delete-dialog.html',
                        controller: 'SalesRegionDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['SalesRegion', function(SalesRegion) {
                                return SalesRegion.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('salesRegion', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    });
