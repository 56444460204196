'use strict';

angular.module('vmaxmanagerApp')
    .factory('License', function ($resource, DateUtils) {
        return $resource('api/licenses/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                    data.expiryDate = DateUtils.convertDateTimeFromServer(data.expiryDate);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'filterQuery': { method: 'POST', url: 'api/licenses/query', isArray:true },
            'filterVars': { method: 'GET', url: 'api/licenses/info', isArray:true },
            'activeLicenses': { method: 'POST', url: 'api/activeLicenses', isArray:true}
        });
    }).factory('DownloadService', function($http) {
        return {
          downloadFile : function(httpPath) {
              // Use an arraybuffer
              $http.get(httpPath, {
                  responseType : 'arraybuffer'
              }).success(function(data, status, headers) {

                  var octetStreamMime = 'application/octet-stream';
                  var success = false;

                  // Get the headers
                  headers = headers();

                  // Get the filename from the x-filename header or default to
                  // "download.bin"
                  var filename = headers['x-filename'] || 'download.bin';

                  // Determine the content type from the header or default to
                  // "application/octet-stream"
                  var contentType = headers['content-type'] || octetStreamMime;

                  try {
                      // Try using msSaveBlob if supported
                      console.log("Trying saveBlob method ...");
                      var blob = new Blob([ data ], {
                          type : contentType
                      });
                      if (navigator.msSaveBlob)
                          navigator.msSaveBlob(blob, filename);
                      else {
                          // Try using other saveBlob implementations, if
                          // available
                          var saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob;
                          if (saveBlob === undefined)
                              throw "Not supported";
                          saveBlob(blob, filename);
                      }
                      console.log("saveBlob succeeded");
                      success = true;
                  } catch (ex) {
                      console.log("saveBlob method failed with the following exception:");
                      console.log(ex);
                  }

                  if (!success) {
                      // Get the blob url creator
                      var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
                      if (urlCreator) {
                          // Try to use a download link
                          var link = document.createElement('a');
                          if ('download' in link) {
                              // Try to simulate a click
                              try {
                                  // Prepare a blob URL
                                  console.log("Trying download link method with simulated click ...");
                                  var blob = new Blob([ data ], {
                                      type : contentType
                                  });
                                  var url = urlCreator.createObjectURL(blob);
                                  link.setAttribute('href', url);

                                  // Set the download attribute (Supported in
                                  // Chrome 14+ / Firefox 20+)
                                  link.setAttribute("download", filename);

                                  // Simulate clicking the download link
                                  var event = document.createEvent('MouseEvents');
                                  event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                                  link.dispatchEvent(event);
                                  console.log("Download link method with simulated click succeeded");
                                  success = true;

                              } catch (ex) {
                                  console.log("Download link method with simulated click failed with the following exception:");
                                  console.log(ex);
                              }
                          }

                          if (!success) {
                              // Fallback to window.location method
                              try {
                                  // Prepare a blob URL
                                  // Use application/octet-stream when using
                                  // window.location to force download
                                  console.log("Trying download link method with window.location ...");
                                  var blob = new Blob([ data ], {
                                      type : octetStreamMime
                                  });
                                  var url = urlCreator.createObjectURL(blob);
                                  window.location = url;
                                  console.log("Download link method with window.location succeeded");
                                  success = true;
                              } catch (ex) {
                                  console.log("Download link method with window.location failed with the following exception:");
                                  console.log(ex);
                              }
                          }

                      }
                  }

                  if (!success) {
                      // Fallback to window.open method
                      console.log("No methods worked for saving the arraybuffer, using last resort window.open");
                      window.open(httpPath, '_blank', '');
                  }
              }).error(function(data, status) {
                  console.log("Request failed with status: " + status);
              });
          }
        };
    }).factory("LicenseService" , function(){

        var message={
          delete:'Are you sure that you want to delete this license?',
          cancel:'Are you sure that you want to cancel this license?',
          upload:'This action will publish on cloud this license.',
          export:'This action will publish this license.',
          update:'This action will re-publish this license.',
          cannotDeleteBecauseDevicesAssigned : 'Please remove devices from this license before Deleting it',
          cannotCancelBecauseDevicesAssigned : 'Please remove devices from this license before Canceling it',
          slotsLessThanDevices : 'Slots quantity must be higher or equal than devices assigned.',
          licenseExpiresEarlierThanToday : 'Cannot modify a license whose expiration date is Today or before Today.'
        };

        var modalTitle = {
          cancel: 'Cancel License',
          other: 'Download Licenses',
          upload:'Upload license CLOUD'
        };

        /**
        * Modal confirmation download licenses.     *
        * @author jmontoya
        *
        * Refactoring code
        * @author amejiav
        */
        var downloadLicenseModal = function(scope, messages, license, event, title){
          var checkBoxHTML='';
          var showCheckBox = license.fleet.deploymentType == 'ONSITE' && license.slotType.slotType == 'APP' ?true:false;
          if(showCheckBox){
              checkBoxHTML='<div class="form-group"><div class="col-md-4"> <label for="downloadFile-0"><input type="checkbox" name="downloadFile" id="downloadFile-0" value="Download Licenses"> Download Licenses </label> </div> </div>';
          }
          var message='<div class="row">  ' +'<div class="col-md-12"> ' +'<form class="form-horizontal"> ' +'<div class="form-group"> ' +'<div class="col-md-10"> ' +'<span  class="control-label">' + messages + '</span> </div> ' +'</div> '+checkBoxHTML+'</form> </div>  </div>';
          bootbox.dialog({
              title: title,
              message: message,
              buttons: {
                  cancel: {
                      label: "Cancel",
                      className: "btn btn-default btn-raised",
                      callback: function () {
                          if(event === 'edit'){
                              $('#saveLicenseModal').modal('show');
                          }
                      }
                  },
                  success: {
                      label: "Ok",
                      className: "btn btn-primary btn-raised",
                      callback: function () {
                          switch(event){
                              case 'edit':
                                  scope.updateAndExport(license);
                                  break;
                              case 'export':
                              {
                                  scope.page=1;
                                  scope.exportModalMethod(license);
                                  break;
                              }
                              case  'cancel':
                                  scope.cancelandExport(license);
                                  break;
                              case 'upload':
                                 scope.uploadLicense(license);
                                 break;
                              default:
                                  bootbox.alert('This feature is not implemented yet');
                                  break;
                          }
                      }
                  }
              }});
        };

        return {
          message : message,
          modalTitle : modalTitle,
          downloadLicenseModal : function(scope, messages, license, event, title){
              downloadLicenseModal(scope, messages, license, event, title);
          }
        }
    });
