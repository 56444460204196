'use strict';

angular.module('vmaxmanagerApp').controller('FleetDialogController',
    ['$scope', '$state', '$stateParams', '$translate', 'entity', 'Fleet', 'Server', 'Customer', 'SalesRegion', 'DeploymentType',
        function($scope, $state, $stateParams, $translate, entity, Fleet, Server, Customer, SalesRegion, DeploymentType) {

        $scope.fleet = entity;
        $scope.servers = Server.query();
        $scope.customers = Customer.query();
        $scope.salesregions = SalesRegion.query();
        $scope.deploymentTypes = DeploymentType.query();

        $scope.load = function(id) {
            Fleet.get({id : id}, function(result) {
                $scope.fleet = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:fleetUpdate', result);
            $scope.isSaving = false;
            $state.go('fleet');
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;

            var error = result.headers ? result.headers('X-vmaxmanagerApp-error') : null;
            if (!_.isNull(error)) {
                $translate('vmaxmanagerApp.fleet.' + error, { param: $scope.fleet.fleetId } ).then(function(fleetIdError) {
                    bootbox.alert(fleetIdError);
                    $scope.fleet.fleetId = '';
                });
            }
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.fleet.id != null) {
                Fleet.update($scope.fleet, onSaveSuccess, onSaveError);
            } else {
                Fleet.save($scope.fleet, onSaveSuccess, onSaveError);
            }
        };

        $scope.deploymentTypeChange = function() {
            $scope.fleet.server = null;
        };
}]);
