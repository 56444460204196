(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('AppResourceUserProfileDialogController', AppResourceUserProfileDialogController);

    AppResourceUserProfileDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', 'entity', 'AppResourceUserProfile', 'DeviceType'];

    function AppResourceUserProfileDialogController ($timeout, $scope, $stateParams, $state, entity, AppResourceUserProfile, DeviceType) {
        var vm = this;

        vm.appResourceUserProfile = entity;
        vm.save = save;
        vm.deviceTypes = DeviceType.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function save () {
            vm.isSaving = true;
            if (vm.appResourceUserProfile.id !== null) {
                AppResourceUserProfile.update(vm.appResourceUserProfile, onSaveSuccess, onSaveError);
            } else {
                AppResourceUserProfile.save(vm.appResourceUserProfile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('vmaxmanagerApp:appResourceUserProfileUpdate', result);
            vm.isSaving = false;
            $state.go('app-resource-user-profile');
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
