'use strict';

angular.module('vmaxmanagerApp')
    .config(function($stateProvider) {
        $stateProvider
            .state('bulkDeviceContainer', {
                abstract: true,
                parent: 'device'
            })
            .state('bulkDevice', {
                parent: 'bulkDeviceContainer',
                url: '/bulk',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.bulk.device.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/bulk/bulk.html',
                        controller: 'BulkController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bulk');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.bulk.device.title',
                    parent: 'device'
                }
            })
            .state('bulkDevice.selectDevices', {
                parent: 'bulkDevice',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.bulk.device.title',
                    stepNumber: 1,
                    process: "device"
                },
                params: {
                    selectedDevices: [],
                    processType: null
                },
                views: {
                    'contentBulk@bulkDevice': {
                        templateUrl: 'scripts/app/bulk/device/selectDevices/bulkDevice-selectDevices.html',
                        controller: 'BulkDeviceSelectDevicesController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bulk');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.bulk.device.selectDevice',
                    parent: 'device'
                }
            })
            .state('bulkDevice.operationDetails', {
                parent: 'bulkDevice.selectDevices',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.bulk.device.title',
                    stepNumber: 2,
                    process: "device"
                },
                views: {
                    'contentBulk@bulkDevice': {
                        templateUrl: 'scripts/app/bulk/device/operationDetails/bulkDevice-operationDetails.html',
                        controller: 'BulkDeviceOperationDetailsController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bulk');
                        return $translate.refresh();
                    }],
                    providersList: ['Providers', function(Providers) {
                        return Providers.query().$promise;
                    }],
                    statusList: ['BulkDeviceStatus', function(BulkDeviceStatus) {
                        return BulkDeviceStatus.get().$promise;
                    }]
                }
            })
            .state('bulkDevice.operationDetails-manual', {
                parent: 'bulkDevice.selectDevices',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.bulk.device.title',
                    stepNumber: 2,
                    process: "device"
                },
                views: {
                    'contentBulk@bulkDevice': {
                        templateUrl: 'scripts/app/bulk/device/operationDetails/bulkDevice-operationDetails-manual.html',
                        controller: 'BulkDeviceOperationDetailsManualController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bulk');
                        return $translate.refresh();
                    }],
                    providersList: ['Providers', function(Providers) {
                        return Providers.query().$promise;
                    }],
                    statusList: ['BulkDeviceStatus', function(BulkDeviceStatus) {
                        return BulkDeviceStatus.get().$promise;
                    }],
                    entity:  function() {
                        return {
                            provider: null,
                            provisionDate: null,
                            status: null
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.bulk.device.operationDetails',
                    parent: 'device'
                }
            })
            .state('bulkDevice.confirmation', {
                parent: 'bulkDevice.operationDetails',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.bulk.device.title',
                    stepNumber: 3,
                    process: "device"
                },
                views: {
                    'contentBulk@bulkDevice': {
                        templateUrl: 'scripts/app/bulk/device/confirmation/bulkDevice-confirmation.html',
                        controller: 'BulkDeviceConfirmationController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bulk');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.bulk.device.confirmation',
                    parent: 'device'
                }
            })
            .state('bulkDevice.confirmation-manual', {
                parent: 'bulkDevice.operationDetails-manual',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.bulk.device.title',
                    stepNumber: 3,
                    process: "device"
                },
                views: {
                    'contentBulk@bulkDevice': {
                        templateUrl: 'scripts/app/bulk/device/confirmation/bulkDevice-confirmation.html',
                        controller: 'BulkDeviceConfirmationController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bulk');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.bulk.device.confirmation',
                    parent: 'device'
                }
            })
            .state('bulkDevice.results', {
                parent: 'bulkDevice',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.bulk.device.title',
                    stepNumber: 4,
                    process: "device"
                },
                views: {
                    'contentBulk@bulkDevice': {
                        templateUrl: 'scripts/app/bulk/device/results/bulkDevice-results.html',
                        controller: 'BulkDeviceResultsController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bulk');
                        return $translate.refresh();
                    }],
                    updateResponse: ['BulkUpdateDevices', 'BulkService', function(BulkUpdateDevices, BulkService) {
                        var devicesToUpdate =  BulkService.getDevicesToUpdate();
                        return BulkUpdateDevices.update(devicesToUpdate).$promise;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.bulk.device.confirmation',
                    parent: 'device'
                }
            })
            .state('bulkDevice.operationDetails.backFromFile', {
                parent: 'bulkDevice.operationDetails',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$state', '$uibModal', function($state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/bulk/device/operationDetails/bulkDevice-operationDetails-back.html',
                        controller: 'BulkDeviceOperationDetailsBackController',
                        size: 'md',
                        backdrop: 'static',
                        keyboard: false
                    }).result.then(function() {
                        $state.go('bulkDevice.selectDevices', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('bulkDevice.operationDetails-manual.backManual', {
                parent: 'bulkDevice.operationDetails-manual',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$state', '$uibModal', function($state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/bulk/device/operationDetails/bulkDevice-operationDetails-back.html',
                        controller: 'BulkDeviceOperationDetailsBackController',
                        size: 'md',
                        backdrop: 'static',
                        keyboard: false
                    }).result.then(function() {
                        $state.go('bulkDevice.selectDevices', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('bulkDevice.confirmation.next', {
                parent: 'bulkDevice.confirmation',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$state', '$uibModal', function($state, $uibModal, $stateParams) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/bulk/device/confirmation/bulkDevice-confirmation-next.html',
                        controller: 'BulkDeviceConfirmationNextController',
                        size: 'md',
                        backdrop: 'static',
                        keyboard: false
                    }).result.then(function() {
                        $state.go('bulkDevice.results');
                    }, function() {
                        $state.go('bulkDevice.confirmation');
                    });
                }]
            })
            .state('bulkDevice.confirmation-manual.next', {
                parent: 'bulkDevice.confirmation-manual',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
                },
                onEnter: ['$state', '$uibModal', function($state, $uibModal, $stateParams) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/bulk/device/confirmation/bulkDevice-confirmation-next.html',
                        controller: 'BulkDeviceConfirmationNextController',
                        size: 'md',
                        backdrop: 'static',
                        keyboard: false
                    }).result.then(function() {
                        $state.go('bulkDevice.results');
                    }, function() {
                        $state.go('bulkDevice.confirmation-manual');
                    });
                }]
            })
    });
