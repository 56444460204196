'use strict';

angular.module('vmaxmanagerApp')
    .directive('export', function (ExportService, $filter,
                                   Server, Customer, Fleet, License, SalesRegion, Device, DeviceType, SlotType, Providers, Software,
                                   SoftwareLicense, SettingsInstance, User, DownloadResourcesAudit, MapServerToExport, MapCustomerToExport, MapFleetToExport,
                                   MapLicenseToExport, MapSalesRegionToExport, MapDeviceToExport, MapDeviceTypeToExport, MapSlotTypeToExport,
                                   MapProvidersToExport, MapSoftwareToExport, MapSoftwareLicenseToExport, MapSettingToExport, MapUserToExport,
                                   AppResource, AppResourceUserProfile, MapAppResourceToExport, MapAppResourceUserProfileToExport,
                                   MapDownloadResourceAuditToExport) {
        return {
            restrict: 'E',
            scope: {
                table: '@table',
                filter: '=filter'
            },
            template: '<i class="glyphicon glyphicon-export export-element" ng-click="exportData()" rel="tooltip" title="Export to Excel"></i>',
            link: function postLink(scope, element, attrs, form) {

                scope.exportData = function(){

                    var date = $filter('date')(new Date(), 'MMddyyyyhhmmss');

                    switch(scope.table){
                        case "server":
                            Server.query({}, function(result, headers) {
                                var exportData = MapServerToExport.convert(result);
                                ExportService.setStyle("Server sheet", "Servers");
                                ExportService.exportToExcel("server-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "customer":
                            Customer.query({}, function(result, headers) {
                                var exportData = MapCustomerToExport.convert(result);
                                ExportService.setStyle("Customer sheet", "Customers");
                                ExportService.exportToExcel("customer-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "fleet":
                            Fleet.query({}, function(result, headers) {
                                var exportData = MapFleetToExport.convert(result);
                                ExportService.setStyle("Fleet sheet", "Fleets");
                                ExportService.exportToExcel("fleet-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "license":
                            License.query({}, function(result, headers) {
                                var exportData = MapLicenseToExport.convert(result);
                                ExportService.setStyle("License sheet", "Licenses");
                                ExportService.exportToExcel("license-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "salesRegion":
                            SalesRegion.query({}, function(result, headers) {
                                var exportData = MapSalesRegionToExport.convert(result);
                                ExportService.setStyle("Sales Region sheet", "Sales Regions");
                                ExportService.exportToExcel("salesRegion-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "device":
                            Device.query({}, function(result, headers) {
                                var exportData = MapDeviceToExport.convert(result);
                                ExportService.setStyle("Device sheet", "Devices");
                                ExportService.exportToExcel("device-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "deviceType":
                            DeviceType.query({}, function(result, headers) {
                                var exportData = MapDeviceTypeToExport.convert(result);
                                ExportService.setStyle("Device Type sheet", "Device Types");
                                ExportService.exportToExcel("deviceType-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "slotType":
                            SlotType.query({}, function(result, headers) {
                                var exportData = MapSlotTypeToExport.convert(result);
                                ExportService.setStyle("Slot Type sheet", "Slot Types");
                                ExportService.exportToExcel("slotType-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "providers":
                            Providers.query({}, function(result, headers) {
                                var exportData = MapProvidersToExport.convert(result);
                                ExportService.setStyle("Providers sheet", "Providers");
                                ExportService.exportToExcel("provider-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "software":
                            Software.query({}, function(result, headers) {
                                var exportData = MapSoftwareToExport.convert(result);
                                ExportService.setStyle("Software sheet", "Software");
                                ExportService.exportToExcel("software-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "softwareLicense":
                            SoftwareLicense.query({}, function(result, headers) {
                                var exportData = MapSoftwareLicenseToExport.convert(result);
                                ExportService.setStyle("Software License sheet", "Software License");
                                ExportService.exportToExcel("softwareLicense-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "setting":
                            SettingsInstance.query({}, function(result, headers) {
                                var exportData = MapSettingToExport.convert(result);
                                ExportService.setStyle("Settings sheet", "Settings");
                                ExportService.exportToExcel("settings-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "user":
                            User.query({}, function(result, headers) {
                                var exportData = MapUserToExport.convert(result);
                                ExportService.setStyle("User sheet", "Users");
                                ExportService.exportToExcel("user-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "appResource":
                            AppResource.query({}, function(result, headers) {
                                var exportData = MapAppResourceToExport.convert(result);
                                ExportService.setStyle("App Resource sheet", "App Resource");
                                ExportService.exportToExcel("appResource-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "appResourceUserProfile":
                            AppResourceUserProfile.query({}, function(result, headers) {
                                var exportData = MapAppResourceUserProfileToExport.convert(result);
                                ExportService.setStyle("App Resource User Profile sheet", "App Resource User Profile");
                                ExportService.exportToExcel("appResourceUserProfile-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                        case "downloadResourceAudit":
                            DownloadResourcesAudit.query({}, function(result, headers) {
                                var exportData = MapDownloadResourceAuditToExport.convert(result);
                                ExportService.setStyle("Download Resource Audit sheet", "Download Resource Audit");
                                ExportService.exportToExcel("downloadResourceAudit-"+date, ExportService.getStyle(), exportData);
                            });
                        break;
                    }
                };
            }
        }
    });

