/* jshint quotmark: false */
"use strict";
// DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
angular.module("vmaxmanagerApp")

.constant("ENV", "prod")

.constant("VERSION", "1.10.2")

.constant("USER_ROLES", {
	"all": "*",
	"admin": "ROLE_SYSTEM_ADMIN",
	"user": "ROLE_OPERATIONS",
	"operator": "ROLE_OPERATIONS_ADMIN"
})

;