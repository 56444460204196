'use strict';

angular.module('vmaxmanagerApp')
    .directive('limitTo', function ($parse) {
        return {
            restrict: "A",
            require: 'ngModel',
            link: function(scope, elem, attrs, ngModel) {
                var limit = parseInt(attrs.limitTo);
                scope.$watch(attrs.ngModel, function (e) {
                    if (ngModel.$viewValue && ngModel.$viewValue.length > limit){
                        ngModel.$setViewValue(ngModel.$viewValue.substring(0, limit) );
                        ngModel.$render();
                    }
                });
            }
        }
    });
