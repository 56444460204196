'use strict';
angular.module('vmaxmanagerApp')
    .controller('SettingsInstanceController', function ($scope, $state, $translate, $filter, AlertService, SettingsInstance, ParseLinks) {

    	$scope.settingsInstances = [];
        $scope.reverse = true;
        $scope.page = 0;
        $scope.predicate = 'name';
        $scope.loadAll = function() {
        	SettingsInstance.query({page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.settingsInstances.push(result[i]);
                }
            });
        }

    $scope.reset = function() {
        $scope.page = 0;
        $scope.settingsInstances = [];
        $scope.loadAll();
    };
    $scope.loadPage = function(page) {
        $scope.page = page;
        $scope.loadAll();
    };

    $scope.loadAll();

    $scope.refresh = function () {
        $scope.reset();
        $scope.clear();
    };

    $scope.clear = function () {
        $scope.settingsInstances = {
            name: null,
            notes: null,
            template: null,
            id: null
        };
    };

    /**
     * Validate if can be delete
     * @since 2016-07-07
     * @autor jcardona
     */
    $scope.validateDelete = function (params) {
    	 $scope.settingsId = params.id;
    	 $scope.result = SettingsInstance.existSettingsInstanceAppliedToTypeSettingsId({typeSettings:params.typeSettings,id: params.id},function() {
             if($scope.result.quantity>0){
            	 AlertService.error($filter('translate')('error.dataIntegrity'));
        	 }else{
        		 $state.go('settings-instance.delete', { id: $scope.settingsId }, { reload: true });
        	 }
    	 });
    };


});
