'use strict';

angular.module('vmaxmanagerApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('providers', {
                parent: 'entity',
                url: '/providers',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.providers.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/providers/providers.html',
                        controller: 'ProvidersController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('providers');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.providers.home.title'
                }
            })
            .state('providers.detail', {
                parent: 'providers',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.providers.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/providers/providers-detail.html',
                        controller: 'ProvidersDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('providers');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Providers', function($stateParams, Providers) {
                        return Providers.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'providers'
                }
            })
            .state('providers.new', {
                parent: 'providers',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/providers/providers-dialog.html',
                        controller: 'ProvidersDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('providers');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
	                            name: null,
	                            icon: null,
	                            id: null
                             };;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'providers'
                }
            })
            .state('providers.edit', {
                parent: 'providers',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/providers/providers-dialog.html',
                        controller: 'ProvidersDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('providers');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Providers', function($stateParams, Providers) {
                        return Providers.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'providers'
                }
            })
            .state('providers.delete', {
                parent: 'providers',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/providers/providers-delete-dialog.html',
                        controller: 'ProvidersDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['Providers', function(Providers) {
                                return Providers.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('providers', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    });
