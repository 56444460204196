'use strict';

angular.module('vmaxmanagerApp')
    .service('UrlService', function ($filter) {

    this.validURL = function(str) {

        var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return regexp.test(str);
    }
});
