'use strict';

angular.module('vmaxmanagerApp').controller('SoftwareDialogController',
    ['$scope', '$state', '$timeout','Upload', 'entity', 'Software', 'SoftwareGroup', 'ParseLinks',
        function($scope, $state, $timeout, Upload, entity, Software, SoftwareGroup, ParseLinks) {

        $scope.software = entity;
        $scope.softwaregroups = SoftwareGroup.query();
        $scope.fileReaderSupported = window.FileReader != null && (window.FileAPI == null || FileAPI.html5 != false);
        $scope.load = function(id) {
            Software.get({id : id}, function(result) {
                $scope.software = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:softwareUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.create = function () {

        	$scope.isSaving = true;
            var fileObject = { file: null };

            if($scope.software.picFile && $scope.software.picFile.size > 0){
                fileObject.file = $scope.software.picFile;
            }
            if(!$scope.software.id){
                $scope.software.id = 0;
            }

            var software = {
            		'id': $scope.software.id,
            		'name': $scope.software.name,
            		'icon': $scope.software.icon,
            		'cloud': $scope.software.cloud,
            		'local': $scope.software.local,
            		'softwareGroup': $scope.software.softwareGroup
            };

            fileObject.upload = Upload.upload({
                url: 'api/softwares',
                method: 'POST',
                data: {'software' : angular.toJson(software) },
                sendObjectsAsJsonBlob: false,
                file: fileObject.file
            });

            fileObject.upload.then(function(response) {
                fileObject.result = response.data;
                $scope.softwares = [];
                $scope.page=1;
                $scope.perPage=20;
                $state.go('software');
            }, function(response,message) {
                if (response.status > 0){
                    bootbox.dialog({
                        message: "Sorry something has gone bad, please contact to SEON",
                        title: "Server Error",
                        buttons: {
                          success: {
                            label: "Ok!",
                            className: "btn-danger",
                            callback: function() {
                                console.log(response.status + ': ' + response.data);
                            }
                          }
                        }});
                }
            });
        };

        $scope.onFileSelect=function(file){
              if (file != null) {
                  if ($scope.fileReaderSupported && file.type.indexOf('image') > -1) {
                      $timeout(function() {
                          var fileReader = new FileReader();
                          fileReader.readAsDataURL(file);
                          fileReader.onload = function(e) {
                              $timeout(function() {
                                  file.dataUrl = e.target.result;
                              });
                          };
                      });
                  }
              }
          };
}]);
