'use strict';

angular.module('vmaxmanagerApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('downloads', {
                abstract: true,
                parent: 'site',
                url: '/downloads',
            });
    });
