'use strict';

angular.module('vmaxmanagerApp')
	.controller('LicenseUnassignDeviceSettingController', function($scope, $translate, $rootScope, $q, $stateParams, $state, $uibModalInstance,
	                                                settings, AlertService, AssignDeviceService, license) {

        var findStatus = function(){
            AssignDeviceService.findDeviceStatuses(function(result){
                $scope.deviceStatusList = [];
                _.each(result,function(item){
                    $scope.deviceStatusList.push({ "id" : item, "name" : item });
                });
            });
        }

        findStatus();

        var assignDevice = $stateParams.assignDevice;

        $scope.settings = settings;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        /**
         * Un Assign Devices to License and retrieve the new lists for the Assigned and Available devices
         */
        $scope.unAssignDevicesToLicenseBatch = function(){
            var unAssignDeviceDto = { licenseId : assignDevice.license.id, newStatus : $scope.deviceStatus , devices : new Array()};
            _.each(assignDevice.assignedDevices, function(device){
                if(device.checked){
                    unAssignDeviceDto.devices.push(device.id);
                }
            });

            AssignDeviceService.unAssignDeviceToLicense(unAssignDeviceDto, $scope.refreshDevicesForLicense);
        }

        $scope.refreshDevicesForLicense = function(){
            if(typeof(license.id) == 'undefined' || license == null){
                return;
            }
            $scope.settings = null;
            $uibModalInstance.close(true);
        };
   });
