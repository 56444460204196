'use strict';

angular.module('vmaxmanagerApp')
    .controller('BulkDeviceConfirmationController', function ($scope, $stateParams, BulkService, $state, BulkConstants) {

        var deviceSelectedList = BulkService.getDevicesSelected();
        var resultConfirmation = BulkService.getResponseConfirmation();
        var content = 0;

        var mapDateFields = function(){
            if(resultConfirmation && _.isArray(resultConfirmation)){
                _.each(resultConfirmation, function(result){
                    if(result.columnId == "provisionDate" && moment(result.value).isValid()){
                        var dateMoment = moment(result.value).toLocaleString();
                        var date = moment(dateMoment).format('YYYY-MM-DD HH:mm:ss');
                        result.value = date.toString();
                    }
                });
            }
        }

        if ( $state.current.parent == 'bulkDevice.operationDetails-manual') {
            content = BulkService.getDevicesSelected();
            $scope.deviceSelectedLength = !_.isNaN(content.length) ? content.length : 0;
        } else {
            content = BulkService.getFileContent();
            $scope.deviceSelectedLength = !_.isNaN(content.length) ? (content.length - 1) : 0;
        }

        mapDateFields();
        $scope.responses = resultConfirmation;

        $scope.goToModalConfirmation = function(){
            if ( $state.current.parent == 'bulkDevice.operationDetails-manual') {
                $state.go("bulkDevice.confirmation-manual.next");
            } else {
                $state.go("bulkDevice.confirmation.next");
            }
        }

        /*
        * Go to back page.
        */
        $scope.goToPreviouslyStep = function() {
            $state.go($state.current.parent);
        };
    });
