'use strict';

angular.module('vmaxmanagerApp')
    .controller('DeviceDetailController', function ($scope, $rootScope, $stateParams, entity, DeviceStatusMonitoring, Fleet, DeviceType, Providers, License, $translate, localDateDeviceSettingRequestAudit, UtcDateString) {

        var parseJsonTemplates = function(){
            if(entity.settingsInstance){
                if(entity.settingsInstance.template){
                    entity.settingsInstance.template = JSON.parse(entity.settingsInstance.template);
                }
                if(entity.settingsInstance.settingsTemplate && entity.settingsInstance.settingsTemplate.template){
                    entity.settingsInstance.settingsTemplate.template = JSON.parse(entity.settingsInstance.settingsTemplate.template);
                }
            }
        }

        var buildLocalDate = function(info){
            if(info && info.localDate){
                var newLocalDateString = UtcDateString.createString(info.localDate);
                $scope.localDateRequestAudit = moment(new Date(newLocalDateString)).format('MM/DD/YYYY HH:mm:ss');
            }else{
                $scope.localDateRequestAudit = null;
            }
        }

        parseJsonTemplates();
        buildLocalDate(localDateDeviceSettingRequestAudit);

        $scope.device = entity;

        $scope.load = function (id) {
            DeviceStatusMonitoring.get({id: id}, function(result) {
                $scope.device = result;
            });
        };
        var unsubscribe = $rootScope.$on('vmaxmanagerApp:deviceUpdate', function(event, result) {
            $scope.device = result;
        });
        $scope.$on('$destroy', unsubscribe);

        var translations = {
            'PATTERN_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.pattern'),
            'REQUIRED_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.required'),
            'MAX_LENGTH_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.maxLength'),
            'URL_EXISTS_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.urlExits')
        };

        // The layout configuration could be: "narrow" or "wide".
        $scope.settingsManagerConfig = {
            layout: 'narrow',
            labelClass: 'control-label',
            inputGroupClass: 'form-group label-floating',
            inputClass: 'form-control',
            selectClass: 'form-control',
            errorTextClass: '',
            translations: translations
        };

        $scope.deploymentTypeIcon = function(){
            if($scope.device && $scope.device.fleet && $scope.device.fleet.deploymentType){
                return $scope.device.fleet.deploymentType === "ONSITE"? 'business' : 'cloud';
            }
        }

    });
