(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('app-resource', {
            parent: 'entity',
            url: '/app-resource',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.appResource.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/appResource/app-resources.html',
                    controller: 'AppResourceController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appResource');
                    $translatePartialLoader.addPart('resourceTypeEnum');
                    $translatePartialLoader.addPart('restrictionDownloadTypeEnum');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            },
            ncyBreadcrumb: {
              label: 'vmaxmanagerApp.appResource.home.title'
            }
        })
        .state('app-resource.view', {
            parent: 'app-resource',
            url: '/{id}/view',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.appResource.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/appResource/app-resource-detail.html',
                    controller: 'AppResourceDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appResource');
                    $translatePartialLoader.addPart('resourceTypeEnum');
                    $translatePartialLoader.addPart('restrictionDownloadTypeEnum');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AppResource', function($stateParams, AppResource) {
                    return AppResource.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'app-resource',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            },
            ncyBreadcrumb: {
                label: 'entity.action.view',
                parent: 'app-resource'
            }
        })
        .state('app-resource.new', {
            parent: 'app-resource',
            url: '/new',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/appResource/app-resource-dialog.html',
                    controller: 'AppResourceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        name: null,
                        url: null,
                        maxDownloads: null,
                        resourceTypeEnum: null,
                        restrictionDownloadTypeEnum: null,
                        id: null
                    };
                },
                allDeviceStatus: ['AllDeviceStatus', function(AllDeviceStatus) {
                    return AllDeviceStatus.query({}).$promise;
                }],
                profiles: [function() {
                  return [];
                }]
            },
            ncyBreadcrumb: {
                label: 'entity.action.new',
                parent: 'app-resource'
            }
        })
        .state('app-resource.edit', {
            parent: 'app-resource',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/appResource/app-resource-dialog.html',
                    controller: 'AppResourceDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['AppResource', '$stateParams', function(AppResource, $stateParams) {
                    return AppResource.get({id : $stateParams.id}).$promise;
                }],
                allDeviceStatus: ['AllDeviceStatus', function(AllDeviceStatus) {
                    return AllDeviceStatus.query({}).$promise;
                }],
                profiles: ['AppResourceUserProfileByResource', '$stateParams', function(AppResourceUserProfileByResource, $stateParams) {
                    return AppResourceUserProfileByResource.query({id : $stateParams.id}).$promise;
                }]
            },
            ncyBreadcrumb: {
                label: 'entity.action.edit',
                parent: 'app-resource'
            }
        })
        .state('app-resource.delete', {
            parent: 'app-resource',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'scripts/app/entities/appResource/app-resource-delete-dialog.html',
                    controller: 'AppResourceDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AppResource', function(AppResource) {
                            return AppResource.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('app-resource', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
