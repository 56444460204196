'use strict';

angular.module('vmaxmanagerApp')
    .factory('SoftwareGroup', function ($resource, DateUtils) {
        return $resource('api/softwareGroups/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'findSoftwareGroupWithSettingsTemplate':
            {
                method:'GET',
                isArray: true,
                url : 'api/softwareGroups/findSoftwareGroupWithSettingsTemplate'
            }
        });
    });
