'use strict';

angular.module('vmaxmanagerApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('emailBounce', {
                parent: 'entity',
                url: '/emailBounces',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.emailBounce.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/emailBounce/emailBounces.html',
                        controller: 'EmailBounceController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('emailBounce');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.emailBounce.home.title'
                }
            })
            .state('emailBounce.detail', {
                parent: 'entity',
                url: '/emailBounce/{id}',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.emailBounce.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/emailBounce/emailBounce-detail.html',
                        controller: 'EmailBounceDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('emailBounce');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'EmailBounce', function($stateParams, EmailBounce) {
                        return EmailBounce.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'emailBounce'
                }
            });
    });
