'use strict';

angular.module('vmaxmanagerApp')
    .factory('SoftwareLicense', function ($resource, DateUtils) {
        return $resource('api/softwareLicenses/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getSoftwareLicensesByGroupId': {method:'GET', url:'api/softwareLicenses/softwaregroupid/:softwaregroupid' ,
                isArray: true,
                transformResponse: function(data){
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    });
