'use strict';

angular.module('vmaxmanagerApp')
    .controller('ExpandableDeviceController', function ($scope,$q,DeviceType,ExpandableService) {

    	$scope.devicetypes = DeviceType.query();
    	$scope.deviceTypeObj;
    	$scope.messageError;
    	$scope.inProgress;
    	$scope.status;
    	$scope.resultSyncronice; 
    	
        /**
         * Selected DeviceType.
         * @author jcardona
         */
        $scope.selectedDeviceType = function(){
        	$scope.status = null;
        	$scope.messageError = null;  
        	$scope.inProgress = false;
        	$scope.resultSyncronice = null;
        };
    	
        
        /**
         * Synchonize
         * @author jcardona
         */
        $scope.synchronizeDeviceType = function(){
        	$scope.status = null;
        	$scope.messageError = null;
        	$scope.inProgress = true;
        	$scope.resultSyncronice = ExpandableService.getSincronizeExpandableDeviceType({deviceTypeId:$scope.deviceTypeObj.id});
           	$q.all([$scope.resultSyncronice.$promise]).then(function() {
           		$scope.status= $scope.resultSyncronice.status;
           		if($scope.resultSyncronice.status=='ERROR'){
           			$scope.messageError = $scope.resultSyncronice.errorMessage;
           		}
            	$scope.inProgress = false;
            	$scope.deviceTypeObj = null;
            	$scope.devicetypes = DeviceType.query();
        	});
        };
    	
    });
