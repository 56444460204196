'use strict';

angular.module('vmaxmanagerApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('deviceType', {
                parent: 'entity',
                url: '/deviceTypes',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.deviceType.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/deviceType/deviceTypes.html',
                        controller: 'DeviceTypeController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('deviceType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.deviceType.home.title'
                }
            })
            .state('deviceType.detail', {
                parent: 'deviceType',
                url: '/{id}/view',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN'],
                    pageTitle: 'vmaxmanagerApp.deviceType.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/deviceType/deviceType-detail.html',
                        controller: 'DeviceTypeDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('deviceType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'DeviceType', function($stateParams, DeviceType) {
                        return DeviceType.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.view',
                    parent: 'deviceType'
                }
            })
            .state('deviceType.new', {
                parent: 'deviceType',
                url: '/new',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/deviceType/deviceType-dialog.html',
                        controller: 'DeviceTypeDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('deviceType');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
                        	 name: null,
                        	 partId: null,
                        	 deviceIdLabel: null,
                             id: null
                             };;
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.new',
                    parent: 'deviceType'
                }
            })
            .state('deviceType.edit', {
                parent: 'deviceType',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/deviceType/deviceType-dialog.html',
                        controller: 'DeviceTypeDialogController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('deviceType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'DeviceType', function($stateParams, DeviceType) {
                        return DeviceType.get({id : $stateParams.id});
                    }]
                },
                ncyBreadcrumb: {
                    label: 'entity.action.edit',
                    parent: 'deviceType'
                }
            })
            .state('deviceType.delete', {
                parent: 'deviceType',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/deviceType/deviceType-delete-dialog.html',
                        controller: 'DeviceTypeDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['DeviceType', function(DeviceType) {
                                return DeviceType.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('deviceType', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    });
