(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('app-resource-user-profile', {
            parent: 'entity',
            url: '/app-resource-user-profile',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.appResourceUserProfile.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/appResourceUserProfile/app-resource-user-profiles.html',
                    controller: 'AppResourceUserProfileController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appResourceUserProfile');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            },
             ncyBreadcrumb: {
                 label: 'vmaxmanagerApp.appResourceUserProfile.home.title'
             }
        })
        .state('app-resource-user-profile.view', {
            parent: 'entity',
            url: '/{id}/view',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.appResourceUserProfile.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/appResourceUserProfile/app-resource-user-profile-detail.html',
                    controller: 'AppResourceUserProfileDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appResourceUserProfile');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AppResourceUserProfile', function($stateParams, AppResourceUserProfile) {
                    return AppResourceUserProfile.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'app-resource-user-profile',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            },
             ncyBreadcrumb: {
               label: 'entity.action.view',
               parent: 'app-resource-user-profile'
           }
        })
        .state('app-resource-user-profile.edit', {
            parent: 'app-resource-user-profile',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
            },
            views: {
                'content@': {
                     templateUrl: 'scripts/app/entities/appResourceUserProfile/app-resource-user-profile-dialog.html',
                     controller: 'AppResourceUserProfileDialogController',
                     controllerAs: 'vm'
                }
            },
            resolve: {
                 translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                     $translatePartialLoader.addPart('appResourceUserProfile');
                     return $translate.refresh();
                 }],
                 entity: ['$stateParams', 'AppResourceUserProfile', function($stateParams, AppResourceUserProfile) {
                     return AppResourceUserProfile.get({id : $stateParams.id}).$promise;
                 }]
             },
              ncyBreadcrumb: {
                label: 'entity.action.edit',
                parent: 'app-resource-user-profile'
            }
        })
        .state('app-resource-user-profile.new', {
            parent: 'app-resource-user-profile',
            url: '/new',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/appResourceUserProfile/app-resource-user-profile-dialog.html',
                    controller: 'AppResourceUserProfileDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                     $translatePartialLoader.addPart('appResourceUserProfile');
                     return $translate.refresh();
                 }],
                 entity: [function() {
                     return {
                         id: null,
                         name: null,
                         deviceType: null
                     };
                 }]
            },
            ncyBreadcrumb: {
                label: 'entity.action.new',
                parent: 'app-resource-user-profile'
            }
        })
         .state('app-resource-user-profile.delete', {
            parent: 'app-resource-user-profile',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'scripts/app/entities/appResourceUserProfile/app-resource-user-profile-delete-dialog.html',
                    controller: 'AppResourceUserProfileDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AppResourceUserProfile', function(AppResourceUserProfile) {
                            return AppResourceUserProfile.get({id : $stateParams.id}).$promise;
                        }],
                        associatedResources: ['AppResourceProfiler', function(AppResourceProfiler) {
                            return AppResourceProfiler.getAppResourceProfilerByProfileId({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('app-resource-user-profile', null, { reload: 'app-resource-user-profile' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
