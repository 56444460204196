'use strict';

angular.module('vmaxmanagerApp')
	.controller('LicenseAssignDeviceController', function($scope, $translate, $rootScope, $q, $stateParams, $state,$filter, ParseLinks, entity,
			DeviceType, AssignDeviceService, SettingsInstance, License, AlertService, SettingsTemplate) {

        //Device Type Available
        $scope.deviceType;
        $scope.deviceTypeAssign;
        $scope.settingsInstance;
        $scope.deviceStatus;
        $scope.settings;
        $scope.license = entity;
        $scope.nonRenewalTooltip = $translate.instant('vmaxmanagerApp.license.nonRenewalStatus');


        /**
         * Refreshes the Devices loading since the first page
         */
        $scope.refreshDevicesForLicense = function(){
            if(typeof($scope.license.id) == 'undefined' || $scope.license == null){
                return;
            }

            var promiseFindAvailableToLicense = AssignDeviceService.findAvailableToLicense($scope.license.id, null, $scope.setAvailableDevicesToScope);
            var promiseFindAssignedByLicense = AssignDeviceService.findAssignedByLicense($scope.license.id, null, $scope.setAssignedDevicesToScope);
            var promiseFindDeviceTypeAssignedToLicense = DeviceType.findDeviceTypeAssignedToLicense({licenseId:$scope.license.id}).$promise;
            var promiseFindDeviceTypeAvailableToLicense = DeviceType.findDeviceTypeAvailableToLicense({licenseId:$scope.license.id}).$promise;

            $q.all([promiseFindAvailableToLicense,
                    promiseFindAssignedByLicense,
                    promiseFindDeviceTypeAssignedToLicense,
                    promiseFindDeviceTypeAvailableToLicense]).then(function(promise) {

                $scope.deviceTypesAssign = promise[2];
                $scope.devicetypes = promise[3];
                $scope.assignDevice.checkAllAsig = false;
                $scope.assignDevice.checkAll = false;
                $scope.settings = null;
            });

        };

        /** Set the available devices retrieved from the Server into the Scope */
        $scope.setAvailableDevicesToScope=function(result, headers){
            $scope.assignDevice.availableDevices = result;
            _.each($scope.assignDevice.availableDevices,function(device){
                if(typeof(device.checked) != 'undefined'){
                    device.checked = true;
                }else{
                    device.checked = false;
                }

            });
            $scope.verifyListAvailableChecked();
        };

        /** Set the Assigned devices retrieved from the server into the scope */
        $scope.setAssignedDevicesToScope=function(result, headers){
            $scope.assignDevice.assignedDevices = result;
        };

        /** Set the Device settings retrieved from the server into the scope */
        $scope.setDeviceSettingsToScope=function(result){
            $scope.assignDevice.settingsOptions = result;
        };

        /**
                 * Load Assign Devices screen for a License
                 */
        if($stateParams.id){

            /** Initialize the Assign Device Object. Useful template to recognize the used parameters while assigning a Device to a License */
            $scope.assignDevice = {
                    "license" : $scope.license,
                    "settings" : 0,
                    "checkAll" : false,
                    "availableDevices" : [],
                    "assignedDevices" : [],
                    unAssignDto : {  "deviceId" : null, "licenseId" : $scope.license.id, "newStatus" : "" }
            };
            $scope.refreshDevicesForLicense();
        };


        /**
         * Performs the preload of a Device and prepare it for removal
         * @author william.cornejo
         */
        $scope.unAssignDeviceToLicenseLoad = function(device){
            $scope.assignDevice.unAssignDto.deviceId = device.id;
        }


        /**/////////////////////////////////// Ckeck rows ////////////////////////*/

        /**
         * Device Type Device Available Change.
         * @since 22/06/2016
         */
        $scope.deviceTypeChange = function(){
        	$scope.settings = SettingsInstance.getSettingsInstanceByDeviceType({deviceType:$scope.deviceType});
        	$scope.settingsTemplate = SettingsTemplate.getSettingsTemplatesByDeviceType({deviceType:$scope.deviceType});

        	AssignDeviceService.findAvailableToLicense($stateParams.id, $scope.deviceType, $scope.setAvailableDevicesToScope);
        	$scope.assignDevice.checkAll = false;
        };

        /**
         * Device Type Device Assigned Change.
         * @author jcardona
         * @since 28/06/2016
         */
        $scope.deviceTypeAssignChange = function(){
        	AssignDeviceService.findAssignedByLicense($scope.assignDevice.license.id, $scope.deviceTypeAssign, $scope.setAssignedDevicesToScope);
        	  $scope.assignDevice.checkAllAsig = false;
        };

        /** Verifies if the Assign button should be disabled */
        $scope.assignButtonDisabled = function(){
            var disabled = true;
            if(typeof($scope.assignDevice) == 'undefined'){
                disabled = true;
            }else{
                _.each($scope.assignDevice.availableDevices, function(device){
                    if(device.checked){
                        disabled = false;
                        return false;
                    }
                });
            }
            return disabled;
        }


        /** Verifies if the Assign button should be disabled */
        $scope.unAssignButtonDisabled = function(){
            var disabled = true;
            if(typeof($scope.assignDevice) == 'undefined'){
                disabled = true;
            }else{
                _.each($scope.assignDevice.assignedDevices, function(device){
                    if(device.checked){
                        disabled = false;
                        return false;
                    }
                });
            }
            return disabled;
        }

        /**
         * Check/Unchecks all devices when the common Check changes status
         */
        $scope.checkAllAvailableDevices  = function(){
            _.each($scope.assignDevice.availableDevices,function(device){
                device.checked = $scope.assignDevice.checkAll;
            });
        }

        /**
         * Check/Unchecks all devices when the common Check changes status
         */
        $scope.checkAllAssignedDevices  = function(){
            _.each($scope.assignDevice.assignedDevices,function(device){
                if(device.status == "ASSIGNED"){
                    device.checked = $scope.assignDevice.checkAllAsig;
                }
            });
        }


        /**
         * Verifies if after checking and individual device the common checkbox must be checked.
         */
        $scope.verifyCheckAllDevices = function(device){
            if(!device.checked){
                $scope.assignDevice.checkAll = false;
            }else{
                $scope.verifyListAvailableChecked();
            }
        }

        /** Logic to verify if the check all checkbox must be modified */
        $scope.verifyListAvailableChecked = function(){
            var finalValue = $scope.assignDevice.availableDevices.length > 0;
            _.each($scope.assignDevice.availableDevices,function(device){
                if(!device.checked){
                    finalValue = false;
                    return false;
                }
            });
            $scope.assignDevice.checkAll = finalValue;
        }

        /**
         * Verifies if after checking and individual device the common checkbox must be checked.
         */
        $scope.verifyCheckAllAssignedDevices = function(device){
            if(!device.checked){
                $scope.assignDevice.checkAllAsig = false;
            }else{
                $scope.verifyListAssignedChecked();
            }
        }

        /** Logic to verify if the check all checkbox must be modified */
        $scope.verifyListAssignedChecked = function(){
            var finalValue = $scope.assignDevice.assignedDevices.length > 0;
            _.each($scope.assignDevice.assignedDevices,function(device){
                if(!device.checked){
                    finalValue = false;
                    return false;
                }
            });
            $scope.assignDevice.checkAllAsig = finalValue;
        }

        /**
        * Edit settings instance from assign device.
        */
        $scope.editSettings = function(evt, settingsInstanceId, deviceId, deviceTypeId) {
            if ($scope.license.id) {
                if (deviceId) {
                    $state.go('license.edit.settings-view', {id: $scope.assignDevice.license.id, settingsId: settingsInstanceId, deviceId: deviceId, deviceTypeId: deviceTypeId, showChangeSettingsButton: true, parentState: 'license.device.assign'});
                } else {
                    $state.go('license.edit.settings-view', {id: $scope.assignDevice.license.id, settingsId: settingsInstanceId, deviceId: deviceId, parentState: 'license.device.assign'});
                }
            }
            evt.stopPropagation();
        }

        /**
        * Assign devices whether they have or not settings templates.
        */
        $scope.assignDevices = function() {

            if (! _.isEmpty($scope.settings) || ! _.isEmpty($scope.settingsTemplate)) {
                $state.go('license.device.assign.setting', {id: $scope.assignDevice.license.id, deviceType: $scope.deviceType, assignDevice: $scope.assignDevice});

            } else {
                var assignDeviceDto = { licenseId : $scope.assignDevice.license.id, devices : new Array()};
                _.each($scope.assignDevice.availableDevices, function(device){
                    if(device.checked){
                        assignDeviceDto.devices.push(device.id);
                    }
                });

                // Validate slots and devices
                if($scope.assignDevice.license.slots < $scope.assignDevice.assignedDevices.length + assignDeviceDto.devices.length){
                    AlertService.error($rootScope.message.error.deviceAssignNotEnoughSlots);
                    return;
                }
                AssignDeviceService.assignDevicesToLicense(assignDeviceDto, $scope.refreshDevicesForLicense);
            }
        }

        $scope.validSettings = function(device){
            return _.isEmpty(device.settingsInstance);
        };

   });
