'use strict';

angular.module('vmaxmanagerApp')
    .controller('BulkController', function ($scope, Principal, $rootScope, $state, $translate) {
        $scope.isAuthenticated = Principal.isAuthenticated;

        switch($state.current.data.process){
            case "device":

                $scope.title = $translate.instant('vmaxmanagerApp.bulk.device.title');

                $scope.tabs = [
                    { title:$translate.instant('vmaxmanagerApp.bulk.device.selectDevice'), content:'contentBulk', disabled: true, stepNumber : 1 },
                    { title:$translate.instant('vmaxmanagerApp.bulk.device.operationDetails'), content:'contentBulk', disabled: true, stepNumber : 2 },
                    { title:$translate.instant('vmaxmanagerApp.bulk.device.confirmation'), content:'contentBulk', disabled: true, stepNumber : 3 },
                    { title:$translate.instant('vmaxmanagerApp.bulk.device.results'), content:'contentBulk', disabled: true, stepNumber : 4 }
                ];
            break;
        }

        $scope.drawStepClasses = function(currentStepNumber){
            if($state.current.data.stepNumber > currentStepNumber || $state.current.data.stepNumber == $scope.tabs.length){
                return 'mdl-stepper-step active-step step-done';
            }
            if($state.current.data.stepNumber < currentStepNumber){
                return 'mdl-stepper-step';
            }
            if($state.current.data.stepNumber == currentStepNumber){
                return 'mdl-stepper-step active-step';
            }
        }

    });
