'use strict';

angular.module('vmaxmanagerApp')
	.controller('BulkDeviceOperationDetailsBackController', function($scope, $uibModalInstance) {

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.continueWorkflow = function() {
            $uibModalInstance.close();
        }

    });
