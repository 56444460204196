(function() {
    'use strict';
    angular
        .module('vmaxmanagerApp')
        .controller('SettingsInstanceDeleteController',SettingsInstanceDeleteController);

    SettingsInstanceDeleteController.$inject = ['$uibModalInstance', 'entity', 'SettingsInstance'];

    function SettingsInstanceDeleteController($uibModalInstance, entity, SettingsInstance) {
        var vm = this;

        vm.settingsInstance = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
        	vm.deleteError = false;
        	SettingsInstance.delete({id: id}, onDeleteSuccess, onDeleteError);
        }
        
        function onDeleteSuccess (result) {
        	$uibModalInstance.close(true);
        }

        function onDeleteError (response) {
        	vm.deleteError = true;
        }
    }
})();
