'use strict';

angular.module('vmaxmanagerApp')
    .controller('BulkDeviceSelectDevicesController', function ($scope, $stateParams, $state, BulkService, BulkConstants, BulkDeviceStatus) {

        BulkService.resetAll();

        if (!_.isNull($stateParams.selectedDevices)) {
            BulkService.setDevicesSelected($stateParams.selectedDevices);
        }

        /*
        * Load the input file.
        */
        $scope.loadFileInput = function(){
            return $scope.radioBulk == BulkConstants.device.radioTypes.file;
        }

        /*
        * Checks the form.
        */
        $scope.checkingValidationForm = function(){
            var wereDevicesManuallySelected = BulkService.getDevicesSelected().length > 0 ? true : false;
            if(($scope.radioBulk == BulkConstants.device.radioTypes.manual && wereDevicesManuallySelected) ||
                ($scope.radioBulk == BulkConstants.device.radioTypes.file && $scope.checkFileContent())){
                return true;
            }else{
                return false;
            }
        }

        $scope.checkFileContent = function(){
            return $scope.errors && $scope.errors.length == 0 && $scope.fileContent && $scope.fileContent.length > 0;
        }

        $scope.$watch("radioBulk", function(newRadioValue, oldRadioValue){
            if(newRadioValue === oldRadioValue){
                return;
            }

            var wereDevicesManuallySelected = BulkService.getDevicesSelected().length > 0 ? true : false;

            if(newRadioValue == BulkConstants.device.radioTypes.manual && !wereDevicesManuallySelected){
                $scope.errorsManual = [];
                $scope.errorsManual.push(BulkConstants.errors.noDevicesSelected);
            }
        });

        /*
        * Go to the next step.
        */
        $scope.goToNextStep = function() {
            if ($scope.radioBulk == BulkConstants.device.radioTypes.manual) {
                $state.go('bulkDevice.operationDetails-manual');
            } else {
                if($scope.fileContent){
                    BulkService.setFileHeader($scope.headers);
                    BulkService.setFileContent($scope.fileContent);
                    BulkService.setSerialNumber($scope.headers.serialNumber);
                    BulkService.setFileHeader($scope.headers);
                    $state.go("bulkDevice.operationDetails");
               }
            }
        }
    });
