'use strict';

angular.module('vmaxmanagerApp')
	.controller('LicenseAssignDeviceSettingController', function($scope, $translate, $rootScope, $q, $stateParams, $state, $uibModalInstance,
	                                                settings, settingsTemplate, AlertService, AssignDeviceService, license, DeviceType) {

        var assignDevice = $stateParams.assignDevice;

        $scope.settings = settings;
        $scope.settingsTemplate = settingsTemplate;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.assignDevicesToLicense = function(){
            var paramSettingsInstance = ($scope.settingsInstance!= null) ? $scope.settingsInstance.id  : "";
            var assignDeviceDto = { licenseId : license.id, settingsInstance : paramSettingsInstance , devices : new Array()};
            _.each(assignDevice.availableDevices, function(device){
                if(device.checked){
                    assignDeviceDto.devices.push(device.id);
                }
            });

            // Validate slots and devices
            if(assignDevice.license.slots < assignDevice.assignedDevices.length + assignDeviceDto.devices.length){
                AlertService.error($rootScope.message.error.deviceAssignNotEnoughSlots);
                return;
            }
            AssignDeviceService.assignDevicesToLicense(assignDeviceDto, $scope.refreshDevicesForLicense);
        };

        $scope.refreshDevicesForLicense = function(){
            if(typeof(license.id) == 'undefined' || license == null){
                return;
            }
            $scope.settings = null;
            $uibModalInstance.close(true);
        };

        $scope.invalidSettings = function(){
            return !(!_.isEmpty($scope.settingsTemplate) && !_.isEmpty($scope.settings));
        };
   });
