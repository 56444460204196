'use strict';

angular.module('vmaxmanagerApp').controller('ProvidersDialogController',
    ['$scope', '$state', 'Upload', 'entity', 'Providers', '$timeout',
        function($scope, $state, Upload, entity, Providers, $timeout) {

        $scope.providers = entity;
        $scope.fileReaderSupported = window.FileReader != null && (window.FileAPI == null || FileAPI.html5 != false);
        $scope.load = function(id) {
            Providers.get({id : id}, function(result) {
                $scope.providers = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:providersUpdate', result);
            $scope.isSaving = false;
            $state.go('providers');
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.onFileSelect = function(file){
              if (file != null) {
                  if ($scope.fileReaderSupported && file.type.indexOf('image') > -1) {
                      $timeout(function() {
                          var fileReader = new FileReader();
                          fileReader.readAsDataURL(file);
                          fileReader.onload = function(e) {
                              $timeout(function() {
                                  file.dataUrl = e.target.result;
                              });
                          };
                      });
                  }
              }
          };

          $scope.create = function () {

        	  $scope.isSaving = true;
              var fileObject = { file: null };

              if($scope.providers.picFile && $scope.providers.picFile.size > 0){
                  fileObject.file = $scope.providers.picFile;
              }

              if(!$scope.providers.id){
                  $scope.providers.id = 0;
              }

              var provider = {
            		  'id': $scope.providers.id,
            		  'icon': $scope.providers.icon,
            		  'name': $scope.providers.name
              };

              fileObject.upload = Upload.upload({
                  url: 'api/providerss',
                  method: 'POST',
                  data: {'provider': angular.toJson(provider)},
                  sendObjectsAsJsonBlob: false,
                  file: fileObject.file
              });

              fileObject.upload.then(function(response) {
                  fileObject.result = response.data;
                  $state.go('providers');
              }, function(response,message) {
                  if (response.status > 0){
                      bootbox.dialog({
                          message: "Sorry something has gone bad, please contact to SEON",
                          title: "Server Error",
                          buttons: {
                            success: {
                              label: "Ok!",
                              className: "btn-danger",
                              callback: function() {
                                  console.log(response.status + ': ' + response.data);
                              }
                            }
                          }
                      });
                  }
              });
          };
}]);
