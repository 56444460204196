(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .controller('SettingsInstanceDialogController', SettingsInstanceDialogController);

    SettingsInstanceDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$window', 'entity', 'SettingsInstance', 'SettingsTemplate', 'SoftwareGroup', 'DeviceType', 'SettingsType', 'Timezone', 'licenseEntity', 'License', 'deviceEntity', 'Device', '$translate'];

    function SettingsInstanceDialogController($timeout, $scope, $state, $stateParams, $window, entity, SettingsInstance, SettingsTemplate, SoftwareGroup, DeviceType, SettingsType, Timezone, licenseEntity, License, deviceEntity, Device, $translate) {
        // Loading the variables
        var vm = this;
        vm.isView = $state.current.data.isView;
        vm.isEdit = $state.current.data.isEdit;
        vm.isClone = $state.current.data.isClone;
        vm.isNew = $state.current.data.isNew;
        vm.isChangeSettings = $state.current.data.isChangeSettings;
        vm.settingsName = entity ? entity.name : null;
        vm.isEditionWithRestrictions = null;
        vm.headerLabel = $state.current.data.headerLabel;
        vm.hideButtons = $state.current.data.hideButtons;
        vm.showChangeSettingsButton = $stateParams.showChangeSettingsButton;
        vm.timezones = Timezone.getTimezones();
        vm.licenseEntity = licenseEntity;
        vm.deviceEntity = deviceEntity;
        vm.showTemplate = false;
        vm.parentState = $stateParams.parentState;
        vm.invalidParams = false;
        vm.notAllowedCharacters = ['$']
        if (vm.isView == true || vm.isChangeSettings == true) {
            vm.allFieldsReadOnly = true;
        } else {
            vm.allFieldsReadOnly = false;
        }

        vm.saveBtnText = 'entity.action.save';
        if (vm.isClone) vm.saveBtnText = 'entity.action.clone';
        if (vm.isChangeSettings) vm.saveBtnText = 'entity.action.change';
        vm.backBtnText = vm.isView ? "entity.action.back" : "entity.action.cancel"

        var existLicense = vm.licenseEntity && vm.licenseEntity.id;

        var translations = {
            'PATTERN_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.pattern'),
            'REQUIRED_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.required'),
            'MAX_LENGTH_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.maxLength'),
            'URL_EXISTS_ERROR': $translate.instant('vmaxmanagerApp.settingsInstance.errorMessage.urlExits')
        };

        // The layout configuration could be: "narrow" or "wide".
        vm.settingsManagerConfig = {
            layout: 'narrow',
            labelClass: 'control-label',
            inputGroupClass: 'form-group label-floating',
            inputClass: 'form-control',
            selectClass: 'form-control',
            errorTextClass: '',
            translations: translations
        };

        // Process to change settings instance.
        if (vm.isChangeSettings && $stateParams.deviceTypeId) {
            vm.deviceSettingsInstanceId = $stateParams.settingsId;
            vm.deviceSettingsInstance = null;
            vm.deviceSettingsInstanceList = SettingsInstance.getSettingsInstanceByDeviceType({
                deviceType: $stateParams.deviceTypeId
            }, function(result) {
                if (result !== null && result !== undefined) {
                    result.forEach(function(settings) {
                        if (settings.id == vm.deviceSettingsInstanceId) {
                            vm.deviceSettingsInstance = settings;
                        }
                    });
                } else {
                    vm.jsonData = null;
                }
            });
        } else {
            if ($stateParams.deviceTypeId) {
                vm.deviceSettingsInstanceList = SettingsInstance.getSettingsInstanceByDeviceType({
                    deviceType: $stateParams.deviceTypeId
                }, function(result) {
                    if (result !== null && result !== undefined) {
                        result.forEach(function(settings) {
                            if (settings.id == vm.deviceSettingsInstanceId) {
                                vm.deviceSettingsInstance = settings;
                            }
                        });
                    } else {
                        vm.jsonData = null;
                    }
                });
            }
        }

        /**
         * This method handle the tab section change.
         */
        vm.changeTab = function(section) {
            vm.section = section;
        }

        /**
         * This method handle the tab section change.
         */
        vm.checkEditionWithRestrictions = function(isApplied) {
            switch (vm.parentState) {
                case 'license.edit':
                case 'license':
                case 'license.device.assign':
                    if (vm.isEdit || vm.isNew || vm.isClone) {
                        vm.isEditionWithRestrictions = true;
                    } else {
                        vm.isEditionWithRestrictions = false;
                    }
                    break;
                default:
                    vm.isEditionWithRestrictions = isApplied;
                    break;
            }
        }

        /**
         * Checks is the current settings was applied to license or device.
         */
        vm.checkAppliedTo = function() {
            if (entity.id && vm.isEdit && !vm.isChangeSettings) {
                vm.appliedTo = SettingsInstance.existSettingsInstanceAppliedToTypeSettingsId({
                    id: entity.id,
                    typeSettings: entity.settingsTemplate.typeEntitySettingsEnum
                }, function(result) {
                    vm.checkEditionWithRestrictions(result.quantity > 0);
                });
            } else {
                vm.checkEditionWithRestrictions(false);
            }
        }

        /**
         * This method parses a string to json.
         */
        function parseJson() {
            vm.jsonData = JSON.parse(vm.settingsInstance.template);
            if (!isNullOrEmpty(vm.jsonData[0])) {
                vm.changeTab(vm.jsonData[0]);
            }
        }

        // Initial controller process
        if ($state.current.data.isClone) {
            // Is a clone process.
            vm.isEditionWithRestrictions = true;
            vm.settingsInstance = {
                name: null,
                id: null,
                settingsTemplate: entity.settingsTemplate,
                template: entity.template,
                notes: entity.notes
            };
        } else {
            // Checking if the current setting instance was applied to licence or device.
            vm.checkAppliedTo();
            vm.settingsInstance = entity;
        }

        if (vm.settingsInstance != null) {
            if (vm.settingsInstance.settingsTemplate != null) {
                if (vm.settingsInstance.settingsTemplate.softwareGroup != null) {
                    vm.appliedToAlertLabel = 'vmaxmanagerApp.settingsInstance.settingsBeingUsedByLicenses';
                } else {
                    vm.appliedToAlertLabel = 'vmaxmanagerApp.settingsInstance.settingsBeingUsedByDevices';
                }
            }
        }

        vm.save = save;

        if (!isNullOrEmpty(vm.settingsInstance.template)) {
            parseJson();
        }

        loadSettingsTypeStore(vm);

        /**
         * Timeout method.
         */
        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        /**
         * Updates settings instance for devices.
         */
        function updateDeviceSettingsInstance(newSettingsInstance) {
            var device = vm.deviceEntity;
            if (device) {
                device.settingsInstance = newSettingsInstance;
                Device.update(device, function() {
                    saveStateHandler()
                });
            }
        }

        /**
         * Save action.
         */
        function save() {
            vm.isSaving = true;
           if(validateAdditionalParams()) {
                if (vm.isChangeSettings) {
                    updateDeviceSettingsInstance(vm.settingsInstance);
                } else {
                    if (!isNullOrEmpty(vm.jsonData)) {
                        vm.settingsInstance.template = JSON.stringify(vm.jsonData);
                    }
                    if (vm.settingsInstance.id !== null) {
                        SettingsInstance.update(vm.settingsInstance, onSaveSuccess, onSaveError);
                    } else {
                        SettingsInstance.save(vm.settingsInstance, onSaveSuccess, onSaveError);
                    }
                }
           } else {
               vm.isSaving = false;
               vm.invalidParams = true;
           }
        }

        function validateAdditionalParams() {
        var validParameters = true;
            _.each(vm.jsonData, function(section){
                if (section.id == "additionalParams") {
                    if (!_.isEmpty(section.fields[0].data)) {
                        var advancedParams = section.fields[0].data.split("\n");
                        _.each(advancedParams, function(advancedParam){
                            if (_.isEmpty(advancedParam) || advancedParam.indexOf(" ") != -1 || advancedParam.indexOf("=") == -1 ||  _.isEmpty(advancedParam.substring(advancedParam.indexOf("=") + 1 ))) {
                                validParameters = false;
                            }
                        });
                    }
                }
            });
        return validParameters;
        }

        /**
         * State handler method for save event.
         */
        function saveStateHandler() {
            if (vm.parentState == null || vm.parentState == undefined) {
                $state.go($state.current.parent);
            } else {
                switch (vm.parentState) {
                    case 'device':
                        $state.go(vm.parentState, null, { reload: true });
                    case 'license.edit':
                    case 'license':
                        $state.go(vm.parentState, $stateParams);
                        break;
                    case 'license.device.assign':
                        $stateParams["device"] = vm.deviceEntity;
                        $stateParams["license"] = vm.licenseEntity;
                        $state.go(vm.parentState, $stateParams);
                        break;
                    default:
                        $state.go(vm.parentState);
                        break;
                }
            }
        }

        /**
         * Save success handler.
         */
        function onSaveSuccess(result) {
            $scope.$emit('vmaxmanagerApp:settingsInstanceUpdate', result);
            vm.isSaving = false;
            var isNormalSaveProcess = true;

            if (vm.licenseEntity != null && vm.licenseEntity != undefined) {
                if (result != null) {
                    if ($state.current.data.isClone || $state.current.data.isNew || $state.current.data.isChangeSettings) {
                        switch (vm.parentState) {
                            case 'license.edit':
                            case 'license':
                                isNormalSaveProcess = false;
                                vm.licenseEntity.settingsInstance = result;
                                License.save(vm.licenseEntity, function() {
                                    saveStateHandler()
                                });
                                break;
                            case 'license.device.assign':
                                isNormalSaveProcess = false;
                                updateDeviceSettingsInstance(result);
                                break;
                        }
                    }
                }
            } else {
                if (vm.parentState === 'device') {
                    updateDeviceSettingsInstance(result);
                }
            }
            if (isNormalSaveProcess) {
                saveStateHandler()
            }
        }

        /**
         * Save error handler.
         */
        function onSaveError(httpResponse) {
            vm.isSaving = false;
            var error = httpResponse.headers ? httpResponse.headers('X-vmaxmanagerApp-error') : null;
            if (error == "error.nameExists") {
                vm.settingsInstance.name = '';
            }
        }

        /**
         * Method to load the settings types store.
         */
        function loadSettingsTypeStore(vm) {
            vm.showTemplate = false;
            vm.softwaregroups = SoftwareGroup.findSoftwareGroupWithSettingsTemplate();
            vm.devicetypes = DeviceType.findDeviceTypeWithSettingsTemplate();

            vm.settingstypes = [];
            SettingsType.query(function(result) {
                _.each(result, function(item) {
                    vm.settingstypes.push(item["name"]);
                });
            });

            var isNew = validateIsNewSetting(vm);
            if (isNew) {
                if ($state.current.data.isNewFromLicense) {
                    var settingsTemplate = vm.settingsInstance.settingsTemplate;
                    var typeEntitySettings = settingsTemplate.typeEntitySettingsEnum;
                    var settingsTemplateId = settingsTemplate.id;
                    switch (typeEntitySettings) {
                        case "DEVICE":
                            loadDeviceTypes(true, settingsTemplateId);
                            break;
                        case "SOFTWARE":
                            loadSoftwareGroup();
                            break;
                    }
                } else if (vm.parentState === 'device') {
                    vm.isEditionWithRestrictions = true;
                    loadDeviceTypes(true, settingsTemplateId);
                }
            } else {
                loadDeviceTypes(isNew, null);
            }
        }

        /**
         * Method to validate if the current setting is new or not.
         */
        function validateIsNewSetting(vm) {
            var isNew = false;
            if (!isNullOrEmpty(vm) && !vm.isClone) {
                if (!isNullOrEmpty(vm.settingsInstance)) {
                    isNew = isNullOrEmpty(vm.settingsInstance.id);
                }
            }
            return isNew;
        }

        /**
         * Method to validate if a value is null or empty.
         */
        function isNullOrEmpty(value) {
            return (value === undefined || value === null || value === '') ? true : false;
        }

        /**
         * This method updates the settings template and parse a string to json.
         */
        function updateSettingsTemplate(settingsTemplate) {
            vm.settingsInstance.settingsTemplate = settingsTemplate;
            vm.settingsInstance.template = settingsTemplate.template;
            parseJson();
        }

        /**
         * Loads the software group store.
         */
        function loadSoftwareGroup() {
            vm.showTemplate = false;
            vm.settingsInstance.template = '';
            vm.settingstemplates = null;

            if (!isNullOrEmpty(vm.settingsInstance.settingsTemplate.softwareGroup)) {
                if (!isNullOrEmpty(vm.settingsInstance.settingsTemplate.softwareGroup.id)) {
                    var softwareGroupId = vm.settingsInstance.settingsTemplate.softwareGroup.id;
                    SettingsTemplate.getSettingsTemplatesByTypeSettingsAndSoftwareGroup({
                        typeSettings: 'SOFTWARE',
                        softwareGroup: softwareGroupId
                    }, function(result) {
                        if (result !== null && result !== undefined) {
                            if (result[0] !== null && result[0] !== undefined) {
                                updateSettingsTemplate(result[0]);
                            }
                        } else {
                            vm.jsonData = null;
                        }
                    });
                }
            }
        }

        /**
         * Loads the device type store.
         */
        function loadDeviceTypes(isNew, settingsTemplateId) {
            vm.showTemplate = false;
            var currentTemplate = vm.settingsInstance.settingsTemplate;
            if (!isNullOrEmpty(vm.settingsInstance.settingsTemplate.deviceType)) {
                var deviceType = vm.settingsInstance.settingsTemplate.deviceType;
                var deviceTypeId = deviceType.id ? deviceType.id : deviceType;
                SettingsTemplate.getSettingsTemplatesByTypeSettingsAndDeviceType({
                    typeSettings: 'DEVICE',
                    deviceType: deviceTypeId
                }, function(result) {
                    if (result != null && vm != null) {
                        if (result.length > 1) {
                            vm.showTemplate = true;
                            vm.settingstemplates = result;
                            if (settingsTemplateId != null && settingsTemplateId != undefined) {
                                result.forEach(function(settingsTemplateResult) {
                                    if (settingsTemplateResult.id == settingsTemplateId) {
                                        updateSettingsTemplate(settingsTemplateResult);
                                    }
                                });
                            } else {
                                if (isNew) {
                                    vm.settingsInstance.settingsTemplate = {
                                        id: null,
                                        deviceType: {
                                            id: deviceTypeId
                                        },
                                        typeEntitySettingsEnum: "DEVICE"
                                    };
                                    vm.settingsInstance.template = null;
                                    vm.jsonData = null;
                                }
                            }
                        } else {
                            if (isNew) {
                                updateSettingsTemplate(result[0]);
                            }
                        }
                    }
                });
            }
        }

        /**
         * This method handle the parent breadcrumb for external calls, for this reason is part of the scope.l
         */
        $scope.dynamicParentBreadcrumb = function() {
            switch (vm.parentState) {
                case 'license.device.assign':
                    return 'license.device.assign';
                    break;
                case 'device':
                    return 'device';
                    break;
                default:
                    return 'license.edit';
                    break;
            }
        };

        /**
         * Handler for Settings Type control.
         */
        vm.selectedSettingsType = function() {
            vm.showTemplate = false;
            vm.settingsInstance.settingsTemplate.softwareGroup = null;
            vm.settingsInstance.settingsTemplate.deviceType = null;
            vm.settingsInstance.template = '';
            vm.jsonData = null;
            vm.settingstemplates = null;

            switch (vm.settingsInstance.settingsTemplate.typeEntitySettingsEnum) {
                case "DEVICE":
                    vm.settingsInstance.settingsTemplate.deviceType = {};
                    break;
                case "SOFTWARE":
                    vm.settingsInstance.settingsTemplate.softwareGroup = {};
                    break;
            }
        };

        /**
         * Handler for Software Group control.
         */
        vm.selectedSoftwareGroup = function() {
            loadSoftwareGroup();
        };

        /**
         * Handler for Device Type control.
         */
        vm.selectedDeviceType = function() {
            loadDeviceTypes(true, null);
        };

        /**
         * Handler for the template control.
         */
        vm.selectedTemplate = function() {
            if (vm.settingsInstance.settingsTemplate != null) {
                vm.settingsInstance.template = vm.settingsInstance.settingsTemplate.template;
                parseJson();
            }
        };

        /**
         * This method triggered the back history event.
         */
        vm.back = function() {
            if ($state.current.data.isView) {
                if (vm.parentState == null || vm.parentState == undefined) {
                    $state.go($state.current.parent);
                } else {
                    switch (vm.parentState) {
                        case 'license.edit':
                        case 'license':
                            $state.go(vm.parentState, $stateParams);
                            break;
                        case 'license.device.assign':
                            $stateParams["device"] = vm.deviceEntity;
                            $stateParams["license"] = vm.licenseEntity;
                            $state.go(vm.parentState, $stateParams);
                            break;
                        case 'device':
                            $state.go(vm.parentState, $stateParams);
                            break;
                        default:
                            $state.go(vm.parentState);
                            break;
                    }
                }
            } else {
                switch (vm.parentState) {
                    case 'license.edit':
                    case 'license':
                        $state.go('license.edit.settings-view', $stateParams);
                        break;
                    case 'license.device.assign':
                        $stateParams["device"] = vm.deviceEntity;
                        $stateParams["license"] = vm.licenseEntity;
                        $state.go('license.edit.settings-view', $stateParams);
                        break;
                    case 'device':
                        $state.go('device.settings-view', {
                            parentState: "device",
                            id: vm.deviceEntity.id,
                            deviceId: vm.deviceEntity.id,
                            deviceTypeId: vm.deviceEntity.deviceType.id,
                            settingsId: vm.deviceEntity.settingsInstance.id
                        });
                        break;
                    default:
                        $state.go('settings-instance');
                        break;
                }
            }
        }

        /**
         * This method creates a new settings instance.
         */
        vm.newSettingsInstance = function() {
            if (existLicense) {
                var softwareGroupOrDeviceTypeId = null;
                switch (vm.settingsInstance.settingsTemplate.typeEntitySettingsEnum) {
                    case "DEVICE":
                        softwareGroupOrDeviceTypeId = vm.settingsInstance.settingsTemplate.deviceType.id;
                        break;
                    case "SOFTWARE":
                        softwareGroupOrDeviceTypeId = vm.settingsInstance.settingsTemplate.softwareGroup.id;
                        break;
                }

                $state.go('license.edit.settings-new', {
                    id: vm.licenseEntity.id,
                    settingsId: vm.settingsInstance.id,
                    deviceId: vm.deviceEntity ? vm.deviceEntity.id : null,
                    settingsTemplateId: vm.settingsInstance.settingsTemplate.id,
                    typeEntitySettingsEnum: vm.settingsInstance.settingsTemplate.typeEntitySettingsEnum,
                    softwareOrDeviceId: softwareGroupOrDeviceTypeId,
                    parentState: vm.parentState,
                    showChangeSettingsButton: vm.showChangeSettingsButton,
                    deviceTypeId: $stateParams.deviceTypeId
                });
            } else {
                if (vm.parentState = 'device') {
                    $state.go('device.settings-new', {
                        parentState: "device",
                        id: vm.deviceEntity.id,
                        deviceId: vm.deviceEntity.id,
                        deviceTypeId: vm.deviceEntity.deviceType.id,
                        settingsId: vm.deviceEntity.settingsInstance.id,
                        settingsTemplateId: vm.deviceEntity.settingsInstance.settingsTemplate.id
                    });
                }
            }
        }

        /**
         * This method edits a new settings instance.
         */
        vm.editSettingsInstance = function() {
            if (existLicense) {
                $state.go('license.edit.settings-edit', {
                    id: vm.licenseEntity.id,
                    settingsId: vm.settingsInstance.id,
                    deviceId: $stateParams.deviceId,
                    deviceTypeId: $stateParams.deviceTypeId,
                    parentState: vm.parentState,
                    showChangeSettingsButton: vm.showChangeSettingsButton
                });
            } else {
                if (vm.parentState = 'device') {
                    $state.go('device.settings-edit', {
                        parentState: "device",
                        id: vm.deviceEntity.id,
                        deviceId: vm.deviceEntity.id,
                        deviceTypeId: vm.deviceEntity.deviceType.id,
                        settingsId: vm.deviceEntity.settingsInstance.id
                    });
                }
            }
        }

        /**
         * This method clones a new settings instance.
         */
        vm.cloneSettingsInstance = function() {
            if (existLicense) {
                $state.go('license.edit.settings-clone', {
                    id: vm.licenseEntity.id,
                    settingsId: vm.settingsInstance.id,
                    deviceId: $stateParams.deviceId,
                    deviceTypeId: $stateParams.deviceTypeId,
                    parentState: vm.parentState,
                    showChangeSettingsButton: vm.showChangeSettingsButton
                });
            } else {
                if (vm.parentState = 'device') {
                    $state.go('device.settings-clone', {
                        parentState: "device",
                        id: vm.deviceEntity.id,
                        deviceId: vm.deviceEntity.id,
                        deviceTypeId: vm.deviceEntity.deviceType.id,
                        settingsId: vm.deviceEntity.settingsInstance.id
                    });
                }
            }
        }

        /**
         * This method change a current settings instance template.
         */
        vm.changeSettingsInstance = function() {
            if (existLicense) {
                $state.go('license.edit.settings-change', {
                    id: vm.licenseEntity.id,
                    settingsId: vm.settingsInstance.id,
                    deviceId: $stateParams.deviceId,
                    deviceTypeId: $stateParams.deviceTypeId,
                    parentState: vm.parentState,
                    showChangeSettingsButton: vm.showChangeSettingsButton
                });
            } else {
                if (vm.parentState = 'device') {
                    $state.go('device.settings-change', {
                        parentState: "device",
                        id: vm.deviceEntity.id,
                        deviceId: vm.deviceEntity.id,
                        deviceTypeId: vm.deviceEntity.deviceType.id,
                        settingsId: vm.deviceEntity.settingsInstance.id
                    });
                }
            }
        }

        /**
         * Event handler for change settings instance for a device.
         */
        vm.selectedDeviceSettingsInstance = function() {
            vm.settingsInstance = vm.deviceSettingsInstance;
            parseJson();
        }

        /**
         * This method replace all $ from a string.
         * (This validation was added for wildcards process).
         */
        vm.replaceWildcardSymbol = function(originalData) {
            var wildcardSymbol = '$';
            var newData = originalData.replace(wildcardSymbol, '')

            if (newData.indexOf(wildcardSymbol) !== -1) {
                newData = vm.replaceWildcardSymbol(newData);
            }
            return newData;
        }
    }
})();
