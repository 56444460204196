'use strict';

angular.module('vmaxmanagerApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('expandable-devices', {
                parent: 'admin',
                url: '/expandable-devices',
                data: {
                    authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                    pageTitle: 'vmaxmanagerApp.expandableDevices.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/interfaces/expandableDevices/expandable-devices.html',
                        controller: 'ExpandableDeviceController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expandableDevices');
                        return $translate.refresh();
                    }]
                },
                ncyBreadcrumb: {
                    label: 'vmaxmanagerApp.expandableDevices.title'
                }
            });
    });
