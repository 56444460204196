'use strict';

angular.module('vmaxmanagerApp')
    .controller('FleetController', function ($scope, $state, Fleet, ParseLinks) {

        $scope.fleets = [];
        $scope.predicate = 'fleetId';
        $scope.reverse = true;
        $scope.page = 0;

        //filter configs
        $scope.filterConfig = {
            inputGroupClass: '',
            btnClass: 'btn-raised'
        };

        $scope.loadAll = function() {
        	var pageable = {page: $scope.page, size: 40, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']};
        	Fleet.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalEntries = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.fleets.push(result[i]);
                }
            });
        };

        $scope.reset = function() {
            $scope.page = 0;
            $scope.fleets = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();
        $scope.fields = Fleet.filterVars();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.fleet = {
                fleetId: null,
                description: null,
                adminEmail: null,
                notes: null,
                isActive: null,
                deploymentType: null,
                contactName: null,
                contactPhone: null,
                contactEmail: null,
                soldName: null,
                soldEmail: null,
                id: null
            };
        };
    });
