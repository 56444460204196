'use strict';

angular.module('vmaxmanagerApp').controller('SalesRegionDialogController',
    ['$scope','$state','entity', 'SalesRegion',
        function($scope, $state, entity, SalesRegion) {

        $scope.salesRegion = entity;
        $scope.load = function(id) {
            SalesRegion.get({id : id}, function(result) {
                $scope.salesRegion = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:salesRegionUpdate', result);
            $scope.isSaving = false;
            $state.go('salesRegion');
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.salesRegion.id != null) {
                SalesRegion.update($scope.salesRegion, onSaveSuccess, onSaveError);
            } else {
                SalesRegion.save($scope.salesRegion, onSaveSuccess, onSaveError);
            }
        };
}]);
