angular.module('seon.queryBuilder', ['queryBuilder/tpls/filtersContainer.html', 'queryBuilder/tpls/filters.html']);

angular.module('seon.queryBuilder').config(function ($translateProvider) {
    $translateProvider.useMissingTranslationHandler('undefHandler');
});

angular.module('seon.queryBuilder').factory('undefHandler', function() {
    return function (translationID, uses) {
        return translationID.replace(/^(filters\.columns\.)/,"") .replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); }).replace('.', ' ');
    }
});

angular.module('seon.queryBuilder').directive('queryBuilderContainer', QueryBuilderContainer);
QueryBuilderContainer.$inject = ['$translate', '$sce', '$uibModal', '$window', '$timeout'];

function QueryBuilderContainer($translate, $sce, $uibModal, $window, $timeout) {
    return {
        restrict: 'E',
        scope: {
            fields: '=',
            advancedQuery: '=',
            queryObject: '=',
            filterCallback: '=',
            config: '=?',
            initialRules: '=?'
        },
        templateUrl: function(ele, attrs) {
            return attrs.templateUrl || 'queryBuilder/tpls/filtersContainer.html';
        },
        link: function(scope, elem, attrs) {
            if (!scope.config) {scope.config = {};}


            if (scope.initialRules) {
                scope.group = angular.copy(scope.initialRules);
            }
            else {
                scope.group = {
                    'operator' : 'and',
                    'rules' : [{condition: '', field:'', data:''}]
                };
            }

            scope.$watch('fields', function(newVal) {
                if (newVal) {
                    angular.forEach(scope.group.rules, function(rule) {
                        if (!rule.hasOwnProperty('group')) {
                            var field;
                            for (var i=0; i<scope.fields.length; i++) {
                                if (scope.fields[i].name === rule.field.name) {
                                    field = scope.fields[i];
                                    break;
                                }
                            }
                            rule.field = field;
                        }
                    });
                }
            });

            scope.queryStr = '';
            scope.showBuilder = false;
            scope.showQueryStr = true;
            scope.advMode = scope.advancedQuery;


            scope.toggleGroups = function(show) {
                var hasGroup = false;
                if (scope.group && scope.group.rules) {
                    for (var i = 0; i<scope.group.rules.length; i++) {
                        if (scope.group.rules[i].hasOwnProperty('group')) {
                            hasGroup = true;
                            break;
                        }
                    }
                }
                if (!show && hasGroup) {
                    scope.modalInstance = $uibModal.open({
                        animation: true,
                        keyboard: true,
                        templateUrl: 'templates/ConfirmBasic.html',
                        controller: function ConfirmBasicCtrl($scope, $uibModalInstance, config) {
                            var vm = this;
                            vm.config = config;
                            vm.ok = function() {$uibModalInstance.close('ok'); }
                            vm.cancel = function() {$uibModalInstance.dismiss('cancel'); };
                        },
                        bindToController: true,
                        controllerAs: 'vm',
                        resolve: {
                            config: function() {return scope.config;}
                        },
                        size: 'sm'
                    });

                    scope.modalInstance.result.then(function(status) {
                        removeAllGroups();
                    }, function(reason) {
                        scope.advMode = true;
                    });
                }
            };

            scope.modalOk = function() {scope.modalInstance.close('ok'); }
            scope.modalCancel = function() {scope.modalInstance.dismiss('cancel'); };

            function removeAllGroups() {
                var newGroup = {'operator' : scope.group.operator, 'rules' : []};
                angular.forEach(scope.group.rules, function(val, key) {
                    if (!val.hasOwnProperty('group')) {
                        newGroup.rules.push(val);
                    }
                });
                scope.group = newGroup;
            }

            scope.$watch('group', function(newVal, oldVal) {
                scope.queryObject = scope.cleanGroup(newVal);
                scope.queryStr = $sce.trustAsHtml(setQueryString(scope.queryObject).slice(1, -1));
            }, true);

            scope.cleanGroup = function(group) {
                var resp = {operator: group.operator, rules: []};
                angular.forEach(group.rules, function(e) {
                    // This change was applied on vMaxManager to resolve the bug VMM-1054.
                    if (e.hasOwnProperty('field')) {
                        if (_.isEmpty(e.data)) {
                            if (!_.isEmpty(e.field)) {
                                if (e.field.type == 'date' ||  e.field.type == 'dateTime') {
                                    e.pickerDate = undefined;
                                }
                            }
                        }
                    }

                    if (e.hasOwnProperty('group')) {
                        var newGrp = {group: scope.cleanGroup(e.group)};
                        if (newGrp.group.rules.length > 0) {
                            resp.rules.push(newGrp);
                        }
                    }
                    else {
                        if (e.field && e.condition && (e.data || e.data === 0)) {
                            resp.rules.push({field: e.field.name, condition:e.condition, data:e.data});
                        }
                    }
                });
                return resp;
            }

            function setQueryString(group) {
                if (!group) return "";
                for (var str = "(", i = 0; i < group.rules.length; i++) {
                    i > 0 && (str += " " + ('<b>' + group.operator.toUpperCase() + '</b>') + " ");
                    str += group.rules[i].group ?
                    setQueryString(group.rules[i].group) :
                    group.rules[i].field + " " + ('<b>' + htmlEntities(group.rules[i].condition) + '</b>') + " " + group.rules[i].data;
                }
                return str + ")";
            }

            function htmlEntities(str) {
                return String(str).replace(/</g, '&lt;').replace(/>/g, '&gt;');
            }

            function cleanGroupAndQueryStr(group) {
                var grp = {operator: group.operator, rules: []};
                var str = "(";

                angular.forEach(group.rules, function(e, i) {
                    i > 0 && (str += " " + group.operator + " ");

                    if (e.hasOwnProperty('group')) {
                        var inner = cleanGroupAndQueryStr(e.group);
                        var newGrp = {group: inner.group};
                        if (newGrp.group.rules.length > 0) {
                            str += inner.string;
                            grp.rules.push(newGrp);
                        }
                    }
                    else {
                        if (e.field && e.condition && e.data) {
                            grp.rules.push({field: e.field.name, condition:e.condition, data:e.data});
                            str += e.field.name + " " + htmlEntities(e.condition) + " " + e.data;
                        }
                    }
                })

                return {group: grp, string: (str + ")")};
            }


            scope.clearFilters = function() {
                scope.group = {
                    'operator' : 'and',
                    'rules' : [{condition: '', field:'', data:''}]
                }
                $timeout(function() {
                    scope.filterCallback();
                });
            }
        }
    };
}

angular.module('seon.queryBuilder').directive('queryBuilder', QueryBuilder);
QueryBuilder.$inject = ['$compile', '$translate', '$timeout'];

function QueryBuilder($compile, $translate, $timeout) {
    return {
        restrict: 'E',
        scope: {
            fields: '=fields',
            group: '=',
            advancedQuery: '=',
            config: '=?'
        },
        templateUrl: function(ele, attrs) {
            return attrs.templateUrl || 'queryBuilder/tpls/filters.html';
        },
        compile: function (element, attrs) {
            var content, directive;
            content = element.contents().remove();
            return function (scope, element, attrs) {

                scope.highlightCondition = function(ev) {
                    angular.element(ev.currentTarget.closest('div')).toggleClass('highlighted', true);
                }

                scope.unhighlightCondition = function(ev) {
                    angular.element(ev.currentTarget.closest('div')).toggleClass('highlighted', false);
                }

                scope.collapseGroup = false;
                scope.toggleGroup = function() {
                    scope.collapseGroup = !scope.collapseGroup;
                    scope.collapseGroup && (scope.innerExpression = computed(scope.group));
                }

                function computed(group) {
                    if (!group) return "";
                    for (var str = "(", i = 0; i < group.rules.length; i++) {
                        var rule = group.rules[i];
                        if (!rule.group && (!rule.field.name || !rule.condition || !rule.data)) { continue;}
                        i > 0 && (str += " " + group.operator + " ");
                        str += group.rules[i].group ?
                        computed(group.rules[i].group) :
                        rule.field.name + " " + (rule.condition) + " " + rule.data;
                    }
                    return str + ")";
                }


                //calendar stuff
                scope.dateFormat = "MM/dd/yyyy";
                scope.datetimeFormat = "MM/dd/yyyy HH:mm:ss";
                // scope.datepickerOpts = { maxDate: moment() }
                // scope.altInputFormats = ["yyyy-MM-dd HH:mm:ss", "MM/dd/yyyy HH:mm:ss", 'yyyy-MM-dd', 'yyyy/MM/dd'];
                // scope.calendarOpened = false;
                // scope.format = scope.altInputFormats[1];


                scope.$watch('advancedQuery', function(newVal) {
                    scope.showRemGrp = scope.advancedQuery && scope.nestedLevel > 0;
                    scope.showAddGrp = scope.advancedQuery && scope.nestedLevel < 5;
                }, true);


                scope.validateString = function (rule) {
                    if (rule.data && rule.data.length > 100) {
                        rule.data = rule.data.substring(0, 100);
                    }
                }

                scope.checkNumbers = function(ev) {
                    if(isNaN(String.fromCharCode(ev.keyCode))){
                        ev.preventDefault();
                    }
                }

                scope.validateNumber = function (rule) {
                    if (rule.data && rule.data.toString().length > 11) {
                        rule.data = Number(rule.data.toString().substring(0, 11));
                    }
                }

                scope.updateData = function(rule) {
                    var d = rule.pickerDate;
                    if (d && rule.field.type == 'date') {
                        rule.data = moment(d).format("YYYY-MM-DD");
                    }
                    else if (d && rule.field.type == 'dateTime') {
                        rule.data = moment.utc(d).format("YYYY-MM-DDTHH:mm:ss[Z]");
                    }
                    else {
                        rule.data = '';
                        rule.pickerDate = undefined;
                    }
                }


                //vars to show/hide elements
                scope.isChildFiltersDirective = ("isChildFiltersDirective" in scope.$parent);
                scope.nestedLevel = scope.isChildFiltersDirective?  (scope.$parent.nestedLevel + 1) : 0;
                scope.showRemGrp = scope.nestedLevel > 0;
                scope.showAddGrp = scope.advancedQuery && scope.nestedLevel < 5;
                scope.minRules = scope.nestedLevel == 0 ? 1 : 2;
                scope.maxRules = 15;

                scope.showRemRule = function() {
                    var r = scope.group.rules.filter(function(v) {
                            return !v.hasOwnProperty('group');
                    });
                    return (r.length > scope.minRules);
                }


                scope.operators = [
                    { name: 'All', value: 'and', locale: 'filters.all' },
                    { name: 'Any', value: 'or' , locale: 'filters.any' }
                ];

                //conditions vars
                var eq = {name: 'equals',       value: '=',    locale: 'filters.equal'      };
                var ne = {name: 'not equal',    value: '!=',   locale: 'filters.notEqual'   };
                var gt = {name: 'greater than', value: '>',    locale: 'filters.greaterThan'};
                var lt = {name: 'less than',    value: '<',    locale: 'filters.lessThan'   };
                var lk = {name: 'contains',     value: 'like', locale: 'filters.contains'   };
                scope.conditions = {
                    "text": [eq, ne, lk],
                    "boolean": [eq, ne],
                    "number": [eq, ne, gt, lt],
                    "date" : [eq, ne, gt, lt],
                    "dateTime" : [eq, ne, gt, lt]
                }

                scope.setConditions = function (r, old) {
                    (old.field && r.field.type != old.field.type) && (r.data = '');
                    (r.field.type == 'boolean') && (r.condition = '=');
                    (r.field.type == 'date') && (r.calendarOpened = false);
                }

                scope.addCondition = function () {
                    if (!scope.group.rules.length >= scope.maxRules) {
                        return;
                    }
                    scope.group.rules.push({
                        condition: '',
                        field: '',
                        data: ''
                    });
                };

                scope.removeCondition = function (index) {
                    if (!scope.showRemRule) {
                        return;
                    }
                    scope.group.rules.splice(index, 1);
                };

                scope.addGroup = function () {
                    if (!scope.group.rules.length >= scope.maxRules) {
                        return;
                    }
                    scope.group.rules.push({
                        group: {
                            operator: 'and',
                            rules: [{condition: '', field:'', data:''}, {condition: '', field:'', data:''}]
                        }
                    });
                };

                scope.removeGroup = function () {
                    "group" in scope.$parent && scope.$parent.group.rules.splice(scope.$parent.$index, 1);
                };

                directive || (directive = $compile(content));

                element.append(directive(scope, function ($compile) {
                    return $compile;
                }));
            }
        }
    }
};

angular.module("queryBuilder/tpls/filtersContainer.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("queryBuilder/tpls/filtersContainer.html",
    "<div ng-show=\"queryStr || showBuilder\" class=\"filterString\">\n" +
    "	<strong>\n" +
    "		<i class=\"glyphicon glyphicon-tasks\"></i>\n" +
    "		<span translate=\"filters.filters\">Filters: </span>\n" +
    "	</strong>\n" +
    "	<span ng-bind-html=\"queryStr\"></span>\n" +
    "</div>\n" +
    "\n" +
    "<button class=\"btn btnShowFilterGroups btn-info\" ng-class=\"config.btnClass\" ng-click=\"showBuilder = !showBuilder;\">\n" +
    "	<span class=\"showHideFilters\">\n" +
    "        {{(showBuilder? 'filters.hideFilters' : ( queryObject.rules.length ? 'filters.filterBy' : 'filters.showFilters' )) | translate}}\n" +
    "        <i class=\"glyphicon\" ng-class=\"showBuilder? 'glyphicon-triangle-top' : 'glyphicon-triangle-bottom'\"></i>\n" +
    "    </span>\n" +
    "</button>\n" +
    "\n" +
    "<label class=\"advanceModeLabel\" ng-show=\"advancedQuery && showBuilder\">\n" +
    "	<span translate=\"filters.advMode\">Advanced Mode: </span>\n" +
    "	<input type=\"checkbox\" ng-model=\"advMode\" ng-change=\"toggleGroups(advMode)\">\n" +
    "</label>\n" +
    "\n" +
    "<div class=\"filterButtons\" ng-show=\"showBuilder && group.rules.length > 6\">\n" +
    "	<button class=\"btn btn-danger\" ng-class=\"config.btnClass\" ng-click=\"clearFilters()\" ng-disabled=\"queryObject.rules.length < 1\">\n" +
    "		<span translate=\"filters.clear\">Clear</span>\n" +
    "	</button>\n" +
    "	<button class=\"btn btn-primary\" ng-class=\"config.btnClass\" ng-click=\"filterCallback()\" ng-disabled=\"queryObject.rules.length < 1\" >\n" +
    "		<span translate=\"filters.filter\">Filter</span>\n" +
    "	</button>\n" +
    "</div>\n" +
    "\n" +
    "<div>\n" +
    "	<query-builder class=\"col-xs-12 filterGroupContainer\" group=\"group\" fields=\"fields\" advanced-query=\"advMode\" config=\"config\" ng-show=\"showBuilder\"></query-builder>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"filterButtons\" ng-show=\"showBuilder\">\n" +
    "	<button class=\"btn btn-danger\" ng-class=\"config.btnClass\" ng-click=\"clearFilters()\" ng-disabled=\"queryObject.rules.length < 1\">\n" +
    "		<span translate=\"filters.clear\">Clear</span>\n" +
    "	</button>\n" +
    "	<button class=\"btn btn-primary\" ng-class=\"config.btnClass\" ng-click=\"filterCallback()\" ng-disabled=\"queryObject.rules.length < 1\" >\n" +
    "		<span translate=\"filters.filter\">Filter</span>\n" +
    "	</button>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"templates/ConfirmBasic.html\">\n" +
    "	<div class=\"modal-body\">\n" +
    "		<p translate=\"filters.deleteGroupsMsg\">Switching to Basic Mode with delete all existing groups</p>\n" +
    "	</div>\n" +
    "	<div class=\"modal-footer\">\n" +
    "		<button type=\"button\" class=\"btn btn-danger\" ng-class=\"vm.config.btnClass\" data-dismiss=\"modal\" ng-click=\"vm.cancel()\">\n" +
    "			<span class=\"glyphicon glyphicon-ban-circle\"></span>&nbsp;<span translate=\"filters.cancel\">Cancel</span>\n" +
    "		</button>\n" +
    "		<button type=\"submit\" class=\"btn btn-primary\" ng-class=\"vm.config.btnClass\" ng-click=\"vm.ok()\">\n" +
    "			<span class=\"glyphicon glyphicon-remove-circle\"></span>&nbsp;<span translate=\"filters.ok\">OK</span>\n" +
    "		</button>\n" +
    "	</div>\n" +
    "\n" +
    "</script>\n" +
    "");
}]);

angular.module("queryBuilder/tpls/filters.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("queryBuilder/tpls/filters.html",
    "<div class=\"alert-group\">\n" +
    "	<div class=\"form-inline\">\n" +
    "		<div ng-hide=\"collapseGroup\">\n" +
    "<!-- 		<button class=\"btn btn-sm btn-info\" style=\"\" ng-click=\"switch(true)\" ng-show=\"showRemGrp\" ng-disabled=\"$first\">\n" +
    "			<span class=\"glyphicon glyphicon-chevron-up\"></span>\n" +
    "		</button>\n" +
    "		<button class=\"btn btn-sm btn-info\" style=\"\" ng-click=\"switch(false)\" ng-show=\"showRemGrp\" ng-disabled=\"$last\">\n" +
    "			<span class=\"glyphicon glyphicon-chevron-down\"></span>\n" +
    "		</button> -->\n" +
    "			<div class=\"displayResultsContent\" ng-show=\"group.rules.length>1\" style=\"margin-bottom:4px;\">\n" +
    "				<span translate=\"filters.rulesLabel1\" ng-if=\"!isChildFiltersDirective\">Display results matching </span>\n" +
    "				<select ng-options=\"o.value as o.locale | translate for o in operators\" ng-model=\"group.operator\" class=\"form-control input-sm\"></select>\n" +
    "				<span translate=\"filters.rulesLabel2\"> of the following expressions</span>\n" +
    "			</div>\n" +
    "			<div class=\"displayInlineButtons\">\n" +
    "				<button ng-click=\"addCondition()\" class=\"btn btn-sm btn-info\" ng-class=\"config.btnClass\" ng-show=\"group.rules.length < maxRules\">\n" +
    "					<span class=\"glyphicon glyphicon-plus-sign\"></span> {{'filters.addCondition' | translate}}\n" +
    "				</button>\n" +
    "				<button ng-click=\"addGroup()\" class=\"btn btn-sm btn-primary\" ng-class=\"config.btnClass\"  ng-show=\"showAddGrp && group.rules.length < maxRules\">\n" +
    "					<span class=\"glyphicon glyphicon-plus-sign\"></span> {{'filters.addGroup' | translate}}\n" +
    "				</button>\n" +
    "				<button ng-click=\"removeGroup()\" class=\"btn btn-sm btn-danger\" ng-class=\"config.btnClass\" ng-show=\"showRemGrp\">\n" +
    "					<span class=\"glyphicon glyphicon-remove\"></span> {{'filters.removeGroup' | translate}} \n" +
    "				</button>\n" +
    "				<button ng-click=\"toggleGroup()\" class=\"btn btn-sm\" ng-class=\"config.btnClass\" ng-show=\"isChildFiltersDirective\">\n" +
    "					<span class=\"glyphicon glyphicon-triangle-top\"></span>\n" +
    "				</button>\n" +
    "	        </div>\n" +
    "	    </div>\n" +
    "	    <div class=\"filterCollapseGroup\" ng-show=\"collapseGroup\">\n" +
    "	    	<span><i class=\"glyphicon glyphicon-tasks\"></i> {{innerExpression}} </span>\n" +
    "	    	<button ng-click=\"toggleGroup()\" class=\"btn btn-sm expand-group-btn\" ng-class=\"config.btnClass\">\n" +
    "	    		<span class=\"glyphicon glyphicon-triangle-bottom\"></span>\n" +
    "	    	</button>\n" +
    "	    </div>\n" +
    "	</div>\n" +
    "	<div class=\"group-conditions\" ng-class=\"collapseGroup? 'collapsed' : ''\">\n" +
    "		<div ng-repeat=\"rule in group.rules | orderBy:'index'\" class=\"condition\">\n" +
    "\n" +
    "\n" +
    "			<div ng-switch=\"rule.hasOwnProperty('group')\">\n" +
    "				<div ng-switch-when=\"true\">\n" +
    "					<query-builder group=\"rule.group\" fields=\"fields\" advanced-query=\"true\" config=\"config\"></query-builder>\n" +
    "				</div>\n" +
    "				<div ng-switch-default=\"ng-switch-default\">\n" +
    "					<div class=\"form-inline hasCondition\">\n" +
    "<!-- 						<button class=\"btn btn-sm btn-info\" style=\"\" ng-click=\"switch(true)\" ng-disabled=\"$first\">\n" +
    "							<span class=\"glyphicon glyphicon-chevron-up\"></span>\n" +
    "						</button>\n" +
    "						<button class=\"btn btn-sm btn-info\" style=\"\" ng-click=\"switch(false)\" ng-disabled=\"$last\">\n" +
    "							<span class=\"glyphicon glyphicon-chevron-down\"></span>\n" +
    "						</button> -->\n" +
    "\n" +
    "						<p  class=\"input-group\">\n" +
    "							<select ng-options=\"t as (('filters.columns.' + t.name) | translate) for t in fields\" class=\"form-control fields-select\"\n" +
    "							        ng-model=\"rule.field\" ng-change=\"setConditions(rule, {{rule}})\"  ng-focus=\"highlightCondition($event)\" ng-blur=\"unhighlightCondition($event)\"></select>\n" +
    "						</p>\n" +
    "						<p  class=\"input-group\">\n" +
    "							<select ng-options=\"c.value as c.locale | translate for c in conditions[rule.field.type]\" class=\"form-control conditions-select\" ng-disabled=\"!rule.field || (rule.field.type == 'boolean')\"\n" +
    "							        ng-model=\"rule.condition\" ng-focus=\"highlightCondition($event)\" ng-blur=\"unhighlightCondition($event)\"></select>\n" +
    "						</p>\n" +
    "\n" +
    "						<p ng-if=\"rule.field.type == 'boolean'\" class=\"input-group\">\n" +
    "							<select class=\"form-control conditions-select\" ng-model=\"rule.data\"ng-focus=\"highlightCondition($event)\" ng-blur=\"unhighlightCondition($event)\">\n" +
    "								<option value=\"true\">{{'filters.true' | translate}}</option>\n" +
    "								<option value=\"false\">{{'filters.false' | translate}}</option>\n" +
    "							</select>\n" +
    "						</p>\n" +
    "\n" +
    "						<p ng-if=\"rule.field.type == 'text'\" class=\"input-group\">\n" +
    "							<input  type=\"text\" ng-model=\"rule.data\" class=\"form-control data-input\" ng-focus=\"highlightCondition($event)\" ng-blur=\"unhighlightCondition($event)\" ng-change=\"validateString(rule)\"/>\n" +
    "						</p>\n" +
    "						<p ng-if=\"rule.field.type == 'number'\" class=\"input-group\">\n" +
    "							<input  type=\"number\" ng-model=\"rule.data\" class=\"form-control data-input\" ng-focus=\"highlightCondition($event)\" ng-blur=\"unhighlightCondition($event)\" ng-keypress=\"checkNumbers($event)\" ng-change=\"validateNumber(rule)\"/>\n" +
    "						</p>\n" +
    "						<p ng-if=\"rule.field.type == 'date'\" class=\"input-group\">\n" +
    "						    <input  type=\"text\" class=\"form-control data-input\" uib-datepicker-popup=\"{{dateFormat}}\" ng-model=\"rule.pickerDate\"\n" +
    "						            is-open=\"rule.calendarOpened\" datepicker-options=\"datepickerOpts\" \n" +
    "						            close-text=\"{{'reports.notificationAudit.close' | translate}}\"  clear-text=\"{{'reports.notificationAudit.clean' | translate}}\" \n" +
    "						            current-text=\"{{'reports.notificationAudit.today' | translate}}\" show-button-bar=\"false\"\n" +
    "						            ng-focus=\"highlightCondition($event)\" ng-blur=\"unhighlightCondition($event)\" ng-change=\"updateData(rule)\"/>\n" +
    "						    <span class=\"input-group-btn\">\n" +
    "						        <button type=\"button\" class=\"btn btn-default\" ng-class=\"config.btnClass\" ng-click=\"rule.calendarOpened = !rule.calendarOpened\"><i class=\"glyphicon glyphicon-calendar\"></i></button>\n" +
    "						    </span>\n" +
    "						</p>\n" +
    "						<p ng-if=\"rule.field.type == 'dateTime'\" class=\"input-group\">\n" +
    "						    <input  type=\"text\" class=\"form-control data-input\" uib-datepicker-popup=\"{{datetimeFormat}}\" ng-model=\"rule.pickerDate\"\n" +
    "						            is-open=\"rule.calendarOpened\" datepicker-options=\"datepickerOpts\" \n" +
    "						            close-text=\"{{'reports.notificationAudit.close' | translate}}\"  clear-text=\"{{'reports.notificationAudit.clean' | translate}}\" \n" +
    "						            current-text=\"{{'reports.notificationAudit.today' | translate}}\" show-button-bar=\"false\"\n" +
    "						            ng-focus=\"highlightCondition($event)\" ng-blur=\"unhighlightCondition($event)\" ng-change=\"updateData(rule)\"/>\n" +
    "						    <span class=\"input-group-btn\">\n" +
    "						        <button type=\"button\" class=\"btn btn-default\" ng-class=\"config.btnClass\" ng-click=\"rule.calendarOpened = !rule.calendarOpened\"><i class=\"glyphicon glyphicon-calendar\"></i></button>\n" +
    "						    </span>\n" +
    "						</p>\n" +
    "\n" +
    "\n" +
    "						<button style=\"margin-left: 5px\" ng-click=\"removeCondition($index)\" class=\"btn btn-sm btn-danger\"  ng-class=\"config.btnClass\"ng-show=\"showRemRule()\">\n" +
    "							<span class=\"glyphicon glyphicon-remove\"></span>\n" +
    "						</button>\n" +
    "\n" +
    "\n" +
    "\n" +
    "					</div>\n" +
    "				</div>\n" +
    "			</div>\n" +
    "		</div>\n" +
    "	</div>\n" +
    "</div>");
}]);
