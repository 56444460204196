'use strict';

angular.module('vmaxmanagerApp')
.factory('entityAuditInterceptor', ['$q', '$injector', function($q, $injector) {
    var requestInterceptor = {
        request: function(config) {
            var deferred = $q.defer();
            // set createdBy | lastModifiedBy for all POST | PUT request
            if (config.method === 'POST' || config.method === 'PUT') {
                var obj = config.data;
                var Principal = $injector.get('Principal');
                Principal.identity().then(function(identity) {
                    if (obj !== undefined && obj !== null && !(typeof obj === 'string' || obj instanceof String)) {
                        if (config.method === 'POST') {
                             //When is create a new user, the identity = null in this case is the 'system' who register audit entity
                             if(identity==null){
                                 obj['createBy'] = 'system';
                             }else{
                                 obj['createdBy'] = identity.login;
                             }
                             obj['createdDate'] = new Date();
                        } else {
		                      if(identity==null){
		                              obj['lastModifiedBy'] = 'system';
		                          }else{
		                              obj['lastModifiedBy'] = identity.login;
		                          }
                            obj['lastModifiedDate'] = new Date();
                        }
                    }
                    deferred.resolve(config);
                }, function() {
                    deferred.resolve(config);
                });
            } else {
                deferred.resolve(config);
            }
            return deferred.promise;
        }
    };
    return requestInterceptor;
}]);
