(function() {
    'use strict';

    angular
        .module('vmaxmanagerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('download-resources-audit', {
            parent: 'entity',
            url: '/download-resources-audit',
            data: {
                authorities: ['ROLE_SYSTEM_ADMIN', 'ROLE_OPERATIONS_ADMIN', 'ROLE_OPERATIONS'],
                pageTitle: 'vmaxmanagerApp.downloadResourcesAudit.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/entities/downloadResourcesAudit/download-resources-audits.html',
                    controller: 'DownloadResourcesAuditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('downloadResourcesAudit');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            },
            ncyBreadcrumb: {
                label: 'vmaxmanagerApp.downloadResourcesAudit.home.title'
            }
        })
    }

})();
