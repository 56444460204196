'use strict';

angular.module('vmaxmanagerApp').controller('DeviceTypeDialogController',
    ['$scope', '$state', 'entity', 'DeviceType',
        function($scope, $state, entity, DeviceType) {

        $scope.deviceType = entity;
        $scope.load = function(id) {
            DeviceType.get({id : id}, function(result) {
                $scope.deviceType = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('vmaxmanagerApp:deviceTypeUpdate', result);
            $scope.isSaving = false;
            $state.go('deviceType');
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.deviceType.id != null) {
                DeviceType.update($scope.deviceType, onSaveSuccess, onSaveError);
            } else {
                DeviceType.save($scope.deviceType, onSaveSuccess, onSaveError);
            }
        };
}]);
