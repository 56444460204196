'use strict';

angular.module('vmaxmanagerApp').controller('UserManagementDialogController', ['$scope', '$state', 'entity', 'User', 'Language',
    function($scope, $state, entity, User, Language) {

        $scope.user = entity;

        //Variables for controller checkbox Roles
        $scope.roleSystemAdminSelected = false;
        $scope.roleOperationsAdminSelected = false;
        $scope.roleOperationsSelected = false;
        $scope.isEdit = false;

        if (!_.isEmpty($scope.user) && !_.isEmpty($scope.user.authorities)) {
            _.each($scope.user.authorities, function(role) {
                switch (role) {
                    case 'ROLE_SYSTEM_ADMIN':
                        $scope.roleSystemAdminSelected = true;
                        break;

                    case 'ROLE_OPERATIONS_ADMIN':
                        $scope.roleOperationsAdminSelected = true;
                        break;

                    case 'ROLE_OPERATIONS':
                        $scope.roleOperationsSelected = true;
                        break;

                    default:
                        break;
                }
            });
        }

        Language.getAll().then(function(languages) {
            $scope.languages = languages;
        });

        var onSaveSuccess = function(result) {
            $scope.isSaving = false;
            $state.go('user-management');
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
        };

        $scope.save = function() {
            $scope.isSaving = true;
            $scope.setAuthorities();
            $scope.user.login = $scope.user.login.toLowerCase();
            if ($scope.user.id != null) {
                User.update($scope.user, onSaveSuccess, onSaveError);
            } else {
                User.save($scope.user, onSaveSuccess, onSaveError);
            }
        };

        /** Assign the roles checked*/
        $scope.setAuthorities = function() {
            $scope.user.authorities = [];
            if ($scope.roleOperationsSelected) {
                $scope.user.authorities.push("ROLE_OPERATIONS");
            }
            if ($scope.roleSystemAdminSelected) {
                $scope.user.authorities.push("ROLE_SYSTEM_ADMIN");
            }
            if ($scope.roleOperationsAdminSelected) {
                $scope.user.authorities.push("ROLE_OPERATIONS_ADMIN");
            }
            if (!$scope.roleOperationsAdminSelected && !$scope.roleSystemAdminSelected && !$scope.roleSystemAdminSelected) {
                $scope.user.authorities.push("ROLE_OPERATIONS");
            }
        };
    }
]);